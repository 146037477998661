import React from "react";
import { ItemDocumentContainer } from "./item-document-style";
import { icons } from "../../assets/icons";
import { images } from "../../assets/images";
import FileUtils from "../../utils/files";
import { RequestFile, AttachedFile } from "../../screens/request-detail/types";

interface ItemDocumentProps {
  file: RequestFile | AttachedFile;
  canDelete?: boolean;
  onDelete?: Function;
  blob?: Blob;
}
interface ItemDocumentState {
  downloading: Boolean;
  finishDownload: Boolean;
}

export default class ItemDocument extends React.Component<ItemDocumentProps, ItemDocumentState> {
  state: ItemDocumentState = {
    downloading: false,
    finishDownload: false,
  };

  public static defaultProps = {
    canDelete: false,
  };

  componentDidMount() {
    console.log(this.props.file);
  }

  downloadDocument() {
    this.setState({ downloading: true });
    setTimeout(() => {
      this.setState({ downloading: false, finishDownload: true });
    }, 3000);
  }

  downloadFile = (blob: Blob, fileName: string) => {
    // var reader = new FileReader();
    // reader.onload = function (event: any) {
    //   let save: any = document.createElement("a");
    //   save.href = event.target.result;
    //   save.target = "_blank";
    //   save.download = fileName || "archivo.dat";
    //   const clicEvent = new MouseEvent("click", {
    //     view: window,
    //     bubbles: true,
    //     cancelable: true,
    //   });
    //   save.dispatchEvent(clicEvent);
    //   const Window: any = window;
    //   (Window.URL || Window.webkitURL).revokeObjectURL(save.href);
    // };
    // reader.readAsDataURL(blob);

    const url = window.URL.createObjectURL(blob);
    const link: HTMLAnchorElement | string = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  };

  render() {
    const { downloading, finishDownload } = this.state;
    const { file, canDelete, onDelete } = this.props;
    return (
      <ItemDocumentContainer className={`${downloading && "active"}`}>
        {canDelete && (
          <div
            className="close-icon"
            onClick={() => {
              if (onDelete) {
                onDelete();
              }
            }}
          >
            <img src={icons.IconUploadDelete} alt="" />
          </div>
        )}

        {this.props.file && this.props.file.uri ? (
          <a href={file.uri} className="link-container" download>
            <div className="item-document-icon" onClick={() => this.downloadDocument()}>
              {downloading ? (
                <img src={icons.IconDownload} alt="" />
              ) : (
                  <div>
                    <img src={finishDownload ? icons.IconDocumentLBlue : icons.IconDocumentBlue} alt="" />
                  </div>
                )}
            </div>
            <div className="item-document-progress">
              <div className="item-document-progress__fill" />
            </div>
            <div className="item-document-title">
              <p>{file.name}</p>
            </div>
            <div className="item-document-data">
              <p>{`${FileUtils.formatBytes(Number(file.size))}, ${file.creation_date}`}</p>
            </div>
          </a>
        ) : this.props.blob ? (
          <div className="link-container" onClick={() => this.downloadFile(this.props.blob, this.props.file.name)}>
            <div className="item-document-icon">
              <div>
                <img src={icons.IconDocumentBlue} alt="" />
              </div>
            </div>
            <div className="item-document-title">
              <p>{file.name}</p>
            </div>
            <div className="item-document-data">
              <p>{`${FileUtils.formatBytes(Number(file.size))}, ${file.creation_date}`}</p>
            </div>
          </div>
        ) : (
              <div className="link-container">
                <div className="item-document-icon">
                  <div>
                    <img src={icons.IconDocumentBlue} alt="" />
                  </div>
                </div>
                <div className="item-document-title">
                  <p>{file.name}</p>
                </div>
                <div className="item-document-data">
                  <p>{`${FileUtils.formatBytes(Number(file.size))}, ${file.creation_date}`}</p>
                </div>
              </div>
            )}
      </ItemDocumentContainer>
    );
  }
}
