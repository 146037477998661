import React, { Component } from "react";
import AsideNotificationLayout from "./aside-notification-layout";
import { withRouter, RouteComponentProps } from "react-router";
import Services from "../../../utils/services";
import { setExpiredToken, setNotifications } from "../../../redux/actions";
import moment from "moment";
import "moment/locale/es.js";
import { connect } from "react-redux";
import { isoStringToStringDate } from "../../../utils/date";
import { store } from "../../../redux/store";

export type TNotification =
  | "approved"
  | "rejected"
  | "new_messages"
  | "new_info";

export interface RequestNotification {
  id: string;
  creation_date: string;
  type: "comment" | "infoRequest";
  description: string;
  request_no: string;
  viewed: boolean;
  messageId?: string;
}

interface AsideNotificationFunctionalProps extends RouteComponentProps {
  onClose: Function;
  user?: any;
}

interface AsideNotificationFunctionalState {
  notifications: Array<RequestNotification>;
}

class AsideNotificationFunctional extends Component<
  AsideNotificationFunctionalProps,
  AsideNotificationFunctionalState
> {
  state: AsideNotificationFunctionalState = {
    notifications: []
  };

  componentDidMount() {
    this.getNotifications();
  }

  getShowDate(date: string) {
    if (moment(date).isSame(new Date(), "day")) {
      return moment(date).format("HH:mm");
    } else if (moment(date).isSame(new Date(), "year")) {
      return moment(date).format("DD MMM");
    } else {
      return moment(date).format("DD MMM YYYY");
    }
  }

  getNotifications(onFinish?: Function) {
    const {
      user: { insured }
    } = this.props;
    let endpoint = `insureds/${insured.id}/health/notifications`;

    Services.get({
      loader: true,
      endpoint,
      then: (response: any) => {
        if (response.data) {
          let notifications: Array<RequestNotification> = response.data.map(
            (item: any) => ({
              id: item.notificationId,
              creation_date: isoStringToStringDate(item.date, "/"),
              type: item.notificationType,
              description: item.notificationContent,
              request_no: item.accidentId,
              viewed: item.viewed,
              messageId: item.message || ""
            })
          );
          this.setState({ notifications }, () => {
            if (onFinish) {
              onFinish();
            }
          });
          store.dispatch(setNotifications(notifications));
        } else {
          this.setState({ notifications: [] }, () => {
            if (onFinish) {
              onFinish();
            }
          });
        }
      },
      catch: (err: any) => {
        if (err.response.status === 401) {
          Services.setValue(setExpiredToken(true));
        } else {
          console.log(err);
        }
      }
    });
  }

  goToNotification(notification: RequestNotification) {
    const endpoint = `notifications/${notification.id}/viewed`;
    const data = {
      viewed: true
    };

    Services.patch({
      loader: true,
      endpoint,
      data,
      then: (response: any) => {
        this.getNotifications(() => {
          this.props.onClose(() => {
            let url = `/solicitud-detalle/${notification.request_no}`;
            // if(notification.messageId) {

            //   url = `/solicitud-detalle/${notification.request_no}#message_${notification.messageId}`
            // }
            this.props.history.push("/");
            this.props.history.push(url);
            this.getNotifications();
          });
        });
      },
      catch: (err: any) => {
        if (err.response.status === 401) {
          Services.setValue(setExpiredToken(true));
        } else {
          console.log(err);
        }
      }
    });
  }

  render() {
    const { notifications } = this.state;
    return (
      <AsideNotificationLayout
        notifications={notifications}
        goToNotification={item => this.goToNotification(item)}
      />
    );
  }
}

export default connect((state: any) => ({ user: state.user.value }))(
  withRouter(AsideNotificationFunctional)
);
