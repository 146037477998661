import styled from "styled-components";

export const TooltipHooksContainer = styled.div`
  .Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }

  .Tooltip-Tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: inherit;
    background: #d3d3d3;
    font-size: 14px;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
    &:before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 6px;
        margin-left: calc(6px * -1);
    }
  }

  .Tooltip-Tip.top {
    top: calc(30px * -1);
    &:before {
        top: 100%;
        border-top-color: #d3d3d3;
    }
  }

  .Tooltip-Tip.right {
    left: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &:before {
        left: calc(6px * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: #d3d3d3;
    }
  }

  .Tooltip-Tip.bottom {
    bottom: calc(30px * -1);
    &:before {
        bottom: 100%;
        border-bottom-color: #d3d3d3;
    }
  }


  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &:before {
        left: auto;
        right: calc(6px * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: #d3d3d3;
    }
  }

`;
