import styled from "styled-components";

export const AddCardContainer = styled.div`
  width: 100%;
  border: 2px dashed #e9eef2;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "museo sans";
  padding: 18px;
  cursor: pointer;

  &.main {
    .add-card-button {
      background: #194e8b;
      p {
        color: white;
      }
      width: fit-content;
    }
  }

  .add-card-button {
    display: flex;
    align-items: center;
    padding: 10px 30px;
    background: rgba(190, 192, 196, 0.15);
    border-radius: 44px;
    width: 120px;
  }

  .add-card-holder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .add-card-icon {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 30px;
    }
  }

  .add-card-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #6b778c;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .add-card-icon {
      img {
        max-width: 40px;
      }
    }

    .add-card-holder {
      justify-content: center;
    }

    .add-card-text {
      max-width: 200px;
      font-size: 18px;
      line-height: 1.5em;
    }
  }

  @media (max-width: 440px) {
    padding: 24px 16px;

    .add-card-text {
      font-size: 16px;
      line-height: 1.35em;
    }
  }

  @media (max-width: 380px) {
    .add-card-text {
      max-width: 170px;
      font-size: 16px;
      line-height: 1.4em;
      font-weight: 600;
    }
  }
`;
