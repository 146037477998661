import styled, { css } from 'styled-components';
 

export const HeadsTitleTextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    
    ${props => css`
        color: ${props.color}
    `}




`;