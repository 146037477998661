import React, { Component } from "react";
import DocumentationDetailCardContainer from "./documentation-detail-card-style";
import { currencyFormat } from "../../../utils/text";
import { icons } from "../../../assets/icons";

interface DocumentationDetailCardProps {
  billProvider: string;
  billNumber: string;
  billDate: string;
  billPrice: string | object;
  billPriceType?: string | object;
  onClick?: (e?: any) => void;
  close?: (e?: any) => void;
}

class DocumentationDetailCard extends Component<DocumentationDetailCardProps> {
  constructor(props: DocumentationDetailCardProps) {
    super(props);
  }

  render() {
    const {
      billNumber,
      billDate,
      billPrice,
      onClick,
      close,
      billPriceType,
      billProvider
    } = this.props;
    return (
      <DocumentationDetailCardContainer onClick={e => onClick(e)}>
        {close && (
          <div className="documentation-detail-card-close-row">
            <div className="documentation-detail-card-close-row-item">
              <img src={icons.IconCloseCircle} alt="close" />
            </div>
          </div>
        )}

        <div className="documentation-detail-card-topbar-row">
          <div>
            <div className="main-detail-card-body-row-item-title">
              <p>MÉDICO</p>
            </div>
            <div className="documentation-detail-card-name-row">
              <p>{billProvider}</p>
            </div>
          </div>
          <div className="documentation-detail-card-icon">
            <img src={icons.IconEyeBlue} alt="" />
          </div>
        </div>

        <div className="bill-body-container">
          <div>
            <div className="main-detail-card-body-row-item-title">FACTURA</div>
            <div className="documentation-detail-card-number">
              <p>{billNumber}</p>
            </div>
          </div>
          {typeof billPrice !== "object" && typeof billPriceType !== "object" && (
            <div className="documentation-detail-card-price">
              <p>
                {currencyFormat(billPrice)}{" "}
                <span>{billPriceType ? billPriceType : "EUR"}</span>
              </p>
            </div>
          )}
        </div>
      </DocumentationDetailCardContainer>
    );
  }
}

export default DocumentationDetailCard;
