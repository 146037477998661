import styled from "styled-components";

export const FileItemModalContainer = styled.div`
  .message-answer-documents-item  {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &__icon {
      width: 42px;
      min-width: 42px;
    }

    &__text {
      padding: 0 16px 0 10px;
      font-size: 14px;
      line-height: 1.3em;
    }

    &__close {
      display: flex;
      align-items: center;
      min-width: 16px;
      width: 16px;
      &.active {
        cursor: pointer;
      }
    }

    .message-answer-documents-item__text__title {
      span {
        color: #6B778C;
      }
      &.width-buttons {
        max-width: 233px;
      }
    }

    .message-answer-documents-input {
      width: 335px;
    }

    .edit-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .flex-gap > * {
      margin: 8px 0 0 8px;
    }
    
    .flex-gap {
      display: inline-flex;
      margin: -8px 0 0 -8px;
    }

    &.fail {
      .message-answer-documents-item__text__title,
      .message-answer-documents-item__text__data {
        color: #ffa800;
        line-break: anywhere;
      }
    }

    &.error {
      .message-answer-documents-item__text__title,
      .message-answer-documents-item__text__data {
        color: #FF4040;
      }
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    .message-answer-documents-item__icons {
      display: flex;
      align-items: center;
      

      img {
        max-height: 24px;
        width: auto;
      }

      &-delete {
        cursor: pointer;
        display: flex;
      }
      
      &-status {
        position: relative;
        display: flex;
        align-items: center;
        cursor: default;

        .tooltip-text {
          display: none;
          width: 240px;
          max-width: 240px;
          background-color: #FFA800;
          color: white;
          text-align: center;
          padding: 5px 0;
          border-radius: 6px;
          padding: 10px
        
          position: absolute;
          z-index: 1;
          top: 130%;
          left: 50%;
          margin-left: -120px;
        }

        .tooltip-text::after {
          content: " ";
          position: absolute;
          bottom: 100%;  /* At the top of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #FFA800 transparent;
        }

        &.warning:hover .tooltip-text {
          display: block;
        }

      }

      &-warning-text {
        display: none;
        color: #FFA800;
        margin-right: 8px;

        &.active {
          display: block;
        }


      }

    }

    @media screen and (max-width: 550px) {
     .edit-buttons {
       flex-direction: column;
       p {
         font-size: 12px;
       }
     }

     .message-answer-documents-input {
        width: auto;
      } 
    }

  }
`;
