import React from "react";
import { ItemDocumentDownloadContainer } from "./item-document-download-style";
import { icons } from "../../assets/icons";
import { images } from "../../assets/images";
import FileUtils from "../../utils/files";
import { RequestFile, AttachedFile } from "../../screens/request-detail/types";
const fileType = require("file-type");

interface ItemDocumentDownloadProps {
  file: any;
  name: string;
  subtitle?: string;
  deleteButton?: () => void;
}
interface ItemDocumentDownloadState {
  downloading: Boolean;
  finishDownload: Boolean;
  file: any,
}

export default class ItemDocumentDownload extends React.Component<
  ItemDocumentDownloadProps,
  ItemDocumentDownloadState
> {
  state: ItemDocumentDownloadState = {
    downloading: false,
    finishDownload: false,
    file: null,
  };

  public static defaultProps = {
    canDelete: false,
  };

  componentDidMount() {
    const { file } = this.props;
    if(file.id) {
      const fileToBlob = this.decodeBase64(
        file.id,
        file.fileName,
      );
      fileToBlob['fileName'] = file.fileName;
      this.setState({ file: fileToBlob });
    }else  this.setState({ file });
  }

  downloadDocument() {
    this.setState({ downloading: true });
    setTimeout(() => {
      this.setState({ downloading: false, finishDownload: true });
    }, 3000);
  }

  decodeBase64 = (base64: string, fileName: string) => {
    const type = fileName.split(".")[1];
    // decode base64
    const fileContent = atob(base64);
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const fileBuffer = new ArrayBuffer(fileContent.length);
    const view = new Uint8Array(fileBuffer);

    // fill the view, using the decoded base64
    for (let index = 0; index < fileContent.length; index++) {
      view[index] = fileContent.charCodeAt(index);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([fileBuffer], { type: type });

    return blob;
  };

  downloadFile = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const link: HTMLAnchorElement | string = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  };

  render() {
    const { name, subtitle, deleteButton } = this.props;
    return (
      <ItemDocumentDownloadContainer>
        <div className="icon-container">
          <img src={icons.IconDocumentBlue} alt="" />
        </div>
        <div className="name-container">
          <p className="name-title">{name}</p>
          {subtitle && <p className="name-subtitle">{subtitle}</p>}
        </div>
        <div className="download-container" onClick={() => this.downloadFile(this.state.file, this.state.file.fileName)}>
          <img src={icons.IconDownload} alt="" />
        </div>
        {
          deleteButton &&
          (
            <div className="delete-container" onClick={deleteButton}>
              <img src={icons.IconUploadDelete} alt="delete" />
            </div>
          )
        }
   
      </ItemDocumentDownloadContainer>
    );
  }
}
