import React, { Component, useContext } from "react";
import SelectSearch from "../../../form-components/select-search/select-search";
import InputBox from "../../../form-components/input-box";
import AddInsuredFormContainer from "./add-insured-form-style";
import { getInsuredAccidents } from "../../../../services/insureds/get-insureds-accidents";
import { connect } from "react-redux";
import {
  getUserRequests,
  getUserNamesRequests
} from "../../../../services/requests/get-user-requests";
import { store } from "../../../../redux/store";
import { setLoading, setExpiredToken, setModalError } from "../../../../redux/actions";
import { isValidEmail } from "../../../../utils/validation";
import services from "../../../../utils/services";
import { icons } from "../../../../assets/icons";
import { IBankData, IDescriptionData } from "../../../../screens/create-request";

type StepsKey = "second" | "third" | "fourth";

interface SelectOptionType {
  label: string;
  value: string;
  secondLine?: string;
}

interface AddInsuredData {
  id: string;
  birthdate: string;
  dni: string;
  name: string;
  policyId: string;
}

interface AddInsuredProps {
  previousState?: AddInsuredFormState;
  user: any;
  submitStep: boolean;
  addData: (selectedInsured: AddInsuredData | IDescriptionData | IBankData | any) => void;
  saveState: (stte: AddInsuredFormState) => void;
}

export interface AddInsuredFormState {
  insureds: any[];
  insuredNames: SelectOptionType[];
  selectedInsured: any;
  formErrors: {
    email: string;
    phone: string;
  };
  formData: {
    email: string;
    phone: string;
  };
}

class AddInsuredForm extends Component<AddInsuredProps, AddInsuredFormState> {
  constructor(props: AddInsuredProps) {
    super(props);

    this.state = {
      insureds: [],
      insuredNames: [],
      selectedInsured: {},
      formData: {
        phone: "",
        email: ""
      },
      formErrors: {
        phone: "",
        email: ""
      }
    };
  }

  componentDidMount() {
    const { previousState } = this.props;
    if (previousState) this.setState(previousState);
    else this.getInsuredNames();
  }

  componentDidUpdate(prevProps) {
    const { submitStep } = this.props;
    if (submitStep && prevProps.submitStep !== submitStep) {
      this.submitForm();
    }
  }

  async submitForm() {
    const { addData, saveState } = this.props;
    const { formData, selectedInsured } = this.state;
    const isCorrect = await this.validateFields();
    if (isCorrect && selectedInsured.id && selectedInsured.dni) {
      saveState(this.state);
      addData({
        id: selectedInsured.id,
        birthdate: selectedInsured.birthDate,
        dni: selectedInsured.dni,
        name: `${selectedInsured.firstname} ${selectedInsured.lastname}`,
        firstname: selectedInsured.firstname,
        lastname: selectedInsured.lastname,
        policyId: selectedInsured.policyId,
        policyNumber: selectedInsured.policyNumber,
        policyOrder: selectedInsured.policyOrder,
        policyControl: selectedInsured.policyControl,
        policyProductCode: selectedInsured.policyProductCode,
        clientCode: selectedInsured.clientCode,
        phone: formData.phone,
        email: formData.email
      });
    }
  }

  getInsuredNames = async () => {
    let { insureds } = this.state;
    let insuredNames: SelectOptionType[] = [{ label: "", value: "", secondLine: "" }];
    const { insured } = this.props.user;
    const insuredsArray = [];
    try {
      const insuredsNamesResponse = await getUserNamesRequests({
        id: insured.id
      });
      insuredNames = [];
      let index = 0;
      for (const request of insuredsNamesResponse.data) {
        if(!request.void) {
          insuredsArray.push({
            ...request,
            id: `${index}`
          });
          if(request.policyProductDescription) {
            insuredNames.push({
              label: `${index}`,
              value: `${request.firstname} ${request.lastname}`,
              secondLine: `(${request.policyProductDescription})`
            });
          } else {
            insuredNames.push({
              label: `${index}`,
              value: `${request.firstname} ${request.lastname}`
            });
          }
          index = index + 1;
        }
      }
      index = 0;
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = services.errorHandler(err);
      console.error(formatError);
    }

    insureds = insuredsArray;
    store.dispatch(setLoading(false));
    this.setState({ insuredNames, insureds });
  };

  changeValue = (value: string, name: string) => {
    const { formData } = Object.assign(this.state);
    formData[name] = value;
    this.setState({ formData });
  };

  async validateFields() {
    const { formData } = this.state;
    const entries = Object.entries(formData);
    let isCorrect = true;
    for (let i = 0; i < entries.length; i++) {
      isCorrect =
        isCorrect && (await this.validateField(entries[i][1], entries[i][0]));
    }
    return isCorrect;
  }

  validateField = (value: string, name: string) => {
    let { formErrors, formData } = this.state;
    let errorText = "";

    formData[name] = value;

    if (!value) {
      errorText = "Campo vacío";
    } else if (name === "email" && !isValidEmail(value)) {
      errorText = "Email inválido";
    }

    formErrors = {
      ...formErrors,
      [name]: errorText
    };
    return new Promise<boolean>((res, rej) => {
      this.setState({ formErrors, formData }, () => res(errorText === ""));
    });
  };

  selectInsured = (name: string, value: string) => {
    const { insureds } = this.state;
    let { selectedInsured, formData, formErrors } = this.state;
    const insured = insureds.find(element => element.id === value);
    selectedInsured = insured;
    
    formData = {
      phone: insured.mobilePhoneNumber,
      email: insured.email
    };

    formErrors = {
      phone: "",
      email: ""
    };

    this.setState({ selectedInsured, formData, formErrors });
  };

  render() {
    const { insuredNames, selectedInsured } = this.state;
    const step = 0;
    return (
      <AddInsuredFormContainer>
        <div className="insured-selector">
          <SelectSearch
            name="insured"
            initialValue={{
              label: "Seleccione un asegurado/a...",
              value: "Seleccione un asegurado/a..."
            }}
            options={insuredNames}
            onChange={this.selectInsured}
            iconImg={icons.IconUserGrey}
            iconImgActive={icons.IconUserBlue}
          />
        </div>
        <div className="insured-info">
          <div className="insured-info-content">
            <div className="insured-info-field-row">
              <div className="insured-info-field">
                <InputBox
                  name="policy"
                  disabled
                  initialValue={`${
                    selectedInsured.policyNumber
                      ? selectedInsured.policyNumber
                      : " "
                    }`}
                  labelText="NÚMERO DE PÓLIZA"
                />
              </div>

              <div className="insured-info-field">
                <InputBox
                  name="nif"
                  disabled
                  initialValue={
                    selectedInsured && selectedInsured.id
                      ? `${selectedInsured.dni}`
                      : " "
                  }
                  labelText="NIF"
                />
              </div>
            </div>
            <div className="insured-info-field-row">
              <div className="insured-info-field">
                <InputBox
                  name="phone"
                  initialValue={this.state.formData.phone}
                  errorText={this.state.formErrors.phone}
                  labelText="TELÉFONO MÓVIL"
                  onChange={(name: string, value: string) =>
                    this.changeValue(value, "phone")
                  }
                  onBlur={e => this.validateField(e.target.value, "phone")}
                />
              </div>

              <div className="insured-info-field">
                <InputBox
                  name="email"
                  initialValue={this.state.formData.email}
                  errorText={this.state.formErrors.email}
                  labelText="CORREO ELECTRÓNICO"
                  onChange={(name: string, value: string) =>
                    this.changeValue(value, "email")
                  }
                  onBlur={e => this.validateField(e.target.value, "email")}
                />
              </div>
            </div>
          </div>
        </div>
      </AddInsuredFormContainer>
    );
  }
}

export default connect((state: any) => ({ user: state.user.value }))(
  AddInsuredForm
);
