interface RequestStatusType {
  pendingInfo: string;
  gracefulPayment: string;
  inProgress: string;
  accepted: string;
  paid: string;
  denied: string;
  notAcepted: string;
}

export const REQUEST_STATUS: RequestStatusType = {
  pendingInfo: "Aporta información adicional",
  gracefulPayment: "Pago graciable",
  inProgress: "En trámite",
  accepted: "Aceptado",
  paid: "Pagado",
  denied: "Anulado",
  notAcepted: "No aceptado"
};

export const REQUEST_STATUS_FILTERS: any = {
  pendingInfo: "Aporta información adicional",
  inProgress: "En trámite",
  accepted: "Aceptado",
  //paid: "Pagado",
  denied: "Anulado",
  notAcepted: "No aceptado"
};

