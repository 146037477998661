import styled from "styled-components";
import { CardRequestTypeContainer } from "../../components/cards/card-request-type/card-request-style";
import { MainButtonContainer } from "../../components/buttons/main-button/main-button-style";

export const CreateRequestContainer = styled.section`
  max-width: 1012px;
  margin: 0 auto;

  .create-request-topbar {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .create-request-title {
    font-family: "museo sans";
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    color: #bec0c4;
  }

  .create-request-steps-container {
    display: flex;
  }

  .create-request-steps-item {
    width: 26px;
    min-width: 26px;
    max-width: 26px;
    height: 26px;
    min-height: 26px;
    max-height: 26px;
    background: #ffffff;
    border-radius: 50%;
    margin-right: 36px;
    color: #80d8f4;
    border: 1px solid #80d8f4;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    p {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: -23px;
      transform: translateY(-50%);
      background: #01b2ea;
      border-radius: 50%;
      width: 5px;
      height: 5px;
    }

    &:last-child {
      margin-right: 0;
      &:after {
        content: "";
        display: none;
      }
    }

    &.active {
      background: #01b2ea;
      color: #ffffff;
      border: 1px solid #01b2ea;
    }
  }

  .create-request-description {
    font-family: "museo sans";
    font-size: 16px;
    line-height: 24px;
    color: #091e42;

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .create-request-step-titles {
    margin-bottom: 47px;

    .title {
      font-family: "museo Sans";
      font-size: 28px;
      line-height: 36px;
      font-weight: 600;
      color: #091e42;
      margin-bottom: 16px;
    }

    .subtitle {
      font-family: "museo sans";
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #091e42;
    }
  }

  .create-request-step-description {
    font-family: "museo sans";
    font-size: 16px;
    line-height: 24px;
    color: #091e42;
    padding-left: 72px;
    margin-bottom: 52px;
  }

  .insured-selector {
    max-width: 620px;
  }

  .create-request-step-content {
    margin-bottom: 74px;

    &.add-insured {
      max-width: 800px;
    }
  }

  .create-request-buttons {
    display: flex;
    align-items: center;
    padding-left: 72px;

    .btn-send {
      width: 240px;
      margin-right: 20px;
    }

    .btn-cancel {
      width: 240px;
    }
  }

  .card-step-container {
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(206, 206, 206, 0.15);
    border-radius: 4px;
  }

  .card-step-container-main {
    padding: 40px 40px 0;
    border-radius: 4px 4px 0 0;
  }

  .card-step-container-actions {
    padding: 20px 40px;
    border-top: 1px solid #e9eef2;
    border-radius: 0 0 4px 4px;

    ${MainButtonContainer} {
      text-transform: capitalize;
    }
  }

  .create-request-step-buttons-container {
    display: flex;
  }

  .create-request-step-buttons-item {
    margin-right: 12px;
    width: 100px;
    text-align: center;

    &:last-child {
      width: 215px;
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    .create-request-step-title {
      flex-direction: column;
      align-items: flex-start;
      .step {
        margin-bottom: 12px;
      }
    }
    .create-request-step-description {
      padding-left: 0px;
    }
    .create-request-step-content  {
      padding-left: 0px;
    }
    .create-request-buttons {
      padding-left: 0;
    }
  }

  @media (max-width: 440px) {
    .create-request-buttons {
      padding: 0;
    }

    .create-request-step-title {
      flex-direction: row;
      align-items: center;

      .step {
        min-width: 52px;
        font-size: 22px;
        margin: 0;
        margin-right: 16px;
      }

      .title {
        font-size: 24px;
        line-height: 1.3em;
        padding-top: ;
      }
    }

    .create-request-title {
      font-size: 26px;
    }
  }
`;

export const CreateRequestFormsContainer = styled.div`
  .create-request-form-item {
    margin-bottom: 120px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;
