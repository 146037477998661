import styled from "styled-components";

const CardBillInfoContainer = styled.div`
  font-family: "museo sans";
  border: 1px solid #091e42;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px 16px;
  cursor: pointer;
  position: relative;

  .card-bill-topbar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* margin-bottom: 10px; */
  }

  .bill-title {
    font-weight: 500;
    font-size: 12px;
    line-height: initial;
    color: #6b778c;
    margin-bottom: 6px;
  }

  .bill-title-2 {
    font-weight: 500;
    font-size: 12px;
    line-height: initial;
    color: #6b778c;
    margin-bottom: 3px;
  }

  .container-close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    img {
      max-width: 24px;
    }
  }

  .bill-name {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .bill-number {
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #091e42;
    margin-right: 30px;
  }

  .bill-price {
    font-size: 20px;
    color: #01b2ea;
    font-weight: 500;
    line-height: 26px;
    span {
      font-size: 12px;
    }
  }

  .bill-body-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media (max-width: 440px) {
    padding: 16px;
    .bill-title {
      margin-bottom: 22px;
      font-weight: 700;
      padding-right: 20px;
    }
  }
`;

export default CardBillInfoContainer;
