import styled from "styled-components";
import { LabelTextContainer } from "../../text/label-text/label-text-style";

export const TextareaBoxContainer = styled.div`
  &.show-tooltip {
    .textarea-box-topbar {
      z-index: 12;
    }

    .textarea-box-tooltip {
      display: block;
    }
  }

  &.error {
    .textarea-box-main-field {
      border-bottom: 1.5px solid #ff4100;
    }
  }

  &.disabled {
    pointer-events: none;

    .textarea-box-topbar-label {
      color: #b5bcc6;
    }

    .textarea-box-main-field {
      background: #f7f7f7;

      input {
        border-bottom: 1px solid #b5bcc6;
        color: #b5bcc6;
      }

      &:before {
        background: #b5bcc6;
      }
    }
  }

  .textarea-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 10px;
  }

  .textarea-box-topbar-label {
    font-size: 14px;
    text-transform: uppercase;
    color: #6b778c;
  }

  .textarea-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .textarea-box-main-field {
    position: relative;
  }

  textarea {
    font-family: "museo sans";
    width: 100%;
    font-weight: 300;
    transition: all 0.2s ease-in-out;
    border: none;
    font-size: 18px;
    line-height: 24px;
    color: #091e42;
    resize: none;
    outline: none;
    border-bottom: 1px solid #e2e5ea;
    height: auto;

    &::placeholder {
      color: #091e42;
    }

    &:focus {
      border-bottom: 1px solid #091e42;
    }
  }

  .textarea-box-main {
    position: relative;
  }

  .textarea-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .textarea-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    display: none;
    right: 0;
    display: none;
  }

  .textarea-box-error {
    color: #ff4100;
    margin-top: 4px;
  }

  @media (max-width: 900px) {
    textarea {
      font-size: 16px;
    }
  }

  @media (max-width: 600px) {
    .textarea-box-topbar {
      margin-bottom: 8;
    }

    .textarea-box-topbar-label {
      ${LabelTextContainer} {
        font-size: 12px;
      }
    }

    textarea {
      font-size: 14px;
      max-height: 60px;
      &:focus {
        max-height: none;
      }
    }
  }
`;
