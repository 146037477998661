import styled from "styled-components";

export const ModalFileValidationContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #ffffffaa;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1019;
  overflow: hidden;

  .space-between {
    flex-grow: 1;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .item-modal-message-container {
    max-width: 660px;
    padding: 44px 40px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.03);
    background: white;
    width: 80%;
    border-radius: 8px;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .item-modal-title {
    color: #004F8B;
    text-transform: uppercase;
    margin-bottom: 33px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }


  .item-modal-message-buttoms {
    display: flex;
    justify-content: space-between;
  }

  .item-modal-message-buttom {
    width: 278px;
  }

  .item-modal-files {
      .file-item {
        height: 42px;
        border-bottom: 0.5px solid #E2E5EA;
        padding-bottom: 15px;
        width: 100%;
        display: inline-table;
        margin-bottom: 20px;
      }

      .file-item:last-child {
        border: 0;
        margin-bottom: 46px
      }
  }


  @media (max-width: 550px) {
    background: #a7a7a7d6;
    .item-modal-message-container {
      width: 90%;
      margin: 0 auto;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    }
  }

  @media (max-width: 440px) {
    .item-modal-message-container {
      padding: 24px 16px;
    }
  }
`;