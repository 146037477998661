import { SERVICES_URL, SERVICES_KEY } from "../../env";
import axios from "axios";
import { store } from "../../redux/store";
import { setLoading } from "../../redux/actions";

interface GetDefaultBankParams {
  id: string;
  policyNumber: string;
  policyControl: string;
  policyOrder: number;
  policyProductCode: number;
  clientCode: number;
}

export const getDefaultBank = (params: GetDefaultBankParams) => {
  const endpoint = `insureds/bank`;
  store.dispatch(setLoading(true));
  return axios({
    method: "POST",
    url: `${SERVICES_URL}${endpoint}`,
    data: {
      policyNumber: params.policyNumber,
      policyControl: Number(params.policyControl),
      policyOrder: params.policyOrder,
      policyProductCode: params.policyProductCode,
      clientCode: params.clientCode
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};
