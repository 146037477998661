import React from "react";
import Services from "../../utils/services";
import { setExpiredToken, setLoading, setModalError } from "../../redux/actions";
import { iData, iError } from "./types";
import LoginLayout from "./login-layout";
import { authLogin } from "../../services/auth/auth-validate-token";
import { store } from "../../redux/store";

interface LoginProps {
  getUser: Function
}

interface LoginFunctionalState {
  activeIntro: boolean;
  data: iData;
  errors: iError;
  loginError: string;
}

class LoginFunctional extends React.Component<LoginProps, LoginFunctionalState> {

  state: LoginFunctionalState = {
    activeIntro: true,
    data: {
      email: '',
      password: ''
    },
    errors: {
      email: true,
      password: true
    },
    loginError: ''
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ activeIntro: false })
    }, 1500);
  }

  handleChange(name: string, value: string, error: boolean, submit?: boolean) {
    const { data, errors } = this.state;
    data[name] = value;
    errors[name] = error;
    this.setState({ data, errors }, () => {
      if (submit) this.handleLogin();
    })
  }

  handleLogin = async () => {
    const { errors, data } = this.state;
    let error: boolean = false
    Object.keys(errors).forEach((key: string) => {
      if (errors[key]) error = errors[key]
    });
    if (!error) {
      try {
        store.dispatch(setLoading(true));
        const response = await authLogin(data);

        response.data = response.data || {};
        response.data.insured = response.data.insured || {};
        localStorage.setItem('accessToken', response.data.token)
        const user = `${response.data.insured.firstname} ${response.data.insured.lastname}`
        const userID = response.data.id
        localStorage.setItem('userID', userID)
        this.props.getUser(userID);
        Services.setValue(setExpiredToken(false))

      }catch (err) {
       if(err && err.response && err.response.status && err.response.status === 404) {
          store.dispatch(setModalError({
            show: true,
            message: 'Te recordamos que para acceder a este espacio, es necesario que tengas una póliza de reembolso de gastos.',
            redirect: '',
            title:'¡Oops!',
            type: "error",
          }));
        } else {
          if (err && err.response && err.response.status && err.response.status === 401) {
            this.setState({ loginError: 'El usuario y/o contraseña introducida no es válida.' });
          }
          const formatError = Services.errorHandler(err);
           console.error(formatError);
        }

      } finally {
        store.dispatch(setLoading(false));
      }
    }
  }

  render() {
    const { activeIntro, loginError, errors } = this.state;
    return (
      <LoginLayout
        activeIntro={activeIntro}
        loginError={loginError}
        handleChange={(name: string, value: string, error: boolean, submit?: boolean) => this.handleChange(name, value, error, submit)}
        validationErrors={errors}
        handleLogin={this.handleLogin}
      />
    );
  }
}

export default LoginFunctional;
