import React, { Component } from "react";
import ModalBillInfoContainer from "./modal-bill-info-style";
import ItemDocument from "../../item-document";
import { icons } from "../../../assets/icons";
import {
  IReport,
  IInvoice
} from "../../../services/requests/create-user-request";
import { getDoc } from "../../../services/docs/get-doc";
import { store } from "../../../redux/store";
import { setLoading, setExpiredToken, setModalError } from "../../../redux/actions";
import { currencyFormat } from "../../../utils/text";
import { getSpecialitiesRequest } from "../../../services/info/get-specialities";
import { getRequestTypesRequest } from "../../../services/info/get-request-types";
import Services from "../../../utils/services";

const fileType = require("file-type");
var base64 = require("file-base64");

interface ModalBillInfoProps {
  invoiceData?: IInvoice;
  onClose: () => void;
}

interface ModalBillInfoState {
  docs: any[];
  reports: any[];
  invoiceDataDocs: any[];
  invoiceDataReports: any[];
  speciality: string;
  requestType: string;
}

class ModalBillInfo extends Component<ModalBillInfoProps, ModalBillInfoState> {
  state: ModalBillInfoState;

  constructor(props: ModalBillInfoProps) {
    super(props);

    this.state = {
      docs: [],
      reports: [],
      invoiceDataDocs: [],
      invoiceDataReports: [],
      speciality: "",
      requestType: ""
    };
  }

  componentDidMount() {
    document.body.classList.add("no-scroll");
    this.props.invoiceData.reports && this.getReports();
    this.props.invoiceData.documents && this.getDocs();
    this.getRequestType();
    this.props.invoiceData.speciality && this.getSpeciality();
  }
  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  // componentDidMount = () => {
  //   this.getDocs();
  // };
  decodeBase64 = (base64: string, fileName: string) => {
    try {
      const type = fileName.split(".")[1];
      // decode base64
      const fileContent = atob(base64);
      // create an ArrayBuffer and a view (as unsigned 8-bit)
      const fileBuffer = new ArrayBuffer(fileContent.length);
      const view = new Uint8Array(fileBuffer);

      // fill the view, using the decoded base64
      for (let index = 0; index < fileContent.length; index++) {
        view[index] = fileContent.charCodeAt(index);
      }

      // convert ArrayBuffer to Blob
      const blob = new Blob([fileBuffer], { type: type });
      return blob;
    } catch (err) {
      return null;
    }
  };

  downloadFile = (blob: Blob, fileName: string) => {
    var reader = new FileReader();
    reader.onload = function (event: any) {
      let save: any = document.createElement("a");
      save.href = event.target.result;
      save.target = "_blank";
      save.download = fileName || "archivo.dat";
      const clicEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
      });
      save.dispatchEvent(clicEvent);
      const Window: any = window;
      (Window.URL || Window.webkitURL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(blob);
  };

  getSpeciality = async () => {
    const { invoiceData } = this.props;
    try {
      const specialityRequest = await getSpecialitiesRequest();

      const specialities = specialityRequest.data;
      let { speciality } = this.state;
      for (let special of specialities) {
        let specialityKey = Object.keys(special)[0];
        if (special[specialityKey] === invoiceData.speciality) {
          speciality = specialityKey;
        }
      }

      this.setState({ speciality });
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
  };

  getRequestType = async () => {
    const { invoiceData } = this.props;
    try {
      const requestTypeRequest = await getRequestTypesRequest();

      const requestTypes = requestTypeRequest.data;
      let { requestType } = this.state;
      for (let request of requestTypes) {
        let requestKey = Object.keys(request)[0];
        if (request[requestKey] === invoiceData.assistanceType) {
          requestType = requestKey;
        }
      }
      this.setState({ requestType });
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
  };

  getDocs = async () => {
    const { invoiceData } = this.props;
    let { docs, invoiceDataDocs } = this.state;
    docs = [];
    invoiceDataDocs = [];
    for (let document of invoiceData.documents) {
      // String con el base 64
      let mimeInfo = fileType(Buffer.from(document.id, "base64"));
      if (mimeInfo) {
        const blobFile = this.decodeBase64(document.id, "Document." + mimeInfo.ext);
        docs.push(blobFile);
        invoiceDataDocs.push(document);
      } else {
        const blobFile = this.decodeBase64(document.id, document.fileName);
        docs.push(blobFile);
        invoiceDataDocs.push(document);
      }
      // this.downloadFile(blobFile, "Document." + mimeInfo.ext);
    }

    this.setState({ docs, invoiceDataDocs });
    store.dispatch(setLoading(false));
  };

  getReports = async () => {
    const { invoiceData } = this.props;
    let { reports, invoiceDataReports } = this.state;
    reports = [];
    invoiceDataReports = [];
    for (let report of invoiceData.reports) {
      // String con el base 64
      let mimeInfo = fileType(Buffer.from(report.id, "base64"));
      if (mimeInfo) {
        const blobFile = this.decodeBase64(report.id, "Document." + mimeInfo.ext);
        reports.push(blobFile);
        invoiceDataReports.push(report);
      } else {
        const blobFile = this.decodeBase64(report.id, report.fileName);
        reports.push(blobFile);
        invoiceDataReports.push(report);
      }
      // this.downloadFile(blobFile, "Document." + mimeInfo.ext);
    }

    this.setState({ reports, invoiceDataReports });
    store.dispatch(setLoading(false));
  };

  render() {
    const { invoiceData, onClose } = this.props;
    const { docs, invoiceDataDocs, invoiceDataReports } = this.state;
    return (
      <ModalBillInfoContainer>
        <div className="modal-bill-info-wrapper">
          <div className="modal-bill-info-topbar">
            <div className="title-container">
              <div className="title">
                <h2>Factura</h2>
              </div>
              <div className="close-icon" onClick={() => onClose()}>
                <img src={icons.closeModalBillInfo} alt="" />
              </div>
            </div>

            <div className="topbar-content">
              <div className="field">
                <div className="field-label">
                  <label>NÚMERO FACTURA</label>
                </div>
                <div className="field-data">
                  <p>{invoiceData.id}</p>
                </div>
              </div>

              <div className="field">
                <div className="field-label">
                  <label>FECHA DE LA FACTURA</label>
                </div>
                <div className="field-data">
                  <p>{invoiceData.date}</p>
                </div>
              </div>

              <div className="field">
                <div className="field-label">
                  <label>PAÍS</label>
                </div>
                <div className="field-data">
                  <p>
                    {invoiceData.assistancePlace === "S"
                      ? "España"
                      : "Extranjero"}
                  </p>
                </div>
              </div>

              <div className="prize-container">
                <div className="prize-label">
                  <p>Importe</p>
                </div>
                <div className="prize">
                  <p>
                    {currencyFormat(invoiceData.amount)}{" "}
                    <span>{invoiceData.currency || "EUR"}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-bill-info-row">
            <div className="title">
              <h2>DATOS DEL DOCTOR/CENTRO MÉDICO</h2>
            </div>
            <div className="row">
              <div className="field">
                <div className="field-label">
                  <label>NOMBRE DEL MÉDICO</label>
                </div>
                <div className="field-data">
                  <p>{invoiceData.doctorName}</p>
                </div>
              </div>
              <div className="field">
                <div className="field-label">
                  <label>NIF/CIF DE LA FACTURA</label>
                </div>
                <div className="field-data">
                  <p>{invoiceData.doctorId}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-bill-info-row">
            <div className="title">
              <h2>DESCRIPCIÓN DE LA ASISTENCIA</h2>
            </div>
            <div className="description-reason-container">
              <div className="description-reason-text">
                <p>
                  La asistencia prestada es consecuencia de un accidente de
                  tráfico, laboral, escolar, deportivo,...
                </p>
              </div>
              <div className="description-reason-type">
                <p>
                  {invoiceData.invoiceDescription &&
                    typeof invoiceData.invoiceDescription === "string"
                    ? "Sí"
                    : "No"}
                </p>
              </div>
            </div>
            {invoiceData.invoiceDescription &&
              typeof invoiceData.invoiceDescription === "string" && (
                <div className="description-user">
                  <p>{invoiceData.invoiceDescription}</p>
                </div>
              )}
          </div>

          <div className="modal-bill-info-row">
            <div className="title">
              <h2>TIPO DE ASISTENCIA</h2>
            </div>

            <div className="row">
              <div className="field">
                <div className="field-label">
                  <label>Asistencia prestada</label>
                </div>
                <div className="field-data">
                  <p>{this.state.requestType}</p>
                </div>
              </div>

              {invoiceData.speciality &&
                Object.keys(invoiceData.speciality).length > 0 && (
                  <div className="field">
                    <div className="field-label">
                      <label>ESPECIALIDAD</label>
                    </div>
                    <div className="field-data">
                      <p>{this.state.speciality}</p>
                    </div>
                  </div>
                )}
            </div>
            {invoiceData.first && invoiceData.assistanceType === "TYPE_O2" && (
              <div className="successive-treatment-message">
                <div className="successive-treatment-message__img">
                  <img src={icons.IconAlertBlue} alt="" />
                </div>
                <p>
                  Se trata de un expediente o pertenece a un tratamiento
                  sucesivo
                </p>
              </div>
            )}
          </div>

          {/* TODO: when we have the data */}
          {(docs.length !== 0 || this.state.reports.length !== 0) && (
            <div className="modal-bill-info-row">
              <div className="title">
                <h2>DOCUMENTACIÓN</h2>
              </div>

              {docs.length !== 0 && (
                <div className="field">
                  <div className="field-label">
                    <label>FACTURAS</label>
                  </div>
                  <ul className="bill-list">
                    {docs.map((doc: any, index: number) => {
                      if (doc) {
                        return (
                          <li className="bill-element">
                            <ItemDocument
                              file={{
                                name: invoiceDataDocs[index].fileName,
                                type: doc.type,
                                size: doc.size,
                                uri: "",
                                creation_date: invoiceDataDocs[index].date
                              }}
                              blob={doc}
                            />
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              )}

              {this.state.reports.length !== 0 && (
                <div className="field">
                  <div className="field-label">
                    <label>INFORMES O PREESCRIPCIÓN MÉDICA</label>
                  </div>
                  <ul className="bill-list">
                    {this.state.reports.map((report: any, index: number) => (
                      <li className="bill-element">
                        <ItemDocument
                          file={{
                            name: invoiceDataReports[index].fileName,
                            type: report.type,
                            size: report.size,
                            uri: "",
                            creation_date: invoiceDataReports[index].date
                          }}
                          blob={report}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* <div className="field">
            <div className="field-label">
              <label>INFORMES O PREESCRIPCIÓN MÉDICA</label>
            </div>
            <ul className="inform-list">
              <li className="inform-element" />
            </ul>
          </div> */}
            </div>
          )}
        </div>
      </ModalBillInfoContainer>
    );
  }
}

export default ModalBillInfo;
