import React, { Component } from "react";
import { InputBoxContainer } from "./input-box-style";
import { withRouter, RouteComponentProps } from "react-router";
import { images } from "../../../assets/images";
import TooltipBox from "../../util-components/tooltip-box";
import NoteText from "../../text/note-text";
import LabelText from "../../text/label-text";
import Cleave from "cleave.js/react";

interface InputBoxProps extends RouteComponentProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  labelText: string;
  errorText?: string;
  tooltipTitle?: string;
  tooltipText?: string | string[];
  onChange?: Function;
  onBlur?: Function;
  onKeyDown?: Function;
  value?: string;
  initialValue?: string;
  defaultValue?: string;
  withTooltip?: boolean;
  placeholderText?: string;
  type?: string;
  money?: any;
  name: string;
  cleaveOptions?: any;
  required?: boolean;
}

interface InputBoxState {
  prevInitialValue: string;
  focus: boolean;
  showTooltip: boolean;
  value: string;
}

class InputBox extends Component<InputBoxProps, {}> {
  state: InputBoxState = {
    prevInitialValue: "",
    focus: false,
    showTooltip: false,
    value: ""
  };

  public static defaultProps = {
    withTooltip: false,
    placeholderText: "Escribir aquí"
  };

  componentDidMount() {
    const { value } = this.state;
    const { initialValue, defaultValue } = this.props;
    if (!defaultValue && initialValue && value === "") {
      this.setState({ value: initialValue });
    }
    if (defaultValue) {
      this.setState({ value: defaultValue });
    }
    if (this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (
      initialValue &&
      initialValue !== value &&
      prevProps.initialValue !== initialValue
    ) {
      this.setState({ value: initialValue });
    }

    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChangeMoney = e => {
    const value = e.target.value
      .replace(",", "")
      .replace("-", "")
      .replace(/\./g, "");
    if (Number(value) || Number(value) === 0) {
      this.setState(
        {
          value: Number(value / 100).toLocaleString("es-ES", {
            minimumFractionDigits: Number(value) === 0 ? 0 : 2
          })
        },
        () => {
          this.props.onChange(this.state.value);
        }
      );
    } else {
      this.setState({ value: "0,00" }, () => {
        this.props.onChange(this.state.value);
      });
    }
  };

  onTooltipMouseLeave() {
    let { showTooltip } = this.state;
    showTooltip = false;
    this.setState({ showTooltip });
  }

  onTooltipMouseEnter() {
    let { showTooltip } = this.state;
    showTooltip = true;
    this.setState({ showTooltip });
  }

  render() {
    const {
      disabled,
      className,
      money,
      placeholderText,
      withTooltip,
      icon,
      type,
      iconDisabled,
      labelText,
      errorText,
      tooltipTitle,
      tooltipText,
      onChange,
      onBlur,
      onKeyDown,
      cleaveOptions,
      required
    } = this.props;
    const { showTooltip, value } = this.state;

    return (
      <InputBoxContainer
        className={`${className} ${showTooltip ? "show-tooltip" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>
              <p>
                {labelText}
                {required && <label className="mandatory-field"> * </label>}
              </p>
              
            </LabelText>
          </div>

          {withTooltip && (
            <div
              className="input-box-topbar-icon"
              onMouseEnter={ev => this.onTooltipMouseEnter()}
              onMouseLeave={ev => this.onTooltipMouseLeave()}
            >
              <img
                src={disabled ? images.IconInfoGrey : images.IconInfoGreen}
              />
              <div className="input-box-tooltip">
                <TooltipBox
                  tooltipTitle={tooltipTitle}
                  tooltipText={tooltipText}
                />
              </div>
            </div>
          )}
        </div>
        <div className="input-box-main">
          <div className="input-box-main-field">
            {!cleaveOptions && (
              <input
                type={type || "text"}
                value={value}
                onBlur={e => {
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
                onChange={e => {
                  if (onChange) {
                    if (money) return this.onChangeMoney(e);
                    this.setState({ value: e.target.value }, () =>
                      onChange(this.props.name, this.state.value)
                    );
                  } else {
                    this.setState({ value: e.target.value });
                  }
                }}
                placeholder={placeholderText}
                onKeyDown={(ev: any) => !disabled && onKeyDown && onKeyDown(ev)}
              />
            )}

            {cleaveOptions && (
              <Cleave
                type={type || "text"}
                value={value}
                onBlur={e => {
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
                onChange={e => {
                  if (onChange) {
                    if (money) return this.onChangeMoney(e);
                    this.setState({ value: e.target.value }, () =>
                      onChange(this.props.name, this.state.value)
                    );
                  } else {
                    this.setState({ value: e.target.value });
                  }
                }}
                placeholder={placeholderText}
                options={cleaveOptions}
                disabled={disabled}
              />
            )}
          </div>
          <div className="input-box-icon">
            <img src={disabled ? iconDisabled : icon} />
          </div>
        </div>
        {errorText && (
          <div className="input-box-error">
            <NoteText>
              <p>{errorText}</p>
            </NoteText>
          </div>
        )}
      </InputBoxContainer>
    );
  }
}

export default withRouter(InputBox);
