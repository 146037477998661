import React, { Component } from "react";
import { NotFoundContainer } from "./not-found-style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MainButton from "../../buttons/main-button";
import { images } from "../../../assets/images";
import GoBack from "../../go-back";

class NotFound extends Component<RouteComponentProps, {}> {
  render() {
    return (
      <NotFoundContainer>
        <div className="not-found-arrow">
          <GoBack img={images.IconArrowLeftGrey} text="Volver" />
        </div>

        <div className="not-found-content">
          <div className="not-found-item">
            <div className="not-found-item__title">
              <p>404</p>
            </div>
            <div className="not-found-item__content">
              <p>
                Algo no ha ido bien, inténtelo de nuevo en unos minutos. Si el problema persiste, póngase en contacto
                con nosotros en el 666 666 666 o en reembolso@asisa.es
              </p>
            </div>
            <div className="not-found-item__return">
              <MainButton type="grey" text="Volver" onClick={() => this.props.history.goBack()} />
            </div>
          </div>
          <div className="not-found-img">
            <img src={images.NoContent} alt="" />
          </div>
        </div>
      </NotFoundContainer>
    );
  }
}

export default withRouter(NotFound);
