import axios from "axios";
import { SERVICES_URL, SERVICES_KEY } from "../../env";

export const getRequestTypesRequest = () => {
  const endpoint = `info/assistance-types`;

  return axios({
    method: "GET",
    url: `${SERVICES_URL}${endpoint}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};
