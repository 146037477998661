import styled from "styled-components";

const PrivatePoliticsContainer = styled.section`
  display: flex;
  justify-content: center;

  .private-politics-wrapper {
    width: 90%;
    padding-top: 84px;
    margin-bottom: 200px;
  }

  .go-back {
    margin-bottom: 20px;
    img {
      width: 40px;
    }
  }

  .private-politics-title {
    font-family: "museo sans";
    font-size: 44px;
    line-height: 50px;
    color: #091e42;
    margin-bottom: 62px;
  }

  .private-politics-article {
    .article-title {
      font-family: "museo sans";
      font-size: 28px;
      line-height: 36px;
      color: #091e42;
      margin-bottom: 20px;
    }

    .article-content {
      font-family: "museo sans";
      font-size: 18px;
      line-height: 26px;
      color: #6b778c;
      text-align: justify;
      font-weight: 300;

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default PrivatePoliticsContainer;
