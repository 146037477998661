import React from "react";
import { NoContentContainer } from "./no-content-style";
import { images } from "../../../assets/images";
export default class NoContent extends React.Component {
  render() {
    return (
      <NoContentContainer>
        <div className="no-content-img">
          <img src={images.NoContent} alt="" />
        </div>

        <div className="no-content-text">
          <div className="no-content-text__title">
            <p>Ooops!</p>
          </div>
          <div className="no-content-text__text">
            <p>Su buzón de notificaciones está vacío.</p>
          </div>
        </div>
      </NoContentContainer>
    );
  }
}
