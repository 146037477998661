import styled from "styled-components";

export const NotFoundContainer = styled.div`
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: "museo sans";
  padding: 0 10%;

  .not-found-content  {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .not-found-arrow {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 80px;
    margin-bottom: 120px;
    &__item {
      display: flex;
      cursor: pointer;
    }
  }

  .not-found-item {
    color: #49abb8;
    max-width: 500px;
    margin-right: 200px;

    &__title {
      font-size: 120px;
      line-height: 128px;
      color: #6b778c;
      margin-bottom: 24px;
      font-weight: 700;
    }

    &__content {
      margin-bottom: 60px;
      font-size: 16px;
      line-height: 24px;
      color: #6b778c;
      font-weight: 300;
      width: 361px;
    }

    &__return {
      max-width: 206px;
    }
  }

  @media (max-width: 940px) {
    .not-found-item {
      margin: 0 auto;
    }

    .not-found-text {
      margin: 0;
    }

    .not-found-img {
      display: none;
    }
  }

  @media (max-width: 480px) {
    padding: 42px 5%;
    margin: 0 auto;

    .not-found-arrow {
      margin: 0;
      .go-back-container {
        margin-bottom: 24px;
      }
    }

    .not-found-content  {
      height: calc(100vh - 90px);
      display: flex;
      align-items: center;
    }

    .not-found-item {
      max-width: none;
      width: 100%;

      &__title {
        font-size: 108px;
        margin-bottom: 16px;
      }

      &__content {
        font-size: 14px;
        line-height: 1.75em;
        width: 100%;
        margin-bottom: 32px;
      }

    }
  }
`;
