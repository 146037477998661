/** Ejemplos, para clases y props
     <InputBox
className="error"
icon={images.IconCalendarGreen}
iconDisabled={images.IconCalendarGrey}
/>
<InputBox
disabled
iconDisabled={images.IconCalendarGrey}
/>
<InputBox
className="show-tooltip"
icon={images.IconCalendarGreen}
iconDisabled={images.IconCalendarGrey}
/> 
*/

import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import NoteText from "../../text/note-text";
import LabelText from "../../text/label-text";
import { InputProfileContainer } from "./input-profile-style";

interface InputProfileProps extends RouteComponentProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  labelText: string;
  errorText?: string;
  tooltipTitle?: string;
  tooltipText?: string;
  onChange?: Function;
  initialValue: string;
  editable?: boolean;
  noEditInputText?: string;
  editInputText?: string;
  text?: string;
  email?: string;
}

interface InputProfileState {
  prevInitialValue: string;
  focus: boolean;
  showTooltip: boolean;
  value: string;
}

class InputProfile extends Component<InputProfileProps, {}> {
  readonly state: InputProfileState;

  private toggleContainer: any;

  public static defaultProps = {
    editable: false,
    noEditInputText: "Texto no editable",
    editInputText: "Texto editable",
  };

  constructor(props: InputProfileProps) {
    super(props);
    this.state = {
      prevInitialValue: "",
      focus: false,
      showTooltip: false,
      value: "",
    };

    this.toggleContainer = React.createRef();
  }

  componentDidMount() {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (initialValue && value === "") {
      this.setState({ value: initialValue });
    }
  }

  componentDidUpdate() {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (initialValue && initialValue !== value) {
      this.setState({ value: initialValue });
    }
  }

  render() {
    const { disabled, className, icon, iconDisabled, labelText, errorText, text, email } = this.props;

    const { showTooltip, value, focus } = this.state;

    return (
      <InputProfileContainer
        className={`${className} ${showTooltip ? "show-tooltip" : ""} ${disabled ? "disabled" : ""}`}
      >
        <div className="input-profile-left" />
        <div className="input-profile-right">
          <div className="input-box-topbar">
            <div className="input-box-topbar-label">
              <LabelText>
                <p>{labelText}</p>
              </LabelText>
            </div>
          </div>
          <div className="input-box-main" onClick={() => this.setState({ focus: !focus })}>
            {email ?
              (<a href={`mailto:${email}`} className="input-box-main-field-email">{text}</a>)
              : (<div className="input-box-main-field">{text}</div>)}

            <div className="input-box-icon">
              <img src={disabled ? iconDisabled : icon} />
            </div>
          </div>
          {errorText && (
            <div className="input-box-error">
              <NoteText>
                <p>{errorText}</p>
              </NoteText>
            </div>
          )}
        </div>
      </InputProfileContainer>
    );
  }
}

export default withRouter(InputProfile);
