import React, { Component } from "react";
import { UploadDocsContainer } from "./upload-docs-style";
import { withRouter, RouteComponentProps } from "react-router";
import ItemDocument from "../../item-document";
import MainButton from "../../buttons/main-button";
import { images } from "../../../assets/images";
import ModalUploadFiles from "../../modals/modal-upload-files";
import { AttachedFile } from "../../../screens/request-detail/types";
import { icons } from "../../../assets/icons";
import FileUtils from "../../../utils/files";
import imageCompression from 'browser-image-compression';
import TooltipBox from "../../util-components/tooltip-box";

interface UploadDocsProps extends RouteComponentProps {
  title: string;
  maxLimitFiles?: number;
  minLimitFiles?: number;
  onConfirm?: Function;
  files?: any[];
  totalFilesSize?: number;
  bodyInfoText?: string;
  checkIfDocumentsExist: Function;
  required?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string | string[];
}

interface UploadDocsState {
  files: any[];
  showUploadModal: boolean;
  errorMaxLimit: boolean;
  errorMinLimit: boolean;
  showTooltip: boolean;
}

const imageCompressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
}

class UploadDocs extends Component<UploadDocsProps, UploadDocsState> {
  public static defaultProps = {};

  state: UploadDocsState = {
    files: [],
    showUploadModal: false,
    errorMaxLimit: false,
    errorMinLimit: false,
    showTooltip: false
  };

  componentDidUpdate(prevprops: UploadDocsProps) {
    if (prevprops.files !== this.props.files && this.props.files.length !== 0) {
      this.setState({ files: this.props.files });
    }
  }

  async checkFilesAmount(newFiles: Array<AttachedFile>) {
    const { maxLimitFiles, minLimitFiles, onConfirm, checkIfDocumentsExist } = this.props;
    const compressFiles = ['png', 'jpg', 'jpeg'];
    const { files } = this.state;

    const newFilesChecked : Array<AttachedFile> = checkIfDocumentsExist(newFiles)
    if(newFilesChecked.length === 0) return

    const auxArray = await Promise.all (newFilesChecked.map(async (file) => {
      if(file.data) {
        if (compressFiles.includes(FileUtils.getExtension(file.name).toLowerCase())) {
          const compressedFile = await imageCompression(file.data, imageCompressionOptions);
          compressedFile['creation_date'] = file.creation_date;
          return compressedFile
        }else return file
        
      }else return file
    }));
    const auxFiles = auxArray.concat(files);
    let error = { max: false, min: false };
    if (maxLimitFiles && auxFiles.length > maxLimitFiles) {
      error.max = true;
    }
    if (minLimitFiles && auxFiles.length < minLimitFiles) {
      error.min = true;
    }

    this.setState({
      files: auxFiles,
      errorMaxLimit: error.max,
      errorMinLimit: error.min
    });

    if (onConfirm) onConfirm(auxFiles);
  }

  removeFile(item: any) {
    const { onConfirm } = this.props;
    const { files } = this.state;
    let tempFiles = files;

    tempFiles = tempFiles.filter(e => e !== item);
    this.setState({ files: tempFiles }, () => {
      onConfirm(tempFiles);
    });
  }

  onTooltipMouseLeave() {
    let { showTooltip } = this.state;
    showTooltip = false;
    this.setState({ showTooltip });
  }

  onTooltipMouseEnter() {
    let { showTooltip } = this.state;
    showTooltip = true;
    this.setState({ showTooltip });
  }

  render() {
    const { title, maxLimitFiles, minLimitFiles, totalFilesSize, bodyInfoText, required, withTooltip , tooltipTitle, tooltipText } = this.props;
    const { files, showUploadModal, errorMaxLimit, errorMinLimit, showTooltip } = this.state;

    return (
      <UploadDocsContainer>
        <div className="upload-docs-container">
          <div className="docs-container">
            <div className="docs-top">
              <div className="docs-title">
                <p className="p-inline">
                  {title}
                  {required && <label className="mandatory-field"> * </label>}
                </p>
                { withTooltip && (
                  <div className="input-box-topbar-icon" onMouseEnter={ev => this.onTooltipMouseEnter()} onMouseLeave={ev => this.onTooltipMouseLeave()}>
                    <img src={images.IconInfoGreen} />
                    { showTooltip && (
                      <div className="input-box-tooltip">
                        <TooltipBox tooltipTitle={tooltipTitle} tooltipText={tooltipText}/>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="docs-button">
                <MainButton
                  type="upload"
                  icon={true}
                  iconImg={icons.IconUploadBlue}
                  text="Añadir"
                  onClick={() => this.setState({ showUploadModal: true })}
                />
              </div>
            </div>
            <div className="docs-content">
              {this.props.files.length !== 0 ? (
                files.map((file: any, index: number) => (
                  <ItemDocument
                    key={`${index}-${file.name}`}
                    file={file}
                    canDelete={true}
                    onDelete={() => this.removeFile(file)}
                  />
                ))
              ) : files.length <= 0 ? (
                <div className="no-docs">
                  <p>
                    Puedes añadir varios documentos correspondientes a una única
                    factura.
                  </p>
                  <br />
                  <p>
                    {bodyInfoText ? bodyInfoText : 'ASISA se reserva el derecho de solicitarte los originales de las facturas'}
                  </p>
                </div>
              ) : (
                    files.map((item, i) => (
                      <ItemDocument
                        key={`${i}-${item.name}`}
                        file={item}
                        canDelete={true}
                        onDelete={() => this.removeFile(item)}
                      />
                    ))
                  )}
            </div>
            {errorMaxLimit && (
              <div className="error-limit-files">
                <p>ERROR! No puedes subir más de {maxLimitFiles} archivo/s.</p>
              </div>
            )}
            {errorMinLimit && (
              <div className="error-limit-files">
                <p>
                  ERROR! Es obligatorio subir como mínimo {minLimitFiles}{" "}
                  archivo/s.
                </p>
              </div>
            )}
          </div>
        </div>
        {showUploadModal && (
          <ModalUploadFiles
            totalFilesSize={totalFilesSize}
            showModal={showUploadModal}
            onClose={() => this.setState({ showUploadModal: false })}
            onConfirm={files =>
              this.setState({ showUploadModal: false }, () => {
                this.checkFilesAmount(files);
              })
            }
          />
        )}
      </UploadDocsContainer>
    );
  }
}

export default withRouter(UploadDocs);
