import React, { Component } from "react";
import DetailRequestContainer from "./detail-request-style";
import DocumentationDetailCard from "../../components/cards/documentation-detail-card/documentation-detail-card";
import CardMessage from "../../components/cards/card-message/card-message";
import {
  GetRequestResponse,
  getRequest
} from "../../services/requests/get-request";
import { withRouter, RouteComponentProps } from "react-router";
import Services from "../../utils/services";
import {
  getRequestMessages,
  GetRequestMessagesResponse,
  IMessage
} from "../../services/messages/get-request-messages";
import { setLoading, setExpiredToken, setModalError } from "../../redux/actions";
import { store } from "../../redux/store";
import { isoStringToStringDate } from "../../utils/date";
import ModalBillInfo from "../../components/modals/modal-bill-info/modal-bill-info";
import { sendRequestMessages } from "../../services/messages/send-request-messages";
import { IInvoice } from "../../services/requests/create-user-request";
import { icons } from "../../assets/icons";
import anime from "animejs";
import { REQUEST_STATUS } from "../../constants/request-status";

interface MatchParams {
  id: string;
}

interface DetailRequestProps extends RouteComponentProps<MatchParams> { }

interface DetailRequestState {
  accidentId: string;
  showModalBillInfo: boolean;
  selectInvoiceIndex: number;
  detailRequestData: GetRequestResponse;
  requestMessages: IMessage[];
  requestMessagesWithChild: IMessage[];
  requestMessagesWithoutChild: IMessage[];
  isRequestMessagesWithChild: boolean;
  focus: any;
  selectedIdMessage: string;
  accordionAssistance: boolean;
  accordionDocumentation: boolean;
  accordionActivity: boolean;
}

class DetailRequest extends Component<DetailRequestProps, DetailRequestState> {
  state: DetailRequestState;
  messageRef: any = React.createRef();
  constructor(props: DetailRequestProps) {
    super(props);

    const url = window.location.href;
    const isMessageId = url.indexOf("#") !== -1;

    const urlMessageId = isMessageId
      ? url
        .split("#")[1]
        .replace("message_", "")
        .substr(0, 2)
      : "";

    this.state = {
      accidentId: "",
      accordionAssistance: true,
      accordionDocumentation: true,
      accordionActivity: true,
      showModalBillInfo: false,
      selectInvoiceIndex: -1,
      selectedIdMessage: urlMessageId,
      detailRequestData: {
        accidentId: "",
        policyId: "",
        policyControl: 0,
        policyOrder: 0,
        insuredPhone: "",
        insuredMail: "",
        bankId: "",
        bankPlace: "",
        bankControl: "",
        bankAccount: "",
        bankInsuredName: "",
        bankInsuredNif: "",
        accidentType: "",
        accidentDescription: "",
        accidentDate: "",
        accidentStatus: "",
        accidentStatusDescription: "",
        accidentAmount: 0,
        currency: "EUR",
        insuredId: 0,
        insuredFullName: "",
        insuredNif: "",
        policyNumber: "",
        invoices: [
          {
            id: "",
            assistanceType: "",
            speciality: "",
            assistancePlace: "S",
            doctorId: "",
            doctorName: "",
            amount: 0,
            currency: "EUR",
            date: "",
            first: false,
            reports: []
          }
        ],
        reports: [],
        infoAccidents: [
          {
            infoAccidentId: 0,
            asisaComments: "",
            insuredComments: "",
            infoAccidentDate: "",
            responseDt: "",
            status: ""
          }
        ]
      },
      requestMessages: [],
      requestMessagesWithChild: [],
      requestMessagesWithoutChild: [],
      isRequestMessagesWithChild: true,
      focus: -1
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ accidentId: id }, () => this.getData());
  }

  componentDidUpdate() {
    const { accidentId } = this.state;
    const { id } = this.props.match.params;
    if (id !== accidentId) {
      this.setState({ accidentId: id }, () => this.getData());
    }
  }

  getData() {
    this.getRequestData().then(() => {
      if (this.state.selectedIdMessage) {
        const topScroll = this.messageRef.current.offsetTop;
        this.transitionScroll(topScroll, 1000);
      }
    });
  }

  transitionScroll = (ends: number, time: number) => {
    const scrollElement =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement;

    anime({
      targets: scrollElement,
      scrollTop: ends - 50,
      duration: time,
      easing: "easeInOutQuad"
    });
  };

  changeShowModalBillInfo = (show: boolean) => {
    this.setState({ showModalBillInfo: show });
  };

  selectInvoice = (index: number) => {
    this.setState({ selectInvoiceIndex: index, showModalBillInfo: true });
  };

  getRequestData = async () => {
    const { id } = this.props.match.params;
    try {
      const requestData = await getRequest({ id });
      const requestMessagesData = await getRequestMessages({ id });
      //COPIO LA DATA EN DOS VARIABLES PARA EDITARLAS

      const messages: any[] = JSON.parse(
        JSON.stringify(requestMessagesData.data)
      );
      //RECORRO TODOS LOS MENSAJES PARA VER CUAL SON HIJOS Y LOS VOY METIENDO EN EL PADRE Y ELIMINANDO
      // messages.forEach((message: any) => {
      //   const index = messagesAux.findIndex((i: any) => i.child === message.id);
      //   if (index > -1) {
      //     messagesAux[index].children = JSON.parse(JSON.stringify(message));
      //     const indexRemove = messagesAux.findIndex((i: any) => i.id === message.id);
      //     if (indexRemove > -1) {
      //       messagesAux.splice(indexRemove, 1);
      //     }
      //   }
      // });
      const requestMessagesWithChilds = messages.filter(message => message.child.length > 0)
      this.setState({
        detailRequestData: requestData.data,
        requestMessages: messages,
        requestMessagesWithChild: messages.filter(message => message.child.length > 0),
        requestMessagesWithoutChild: messages.filter(message => message.child.length === 0),
        isRequestMessagesWithChild: requestMessagesWithChilds.length > 0
      }, () => store.dispatch(setLoading(false)));
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
  };

  sendMessage = async (data, id, index) => {
    try {
      store.dispatch(setLoading(true));
      await sendRequestMessages({ id, data });
      const { requestMessages } = this.state;
      requestMessages[index].child = [data];
      this.setState({ requestMessages, focus: "" });
      store.dispatch(setLoading(false));
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    };
  }

  render() {
    const {
      detailRequestData,
      requestMessages,
      showModalBillInfo,
      selectInvoiceIndex,
      focus,
      accordionActivity,
      accordionAssistance,
      accordionDocumentation,
      requestMessagesWithoutChild,
      isRequestMessagesWithChild,
    } = this.state;
    const bankNumber = `****${detailRequestData.bankAccount}`;    
    return (
      <DetailRequestContainer>
        {/* breadcrumbs */}
        <div className="detail-breadcrumbs">
          <ul className="breadcrumbs-container">
            <li className="breadcrumbs-item">
              <a href="/">Solicitudes</a>
            </li>
            <li className="breadcrumbs-item">
              <p>Detalle de solicitud</p>
            </li>
          </ul>
        </div>

        {/* info principal detalle */}
        <div className="main-detail-card">
          <div className="main-detail-card-topbar">
            <div className="main-detail-card-topbar-item">
              <div className="main-detail-card-topbar-title">
                <p>{detailRequestData.insuredFullName}</p>
              </div>
            </div>
            <div className="main-detail-card-topbar-item">
              <div className="main-detail-card-topbar-info">
                <div className="main-detail-card-topbar-info-row">
                  <div className="main-detail-card-topbar-info-row-date">
                    <p>
                      {isoStringToStringDate(
                        detailRequestData.accidentDate,
                        "/"
                      )}
                    </p>
                  </div>
                  <div
                    className={`main-detail-card-topbar-info-row-status ${
                      detailRequestData.accidentStatus
                      }`}
                  >
                    <p>{REQUEST_STATUS[detailRequestData.accidentStatus]}</p>
                  </div>
                </div>
                <div className="main-detail-card-topbar-info-row">
                  <div className="main-detail-card-topbar-info-row-request">
                    <p>
                      Solicitud Nº <span>{detailRequestData.accidentId}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-detail-card-body">
            <div className="main-detail-card-body-row main-detail-card-body-row__holder">
              <div className="main-detail-card-body-row-container">
                <div className="main-detail-card-body-row-item">
                  <div className="main-detail-card-body-row-item-title">
                    <p>Nº póliza</p>
                  </div>
                  <div className="main-detail-card-body-row-item-content">
                    <p>{detailRequestData.policyNumber}</p>
                  </div>
                </div>
                <div className="main-detail-card-body-row-item">
                  <div className="main-detail-card-body-row-item-title">
                    <p>Dni</p>
                  </div>
                  <div className="main-detail-card-body-row-item-content">
                    <p>{detailRequestData.insuredNif}</p>
                  </div>
                </div>
              </div>
              <div className="main-detail-card-body-row-container">
                <div className="main-detail-card-body-row-item">
                  <div className="main-detail-card-body-row-item-title">
                    <p>Tel. móvil</p>
                  </div>
                  <div className="main-detail-card-body-row-item-content">
                    <p>
                      {typeof detailRequestData.insuredPhone !== "string"
                        ? "-"
                        : detailRequestData.insuredPhone}
                    </p>
                  </div>
                </div>
                <div className="main-detail-card-body-row-item">
                  <div className="main-detail-card-body-row-item-title">
                    <p>Email</p>
                  </div>
                  <div className="main-detail-card-body-row-item-content">
                    <a
                      href={
                        typeof detailRequestData.insuredMail === "string"
                          ? `mailto:${detailRequestData.insuredMail}`
                          : ""
                      }
                      className="main-detail-card-body-row-item-content_email"
                    >
                      {typeof detailRequestData.insuredMail !== "string"
                        ? "-"
                        : detailRequestData.insuredMail}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-detail-card-body-row">
              <div className="main-detail-card-body-row-title">
                <p>Datos Bancarios</p>
              </div>
              <div className="main-detail-card-body-row-container">
                <div className="main-detail-card-body-row-item">
                  <div className="main-detail-card-body-row-item-title">
                    <p>Nombre del titular</p>
                  </div>
                  <div className="main-detail-card-body-row-item-content">
                    <p>{detailRequestData.bankInsuredName}</p>
                  </div>
                </div>
                <div className="main-detail-card-body-row-item">
                  <div className="main-detail-card-body-row-item-title">
                    <p>NIF</p>
                  </div>
                  <div className="main-detail-card-body-row-item-content">
                    <p>{detailRequestData.bankInsuredNif}</p>
                  </div>
                </div>
                <div className="main-detail-card-body-row-item">
                  <div className="main-detail-card-body-row-item-title">
                    <p>IBAN</p>
                  </div>
                  <div className="main-detail-card-body-row-item-content">
                    <p>{`${detailRequestData.bankId} ${
                      detailRequestData.bankPlace
                      } ${detailRequestData.bankControl} ${
                      detailRequestData.bankAccount
                      }`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* acordeon asistencia */}
        <div
          className={`accordion-container ${accordionAssistance ? "" : "open"}`}
        >
          <div
            className="accordion-topbar"
            onClick={() =>
              this.setState({ accordionAssistance: !accordionAssistance })
            }
          >
            <div className="accordion-topbar-container">
              <div className="accordion-topbar-item">
                <p>Asistencia derivada de un accidente</p>
              </div>
              <div className="accordion-topbar-item accordion-topbar-item__img">
                <img src={icons.IconArrowDownBlue} alt="" />
              </div>
            </div>
          </div>
          <div
            className={`accordion-content ${
              accordionAssistance ? "collapse" : ""
              }`}
          >
            <div className="accordion-content-type">
              <div className="accordion-content-description-type-icon">
                <img
                  src={
                    detailRequestData.accidentDescription
                      ? icons.yesAssistance
                      : icons.notAssistance
                  }
                  alt=""
                />
              </div>
              <div className="accordion-content-description-type-text">
                {detailRequestData.accidentDescription ? (
                  <p className="success">La asistencia fue por accidente</p>
                ) : (
                    <p>La asistencia no fue por accidente</p>
                  )}
              </div>
            </div>
            {detailRequestData.accidentDescription && (
              <div className="accordion-content-description">
                <div className="accordion-content-description-title">
                  <p>Descripción de la asistencia</p>
                </div>
                <div className="accordion-content-description-main">
                  <p>{detailRequestData.accidentDescription}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* acordeon documentacion */}
        <div
          className={`accordion-container ${
            accordionDocumentation ? "" : "open"
            }`}
        >
          <div
            className="accordion-topbar"
            onClick={() =>
              this.setState({ accordionDocumentation: !accordionDocumentation })
            }
          >
            <div className="accordion-topbar-container">
              <div className="accordion-topbar-item">
                <p>Documentación {`(${detailRequestData.invoices.length})`}</p>
              </div>
              <div className="accordion-topbar-item accordion-topbar-item__img">
                <img src={icons.IconArrowDownBlue} alt="" />
              </div>
            </div>
          </div>
          <div
            className={`accordion-content ${
              accordionDocumentation ? "collapse" : ""
              }`}
          >
            <ul className="bill-list">
              {detailRequestData.invoices &&
                detailRequestData.invoices.map(
                  (invoice: IInvoice, index: number) => (
                    <li className="bill-element">
                      <DocumentationDetailCard
                        billProvider={invoice.doctorName}
                        billDate={invoice.date}
                        billNumber={invoice.id}
                        billPrice={`${invoice.amount}`}
                        billPriceType={invoice.currency}
                        onClick={() => this.selectInvoice(index)}
                      />
                    </li>
                  )
                )}
            </ul>
          </div>
        </div>

        {/* acordeon Actividad */}
        <div
          className={`accordion-container ${(accordionActivity && isRequestMessagesWithChild) ? "" : "open"}`}
        >
          <div
            className="accordion-topbar"
            onClick={() =>
              this.setState({ accordionActivity: !accordionActivity, isRequestMessagesWithChild: true })
            }
          >
            <div className="accordion-topbar-container">
              <div className="accordion-topbar-item with-icon">
                <p>Actividad {`(${requestMessages.length}`}</p>
                <img src={icons.IconEmailClosedBlue} />
                <p>)</p>
              </div>
              {
                requestMessagesWithoutChild.length > 0 && (
                  <div className="messages-without-request">
                  <span>{` ${requestMessagesWithoutChild.length} `}</span>mensaje(s) sin responder
                </div>
                )
              }
              <div className="accordion-topbar-item accordion-topbar-item__img">
                <img src={icons.IconArrowDownBlue} alt="" />
              </div>
            </div>
          </div>
          <div
            className={`accordion-content ${
              (accordionActivity && isRequestMessagesWithChild) ? "collapse" : ""
              }`}
          >
            <ul className="chat-list">
              {requestMessages &&
                requestMessages.map((message: IMessage, index: number) => {
                  return Number(this.state.selectedIdMessage) === message.id ? (
                    <li
                      ref={this.messageRef}
                      className="chat-element"
                      onClick={() => this.setState({ focus: message.id })}
                    >
                      <CardMessage
                        date={isoStringToStringDate(message.createdAt, "/")}
                        messageId={message.id}
                        isNew={message.isViewed}
                        isFocus={focus === message.id}
                        userName={``}
                        message={message.body}
                        placeholderText={'¿Quieres indicar algún comentario sobre el documento?'}
                        responseMessage={
                          message.child ? message.child.body : ""
                        }
                        responseDocs={
                          message.child && message.child.fileId
                            ? [message.child.fileId]
                            : []
                        }
                        onClick={(data: any) =>
                          this.sendMessage(data, message.id, index)
                        }
                      />
                    </li>
                  ) : (
                      <li
                        className="chat-element"
                        onClick={() => this.setState({ focus: message.id })}
                      >
                        <CardMessage
                          messageId={message.id}
                          date={isoStringToStringDate(message.createdAt, "/")}
                          isNew={!message.isViewed}
                          isFocus={focus === message.id}
                          userName={``}
                          message={message.body}
                          placeholderText={'¿Quieres indicar algún comentario sobre el documento?'}
                          responseMessage={
                            message.child && message.child[0] ? message.child[0].body : ""
                          }
                          responseDocs={
                            message.child && message.child[0]
                              ? message.child[0].files
                              : []
                          }
                          onClick={(data: any) =>
                            this.sendMessage(data, message.id, index)
                          }
                        />
                      </li>
                    );
                })}
            </ul>
          </div>
        </div>

        {showModalBillInfo && (
          <ModalBillInfo
            invoiceData={
              selectInvoiceIndex !== -1 &&
              detailRequestData.invoices[selectInvoiceIndex]
            }
            onClose={() => this.changeShowModalBillInfo(false)}
          />
        )}
      </DetailRequestContainer>
    );
  }
}

export default withRouter(DetailRequest);
