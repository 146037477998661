import React, { Component } from "react";
import CardBillInfoContainer from "./card-bill-info-style";
import { currencyFormat } from "../../../utils/text";
import { icons } from "../../../assets/icons";

interface CardBillInfoProps {
  billProvider: string;
  billNumber: string;
  billDate: string;
  billPrice: string;
  billPriceType?: string;
  onClick?: (e?: any) => void;
  close?: (e?: any) => void;
}

class CardBillInfo extends Component<CardBillInfoProps> {
  constructor(props: CardBillInfoProps) {
    super(props);
  }

  render() {
    const {
      billNumber,
      billDate,
      billPrice,
      onClick,
      close,
      billPriceType,
      billProvider
    } = this.props;
    return (
      <CardBillInfoContainer onClick={e => onClick(e)}>
        <div className="card-bill-topbar">
          <div />
          {close && (
            <div className="container-close" onClick={e => close(e)}>
              {<img src={icons.IconCloseCircle} alt="close" />}
            </div>
          )}
        </div>
        <div className="bill-title">
          <p>MÉDICO</p>
        </div>
        <div className="bill-name">
          <p>{billProvider}</p>
        </div>
        <div className="bill-body-container">
          <div>
            <div className="bill-title-2">FACTURA</div>
            <div className="bill-number">
              <p>{billNumber}</p>
            </div>
          </div>
          <div className="bill-price">
            <p>
              {currencyFormat(billPrice)}{" "}
              <span>{billPriceType ? billPriceType : "EUR"}</span>
            </p>
          </div>
        </div>
      </CardBillInfoContainer>
    );
  }
}

export default CardBillInfo;
