import styled from "styled-components";
import { LabelTextContainer } from "../../text/label-text/label-text-style";
import { BodyTextContainer } from "../../text/body-text/body-text-styles";
import { CalendarItemContainer } from "../calendar-item/calendar-item-style";

export const CalendarBoxContainer = styled.div`
  position: relative;
  z-index: 9;
  width: 100%;

  &.show-tooltip {
    .input-box-topbar {
      z-index: 12;
    }

    .input-box-tooltip {
      display: block;
    }
  }

  &.show-calendar {
    z-index: 12;
    .input-box-main-field {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
    .calendar-popup {
      display: block;
    }
  }

  &.error {
    .input-box-main-field {
      border-bottom: 1.5px solid #ff4100;
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: #b5bcc6;
    }

    .input-box-main-field {
      background: #f7f7f7;

      input {
        border-bottom: 1px solid #b5bcc6;
        color: #b5bcc6;
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    font-size: 14px;
    text-transform: uppercase;
    color: #6b778c;
    padding: 2px 0;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 0;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    color: #091e42;
    border-bottom: 1px solid #cecece;
    cursor: pointer;
    height: 48px;

    p {
      cursor: inherit;
    }

    &:hover {
      background: #f0fafd;
    }

    &:focus {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 24px;
    height: 24px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 24px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    display: none;
    right: 0;
    display: none;
  }

  .calendar-popup {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    display: none;
    color: #49abb8;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
        background: #f0fafd;
      }
    }
  }

  .input-box-error {
    color: #ff4100;
    min-height: 17px;
    margin-top: 4px;
  }

  @media (max-width: 900px) {
    input {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    .input-box-topbar {
      margin-bottom: 0;
    }

    .input-box-topbar-label {
      ${LabelTextContainer} {
        font-size: 12px;
      }
    }

    input {
      font-size: 14px;
    }

    .input-box-main-field {
      ${BodyTextContainer} {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 370px) {
    .calendar-popup {
      width: 100%;
      left: 0;
      right: initial;
      max-width: none;
      min-width: 230px;

      ${CalendarItemContainer} {
        width: 100%;
      }
    }
  }
`;
