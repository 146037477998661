import React, { Component } from "react";
import { LoginContainer } from "./styles";
import InputStandard from "../../components/form-components/input-standard";
import MainButton from "../../components/buttons/main-button";
import { images } from "../../assets/images";
import { BodyText } from "../../components/text";
import { colors } from "../../assets/colors";
import ModalLegal from "../../components/modals/modal-legal";

import { URLS } from "../../constants/urls";

interface LoginLayoutProps {
  activeIntro: boolean;
  loginError: string;
  handleChange: Function;
  handleLogin: Function;
  validationErrors: any;
}

class LoginLayout extends Component<LoginLayoutProps, {}> {
  render() {
    const { activeIntro, loginError, handleChange, handleLogin } = this.props;
    return (
      <LoginContainer className="login-container">
        <div className={`login-intro ${activeIntro && "active"}`}>
          <div className="login-header">
            <div className="login-header__logo">
              <img src={images.logoAsisa} alt="" />
            </div>
            <div className="login-header__subtitle">
              <p>Portal</p>
            </div>
            <div className="login-header__title">
              <p>Reembolso</p>
            </div>
          </div>
          <div className="login-intro-background">
            <img src={images.introBackground} alt="" />
          </div>
        </div>

        <div className="login-background">
          <img src={images.kidBackground} alt="" />
        </div>

        <div className="login-header">
          <div className="login-header__logo">
            <img src={images.logoAsisa} alt="" />
          </div>
          <div className="login-header__subtitle">
            <p>Portal</p>
          </div>
          <div className="login-header__title">
            <p>Reembolso</p>
          </div>
        </div>

        <div className="login-form">
          <div className="login-form-fields">
            <div className="login-form-row">
              <InputStandard
                title="email"
                placeholder="mi@email.com"
                required
                type="email"
                field="email"
                onBlur={(name: string, value: string, error: boolean, submit?: boolean) =>
                  handleChange(name, value, error, submit)
                }
                submit={handleLogin}
              />
            </div>
            <div className="login-form-row">
              <InputStandard
                title="password"
                placeholder=""
                required
                type="password"
                field="password"
                hidden
                onBlur={(name: string, value: string, error: boolean, submit?: boolean) =>
                  handleChange(name, value, error, submit)
                }
                submit={handleLogin}
              />
            </div>

            {loginError !== "" && (
              <div className="error-login">
                <BodyText color={colors["SE-003-100"]}>{loginError}</BodyText>
              </div>
            )}
          </div>

          <div className="login-form-button">
            <MainButton type="dark-blue" onClick={() => handleLogin()} text="Iniciar Sesión" />
          </div>

          <div className="login-form-forgot">
            <p>
              ¿No estás registrado?{" "}
              <a href={URLS.register} target="_blank">
                Registrarme
              </a>
            </p>
          </div>
        </div>
      </LoginContainer>
    );
  }
}

export default LoginLayout;
