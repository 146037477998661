import styled from "styled-components";
import { TextareaBoxContainer } from "../../form-components/textarea-box/textarea-box-style";

const ModalBillInfoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.98);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  font-family: "museo sans";

  .description-reason-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }

  .description-reason-text {
    margin-right: 16px;
    font-size: 18px;
    line-height: 1.3em;
    color: #091e42;
    font-weight: 400;
  }

  .description-user {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4em;
    display: flex;
    align-items: center;
    color: #091e42;
  }

  .description-reason-type {
    background: #f7f9fa;
    border: 1px solid #e9eef2;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 28px;
    width: 72px;
    height: 36px;
    font-size: 18px;
    color: #091e42;
  }

  .successive-treatment-message {
    margin-top: 28px;
    font-size: 16px;
    color: #01b2ea;
    display: flex;
    align-items: center;

    &__img {
      max-width: 24px;
      margin-right: 18px;
    }

    img {
      max-width: 24px;
    }
  }

  .modal-bill-info-wrapper {
    width: 980px;
    max-height: 80vh;
    overflow-y: scroll;
    background: #ffffff;
    border: 0.75px solid #e2e5ea;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .modal-bill-info-topbar {
    background: #f7f9fa;
    border-radius: 8px;
    padding: 42px 52px;
    margin-bottom: 23px;

    .close-icon {
      cursor: pointer;
    }

    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .title {
        font-family: "museo sans";
        font-size: 28px;
        line-height: 36px;
        color: #091e42;
        font-weight: 600;
      }
    }

    .topbar-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .field {
        .field-label {
          font-family: "museo sans";
          font-size: 14px;
          line-height: 17px;
          color: #6b778c;
          margin-bottom: 12px;
          font-weight: 600;
          text-transform: uppercase;
        }

        .field-data {
        }
      }
    }
  }

  .modal-bill-info-row {
    margin-bottom: 80px;
    padding-left: 52px;
    padding-right: 52px;

    .title {
      font-family: "museo sans";
      font-size: 20px;
      line-height: 28px;
      color: #091e42;
      margin-bottom: 32px;
      font-weight: 600;
    }

    .row {
      display: flex;
      align-items: center;
    }

    .field {
      margin-right: 80px;

      .field-label {
        font-family: "museo sans";
        font-size: 14px;
        line-height: 17px;
        color: #6b778c;
        margin-bottom: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .field-data {
        font-family: "museo sans";
        font-size: 18px;
        line-height: 24px;
        color: #091e42;
      }

      .bill-list {
        margin-bottom: 60px;
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(auto-fill, minmax(246px, 1fr));
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .prize-container {
    padding: 16px 20px 18px;
    background: #e9eef2;
    border-radius: 8px;
    font-family: "museo sans";
  }

  .prize-label {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .prize {
    color: #01b2ea;
    font-size: 40px;

    span {
      font-size: 24px;
    }
  }

  @media (max-width: 768px) {
    .modal-bill-info-wrapper {
      height: 100vh;
      max-height: 100vh;
    }
    .modal-bill-info-topbar  {
      .topbar-content  {
        flex-direction: column;
        align-items: flex-start;

        .field {
          margin-bottom: 30px;
        }
      }
    }
    .modal-bill-info-row {
      flex-direction: column;
      align-items: flex-start;
      .row {
        flex-direction: column;
        align-items: flex-start;

        .field {
          margin-bottom: 30px;
        }
      }
    }
  }
`;

export default ModalBillInfoContainer;
