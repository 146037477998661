import React from 'react';
import CardAsideLayout from './card-aside-layout';

export default class CardAside extends React.Component {
    
    
    render() {
        return(
       <CardAsideLayout {...this.props} />
        )
    }

}