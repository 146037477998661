import { SERVICES_URL, SERVICES_KEY } from "../../env";
import axios from "axios";

interface TrackBankParams {
  id: string;
  selectedBank: string;
}

export const trackSelectedBank = (params: TrackBankParams) => {
  const endpoint = `insureds/${params.id}/bank/track`;
  axios({
    method: "POST",
    url: `${SERVICES_URL}${endpoint}`,
    data: {
      selectedBank: params.selectedBank,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};
