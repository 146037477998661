import React, { Component, RefObject } from "react";
import { TooltipContainer } from "./tooltip-style";

interface TooltipProps {
  text?: string;
  className?: string;
  position: {
    top: number;
    left: number;
  };
}

interface TooltipState {
  containerWidth: number;
}

class Tooltip extends Component<TooltipProps, TooltipState> {
  tooltipRef: RefObject<HTMLDivElement> = React.createRef();
  state: TooltipState = {
    containerWidth: 0
  };

  componentDidMount() {
    this.setState({ containerWidth: this.tooltipRef.current.offsetWidth });
  }

  componentDidUpdate(prevProps) {
    const { position } = this.props;
    if (position !== prevProps.position) {
      this.setState({ containerWidth: this.tooltipRef.current.offsetWidth });
    }
  }

  render() {
    const { text, className, position } = this.props;
    const { containerWidth } = this.state;
    const positionFormatted = {
      top: position.top + 30,
      left: position.left - containerWidth + 30
    };
    return (
      <TooltipContainer className={className} position={positionFormatted}>
        <div className="tooltip-container" ref={this.tooltipRef}>
          <div className="tooltip-item">
            <p>{text ? text : "No hay texto"}</p>
          </div>
        </div>
      </TooltipContainer>
    );
  }
}

export default Tooltip;
