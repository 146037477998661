import styled from "styled-components";
import { images } from "../../../assets/images";
import { FileItemModalContainer } from "../file-item-modal/file-item-modal-styles";

export const ModalUploadFilesContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #ffffffaa;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1019;
  overflow: hidden; 

  .space-between {
    flex-grow: 1;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .item-modal-message-container {
    max-width: 740px;
    padding: 44px 40px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.03);
    background: white;
    width: 80%;
    border-radius: 8px;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .item-modal-message-top {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    &__title {
      line-height: 26px;
      font-size: 26px;
      color: #091e42;
      font-weight: 700;
    }
    &__close  {
      display: flex;
      cursor: pointer;
    }
  }

  .item-modal-message-drag {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // background-image: url(${images.DashedBack});
    // background-size: cover;
    border: 2px dashed #E2E5EA;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 26px 12px 12px 12px;

    input {
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 2;
    }
    &__icon {
      margin-right: 20px;
      pointer-events: none;
    }

    &__text {
      pointer-events: none;
      line-height: 22px;
      font-size: 16px;
      max-width: 100%;
      color: #091e42;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        text-align: center;
      }

      span {
        font-weight: bold;
      }
    }

    &__text-rules {
      font-size: 12px;
      // max-width: 415px;
      // text-align: center;
      border-radius: 8px;
      margin: 0 auto;
      margin-top: 16px;
      line-height: 150%;
      background: aliceblue;
      padding: 10px;

    }
  }
  .item-modal-message-bottom {
    display: flex;
  }
  .item-modal-message-button {
    width: 234px;
  }

  .item-modal-message-counter {
    margin-left: 32px;
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 16px;
    text-align: right;
    color: #004f8b;

    &__icon {
      margin-top: 3px;
    }
  }

  .item-modal-message-bad-docs {
    border-top: 0.5px solid #E2E5EA;
    padding-top: 28px;
    &__title {
      margin-bottom: 23px;
      span {
        font-weight: bold;
      }
    }
  }

  .item-modal-message-docs {
    margin-bottom: 32px;
    ${FileItemModalContainer} {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 550px) {
    background: #a7a7a7d6;
    .item-modal-message-container {
      width: 90%;
      margin: 0 auto;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    }

    .item-modal-message-counter {
      &__icon {
        min-width: 32px;
      }
    }

    .item-modal-message-drag {
      &__text {
        font-size: 14px;
        line-height: 1.3em;
      }
    }
  }

  @media (max-width: 440px) {
    .item-modal-message-container {
      padding: 24px 16px;
    }
  }
`;
