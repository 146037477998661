export const isoStringToStringDate = (isoString: string, delimiter: "-" | "/") => {
  const enDate = isoString.split("T")[0];
  const year = enDate.split("-")[0];
  const month = enDate.split("-")[1];
  const day = enDate.split("-")[2];
  let stringDate = "";

  switch (delimiter) {
    case "-":
      stringDate = `${day}-${month}-${year}`;
      break;
    case "/":
      stringDate = `${day}/${month}/${year}`;
      break;
  }

  return stringDate;
};
