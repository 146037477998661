import styled from "styled-components";

export const ItemDocumentContainer = styled.div`
  width: 100%;
  font-family: "museo sans";
  position: relative;
  max-width: 246px;

  .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 19;
    cursor: pointer;

    img {
      max-width: 24px;
    }
  }

  .link-container {
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e5ea;
    border-radius: 3px;
    /* height: 186px;
    width: 246px; */
    transition: all 0.3s ease-in-out;
  }

  .item-document-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
    cursor: pointer;

    img {
      max-width: 48px;
    }
  }

  .item-document-progress {
    width: 100%;
    height: 3px;
    position: relative;
    background: white;
    margin-bottom: 20px;
    &__fill {
      position: absolute;
      width: 0%;
      height: 3px;
      background: #6cc9f0;
      bottom: 0;
      left: 0;
    }
  }

  .item-document-title {
    line-height: normal;
    font-size: 14px;
    font-weight: 700;
    color: #091e42;
    margin-bottom: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .item-document-data {
    line-height: normal;
    font-size: 12px;
    font-weight: 300;
    color: #091e42;
    text-transform: capitalize;
  }

  &.active {
    background: #e9f7fd;
    border: 1px solid #e9f7fd;

    .item-document-progress {
      &__fill {
        width: 100%;
        transition: width 2.6s ease-in-out;
      }
    }
  }

  @media (max-width: 700px) {
    .link-container {
      width: 100%;
      min-height: 0;
    }
  }

  @media (max-width: 440px) {
    .item-document-icon {
      justify-content: flex-start;
    }
  }
`;
