interface INTERFACE_STATE_ACCIDENTS {
  [key: string]: string;
}

export const STATE_ACCIDENTS: INTERFACE_STATE_ACCIDENTS = {
  pendingInfo: "Aporta información adicional",
  gracefulPayment: "Pago graciable",
  inProgress: "En trámite",
  accepted: "Aceptado",
  paid: "Pagado",
  denied: "Anulado",
  notAcepted: "No aceptado"
};

interface InterfaceRequestFinished {
  accepted: boolean;
  denied: boolean;
  pendingInfo: boolean;
  gracefulPayment: boolean;
  inProgress: boolean;
  [key: string]: boolean;
}

export const IS_REQUEST_FINISHED: InterfaceRequestFinished = {
  accepted: true,
  denied: true,
  pendingInfo: false,
  gracefulPayment: false,
  inProgress: false
};

interface InterfacePolizeTypeName {
	health: string;
	ctb80: string;
	dental20: string;
	ctb80_dental20: string;
	[key: string]: string;
}

export const POLIZE_TYPE_NAME: InterfacePolizeTypeName = {
	health: "SALUD",
	ctb80: "BUCODENTAL",
	dental20: "BUCODENTAL",
	ctb80_dental20: "BUCODENTAL FAMILIAR",
};

interface InterfacePolizeUrl {
	health: string;
	ctb80: string;
	dental20: string;
	ctb80_dental20: string;
	[key: string]: string;
}

export const POLIZE_URL: InterfacePolizeUrl = {
	health: "salud",
	ctb80: "bucodental",
	dental20: "bucodental",
	ctb80_dental20: "bucodental",
};

interface InterfaceQueryFilters {
	pending: string;
	processed: string;
	salud: string;
	dental: string;
}

export const QUERY_FILTERS: InterfaceQueryFilters = {
	pending: "status=inProgress&status=pendingInfo",
	processed: "status=accepted&status=denied",
	salud: "policyType=health",
	dental: "policyType=ctb80&policyType=dental20&policyType=ctb80_dental20",
};

interface InterfaceDentalDocNameTypes {
	initialDentalReport: string;
	initialDentalInvoice: string;
	recoveryDentalReport: string;
	recoveryDentalInvoice: string;
	medicalReport: string;
	medicalDischargeReport: string;
	medicalInvoice: string;
	[key: string]: string;
}

export const DENTAL_DOC_NAME_TYPES: InterfaceDentalDocNameTypes = {
	initialDentalReport: "Informe de alta Tratamiento dental inicial",
	initialDentalInvoice: "Facturas Tratamiento dental inicial",
	recoveryDentalReport: "Informe de alta Tratamiento dental de recuperación",
	recoveryDentalInvoice: "Facturas Tratamiento dental de recuperación",
	medicalReport: "Informe médico del accidente emitido por profesional de ASISA",
	medicalDischargeReport: "Informe de alta",
	medicalInvoice: "Facturas acreditativas de importes desembolsados",
};

interface InterfaceDentalDocTypesByCategory {
	cat: Array<string>;
	aoc: Array<string>;
}

export const DENTAL_DOC_TYPES_BY_CATEGORY: InterfaceDentalDocTypesByCategory = {
	cat: ["initialDentalReport", "initialDentalInvoice", "recoveryDentalReport", "recoveryDentalInvoice"],
	aoc: ["medicalReport", "medicalDischargeReport", "medicalInvoice"],
};

interface InterfaceMessageTypeName {
	comment: string;
	infoRequest: string;
}

export interface IPoliticsProps {
	goBackButton?: boolean;
  }

export const MESSAGE_TYPE_NAME: InterfaceMessageTypeName = {
	comment: "Comentario",
	infoRequest: "Solicitud de información",
};

interface InterfaceFileTypes {
	DOC: Array<string>;
	PDF: Array<string>;
	IMG: Array<string>;
}

export const FILE_TYPE: InterfaceFileTypes = {
	DOC: ["doc", "docx"],
	PDF: ["pdf"],
	IMG: ["png", "jpg", "jpeg", "tiff", "gif", "bmp", "ico"],
};

export const MODAL_COOKIES_TEXT = {
	cookiesText: `Este sitio web utiliza cookies propias para que se logre disfrutar de una experiencia óptima de navegación.`,
	cookiesButtonTextSettings: "Opciones",
	cookiesButtonTextAccept: "ACEPTAR",
	titleSettings: "¡Bienvenidos!",
	textSettings: [
		`Cuando visita cualquier sitio web, puede almacenar o recuperar información en su navegador, principalmente en forma de cookies. 
		Esta información puede ser sobre usted, sus preferencias o su dispositivo y se utiliza principalmente para hacer que el sitio 
		funcione como espera. Por lo general, la información no lo identifica directamente, pero puede brindarle una experiencia web más 
		personalizada.`,
		`Debido a que respetamos su derecho a la privacidad, puede optar por no permitir algunos tipos de cookies. Haga clic en los títulos 
		de las diferentes categorías para obtener más información y cambiar nuestra configuración predeterminada. Sin embargo, bloquear 
		algunos tipos de cookies puede afectar su experiencia en el sitio y los servicios que podemos ofrecer.`
	],
	titleOptions: "USTED PERMITE",
	optionsItems: [
		{
			text: `Cookies estrictamente necesarias`,
			cookieKey: "accessInfoRequired",
			options: [
				`Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Por lo general, 
				solo se establecen en respuesta a acciones realizadas por usted que equivalen a una solicitud de servicios, como establecer 
				sus preferencias de privacidad, iniciar sesión o completar formularios. Puede configurar su navegador para bloquear o alertar 
				sobre estas cookies, pero algunas partes del sitio no funcionarán. Estas cookies no almacenan ninguna información de 
				identificación personal.`,
			],
			value: true,
			required: true,
		},
	],
	buttonAcceptText: "Aceptar",
	buttonRejectText: "Rechazar",
};
