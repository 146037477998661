import iconInputEye from "./icon-input-eye.svg";
import iconClose from "./icon-close.svg";
import iconFilterOff from "./icon-filter-off.svg";
import iconFilterOn from "./icon-filter-on.svg";
import iconAdd from "./icon-add.svg";
import iconTeeth from "./icon-teeth.svg";
import iconTeethWhite from "./icon-teeth-white.svg";
import iconAsisaBlue from "./icon-asisa-blue.svg";
import iconAsisaLightBlue from "./icon-asisa-light-blue.svg";
import iconAsisaWhite from "./icon-asisa-white.svg";
import iconChevronDown from "./icon-chevron-down.svg";
import iconMail from "./icon-mail.svg";
import iconStethoscope from "./icon-stethoscope.svg";
import iconCloseFilter from "./icon-close-filter.svg";
import IconDownload from "./icon-download.svg";
import IconDocumentBlue from "./icon-document-blue.svg";
import IconDocumentLBlue from "./icon-document-light-blue.svg";
import IconDocumentYellow from "./icon-doc-yellow.svg";
import IconSend from "./icon-send.svg";
import IconClip from "./icon-clip.svg";
import IconClipBlue from "./icon-clip-blue.svg";
import IconMessageCloseGrey from "./icon-mail-close-grey.svg";
import IconMessageGrey from "./icon-mail-grey.svg";
import IconUploadFail from "./icon-upload-fail.svg";
import IconUploadSuccess from "./icon-upload-success.svg";
import IconUploadError from "./icon-upload-error.svg";
import IconUploadDelete from "./icon-upload-delete.svg";
import IconLogoutBlue from "./icon-logout-blue.svg";
import IconCloseBlue from "./icon-close-blue.svg";
import IconCloseCircle from "./icon-close-circle.svg";
import IconUpload from "./icon-upload.svg";
import IconUploadWhite from "./icon-upload-white.svg";
import IconArrowDownBlue from "./icon-arrow-down-blue.svg";
import IconUserBlue from "./icon-user-blue.svg";
import IconUserGrey from "./icon-user-grey.svg";
import IconPencilGrey from "./icon-pencil-grey.svg";
import IconPencilBlue from "./icon-pencil-blue.svg";
import IconFolderGrey from "./icon-folder-grey.svg";
import IconFolderBlue from "./icon-folder-blue.svg";
import IconUploadBlue from "./icon-upload-blue.svg";
import IconEyeBlue from "./icon-eye-blue.svg";
import IconAlertBlue from "./icon-alert-blue.svg";
import IconEmailClosedBlue from "./icon-email-closed-blue.svg";
import ReadMessageIconBlue from "./read-message-icon-blue.svg";

//icons profile
import IconProfileCard from "./icon-profile-card.svg";
import IconProfileCake from "./icon-profile-cake.svg";
import IconProfileLocation from "./icon-profile-location.svg";
import IconProfileUser from "./icon-profile-user.svg";
import IconProfilePhone from "./icon-profile-phone.svg";
import IconProfileMail from "./icon-profile-mail.svg";

// import IconProfileCardBlue from './icon-profile-card.svg';
// import IconProfileCakeBlue from './icon-profile-cake.svg';
// import IconProfileLocationBlue from './icon-profile-location.svg';
import IconProfileUserBlue from "./icon-profile-user-blue.svg";
import IconProfilePhoneBlue from "./icon-profile-phone-blue.svg";
import IconProfileMailBlue from "./icon-profile-mail-blue.svg";

// import IconProfileCardRed from './icon-profile-card.svg';
// import IconProfileCakeRed from './icon-profile-cake.svg';
// import IconProfileLocationRed from './icon-profile-location.svg';
import IconProfileUserRed from "./icon-profile-user-red.svg";
import IconProfilePhoneRed from "./icon-profile-phone-red.svg";
import IconProfileMailRed from "./icon-profile-mail-red.svg";

import IconCardSlider from "./icon-card-slider.svg";
import IconButtonSucces from "./icon-button-succes.svg";
import IconButtonError from "./icon-button-error.svg";

import logoAsisaBlue from "./logo-asisa-blue.svg";
import logoAsisaWhite from "./logo-asisa-white.svg";

import gridIcon from "./grid-icon.png";
import listIcon from "./list-icon.svg";

import btnCloseFilter from "./btn-close-filter.svg";
import selectSearchArrow from "./select-search-arrow.svg";
import searchIcon from "./search.svg";
import addCardPlus from "./add-card-plus.svg";
import closeBillModal from "./close-bill-modal.svg";
import closeMessageModal from "./close-modal-message.svg";
import newMessageIcon from "./new-message-icon.svg";
import readMessageIcon from "./read-message-icon.svg";
import clipIcon from "./clip-icon.svg";
import sendMessageIcon from "./send-message-icon.svg";
import closeModalBillInfo from "./close-modal-bill-info.svg";
import notAssistance from "./not-assistance.svg";
import yesAssistance from "./yes-assistance.svg";
import iconArrowLeft from "./icon-arrow-left-blue.svg";


export const iconUser = [
  "M1.59946 12.2661C2.38346 11.4821 3.4468 11.0417 4.55556 11.0417H11.6667C12.7754 11.0417 13.8388 11.4821 14.6228 12.2661C15.4068 13.0501 15.8472 14.1135 15.8472 15.2222V17C15.8472 17.3452 15.5674 17.625 15.2222 17.625C14.877 17.625 14.5972 17.3452 14.5972 17V15.2222C14.5972 14.445 14.2885 13.6996 13.7389 13.15C13.1893 12.6004 12.4439 12.2917 11.6667 12.2917H4.55556C3.77832 12.2917 3.03293 12.6004 2.48334 13.15C1.93375 13.6996 1.625 14.445 1.625 15.2222V17C1.625 17.3452 1.34518 17.625 1 17.625C0.654822 17.625 0.375 17.3452 0.375 17V15.2222C0.375 14.1135 0.81545 13.0501 1.59946 12.2661Z",
  "M8.1111 1.625C6.4926 1.625 5.18054 2.93705 5.18054 4.55556C5.18054 6.17406 6.4926 7.48611 8.1111 7.48611C9.7296 7.48611 11.0417 6.17406 11.0417 4.55556C11.0417 2.93705 9.7296 1.625 8.1111 1.625ZM3.93054 4.55556C3.93054 2.2467 5.80224 0.375 8.1111 0.375C10.42 0.375 12.2917 2.2467 12.2917 4.55556C12.2917 6.86441 10.42 8.73611 8.1111 8.73611C5.80224 8.73611 3.93054 6.86441 3.93054 4.55556Z"
];

//Header SVGs

export const iconHeaderHome = [
  "M7.81629 0.506655C8.04198 0.331115 8.35802 0.331115 8.58371 0.506655L15.7837 6.10665C15.936 6.22506 16.025 6.40713 16.025 6.6V15.4C16.025 15.9901 15.7906 16.556 15.3733 16.9733C14.956 17.3906 14.3901 17.625 13.8 17.625H2.6C2.00989 17.625 1.44396 17.3906 1.02669 16.9733C0.609419 16.556 0.375 15.9901 0.375 15.4V6.6C0.375 6.40713 0.464046 6.22506 0.616287 6.10665L7.81629 0.506655ZM1.625 6.90568V15.4C1.625 15.6586 1.72772 15.9066 1.91057 16.0894C2.09342 16.2723 2.34141 16.375 2.6 16.375H13.8C14.0586 16.375 14.3066 16.2723 14.4894 16.0894C14.6723 15.9066 14.775 15.6586 14.775 15.4V6.90568L8.2 1.79179L1.625 6.90568Z"
];
export const iconHeaderPlus = [
  "M9 0.375008C9.34518 0.375008 9.625 0.65483 9.625 1.00001V17C9.625 17.3452 9.34518 17.625 9 17.625C8.65482 17.625 8.375 17.3452 8.375 17V1.00001C8.375 0.65483 8.65482 0.375008 9 0.375008Z",
  "M0.375 9.00001C0.375 8.65483 0.654822 8.37501 1 8.37501H17C17.3452 8.37501 17.625 8.65483 17.625 9.00001C17.625 9.34519 17.3452 9.62501 17 9.62501H1C0.654822 9.62501 0.375 9.34519 0.375 9.00001Z"
];
export const iconHeaderBell = [
  "M13.0021 5.80085C13.0021 4.52759 12.4963 3.30647 11.596 2.40614C10.6957 1.5058 9.47454 1 8.20128 1C6.92801 1 5.7069 1.5058 4.80656 2.40614C3.90623 3.30647 3.40043 4.52759 3.40043 5.80085C3.40043 11.4018 1 13.0021 1 13.0021H15.4026C15.4026 13.0021 13.0021 11.4018 13.0021 5.80085Z",
  "M9.58557 16.2027C9.4449 16.4452 9.24298 16.6465 9.00005 16.7864C8.75711 16.9263 8.48168 17 8.20132 17C7.92097 17 7.64554 16.9263 7.4026 16.7864C7.15966 16.6465 6.95775 16.4452 6.81708 16.2027"
];
export const iconHeaderLogOut = [
  "M3.33333 2.625C3.14547 2.625 2.9653 2.69963 2.83247 2.83247C2.69963 2.9653 2.625 3.14547 2.625 3.33333V12.6667C2.625 12.8545 2.69963 13.0347 2.83247 13.1675C2.9653 13.3004 3.14547 13.375 3.33333 13.375H6C6.34518 13.375 6.625 13.6548 6.625 14C6.625 14.3452 6.34518 14.625 6 14.625H3.33333C2.81395 14.625 2.31584 14.4187 1.94858 14.0514C1.58132 13.6842 1.375 13.186 1.375 12.6667V3.33333C1.375 2.81395 1.58132 2.31584 1.94858 1.94858C2.31584 1.58132 2.81395 1.375 3.33333 1.375H6C6.34518 1.375 6.625 1.65482 6.625 2C6.625 2.34518 6.34518 2.625 6 2.625H3.33333Z",
  "M10.2247 4.22468C10.4688 3.98061 10.8646 3.98061 11.1086 4.22468L14.442 7.55802C14.686 7.80209 14.686 8.19782 14.442 8.4419L11.1086 11.7752C10.8646 12.0193 10.4688 12.0193 10.2247 11.7752C9.98067 11.5312 9.98067 11.1354 10.2247 10.8914L13.1161 7.99996L10.2247 5.10857C9.98067 4.86449 9.98067 4.46876 10.2247 4.22468Z",
  "M5.375 8C5.375 7.65482 5.65482 7.375 6 7.375H14C14.3452 7.375 14.625 7.65482 14.625 8C14.625 8.34518 14.3452 8.625 14 8.625H6C5.65482 8.625 5.375 8.34518 5.375 8Z"
];

export const iconsSVG = {
  iconsUser: iconUser,
  iconInputEye: iconInputEye,
  iconHeaderHome: iconHeaderHome,
  iconHeaderPlus: iconHeaderPlus,
  iconHeaderBell: iconHeaderBell,
  iconHeaderLogOut: iconHeaderLogOut
};



export const icons = {
  iconClose: iconClose,
  iconFilterOn: iconFilterOn,
  iconFilterOff: iconFilterOff,
  iconAdd: iconAdd,
  iconTeeth: iconTeeth,
  iconTeethWhite: iconTeethWhite,
  iconChevronDown: iconChevronDown,
  iconAsisaBlue,
  iconAsisaLightBlue,
  iconAsisaWhite,
  iconMail: iconMail,
  iconStethoscope: iconStethoscope,
  iconCloseFilter: iconCloseFilter,
  IconDocumentBlue: IconDocumentBlue,
  IconDocumentLBlue: IconDocumentLBlue,
  IconDownload: IconDownload,
  IconSend: IconSend,
  IconClip: IconClip,
  IconClipBlue: IconClipBlue,
  IconMessageCloseGrey: IconMessageCloseGrey,
  IconMessageGrey: IconMessageGrey,
  IconDocumentYellow: IconDocumentYellow,
  IconUploadFail: IconUploadFail,
  IconUploadError,
  IconUploadSuccess,
  IconUploadDelete: IconUploadDelete,
  IconUploadWhite: IconUploadWhite,
  IconLogoutBlue: IconLogoutBlue,
  IconCloseBlue: IconCloseBlue,
  IconCloseCircle: IconCloseCircle,
  IconUpload: IconUpload,
  IconArrowDownBlue: IconArrowDownBlue,
  IconUserBlue: IconUserBlue,
  IconUserGrey: IconUserGrey,
  IconPencilGrey: IconPencilGrey,
  IconPencilBlue: IconPencilBlue,
  IconFolderGrey: IconFolderGrey,
  IconFolderBlue: IconFolderBlue,
  IconUploadBlue: IconUploadBlue,
  IconEyeBlue: IconEyeBlue,
  IconAlertBlue: IconAlertBlue,
  IconEmailClosedBlue: IconEmailClosedBlue,
  ReadMessageIconBlue: ReadMessageIconBlue,
  iconArrowLeft,

  //icons profile

  IconProfileCard: IconProfileCard,
  IconProfileCake: IconProfileCake,
  IconProfileLocation: IconProfileLocation,
  IconProfileUser: IconProfileUser,
  IconProfilePhone: IconProfilePhone,
  IconProfileMail: IconProfileMail,
  // 'IconProfileCardBlue': IconProfileCardBlue,
  // 'IconProfileCakeBlue': IconProfileCakeBlue,
  // 'IconProfileLocationBlue': IconProfileLocationBlue,
  IconProfileUserBlue: IconProfileUserBlue,
  IconProfilePhoneBlue: IconProfilePhoneBlue,
  IconProfileMailBlue: IconProfileMailBlue,
  // 'IconProfileCardRed': IconProfileCardRed,
  // 'IconProfileCakeRed': IconProfileCakeRed,
  // 'IconProfileLocationRed': IconProfileLocationRed,
  IconProfileUserRed: IconProfileUserRed,
  IconProfilePhoneRed: IconProfilePhoneRed,
  IconProfileMailRed: IconProfileMailRed,
  IconCardSlider: IconCardSlider,

  IconButtonSucces: IconButtonSucces,
  IconButtonError: IconButtonError,

  // logo
  logoAsisaBlue: logoAsisaBlue,
  logoAsisaWhite: logoAsisaWhite,

  gridIcon: gridIcon,
  listIcon: listIcon,
  btnCloseFilter: btnCloseFilter,
  selectSearchArrow: selectSearchArrow,
  searchIcon: searchIcon,
  addCardPlus: addCardPlus,
  closeBillModal: closeBillModal,
  closeMessageModal: closeMessageModal,
  newMessageIcon: newMessageIcon,
  readMessageIcon: readMessageIcon,
  clipIcon: clipIcon,
  sendMessageIcon: sendMessageIcon,
  closeModalBillInfo: closeModalBillInfo,
  notAssistance: notAssistance,
  yesAssistance: yesAssistance
};
