import styled from "styled-components";

export const CardAsideContainer = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 223px;
  overflow: hidden;
  position: relative;
  .card-aside-background {
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .card-aside-top {
    display: flex;
    justify-content: space-between;
    .card-aside-top-right {
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      color: #ffffff;
    }
    .card-aside-top-left {
      &__icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
      display: flex;
      &__text {
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        &__title {
          font-weight: 300;
        }
        &__subtitle {
          font-weight: 700;
        }
      }
    }
  }

  .card-aside-bottom {
    &__title {
      font-size: 16px;
      line-height: normal;
      color: #ffffff88;
      margin-bottom: 6px;
    }
    &__text  {
      font-size: 28px;
      line-height: normal;
      color: #ffffff;
    }
  }

  @media (max-width: 550px) {

    .card-aside-bottom {
      &__text {
        font-size: 16px;
      }
      &__title {
        font-size: 12px;
      }
    }

    .slick-list {
      transform: translateX(-12px);
    }



    

  }
`;
