import React, { Component } from "react";
import SelectSearchContainer from "./select-search-style";
import CircleCheckbox from "../circle-checkbox/circle-checkbox";
import { icons } from "../../../assets/icons";

interface OptionType {
  label: string;
  value: string;
  secondLine?: string;
}

interface SelectSearchProps {
  name: string;
  value?: OptionType;
  initialValue?: OptionType;
  options: OptionType[];
  onChange: (name: string, value: string) => void;
  isMulti?: boolean;
  hasAll?: boolean;
  iconImg?: string;
  iconImgActive?: string;
}

interface SelectSearchState {
  selectedValue: string;
  selectedText: string;
  options: OptionType[];
  showList: boolean;
}

class SelectSearch extends Component<SelectSearchProps, SelectSearchState> {
  state: SelectSearchState;

  constructor(props: SelectSearchProps) {
    super(props);
    this.state = {
      selectedValue: this.props.initialValue
        ? this.props.initialValue.label || "all"
        : "",
      selectedText: this.props.initialValue
        ? this.props.initialValue.value || "Todos los asegurados"
        : "",
      options: this.props.options,
      showList: false
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;
    window.addEventListener("click", this.onClickOutsideHandler.bind(this));
    if (initialValue) {
      this.setState({
        selectedValue: this.props.initialValue.label,
        selectedText: this.props.initialValue.value
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler.bind(this));
  }

  onClickOutsideHandler() {
    this.changeShowList(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options.length === 0 && this.props.options.length !== 0) {
      this.setState({ options: this.props.options });
    }

    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options });
    }

    if (prevProps.value !== this.props.value) {
      this.setState({
        selectedValue: this.props.value.label,
        selectedText: this.props.value.value
      });
    }
  }

  changeShowList = (show: boolean) => {
    let { showList } = this.state;

    showList = show;

    this.setState({ showList });
  };

  searchValues = (val: string) => {
    let { options } = this.state;
    const lowerCaseValue = val.toLowerCase();
    options = this.props.options.filter(
      option => option.value.toLowerCase().indexOf(lowerCaseValue) !== -1
    );

    this.setState({ options });
  };

  selectAllValues = (name: string, value: boolean) => {
    const { onChange } = this.props;
    let { selectedValue, selectedText } = this.state;

    const allText = "Todos los asegurados";

    selectedValue = name;

    selectedText = allText;
    onChange("all", "all");
    this.setState({ selectedValue, selectedText });
  };

  changeChangeSelectedValue = (name: string, value: boolean) => {
    let { selectedValue, selectedText, showList } = this.state;
    const { options, onChange, isMulti } = this.props;
    const selectedElement = options.find(element => element.label === name);

    selectedValue = name;

    selectedText = selectedElement.value;

    this.setState({ selectedValue, selectedText });

    onChange(this.props.name, name);
    if (!isMulti) {
      this.changeShowList(!showList);
    }
  };

  render() {
    const { selectedText, options, showList, selectedValue } = this.state;
    const { hasAll, iconImg, iconImgActive } = this.props;

    return (
      <SelectSearchContainer className={`${showList ? " active" : ""}`}>
        <div
          className="select-search-topbar"
          onClick={ev => {
            ev.stopPropagation();
            this.changeShowList(!showList);
          }}
        >
          <div className="select-search-label">
            {iconImg && <img src={showList ? iconImgActive : iconImg} />}
            <p>{selectedText}</p>
          </div>

          <div className="select-search-topbar-icon">
            <img
              src={showList ? icons.IconArrowDownBlue : icons.selectSearchArrow}
              alt=""
            />
          </div>
        </div>

        {showList && (
          <div
            className="select-search-list-container"
            onClick={ev => ev.stopPropagation()}
          >
            <div className="select-search-input">
              <input
                placeholder="Buscar"
                onClick={ev => {
                  ev.stopPropagation();
                }}
                onChange={e => this.searchValues(e.target.value)}
              />
              <div className="select-search-input-icon">
                <img src={icons.searchIcon} alt="" />
              </div>
            </div>

            <ul className="select-search-list">
              {hasAll && (
                <li className="select-search-option">
                  <CircleCheckbox
                    initialValue={selectedValue === "all"}
                    value={selectedValue === "all"}
                    name="all"
                    labelText="Todos"
                    onChange={this.selectAllValues}
                  />
                </li>
              )}

              {options &&
                options.map((key: OptionType) => (
                  <li className="select-search-option">
                    <CircleCheckbox
                      initialValue={key.label === selectedValue}
                      value={key.label === selectedValue}
                      name={key.label}
                      labelText={key.value}
                      secondLine={key.secondLine}
                      onChange={this.changeChangeSelectedValue}
                    />
                  </li>
                ))}
            </ul>
          </div>
        )}
      </SelectSearchContainer>
    );
  }
}

export default SelectSearch;
