import styled from "styled-components";

const CircleCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .circle-checkbox {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    max-height: 20px;
    max-width: 20px;
    border-radius: 50%;
    border: 1px solid #091e42;
    margin-right: 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected {
      background: #01b2ea;
      border: 1px solid #01b2ea;
    }

    img {
      max-width: 14px;
    }
  }

  .circle-checkbox-label {
    font-family: "museo sans";
    color: #6b778c;
    font-size: 16px;
    font-weight: 500;

    &.selected {
      color: #091e42;
    }

    label {
      cursor: pointer;
    }
  }
`;

export default CircleCheckboxContainer;
