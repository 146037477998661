import styled from "styled-components";
import { images } from "../../../assets/images";
import { colors } from "../../../assets/colors";

export const HeaderContainer = styled.div`
  width: 100%;
  background: ${colors["PR-001-100"]};
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  position: relative;

  .header-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-asisa-white {
        padding-right: 20px;
        border-right: 1px solid #fff;
        min-height: 22px;
        max-height: 22px;
        width: 115px;
      }

      .logo-asisa-name {
        padding-left: 20px;
        font-family: "museo sans";
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }

  .header-buttons {
    display: flex;
    overflow: hidden;

    .header-buttons-item {
      margin-left: 30px;
      display: flex;
      min-width: 44px;
      max-width: 44px;
      height: 44px;
      align-items: center;
      border-radius: 44px;
      padding-left: 2px;
      border: 1px solid #01b2ea00;
      transition: all 0.6s ease-in-out;
      cursor: pointer;

      .header-buttons-item-svg {
        min-width: 24px;
        min-height: 24px;
        margin-left: 6px;
        background-size: cover;
        background-position: center;
        transition: all 0.3s ease-in-out;

        &.home {
          background-image: url(${images.IconHeaderHomeWhite});
        }
        &.add {
          background-image: url(${images.IconHeaderCreateWhite});
        }
        &.bell {
          background-image: url(${images.IconHeaderAlertWhite});
        }
        &.user {
          background-image: url(${images.IconHeaderUserWhite});
        }
        &.legal {
          background-image: url(${images.IconHeaderBellWhite});
        }
        &.logout {
          background-image: url(${images.IconHeaderLogOutWhite});
        }
      }
      .header-button-item-notifications {
        display: flex;
        position: absolute;
        right: 0;
        justify-content: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: red;
        color: white;
        margin-top: 30px;
      }

      .header-buttons-item-text {
        transition: all 0.2s ease-in-out;
        color: white;
        font-family: "museo sans";
        line-height: normal;
        font-size: 14px;
        color: white;
        visibility: hidden;
        opacity: 0;
        margin-left: 12px;
        flex: auto;
        padding-right: 12px;

        &.header-button-aside {
          display: none;
        }
      }

      &:hover {
        border: 1px solid #fff;
      }

      &.active {
        max-width: 280px;
        border: 1px solid #fff;
        background: #fff;
        padding: 0 8px;

        &.header-buttons-create {
          .header-buttons-item-text {
            min-width: 135px;
          }
        }

        .header-buttons-item-svg {
          display: none;

          &.home {
            background-image: url(${images.IconHeaderHomeLightBlue});
          }
          &.add {
            background-image: url(${images.IconHeaderCreateLightBlue});
          }
          &.bell {
            background-image: url(${images.IconHeaderAlertLightBlue});
          }
          &.user {
            background-image: url(${images.IconHeaderUserLightBlue});
          }
          &.legal {
            background-image: url(${images.IconHeaderBellLightBlue});
          }
          &.logout {
            background-image: url(${images.IconHeaderLogOutLightBlue});
          }
        }

        .header-buttons-item-text {
          visibility: visible;
          opacity: 1;
          text-transform: uppercase;
          color: #004f8b;
          -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
        }
      }
    }
  }

  .menu-header {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  .menu-navicon {
    display: none;
  }

  @media (max-width: 900px) {
    padding: 0 48px;
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 24px;
    z-index: 990;

    .header-logo {
      margin-right: 12px;
      a {
        padding: 6px;
      }
    }

    .menu-navicon {
      display: block;
      width: 24px;
      height: 20px;
      position: relative;
      cursor: pointer;
      text-indent: -9999px;

      &:after {
        content: "";
        width: 24px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0 0px 0 #fff, 0 8px 0 #fff, 0 16px 0 #fff;
        transition: all 0.2s;
      }
    }

    .menu-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      margin-bottom: 80px;

      img {
        max-width: 280px;
      }
    }

    .header-buttons {
      width: 100%;
    }

    .menu-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: #004f8b;
      z-index: 99;
      overflow: hidden;
      max-height: 0;
      transition: 0.6s all ease-in-out;
      padding: 0;

      .menu-close {
        display: flex;
        width: 14px;
        height: 14px;
        cursor: pointer;

        img {
          max-width: 16px;
        }
      }

      &.active {
        padding: 42px 24px;
        max-height: 600px;

        .menu-header {
          visibility: visible;
          opacity: 1;
          transition: 0.5s all ease-in-out;
          transition-delay: 0.5s;
        }

        .header-buttons {
          visibility: visible;
          opacity: 1;
          transition: 0.3s all ease-in-out;
          transition-delay: 0.5s;

          .header-buttons-item {
            max-width: 280px;
            margin: 0;
            margin-bottom: 20px;
            padding: 16px 8px;

            .header-buttons-item-svg {
              min-width: 20px;
              min-height: 16px;
              margin-bottom: 2px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .header-buttons-item-text {
              visibility: visible;
              opacity: 1;
              color: #fff;
              transition-delay: 0.4s;

              &.header-button-aside {
                display: block;
              }
            }

            &.active {
              background: none;
              border-bottom: 1px solid white;
              .header-buttons-item-svg {
                &.home {
                  background-image: url(${images.IconHeaderHomeWhite});
                }
                &.add {
                  background-image: url(${images.IconHeaderCreateWhite});
                }
                &.bell {
                  background-image: url(${images.IconHeaderAlertWhite});
                }
                &.user {
                  background-image: url(${images.IconHeaderUserWhite});
                }
                &.legal {
                  background-image: url(${images.IconHeaderBellWhite});
                }
                &.logout {
                  background-image: url(${images.IconHeaderLogOutWhite});
                }
              }
            }
          }
        }
      }
    }

    .header-buttons {
      visibility: hidden;
      opacity: 0;
      display: block;

      .header-buttons-item {
        margin-bottom: 24px;
      }
    }

    .header-buttons-item {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 440px) {
    .header-logo {
      margin: 0;
      a {
        display: block;
        .logo-asisa-white {
          padding: 0;
          margin: 0;
          max-width: 70px;
          border: none;
          display: flex;
          align-items: center;
        }

        .logo-asisa-name {
          padding: 0;
          font-size: 14px;
        }
      }
    }

    .menu-container {
      .menu-close {
        margin-bottom: 4px;
      }
    }
  }
`;

export const HeaderAside = styled.div`
  position: fixed;
  right: 0;
  top: 0px;
  height: 100vh;
  background: white;
  width: 513px;
  z-index: 99;
  max-width: 0px;
  transition: all 0.6s ease-in-out;
  overflow-y: hidden;
  box-shadow: 0px 4px 10px rgba(107, 119, 140, 0.1);

  .header-aside-container {
    width: 513px;
    height: calc(100vh - 83px);
    overflow-y: scroll;
  }

  .header-aside-close  {
    width: 100%;
    height: 83px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 30px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &__item {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
    }
  }

  .header-aside-veil {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;

    visibility: hidden;
    z-index: -4;
  }
  &.active {
    .header-aside-veil {
      visibility: visible;
    }
    max-width: 513px;
    .header-aside-close  {
      opacity: 1;
    }
  }

  @media (max-width: 550px) {
    transition: none;

    &.active {
      width: 100%;
      max-width: none;
      bottom: 0;
      height: auto;
      overflow: scroll;
    }

    .header-aside-veil {
      display: none;
    }

    .header-aside-container {
      width: 100%;
    }

    .header-aside-close  {
      height: 53px;
      padding: 0 18px;

      &__item {
        width: 24px;
        height: 24px;
      }
    }

    .header-aside-container {
      height: auto;
    }

    .no-content-img {
      margin-bottom: 24px;
    }
  }
`;
