import React, { Component } from "react";
import AddDocFormContainer from "./add-doc-form-style";
import AddCard from "../../../cards/add-card";
import ModalNewBill from "../../../modals/modal-new-bill/modal-new-bill";
import CardBillInfo from "../../../cards/card-bill-info/card-bill-info";
import { currencyFormat } from "../../../../utils/text";
import { IInvoice } from "../../../../services/requests/create-user-request";

interface AddDocProps {
  addData: (invoice: IInvoice, index?: number) => void;
  invoices: any[];
  removeInvoice: Function;
}

export interface AddDocFormState {
  showModalNewBill: boolean;
  selectedIndexInvoice: number;
}

class AddDocForm extends Component<AddDocProps, AddDocFormState> {
  state: AddDocFormState;

  constructor(props: any) {
    super(props);

    this.state = {
      showModalNewBill: false,
      selectedIndexInvoice: -1
    };
  }

  changeShowModalNewBill = (show: boolean, index: number) => {
    let { showModalNewBill, selectedIndexInvoice } = this.state;
    showModalNewBill = show;
    selectedIndexInvoice = index;
    this.setState({ showModalNewBill, selectedIndexInvoice });
  };

  render() {
    const { showModalNewBill } = this.state;
    const { invoices, removeInvoice } = this.props;
    let invoiceSize = 0;
    invoices.forEach((inv) => {
      invoiceSize += inv.documents.reduce((size, doc) => doc.size + size, 0);
      invoiceSize += inv.reports.reduce((size, rep) => rep.size + size, 0);
    })
    return (
      <AddDocFormContainer>
        <div
          className="add-doc-form-item"
          onClick={() => this.changeShowModalNewBill(!showModalNewBill, -1)}
        >
          <AddCard text="Añadir nueva factura" className={"main"} />
        </div>
        <div className="add-doc-form-grid">
          <ul className="doc-list">
            {invoices &&
              invoices.map((invoice: any, index: number) => (
                <li key={`invoice-${index}`} className="doc-element">
                  <CardBillInfo
                    billProvider={invoice.doctorName}
                    billNumber={invoice.id}
                    billDate={invoice.date}
                    billPrice={invoice.amount}
                    billPriceType={invoice.currency}
                    onClick={e => {
                      e.preventDefault();
                      this.changeShowModalNewBill(!showModalNewBill, index);
                    }}
                    close={e => {
                      e.stopPropagation();
                      removeInvoice(index);
                    }}
                  />
                </li>
              ))}
          </ul>
        </div>

        {showModalNewBill && (
          <ModalNewBill
            invoice={
              this.state.selectedIndexInvoice !== -1
                ? this.props.invoices[this.state.selectedIndexInvoice]
                : ""
            }
            onSave={(invoice: IInvoice) =>
              this.props.addData(invoice, this.state.selectedIndexInvoice)
            }
            onDelete={() => {
              removeInvoice(this.state.selectedIndexInvoice);
              this.changeShowModalNewBill(false, -1);
            }}
            onClose={() => this.changeShowModalNewBill(false, -1)}
            totalFileSize={invoiceSize}
          />
        )}
      </AddDocFormContainer>
    );
  }
}

export default AddDocForm;
