import styled from "styled-components";
import { images } from "../../../assets/images";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";
import { colors } from "../../../assets/colors";

export const ModalEndingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #ffffffaa;
  top: 0;
  left: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;

  display: none;

  &.active {
    display: flex;
  }

  .modal-ending-card {
    overflow-y: scroll;
    max-height: 80vh;
    position: relative;
    background: ${colors["PR-001-100"]};
    width: 100%;
    max-width: 740px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
  }

  .modal-ending-content {
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 60px;
    margin-bottom: 100px;
  }

  .modal-ending-content-top {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 40px;

    &__close {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;

      img {
        max-width: 52px;
      }
    }
  }

  .modal-ending-content-subtitle {
    font-family: museo sans;
    font-size: 28px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .modal-ending-content-title {
    font-size: 48px;
    line-height: normal;
    margin-bottom: 60px;
    font-weight: 700;
    color: #ffffff;
    max-width: 410px;
  }
  .modal-ending-content-text {
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 60px;
    font-weight: 300;

    p {
      margin-bottom: 28px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    a {
      color: #49abb8;
    }
  }

  .modal-ending-content-button {
    max-width: 220px;
  }

  .modal-ending-content-background {
    width: 100%;
    z-index: 1;
    position: relative;
    overflow: hidden;
    height: 140px;
    background: url(${images.ModalEndingBackground}) no-repeat center;
    background-size: cover;
  }

  @media (max-width: 890px) {
    .modal-ending-content-background {
      height: 130px;
    }
  }

  @media (max-width: 890px) {
    .modal-ending-card {
      max-width: 640px;
    }

    .modal-ending-content {
      margin-bottom: 60px;
    }

    .modal-ending-content-background {
      height: 130px;
    }

    .modal-ending-content-title {
      font-size: 38px;
      max-width: 300px;
      margin-bottom: 32px;
    }

    .modal-ending-content-subtitle {
      font-size: 28px;
    }
  }

  @media (max-width: 630px) {
    .modal-ending-card {
      width: 90%;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    }
    .modal-ending-content {
      padding: 0;
      padding: 54px 18px 30px;
      margin: 0;
    }
    .modal-ending-content-background {
      display: none;
    }
    .modal-ending-content-top {
      &__close {
        top: 16px;
        right: 16px;
      }
    }

    .modal-ending-content-subtitle {
      font-size: 16px;
      margin: 0;
    }

    .modal-ending-content-title {
      margin: 0;
      margin-bottom: 24px;
      font-size: 22px;
    }

    .modal-ending-content-text {
      font-size: 14px;
      line-height: 1.5em;
      margin: 0;
      margin-bottom: 40px;
      p {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .modal-ending-content-button {
      width: 100%;
      max-width: none;
      ${MainButtonContainer} {
        font-size: 14px;
      }
    }
  }
`;
