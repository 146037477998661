import React, { Component, MutableRefObject } from "react";
import { ModalUploadFilesContainer } from "./modal-upload-files-style";
import { icons } from "../../../assets/icons";
import MainButton from "../../buttons/main-button";
import FileItemModal from "../file-item-modal";
import { AttachedFile } from "../../../screens/request-detail/types";
import moment from "moment";
import "moment/locale/es.js";
import Services from "../../../utils/services";
import FileUtils from "../../../utils/files";
import { isValidFileName, isValidSize } from "../../../utils/validation";
import classNames from "classnames";
import cloneDeep from "lodash.clonedeep";

import ModalFileValidation from "../modal-file-validation";
interface ModalUploadFilesProps {
  showModal: boolean;
  onClose: Function;
  multiple?: boolean;
  files?: any[];
  notDisabled?: boolean;
  onConfirm: (files: Array<AttachedFile>) => void;
  totalFilesSize?: number;
}

interface ModalErrorData {
  onConfirm: Function;
  files?: any[];
  title: string;
}
interface ModalUploadFilesState {
  selectedFiles: Array<AttachedFile>;
  filesWithBadName: Array<AttachedFile>;
  finalFiles: Array<AttachedFile>;
  selectedFile: AttachedFile;
  activeSecondaryModal: string;
  modalError: ModalErrorData;
}

export default class ModalUploadFiles extends Component<
  ModalUploadFilesProps,
  ModalUploadFilesState
> {
  state: ModalUploadFilesState = {
    selectedFiles: this.props.files || [],
    filesWithBadName: [],
    finalFiles: [],
    activeSecondaryModal: "",
    selectedFile: null,
    modalError: {
      onConfirm: () => {},
      files: [],
      title: "",
    },
  };

  filesCounter: MutableRefObject<number> = React.createRef();

  componentDidMount() {
    document.body.classList.add("no-scroll");
    document.getElementById("file").click();
  }
  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  // countCorrectFiles() {
  //   const { selectedFiles } = this.state;
  //   return selectedFiles.reduce((sum, item) => {
  //     if (!item.error) {
  //       return sum + 1;
  //     } else {
  //       return sum;
  //     }
  //   }, 0);
  // }

  sendFile(messageID: string, file: AttachedFile) {
    const endpoint = `files`;

    const formData = new FormData();
    formData.set("attachableType", "message");
    formData.set("attachableId", messageID);
    formData.set("file", file.data);

    Services.postFormData({
      loader: true,
      endpoint,
      formData,
      then: (response: any) => {
        console.log(response);
      },
      catch: (err: any) => {
        console.log(err.response);
      },
    });
  }

  getBase64ToFilesValidator = (file: any, fileName: string, originType: string, lastModified: any) => {
    return new Promise(resolve => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event: any) {
        resolve([event.target.result, fileName, originType, lastModified]);
      };
      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  generateFileToFilesValidator = (base64: string, fileName: string, originType: string, lastModified: any) => {
    if(!base64){
      return new File([new Blob(undefined, { type: originType })], fileName, {
        type: originType,
        lastModified: lastModified
      });
    }

    // const type = fileName.split(".")[1];
    const type = originType;
    // decode base64
    const fileContent = atob(base64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const fileBuffer = new ArrayBuffer(fileContent.length);
    const view = new Uint8Array(fileBuffer);

    // fill the view, using the decoded base64
    for (let index = 0; index < fileContent.length; index++) {
      view[index] = fileContent.charCodeAt(index);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([fileBuffer], { type: type });

    const file = new File([blob], fileName, {
      type: type,
      lastModified: lastModified
    });

    return file;
  };

  filesValidator(files: any) {
    const allowedFiles = ["png", "pdf", "jpg", "jpeg"];
    const validatedFiles = [];
    let inputFilesToValidate = files.length;

    [...files].forEach((file, index) => {
      if (!allowedFiles.includes(FileUtils.getExtension(file.name).toLowerCase())) {
        inputFilesToValidate--;
      } else {
        this.getBase64ToFilesValidator(file, file.name, file.type, file.lastModified).then(fileData => {
          var isValidFile = true;
          var base64 = fileData[0].toString().split("base64,")[1];

          if (FileUtils.getExtension(file.name).toLowerCase() === "pdf" && !base64) {
            isValidFile = false;
          }

          validatedFiles.push({
            base64: base64,
            fileName: fileData[1],
            originType: fileData[2],
            lastModified: fileData[3],
            isValidFile: isValidFile
          });

          if (validatedFiles && validatedFiles.length == inputFilesToValidate) {
            var validFilesArray = [];
            var invalidFilesArray = [];

            validatedFiles.forEach((element, index) => {
              if (element.isValidFile) {
                var fileFile = this.generateFileToFilesValidator(element.base64, element.fileName, element.originType, element.lastModified);
                validFilesArray.push(fileFile);
              } else {
                var fileFile = this.generateFileToFilesValidator(element.base64, element.fileName, element.originType, element.lastModified);
                invalidFilesArray.push(fileFile);
              }
            });

            this.uploadFile(validFilesArray, true, invalidFilesArray);
          }
        });
      }
    });
  };

  enableDisableValidationByLibraryValue(){
    if (process.env.ENABLE_FILE_VALIDATION !== undefined && process.env.ENABLE_FILE_VALIDATION === 'false') {
      return false;
    }

    return true;
  }

  uploadFile(files: any, validationCompleted = false, invalidFilesArray = []) {
    let validationEnabled = this.enableDisableValidationByLibraryValue();

    if (validationEnabled && !validationCompleted) {
      this.filesValidator(files);
    }

    if (validationEnabled && validationCompleted) {
      const { selectedFiles } = this.state;
      const allowedFiles = ["png", "pdf", "jpg", "jpeg"];
      let inputFiles: Array<AttachedFile> = selectedFiles.slice();
      const invalidFiles: Array<AttachedFile> = [];
      const badNameFiles: Array<AttachedFile> = [];
      const wrongValidationFiles: Array<AttachedFile> = [];

      if (invalidFilesArray && invalidFilesArray.length && invalidFilesArray.length > 1) {

        [...invalidFilesArray].forEach((invalidFile, index) => {

          wrongValidationFiles.push({
            data: invalidFile,
            error: true,
            name: invalidFile.name,
            id: invalidFile.name,
            size: invalidFile.size,
            type: FileUtils.getExtension(invalidFile.name).toLowerCase(),
            creation_date: moment(invalidFile.lastModified).format("DD MMM YYYY"),
            errorType: "error",
          });
        });
      } else {
        if (invalidFilesArray && invalidFilesArray.length && invalidFilesArray.length > 0) {

          wrongValidationFiles.push({
            data: invalidFilesArray[0],
            error: true,
            name: invalidFilesArray[0].name,
            id: invalidFilesArray[0].name,
            size: invalidFilesArray[0].size,
            type: FileUtils.getExtension(invalidFilesArray[0].name).toLowerCase(),
            creation_date: moment(invalidFilesArray[0].lastModified).format("DD MMM YYYY"),
            errorType: "error",
          });
        }
      }

      if (files && files.length && files.length > 1) {
        [...files].forEach((file, index) => {
          this.filesCounter.current++;
          let error = false;
          let errorType = "";

          if (
            allowedFiles.includes(FileUtils.getExtension(file.name).toLowerCase())
          ) {

            if (
              FileUtils.getExtension(file.name).toLowerCase() === "pdf" &&
              !isValidSize(file)
            ) {
              error = true;
              errorType = "error";
              invalidFiles.push({
                data: file,
                error,
                name: file.name,
                id: file.name + this.filesCounter.current,
                size: file.size,
                type: FileUtils.getExtension(file.name).toLowerCase(),
                creation_date: moment(file.lastModified).format("DD MMM YYYY"),
                errorType,
              });
            } else if (!isValidFileName(file.name)) {
              error = true;
              errorType = "warning";
              badNameFiles.push({
                data: file,
                error,
                name: file.name,
                id: file.name + this.filesCounter.current,
                size: file.size,
                type: FileUtils.getExtension(file.name).toLowerCase(),
                creation_date: moment(file.lastModified).format("DD MMM YYYY"),
                errorType,
              });
            }

            if (errorType !== "error") {
              inputFiles.push({
                data: file,
                error,
                name: file.name,
                id: file.name + this.filesCounter.current,
                size: file.size,
                type: FileUtils.getExtension(file.name).toLowerCase(),
                creation_date: moment(file.lastModified).format("DD MMM YYYY"),
                errorType,
              });
            }
          }
        });
      } else {
        if (files && files.length && files.length > 0) {
          let error = false;
          let errorType = "";
          if (
            files[0] &&
            allowedFiles.includes(
              FileUtils.getExtension(files[0].name).toLowerCase()
            )
          ) {
            if (
              FileUtils.getExtension(files[0].name).toLowerCase() === "pdf" &&
              !isValidSize(files[0])
            ) {
              error = true;
              errorType = "error";
              invalidFiles.push({
                data: files[0].name,
                error,
                name: files[0].name,
                id: files[0].name + this.filesCounter.current,
                size: files[0].size,
                type: FileUtils.getExtension(files[0].name).toLowerCase(),
                creation_date: moment(files[0].lastModified).format("DD MMM YYYY"),
                errorType,
              });
            } else if (!isValidFileName(files[0].name)) {
              error = true;
              errorType = "warning";
              badNameFiles.push({
                data: files[0],
                error,
                name: files[0].name,
                id: files[0].name + this.filesCounter.current,
                size: files[0].size,
                type: FileUtils.getExtension(files[0].name).toLowerCase(),
                creation_date: moment(files[0].lastModified).format("DD MMM YYYY"),
                errorType,
              });
            }

            if (errorType !== "error") {
              inputFiles = [
                {
                  data: files[0],
                  error,
                  errorType,
                  name: files[0].name,
                  size: files[0].size,
                  type: FileUtils.getExtension(files[0].name).toLowerCase(),
                  creation_date: moment(files[0].lastModified).format(
                    "DD MMM YYYY"
                  ),
                },
              ];
            }
          }
        }
      }

      if (invalidFiles.length > 0) {
        this.setState({
          selectedFiles: inputFiles,
          filesWithBadName: inputFiles.filter(
            (file) => file.errorType === "warning"
          ),
          activeSecondaryModal: "errorFiles",
          modalError: {
            onConfirm: () => {
              if (invalidFiles.length > 0) {
                this.setState({
                  modalError: {
                    onConfirm: () => {
                      if (wrongValidationFiles.length > 0) {
                        this.setState({
                          modalError: {
                            onConfirm: () => this.setState({ activeSecondaryModal: "" }),
                            files: wrongValidationFiles,
                            title: "No se ha podido adjuntar el archivo. Por favor, revise si es correcto e inténtelo de nuevo",
                          },
                        });
                      } else this.setState({ activeSecondaryModal: "" })
                    },
                    files: invalidFiles,
                    title: "Los siguientes archivos no se subirán al sistema debido a que ha pasado la capacidad de 7MB.",
                  },
                });
              } else this.setState({ activeSecondaryModal: "" })
            },
            files: invalidFiles,
            title:
            `El nombre del archivo no puede contener tildes, espacios en blanco y 
            los siguientes caracteres: ¡¢£€¤¥§¨©ª«¬®¯°±´µ¶·¸º»ñ¿ÆÇØß÷₫`,
          },
        });

      } else if (wrongValidationFiles.length > 0) {
        this.setState({
          selectedFiles: inputFiles,
          filesWithBadName: inputFiles.filter(
            (file) => file.errorType === "warning"
          ),
          activeSecondaryModal: "errorFiles",
          modalError: {
            onConfirm: () => {
              if (badNameFiles.length > 0) {
                this.setState({
                  modalError: {
                    onConfirm: () => this.setState({ activeSecondaryModal: "" }),
                    files: badNameFiles,
                    title: `El nombre del archivo no puede contener tildes, espacios en blanco y 
                      los siguientes caracteres: ¡¢£€¤¥§¨©ª«¬®¯°±´µ¶·¸º»ñ¿ÆÇØß÷₫`,
                  },
                });
              } else this.setState({ activeSecondaryModal: "" });
            },
            files: wrongValidationFiles,
            title: "No se ha podido adjuntar el archivo. Por favor, revise si es correcto e inténtelo de nuevo",
          },
        });
      } else if (badNameFiles.length > 0) {
        this.setState({
          selectedFiles: inputFiles,
          activeSecondaryModal: "errorFiles",
          filesWithBadName: inputFiles.filter(
            (file) => file.errorType === "warning"
          ),
          modalError: {
            onConfirm: () => this.setState({ activeSecondaryModal: "" }),
            files: badNameFiles,
            title: `El nombre del archivo no puede contener tildes, espacios en blanco y 
                      los siguientes caracteres: ¡¢£€¤¥§¨©ª«¬®¯°±´µ¶·¸º»ñ¿ÆÇØß÷₫`,
          },
        });
      } else {
        this.setState({
          selectedFiles: inputFiles,
        });
      }
    }

    if (!validationEnabled) {
      const { selectedFiles } = this.state;
      const allowedFiles = ["png", "pdf", "jpg", "jpeg"];
      let inputFiles: Array<AttachedFile> = selectedFiles.slice();
      const invalidFiles: Array<AttachedFile> = [];
      const badNameFiles: Array<AttachedFile> = [];

      if (this.props.multiple !== false) {
        [...files].forEach((file, index) => {
          this.filesCounter.current++;
          let error = false;
          let errorType = "";

          if (
            allowedFiles.includes(FileUtils.getExtension(file.name).toLowerCase())
          ) {
            if (
              FileUtils.getExtension(file.name).toLowerCase() === "pdf" &&
              !isValidSize(file)
            ) {
              error = true;
              errorType = "error";
              invalidFiles.push({
                data: file,
                error,
                name: file.name,
                id: file.name + this.filesCounter.current,
                size: file.size,
                type: FileUtils.getExtension(file.name).toLowerCase(),
                creation_date: moment(file.lastModified).format("DD MMM YYYY"),
                errorType,
              });
            } else if (!isValidFileName(file.name)) {
              error = true;
              errorType = "warning";
              badNameFiles.push({
                data: file,
                error,
                name: file.name,
                id: file.name + this.filesCounter.current,
                size: file.size,
                type: FileUtils.getExtension(file.name).toLowerCase(),
                creation_date: moment(file.lastModified).format("DD MMM YYYY"),
                errorType,
              });
            }

            if (errorType !== "error") {
              inputFiles.push({
                data: file,
                error,
                name: file.name,
                id: file.name + this.filesCounter.current,
                size: file.size,
                type: FileUtils.getExtension(file.name).toLowerCase(),
                creation_date: moment(file.lastModified).format("DD MMM YYYY"),
                errorType,
              });
            }
          }
        });
      } else {
        let error = false;
        let errorType = "";
        if (
          files[0] &&
          allowedFiles.includes(
            FileUtils.getExtension(files[0].name).toLowerCase()
          )
        ) {
          if (
            FileUtils.getExtension(files[0].name).toLowerCase() === "pdf" &&
            !isValidSize(files[0])
          ) {
            error = true;
            errorType = "error";
            invalidFiles.push({
              data: files[0].name,
              error,
              name: files[0].name,
              id: files[0].name + this.filesCounter.current,
              size: files[0].size,
              type: FileUtils.getExtension(files[0].name).toLowerCase(),
              creation_date: moment(files[0].lastModified).format("DD MMM YYYY"),
              errorType,
            });
          } else if (!isValidFileName(files[0].name)) {
            error = true;
            errorType = "warning";
            badNameFiles.push({
              data: files[0],
              error,
              name: files[0].name,
              id: files[0].name + this.filesCounter.current,
              size: files[0].size,
              type: FileUtils.getExtension(files[0].name).toLowerCase(),
              creation_date: moment(files[0].lastModified).format("DD MMM YYYY"),
              errorType,
            });
          }

          if (errorType !== "error") {
            inputFiles = [
              {
                data: files[0],
                error,
                errorType,
                name: files[0].name,
                size: files[0].size,
                type: FileUtils.getExtension(files[0].name).toLowerCase(),
                creation_date: moment(files[0].lastModified).format(
                  "DD MMM YYYY"
                ),
              },
            ];
          }
        }
      }

      if (invalidFiles.length > 0) {
        this.setState({
          selectedFiles: inputFiles,
          filesWithBadName: inputFiles.filter(
            (file) => file.errorType === "warning"
          ),
          activeSecondaryModal: "errorFiles",
          modalError: {
            onConfirm: () => {
              if (badNameFiles.length > 0) {
                this.setState({
                  modalError: {
                    onConfirm: () => this.setState({ activeSecondaryModal: "" }),
                    files: badNameFiles,
                    title: `El nombre del archivo no puede contener tildes, espacios en blanco y 
                      los siguientes caracteres: ¡¢£€¤¥§¨©ª«¬®¯°±´µ¶·¸º»ñ¿ÆÇØß÷₫`,
                  },
                });
              } else this.setState({ activeSecondaryModal: "" });
            },
            files: invalidFiles,
            title:
              "Los siguientes archivos no se subirán al sistema debido a que ha pasado la capacidad de 7MB.",
          },
        });
      } else if (badNameFiles.length > 0) {
        this.setState({
          selectedFiles: inputFiles,
          activeSecondaryModal: "errorFiles",
          filesWithBadName: inputFiles.filter(
            (file) => file.errorType === "warning"
          ),
          modalError: {
            onConfirm: () => this.setState({ activeSecondaryModal: "" }),
            files: badNameFiles,
            title: `El nombre del archivo no puede contener tildes, espacios en blanco y 
                      los siguientes caracteres: ¡¢£€¤¥§¨©ª«¬®¯°±´µ¶·¸º»ñ¿ÆÇØß÷₫`,
          },
        });
      } else {
        this.setState({
          selectedFiles: inputFiles,
        });
      }
    }
  }

  removeFile(item: any) {
    const { selectedFiles, filesWithBadName } = this.state;
    const tempFiles = selectedFiles.filter((e) => e !== item);
    const badNameFiles = filesWithBadName.filter((e) => e !== item);
    this.setState({ selectedFiles: tempFiles, filesWithBadName: badNameFiles });
  }


  onEdit = (id: string, newName: string) => {

    if (!newName) return;
    const { filesWithBadName, selectedFiles } = this.state;

    const newFilesWithBadName = cloneDeep(filesWithBadName);
    const fileIndex = newFilesWithBadName.findIndex(
      (fileWithBadName) => fileWithBadName.id === id
    );

    const fileCopy = new File([newFilesWithBadName[fileIndex].data], newName, {
      type: newFilesWithBadName[fileIndex].data.type,
    });
    newFilesWithBadName[fileIndex].data = fileCopy;
    newFilesWithBadName[fileIndex].name = newName;
    newFilesWithBadName[fileIndex].errorType = "";
    newFilesWithBadName[fileIndex].error = false;

    const newValidatedFiles = selectedFiles.map(
      (selectedFile) =>
        newFilesWithBadName.find(
          (fileWithBadName) => selectedFile.id === fileWithBadName.id
        ) || selectedFile
    );

    newFilesWithBadName.splice(fileIndex, 1);

    this.setState({
      selectedFiles: newValidatedFiles,
      filesWithBadName: newFilesWithBadName,
    });
  };

  confirmFiles = () => {
    const { selectedFiles } = this.state;
    const { onConfirm } = this.props;

    if (selectedFiles.some((file) => file.errorType === "warning")) {
      const filesWithBadName = selectedFiles.filter(
        (file) => file.errorType === "warning"
      );
      this.setState({
        activeSecondaryModal: "fileValidation",
        filesWithBadName,
      });
    } else {
      let tempFiles = selectedFiles.slice();
      this.setState({ selectedFiles: [] }, () => {
        onConfirm(tempFiles);
      });
    }
  }

  render() {
    const { onClose, showModal, multiple, notDisabled } = this.props;
    const {
      selectedFiles,
      filesWithBadName,
      activeSecondaryModal,
    } = this.state;

    const errorFiles = selectedFiles.some((file) => file.error);
    const filesCount = selectedFiles.length;
    return (
      <>
        <ModalUploadFilesContainer
          className={classNames({ active: showModal && !activeSecondaryModal })}
        >
          <div className="item-modal-message-container">
            <div className="item-modal-message-top">
              <div className="item-modal-message-top__title">
                <p>Subir archivos</p>
              </div>
              <div
                className="item-modal-message-top__close"
                onClick={() => onClose()}
              >
                <img src={icons.iconClose} alt="" />
              </div>
            </div>
            <div className="item-modal-message-drag">
              <form className="uploader" encType="multipart/form-data">
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e) => {
                    this.uploadFile(e.target.files);
                    e.target.value = "";
                  }}
                  multiple={multiple !== false}
                  accept="application/pdf, image/png, image/jpg, image/jpeg"
                />
              </form>

              <div className="item-modal-message-drag__icon">
                <img src={icons.IconDocumentBlue} alt="" />
              </div>
              <div className="item-modal-message-drag__text">
                <div className="item-modal-message-drag__text-title">
                  <p>
                    Arrastra o <span>selecciona </span>
                    el archivo que quieras subir
                  </p>
                </div>
                <div className="item-modal-message-drag__text-rules">
                  <p>
                    Formatos admitidos: png, pdf, jpg, jpeg <br />
                    El nombre del archivo no puede contener tildes, espacios en
                    blanco y siguientes caracteres:
                    ¡¢£€¤¥§¨©ª«¬®¯°±´µ¶·¸º»ñ¿ÆÇØß÷₫
                    <br />
                    Tamaño máximo (PDF): 7MB
                  </p>
                </div>
              </div>
            </div>

            <div className="item-modal-message-docs">
              {selectedFiles
                // .sort((fileA, fileB) => (fileB.errorType ? -1 : 1))
                .filter(file => !file.error)
                .map((item, i) => {
                  if (item.data === null) {
                    return <></>;
                  }
                  return (
                    <FileItemModal
                      key={item.id}
                      file={item}
                      onRemove={(file) => this.removeFile(file)}
                      errorType={item.errorType}
                    />
                  );
                })}
                {filesWithBadName.map((item) => {
                    if (item.data === null) {
                      return <></>;
                    }
                    return (
                      <FileItemModal
                        key={item.id}
                        file={item}
                        onRemove={(file) => this.removeFile(file)}
                        errorType={item.errorType}
                      />
                    );
                  })}
            </div>

            {filesWithBadName.length > 0 && (
              <div className="item-modal-message-bad-docs">
                <div className="item-modal-message-bad-docs__title">
                  <p>Para agilizar el cambio, te <span>proponemos el nuevo nombre</span>:</p>
                </div>

                <div className="item-modal-message-docs">
                  {filesWithBadName.map((item) => {
                    if (item.data === null) {
                      return <></>;
                    }
                    return (
                      <FileItemModal
                        key={item.id}
                        file={item}
                        onRemove={(file) => this.removeFile(file)}
                        errorType={item.errorType}
                        onEditName={this.onEdit}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            <div className="item-modal-message-bottom">
              <div className="item-modal-message-button">
                <MainButton
                  type={
                    "dark-blue " +
                    (!notDisabled && (errorFiles || filesCount <= 0) ? "disabled" : "")
                  }
                  text="Aceptar"
                  disabled={!notDisabled && (errorFiles || filesCount <= 0)}
                  onClick={this.confirmFiles}
                />
              </div>
              <div className="item-modal-message-counter">
                <div className="item-modal-message-counter__number">
                  <p>{filesCount}</p>
                </div>

                <div className="item-modal-message-counter__icon">
                  <img src={icons.IconClipBlue} alt="" />
                </div>
              </div>
            </div>
          </div>
        </ModalUploadFilesContainer>

        {/* EXTRA MODALS */}

        <ModalFileValidation
          showModal={activeSecondaryModal === "errorFiles"}
          {...this.state.modalError}
        />

        {/* ///////////// */}
      </>
    );
  }
}
