import React from "react";
import { Redirect, Route, withRouter, RouteComponentProps } from "react-router-dom";
import ExpiredSessionModal from "../modals/expired-session-modal";

interface PrivateRouteProps extends RouteComponentProps {
  component: any;
  expiredToken: any;
  [key: string]: any;
}

const PrivateRoute = ({ component: Component, expiredToken, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("accessToken") ? (
        expiredToken ? (
          <ExpiredSessionModal
            title={"¡Su sesión ha caducado!"}
            text={"Es necesario que inicie la sesión de nuevo para poder continuar la navegación"}
            onClickAccept={() => props.history.push("/")}
            buttonText="Si, salir"
          />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default withRouter(PrivateRoute);
