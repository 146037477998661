import styled from "styled-components";
import { colors } from "../../../assets/colors";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";

export const RequestModalContainer = styled.div`
  display: none;
  color: ${colors["PR—001-100"]};
  position: fixed;
  z-index: 9999;
  background: #ffffffe0;
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  font-family: "museo sans";
  bottom: 0;
  min-height: 100vh;

  &.active {
    display: flex;
  }

  .request-modal-top-row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: space-between;
  }

  .top-row-title {
    font-size: 32px;
    color: #091e42;
  }

  .request-modal-main {
    margin-bottom: 52px;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;
    color: #091e42;
  }

  .request-modal-veil {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    z-index: 99;
    bottom: 0;
  }

  ${MainButtonContainer} {
    text-transform: capitalize;
  }

  .request-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 40px;
    max-width: 740px;
    position: relative;
    z-index: 999;
  }

  .top-row-img {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .request-modal-button {
    ${MainButtonContainer} {
      max-width: 160px;
    }
  }

  @media (max-width: 500px) {
    background: #ffffffe0;

    .request-modal-container {
      width: 90%;
      margin: 0 auto;
      padding: 24px;
    }

    .request-modal-button {
      ${MainButtonContainer} {
        font-size: 12px;
        width: auto;
        height: auto;
        min-width: 0;
        padding: 16px;
      }
    }
  }
`;
