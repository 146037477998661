import styled from "styled-components";
import { colors } from "../../../assets/colors";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";

export const LogOutModalContainer = styled.div`
  display: none;
  color: ${colors["PR—001-100"]};
  position: fixed;
  z-index: 9999;
  background: #ffffffe0;
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  font-family: "museo sans";
  bottom: 0;
  min-height: 100vh;

  &.active {
    display: flex;
  }

  &.error {
  }

  .logout-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 40px;
    max-width: 620px;
    width: 100%;
  }

  .logout-modal-close {
    text-align: right;

    img {
      max-width: 22px;
      cursor: pointer;
    }
  }

  .logout-modal-icon {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logout-modal-icon-bg {
    display: inline-block;
    background: ${colors["PR—001-30"]};
    width: 62px;
    height: 62px;
    background: #b3cadc4d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
    }
  }

  .logout-modal-title {
    margin: 50px auto 60px;
    font-size: 28px;
    line-height: 36px;
    color: #091e42;
    max-width: 277px;
    text-align: center;
  }

  .logout-modal-button {
    ${MainButtonContainer} {
      max-width: 160px;
      margin: 0 auto;
    }
  }

  @media (max-width: 900px) {
    .logout-modal-container {
      width: 90%;
      margin: 0 auto;
      padding: 26px 40px 40px;
    }

    .logout-modal-title {
      font-size: 18px;
      line-height: 1.5em;
      margin: 0 auto 30px;
    }

    .logout-modal-close {
      text-align: right;
      margin-bottom: 12px;

      img {
        transform: translate(22px, -10px);
        max-width: 18px;
      }
    }

    .logout-modal-button {
      ${MainButtonContainer} {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 450px) {
    .logout-modal-close {
      margin-bottom: 20px;
    }
    .logout-modal-title {
      margin-bottom: 16px;
      font-size: 16px;
    }
    .logout-modal-button {
      ${MainButtonContainer} {
        height: 40px;
        max-height: none;
        font-size: 12px;
      }
    }
  }
`;
