import React from 'react';
import PropTypes from 'prop-types';
import { InputSelectContainer, InputSelectList } from './input-select-style';
import { images } from '../../../assets/images';

class Dropdown extends React.Component<any, any> {
  constructor(props:any) {
    super(props)

    let message = '';

    if (props.texts.message) ({ message } = props.texts);

    this.state = {
      error: false,
      isFocused: false,
      message: '',
      value: '',
    }
  }

  componentDidMount() {
    if (this.props.value && this.props.value !== '')
      this.setState({ value: this.props.value })
  }

  componentDidUpdate(prevProps:any , prevState:any) {
    const { value } = this.state

    // if (value !== '' && value !== prevState.value) this.validate()
  }

  clearError() {
    const { texts } = this.props;
    const message = texts.message || '';

    this.setState({
      error: false,
      isFocused: true,
      message: ''
    })
  }

  // validate() {
  //   const { value } = this.state;
  //   const { required } = this.props;
  //   const { errors } = lang.inputs;
  //   const newState = {
  //     isFocused: false,
  //     value,
  //   }

  //   if (required && value === '') {
  //     newState.error = true;
  //     newState.message = errors.required;
  //   }

  //   this.setState(newState, this.sendValue(!newState.error))
  // }

  sendValue(shouldSend:any) {
    const { value } = this.state;
    const { field, onBlur } = this.props;

    if (shouldSend) onBlur(field, value);
  }

  setValue(value:any) {
    const { field, onBlur } = this.props;
    this.setState(
      {
        isFocused: false,
        value,
        error: false,
      },
      () => {
        onBlur(field, value)
      }
    )
  }

  setSelected() {
    const { items } = this.props
    let selected = ''

    items.forEach((item:any) => {
      if (item.value === this.state.value) selected = item.label
    })

    return selected
  }

  renderTitle(text:any) {
    return (
      <div className="input-s-title">
        <p>{text}</p>
      </div>
    )
  }

  renderPicker() {
    const { items } = this.props

    return (
      <InputSelectList active={this.state.isFocused}>
        {items.map((item:any) => (
          <div
            key={item.value}
            className="input-select-list-item"
            onClick={() => this.setValue(item.value)}
            // type="button"
          >
            {item.label}
          </div>
        ))}
      </InputSelectList>
    )
  }

  // renderMessage() {
  //   const { error, message } = this.state

  //   if (message === '') return null

  //   const style = [{}]

  //   if (error) style.push({ color: Colors.RED_CTA })

  //   return (
  //     <div>
  //       <img src={error ? DOT_RED : DOT_GREY} alt="" />
  //       <p style={style}>{message}</p>
  //     </div>
  //   )
  // }

  render() {
    const {
      style,
      texts,
      value: inheritedValue,
      inputType,
      name,
      id,
      placeholder,
      onChange,
      onInput,
    } = this.props
    const { error, isFocused, value } = this.state
    const inputStyle = [{}]
    const inputTextStyle = [{}]

    if (inheritedValue && inheritedValue !== '' && !texts.placeholder)
      texts.placeholder = ''

    // if (error) {
    //   inputStyle.push({})
    // } else if (isFocused || value !== '') {
    //   inputStyle.push({})
    // }

    // if (error) {
    //   inputTextStyle.push({ color: Colors.NEUTRAL800 })
    // } else if (value === '') {
    //   inputTextStyle.push({ color: Colors.NEUTRAL700 })
    // }

    return (
      // <div style={style}>
      <InputSelectContainer>
        {texts.label && this.renderTitle(texts.label)}

        <div
          className="input-s-input"
          onClick={() => this.setState({ isFocused: !isFocused })}
        >
          <input
            type={inputType}
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e)}
            onInput={e => onInput(e)}
          />
          <div className="input-select-arrow">
            {/* <img src={images.IconArrowDownBlue} alt="flecha desplegable" /> */}
          </div>
        </div>
        {/* <div className="input-s-error">{this.renderMessage()}</div> */}

        {value !== '' ? this.setSelected() : texts.placeholder}

        {this.renderPicker()}
      </InputSelectContainer>
    )
  }
}

// Dropdown.propTypes = {
//   // Indica la key que debe pasársele a la función onBlur para guardar
//   // El valor en el estado del padre
//   field: PropTypes.string.isRequired,
//   // Array de objectos a mostrar en el dropdown
//   items: PropTypes.array.isRequired,
//   // Función a ejecutar cuando el dropdown pierda el foco
//   // Generalmente es la función para guardar el valor en el estado del padre
//   onBlur: PropTypes.func.isRequired,
//   // Indica si seleccionar algo en el dropdown es obligatorio o no
//   required: PropTypes.bool,
//   // Objecto con los estilos que tiene que heredar el dropdown
//   // Igual en web habría que cambiarlo por una clase que añadirle al dropdown
//   style: PropTypes.object,
//   // Objecto con los textos para la label y el placeholder del dropdown
//   texts: PropTypes.object.isRequired,
//   // Valor que se quiera mostrar en el dropdown al cargarlo
//   value: PropTypes.string,
// }

// Dropdown.defaultProps = {
//   required: false,
//   style: {},
//   value: '',
// }

export default Dropdown
