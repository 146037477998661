import styled, {
    css
} from 'styled-components';

export const Body03TextContainer = styled.div `
    font-family: "museo sans";
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;

    ${props => css`
        color: ${props.color}
    `}




`;