import styled from "styled-components";
import { colors } from "../../../assets/colors";

export const CardRequestContainer = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors["PR-001-100"]};
  padding: 28px 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);

  &.resolved {
    background: #f7f9fa;
    .card-request-bottom {
      &__name  {
        color: #091e42;
      }
      &__info {
        color: #091e42;
        &__code {
          &:before {
            background: ${colors["PR-001-100"]};
          }
        }
      }
    }
  }

  &.deny {
    .card-request-bottom {
      &__state {
        color: #ff4040;
        &:before {
          background: #ff4040;
        }
      }
    }
  }

  &.accepted {
    background: #f7f9fa;
    .card-request-bottom {
      &__name {
        color: #091e42;
      }
      &__info {
        color: #091e42;
      }
      &__state {
        color: #00b98c;
        padding: 8px 18px 8px 38px;
        display: inline-block;
        border-radius: 6px;
        &:before {
          background: #00b98c;
          top: 50%;
          left: 6px;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.in-progress {
    .card-request-top {
      &__text {
        padding-right: 32px;
        &.dental {
          color: #fff;
        }
      }
    }

    .card-request-bottom {
      &__state {
        color: #ffa800;
        background: #fff;
        padding: 8px 18px 8px 38px;
        display: inline-block;
        border-radius: 6px;
        &:before {
          background: #ffa800;
          top: 50%;
          left: 6px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .card-request-top {
    display: flex;
    justify-content: space-between;
    &__icon {
      display: flex;
      width: 32px;
      height: 32px;
      &__left {
        min-width: 32px;
      }
      &__right {
        min-width: 32px;
      }
    }
    &__text {
      font-family: "museo sans";
      width: 100%;
      line-height: 17px;
      font-size: 14px;
      margin: 0 10px;

      &__title {
        font-weight: 300;
        color: #01B2EA;
      }
      &__subtitle {
        font-weight: 700;
        color: #01B2EA;
      }

      &.dental {
        color: ${colors["SC-001-100"]};
      }
      &.health {
        color: ${colors["SC-003-100"]};
      }
    }
  }

  .card-request-bottom {
    &__name  {
      font-family: "museo sans";
      line-height: 30px;
      font-size: 22px;
      color: #ffffff;
      font-weight: 700;
      margin-bottom: 12px;
    }
    &__info {
      font-family: "museo sans";
      line-height: normal;
      font-weight: 300;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      margin-bottom: 24px;
      &__code {
        padding-right: 6px;
        margin-right: 6px;
        position: relative;
        &:before {
          content: "";
          width: 1px;
          height: 12px;
          background: white;
          border-radius: 3px;
          position: absolute;
          right: -1px;
          top: 2px;
        }
      }
    }
    &__state {
      font-family: "museo sans";
      line-height: normal;
      font-size: 12px;
      font-weight: 500;
      color: #ffa800;
      padding-left: 30px;
      position: relative;
      text-transform: uppercase;

      &:before {
        content: "";
        width: 19px;
        height: 2px;
        background: #ffa800;
        border-radius: 3px;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    .card-request-top {
      margin-bottom: 20px;
    }
    .card-request-bottom {
      &__name {
        font-size: 18px;
      }
    }
  }
`;
