import styled from "styled-components";
import { icons } from "../../../assets/icons";
import { LabelTextContainer } from "../../text/label-text/label-text-style";

export const InputProfileContainer = styled.div`
  display: flex;
  &.error {
    /* color: #ff4100; */
    .input-box-main-field {
      input {
        border-bottom: 1px solid #ff4100;
        color: #ff4100;
      }
    }
  }

  &.edit {
    .input-box-main-field {
      input {
        border-bottom: 1px solid #49abb8;
        color: #49abb8;
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: #b5bcc6;
    }

    .input-box-main-field {
      background: #f7f7f7;

      input {
        border-bottom: 1px solid #b5bcc6;
        color: #b5bcc6;
      }

      &:before {
        background: #b5bcc6;
      }
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    font-size: 14px;
    text-transform: uppercase;
    color: #6b778c;
    padding: 2px 0;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
  }

  .input-box-main-field-email {
    color: #0064bd;
    text-decoration: underline;
  }

  input {
    font-family: "museo sans";
    width: 100%;
    font-weight: 300;
    padding: 0px 0 9px;
    padding-right: 16px;
    transition: all 0.2s ease-in-out;
    height: 34px;
    border-bottom: 1px solid #cecece00;
    font-size: 18px;
    line-height: 24px;
    color: #091e42;

    &:focus {
      color: #49abb8;
      border-bottom: 1px solid #49abb8;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-error {
    color: #ff4100;
    min-height: 17px;
    margin-top: 4px;
  }

  .input-profile-left {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 41px;
    height: 41px;
    overflow: visible;
    margin-right: 24px;

    background-size: contain;
    background-repeat: no-repeat;
  }
  .input-profile-right {
    width: 100%;
  }

  &.card {
    .input-profile-left {
      background-image: url(${icons.IconProfileCard});
    }
  }
  &.cake {
    .input-profile-left {
      background-image: url(${icons.IconProfileCake});
    }
  }
  &.location {
    .input-profile-left {
      background-image: url(${icons.IconProfileLocation});
    }
  }
  &.user {
    &.error {
      .input-profile-left {
        background-image: url(${icons.IconProfileUserRed});
      }
    }
    &.edit {
      .input-profile-left {
        background-image: url(${icons.IconProfileUserBlue});
      }
    }

    .input-profile-left {
      background-image: url(${icons.IconProfileUser});
    }
  }
  &.phone {
    &.error {
      .input-profile-left {
        background-image: url(${icons.IconProfilePhoneRed});
      }
    }
    &.edit {
      .input-profile-left {
        background-image: url(${icons.IconProfilePhoneBlue});
      }
    }

    .input-profile-left {
      background-image: url(${icons.IconProfilePhone});
    }
  }
  &.mail {
    &.error {
      .input-profile-left {
        background-image: url(${icons.IconProfileMailRed});
      }
    }
    &.edit {
      .input-profile-left {
        background-image: url(${icons.IconProfileMailBlue});
      }
    }

    .input-profile-left {
      background-image: url(${icons.IconProfileMail});
    }
  }

  @media (max-width: 900px) {
    input {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    .input-box-topbar {
      margin-bottom: 6px;
    }

    .input-box-topbar-label {
      ${LabelTextContainer} {
        font-size: 12px;
      }
    }

    input {
      font-size: 14px;
    }
  }
`;
