import React, { useState } from "react";
import { TooltipHooksContainer } from "./tooltip-hooks-style";

const TooltipHook = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 50);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipHooksContainer>
      <div
        className="Tooltip-Wrapper"
        // When to show the tooltip
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
      >
        {/* Wrapping */}
        {props.children}
        {active && (
          <div className={`Tooltip-Tip ${props.direction || "top"}`}>
            {/* Content */}
            {props.content}
          </div>
        )}
      </div>
    </TooltipHooksContainer>
  );
};

export default TooltipHook;
