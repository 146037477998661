import React, { Component, CSSProperties } from "react";

interface IAutoTextareaProps {
    onChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder: string;
}

class AutoTextarea extends Component<IAutoTextareaProps> {
  refTextarea: React.RefObject<HTMLTextAreaElement>;
  state = {
    textAreaHeight: "auto",
    parentHeight: "auto",
    text: "",
  };
  constructor(props) {
    super(props);
    this.refTextarea = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.text !== this.state.text) {
      this.setState({
        parentHeight: `${this.refTextarea.current.scrollHeight}px`,
        textAreaHeight: `${this.refTextarea.current.scrollHeight}px`,
      });
    }
  }

  textAreaStyle: CSSProperties = {
    height: this.state.textAreaHeight,
  };

  parentStyle: CSSProperties = {
    minHeight: this.state.parentHeight,
  };

  changeTextareaHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(event);
    this.setState({
      textAreaHeight: "auto",
      parentHeight: `${this.refTextarea.current.scrollHeight}px`,
      text: event.target.value,
    });
  };

  render() {
    return (
      <div style={{minHeight: this.state.parentHeight, width: '100%'}}>
        <textarea
          placeholder={this.props.placeholder}
          ref={this.refTextarea}
          style={{
              height: this.state.textAreaHeight,
          }}
          rows={1}
          onChange={this.changeTextareaHandler}
        />
      </div>
    );
  }
}

export default AutoTextarea;
