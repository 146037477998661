import { SERVICES_URL, SERVICES_KEY } from "../../env";
import axios from "axios";
import { store } from "../../redux/store";
import { setLoading } from "../../redux/actions";

export interface GetUserRequestParams {
  id: string;
  dni?: string;
  birthDate?: string;
  loading?: boolean;
  policyNumber?: string;
  policyOrder?: string;
}

interface Insured {
  firstname: string;
  lastname: string;
  email: string;
  birthDate: string;
  streetType: string;
  address: string;
  postalCode: string;
  city: string;
  province: string;
  country: string;
  documentType: string;
  dni: string;
  telephoneNumber: string;
  mobilePhoneNumber: string;
  gender: string;
}

export interface GetUserRequestResponse {
  accidentId: string;
  policyId: string;
  policyControl: number;
  policyOrder: number;
  insuredPhone: string;
  insuredMail: string;
  bankId: string;
  bankPlace: string;
  bankControl: string;
  bankAccount: string;
  accidentType: string;
  accidentDescription: string;
  accidentDate: string;
  accidentStatus: string;
  accidentStatusDescription: string;
  accidentAmount: number;
  insured: Insured;
}

export const getUserRequests = (params: GetUserRequestParams) => {
  const endpoint = `insureds/${params.id}/health/accidents`;
  store.dispatch(setLoading(params.loading !== false));
  return axios({
    method: "GET",
    url: `${SERVICES_URL}${endpoint}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};

export const getUserAccidentsRequests = (params: GetUserRequestParams) => {
  let endpoint = `insureds/${params.id}/health/accidents?dni=${params.dni}&birthDate=${params.birthDate}`;
  if (params.policyNumber) endpoint += `&policyNumber=${params.policyNumber}`;
  if (params.policyOrder) endpoint += `&policyOrder=${params.policyOrder}`;

  store.dispatch(setLoading(params.loading !== false));
  return axios({
    method: "GET",
    url: `${SERVICES_URL}${endpoint}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};

export const getUserNamesRequests = (params: GetUserRequestParams) => {
  const endpoint = `insureds/${params.id}/health`;
  store.dispatch(setLoading(params.loading !== false));
  return axios({
    method: "GET",
    url: `${SERVICES_URL}${endpoint}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};
