import styled from "styled-components";

export const ItemDocumentDownloadContainer = styled.div`
  width: 100%;
  font-family: "museo sans";
  position: relative;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  .icon-container{
    margin-right: 15px;
    img{
      width: 25px;
      height: 25px;
    }
  }


  .name-container{
    width: 100%;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    line-break: anywhere;
  }

  .name-title {
    font-weight: 600;
  }
  .name-subtitle {
    margin-top: 6px;
    font-weight: 300;
  }

  .download-container{
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
  }

  .delete-container {
    cursor: pointer;
    img {
      width: 17px;
      height: auto;
    }
  }
 `;
