import React, { Component } from "react";
import { icons } from "../../../assets/icons";
import { FileItemModalContainer } from "./file-item-modal-styles";
import { AttachedFile } from "../../../screens/request-detail/types";
import MainButton from "../../buttons/main-button";
import classNames from "classnames";
import InputBox from "../../form-components/input-box";
import { onKeyDown } from "../../../utils/utils";
import { isValidFileName } from "../../../utils/validation";

interface ModalUploadFilesProps {
  file: AttachedFile;
  onRemove?: (file: AttachedFile) => void;
  onEditName?: (id: string, newFileName: string) => void;
  errorType?: string;
}

interface ModalChangeFileNameState {
  newFileName: string;
  changeName: boolean;
  inputError: string;
}

const uploadIcon = {
  warning: icons.IconDocumentYellow,
  error: icons.IconUploadError,
};

export default class FileItemModal extends Component<
  ModalUploadFilesProps,
  ModalChangeFileNameState
> {
  state: ModalChangeFileNameState = {
    newFileName: "",
    changeName: false,
    inputError: "",
  };

  componentDidMount() {
    const { file, onEditName } = this.props;
    if (file && onEditName && !this.state.changeName) {
      this.changeNameAutomatically(file.name);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { file } = this.props;

    if (prevState.changeName !== this.state.changeName) {
      if (!this.state.changeName) {
        this.setState({ inputError: "" });
        this.changeNameAutomatically(file.name);
      } else {
        this.setState({ newFileName: "" });
      }
    }
  }

  changeNameAutomatically = (fileName: string) => {
    const name = fileName.substring(0, fileName.lastIndexOf("."));
    const fileExtension = fileName.split(".").pop();
    const regex = /¡| |\+|¢|£|€|¤|¥|§|¨|©|ª|«|¬|®|¯|°|±|´|µ|¶|·|¸|º|»|¿|Æ|Ø|ß|æ|÷|ø|₫|À|Á|Â|Ã|Ä|Å|Ç|È|É|Ê|Ë|Ì|Í|Î|Ï|Ñ|Ò|Ó|Ô|Õ|Ö|Ù|Ú|Û|Ü|à|á|â|ã|ä|å|ç|è|é|ê|ë|ì|í|î|ï|ñ|ò|ó|ô|õ|ö|ù|ú|û|ü|ÿ|á|é|í|ó|ú|Á|É|Í|Ó|Ú/g;
    const specialCharRegex = /¡| |\+|¢|£|€|¤|¥|§|¨|©|ª|«|¬|®|¯|°|±|´|µ|¶|·|¸|º|»|¿|Æ|Ø|ß|æ|÷|ø|₫/g;
    if(!name.match(specialCharRegex)){
      let newName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "-");
      this.setState({ newFileName: `${newName}.${fileExtension}` || fileName });
    }else{
      let newName = name.replace(regex, "-").replace(/\s/g, "-");
      this.setState({ newFileName: `${newName}.${fileExtension}` || fileName });
    }
  };

  onChangeHandler = (newFileName: string) => {
    let inputError = "";
    if (!isValidFileName(newFileName)) {
      inputError = "El nombre contiene caracteres no permitidos";
    }
    this.setState({ newFileName, inputError });
  };

  onConfirmHandler = () => {
    const { newFileName } = this.state;
    const { onEditName, file } = this.props;
    // we check if the user inserted an extension to the name and discard it
    const name = newFileName.substring(0, newFileName.lastIndexOf("."));
    const fileExtension = file.name.split(".").pop();
    if (name) {
      onEditName(file.id, `${name}.${fileExtension}`);
    } else {
      onEditName(file.id, `${newFileName}.${fileExtension}`);
    }
  };

  invalidCharacters = (fileName: string) => {
      const regex = /¡| |\+|¢|£|€|¤|¥|§|¨|©|ª|«|¬|®|¯|°|±|´|µ|¶|·|¸|º|»|¿|À|Á|Â|Ã|Ä|Å|Æ|Ç|È|É|Ê|Ë|Ì|Í|Î|Ï|Ñ|Ò|Ó|Ô|Õ|Ö|Ø|Ù|Ú|Û|Ü|ß|à|á|â|ã|ä|å|æ|ç|è|é|ê|ë|ì|í|î|ï|ñ|ò|ó|ô|õ|ö|÷|ø|ù|ú|û|ü|ÿ|₫|á|é|í|ó|ú|Á|É|Í|Ó|Ú/g;
    if (fileName.match(regex)) {
      const errorsInName = fileName
        .match(regex)
        .join(" ")
        .split("");

      return [...new Set(errorsInName)]
        .join(",")
        .replace(/\s/g, "espacios en blanco");
    }
    return;
  };

  render() {
    const { newFileName, changeName, inputError } = this.state;
    const { file, onRemove, onEditName, errorType } = this.props;
    return (
      <FileItemModalContainer>
        <div
          className={classNames("message-answer-documents-item", {
            fail: errorType === "warning",
            error: errorType === "error",
          })}
        >
          {changeName ? (
            <div className="message-answer-documents-input">
              <InputBox
                value={newFileName}
                name="filename"
                labelText=""
                errorText={inputError}
                onChange={(name: string, value: string) =>
                  this.onChangeHandler(value)
                }
                onKeyDown={(ev: KeyboardEvent) =>
                  !inputError && onKeyDown(ev, "Enter", this.onConfirmHandler)
                }
              />
            </div>
          ) : (
            <>
              <div className="message-answer-documents-item__icon">
                <img
                  src={uploadIcon[errorType] || icons.IconDocumentBlue}
                  alt=""
                />
              </div>
              <div className="message-answer-documents-item__text">
                <div
                  className={classNames(
                    "message-answer-documents-item__text__title",
                    { "width-buttons": !!onEditName }
                  )}
                >
                  {onEditName ? (
                    <p>
                      {newFileName} <span>{`(${file.name})`}</span>
                    </p>
                  ) : (
                    <p>{file.name}</p>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="space-between" />

          {onRemove && !onEditName && (
            <div className={"message-answer-documents-item__icons flex-gap"}>
              <div
                className={classNames(
                  "message-answer-documents-item__icons-status",
                  {
                    warning: errorType === "warning",
                  }
                )}
              >
                <span className="tooltip-text">
                  Caracteres no permitidos: <br />
                  {this.invalidCharacters(file.name)}
                </span>
                <p
                  className={classNames(
                    "message-answer-documents-item__icons-warning-text",
                    {
                      active: errorType === "warning",
                    }
                  )}
                >
                  Error en la carga
                </p>
                <img
                  src={
                    errorType === "warning"
                      ? icons.IconUploadFail
                      : icons.IconUploadSuccess
                  }
                  alt="status button"
                />
              </div>
              <div
                className="message-answer-documents-item__icons-delete"
                onClick={() => {
                  onRemove(file);
                }}
              >
                <img src={icons.IconUploadDelete} alt="delete button" />
              </div>
            </div>
          )}

          {onEditName && errorType !== "error" && (
            <div className="edit-buttons flex-gap">
              <MainButton
                type="edit"
                text="Aceptar"
                disabled={!!inputError || !newFileName}
                onClick={this.onConfirmHandler}
              />
              <MainButton
                type={changeName ? "white-border" : "light-grey"}
                text={changeName ? "Cancelar" : "Rechazar y renombrar"}
                onClick={() => this.setState({ changeName: !changeName })}
              />
            </div>
          )}
        </div>
      </FileItemModalContainer>
    );
  }
}
