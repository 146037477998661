import React from "react";
import { LegalContainer } from "./styles";
import { SimpleLayout } from "../../components/wrappers/layout/layout-style";
import { IPoliticsProps } from "../../constants/data";
import { icons } from "../../assets/icons";
import { Link } from "react-router-dom";

export default class LegalLayout extends React.Component<IPoliticsProps> {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    const { goBackButton } = this.props;
    return (
      <>
        <SimpleLayout>
          <LegalContainer>
          { goBackButton && 
            <Link to="/">
              <div className="go-back">
                <img src={icons.iconArrowLeft} alt=""/>
              </div>
            </Link>
          }
            <div className="legal-title">
              <p>Avisos legales</p>
            </div>
            <div className="legal-text">
              <p>De acuerdo con los requisitos de información establecidos en el artículo 10 de la Ley 34/2002, de 11 de julio de Servicios de la Sociedad de Información y de Comercio electrónico, se informa al Usuario de lo siguiente:</p>
              <p>El propietario de este sitio es “ASISA, ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS S.A.U.”, con domicilio social en Madrid, Calle Juan Ignacio Luca de Tena, nº 12, y CIF: A-08169294 , inscrita en el Registro Mercantil de Madrid, en la hoja nº 38819-1,tomo 4892 general, Libro 4055 de Sociedades, sección 3ª.</p>
              <p></p>
            </div>
            <div className="legal-subtitle">
              <div className="legal-subtitle__number">
                <p>1</p>
              </div>
              <div className="legal-subtitle__text">
                <p>Usuarios</p>
              </div>
            </div>
            <div className="legal-text">
              <p>El acceso y/uso de la página web https://www.asisa.es atribuye al Usuario dicha condición y presupone que éste acepta con dicho acceso y/o utilización los términos y condiciones contenidos en este documento. Estos términos y condiciones estarán en vigor independientemente de aquellos que en su caso sean de aplicación.</p>
              <p>El sitio proporciona el acceso a multitud de información, servicios, programas o datos (en adelante, “contenido”) de ASISA o sus licenciantes a que los usuarios puedan acceder.</p>
              <p>El Usuario es responsable de aportar información veraz y lícita. Al registrarse y proporcionar una contraseña, el Usuario será responsable de hacer un uso diligente de la misma. El Usuario se compromete a hacer un uso adecuado de los contenidos y servicios (como por ejemplo servicios de chat, foros de discusión y grupos de noticias) que ASISA ofrezca en sus páginas web, obligándose a no utilizarlas para (incluyendo pero sin limitarse a) (i) participar en actividades ilegales o contrarias a la buena fe y al orden público; (ii) difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos; (iii) provocar daños en los sistemas físicos y lógicos de ASISA, de sus proveedores o de terceras personas, introducir o difundir virus informáticos u otro tipo de daños en los mencionados sistemas; (iv) intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes.</p>
              <p>ASISA se reserva el derecho de eliminar cualquier comentario y/o contribución que no respeten la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra los jóvenes o niños, el orden o la seguridad pública o que, en su exclusiva opinión, no sea adecuado para su publicación. En todos los casos, ASISA no se hace responsable de las opiniones vertidas por los usuarios a través de foros, chats, u otras herramientas de participación.</p>
              <p>ASISA se reserva el poder de realizar, siempre que sea necesario y sin previo aviso, modificaciones y actualizaciones de la información y condiciones contenidas en sus páginas web, así como en las condiciones de configuración y de presentación y acceso.</p>
              <p>La validez de las condiciones publicadas en el sitio web de ASISA irá en función de su exposición y estará sujeta a la modificación de las mismas por otras debidamente publicadas.</p>
              <p></p>
            </div>
            <div className="legal-subtitle">
              <div className="legal-subtitle__number">
                <p>2</p>
              </div>
              <div className="legal-subtitle__text">
                <p>Propiedad intelectual</p>
              </div>
            </div>
            <div className="legal-text">
              <p>ASISA, por sí misma o sus cesionarios, será la titular exclusiva de todos los derechos de propiedad intelectual de sus sitios web, así como de los elementos contenidos en los mismos (incluyendo pero sin limitarse a, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas necesarios para su funcionamiento, acceso y uso de la computadora, etc.)</p>
              <p>En virtud de las disposiciones de los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, se prohíbe expresamente la reproducción, distribución y comunicación pública, incluidos los medios para proporcionar la totalidad o parte de los contenidos este sitio con fines comerciales, en cualquier forma o por cualquier medio técnico, sin la autorización de ASISA. El Usuario se compromete a respetar los derechos de propiedad de la propiedad intelectual ASISA. El Usuario puede ver los artículos en diferentes sitios e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en otro equipo proporcionado, única y exclusivamente para apoyar su uso personal y privado. El Usuario deberá abstenerse de suprimir, alterar o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas web titularidad de ASISA.</p>
              <p></p>
            </div>
            <div className="legal-subtitle">
              <div className="legal-subtitle__number">
                <p>3</p>
              </div>
              <div className="legal-subtitle__text">
                <p>Exclusión de garantías y de responsabilidad</p>
              </div>
            </div>
            <div className="legal-text">
              <p>ASISA no es responsable de los daños de cualquier clase causados por errores u omisiones en los contenidos, falta de disponibilidad del Sitio web o por la transmisión de virus o programas maliciosos o lesivos en los contenidos, después de haber tomado todas las medidas técnicas necesarias para evitar dichos daños, faltas de disponibilidad y/o transmisión.</p>
              <p>ASISA no asume ninguna responsabilidad por los daños que puedan resultar de la utilización que los usuarios hacen de la información y contenidos de este sitio web o por la aplicación del mismo para justificar o apoyar cualquier juicio o decisión personal.</p>
              <p>En este sentido, el Usuario reconoce y acepta que el contenido y la información a la que accede a través de la web es solamente para fines ilustrativos y están disponibles para su examen; en cualquier caso, no pretenden reemplazar o sustituir el asesoramiento oconsejo médico, diagnóstico, consulta o tratamiento adecuados por parte del médico llamado para diagnosticar y tratar al Usuario, si lo hubiere, y por lo tanto, NO deben proporcionar una base para la toma de decisiones médicas. Si el Usuario tiene dudas, debe consultar a un profesional.</p>
              <p>Aunque todo el contenido ha sido escrito por profesionales de la salud calificados y fuentes de reconocido prestigio y se han tomado todas las medidas necesarias en el sitio web para proporcionar medidas de información actualizada y precisa, ASISA no puede garantizar el rigor ni oportunidad de la información proporcionada, o la ausencia absoluta de cualquier error y/u omisión; y el Usuario a quien se proporciona acceso a la información y a la descripción detallada en este aviso legal debe conocer que no existe garantía de que la información ofrecida en las páginas web de ASISA sea exacta y actualizada.</p>
              <p></p>
            </div>
            <div className="legal-subtitle">
              <div className="legal-subtitle__number">
                <p>4</p>
              </div>
              <div className="legal-subtitle__text">
                <p>Modificación de estos términos y duración</p>
              </div>
            </div>
            <div className="legal-text">
              <p>ASISA puede cambiar en cualquier momento las condiciones aquí debidamente publicitadas.</p>
              <p></p>
            </div>
            <div className="legal-subtitle">
              <div className="legal-subtitle__number">
                <p>5</p>
              </div>
              <div className="legal-subtitle__text">
                <p>Fuero</p>
              </div>
            </div>
            <div className="legal-text">
            <p>La relación entre el Usuario y ASISA se regirá por la legislación española vigente y cualquier controversia se someterá a los tribunales de la ciudad de Madrid.</p>
              <p></p>
            </div>
          </LegalContainer>
        </SimpleLayout>
      </>
    );
  }
}
