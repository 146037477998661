import styled from "styled-components";

export const SimpleCheckboxContainer = styled.div`
  .checkbox-item-text {
    display: inline-block;
  }

  .checkbox-wrapper {
    position: relative;
    cursor: pointer;

    > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ .checkbox-container {
      .checkbox-item-icon {
        background: #49abb8;
        border: 1px solid #49abb8;
      }

      .checkbox-item-text {
        font-weight: 600;
        color: #091e42;
      }
    }
  }

  .checkbox-container {
    display: inline-block;
  }

  .checkbox-item-icon {
    background: #fff;
    border-radius: 50%;
    border: 1px solid #80d8f4;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    position: relative;
    display: inline-block;
    top: 3px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 12px;
    }
  }
  .check-box-error {
    color: #ff4100;
    margin-top: 4px;
    min-height: 17px;
  }

  .checkbox-item-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6b778c;
    display: inline-block;
  }

  @media (max-width: 440px) {
    .checkbox-container {
      display: flex;
    }
  }
`;
