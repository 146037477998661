import React from "react";
import { ModalFileValidationContainer } from "./modal-file-validation-style";
import MainButton from "../../buttons/main-button";
import FileItemModal from "../file-item-modal";
import { AttachedFile } from "../../../screens/request-detail/types";

interface ModalFileValidationProps {
  showModal: boolean;
  files?: any[];
  title: string;
  onConfirm: Function;
}

const ModalFileValidation = (props: ModalFileValidationProps) => {
  const { showModal, files, onConfirm, title } = props;

  return (
    <ModalFileValidationContainer className={showModal ? "active" : ""}>
      <div className="item-modal-message-container">
        <div className="item-modal-title">
          <p>{title}</p>
        </div>
        <div className="item-modal-files">
          {files.length > 0 &&
            files.map((file: AttachedFile, index) => (
              <div className="file-item">
                <FileItemModal
                  key={index}
                  file={file}
                  errorType={file.errorType ? file.errorType : "error"}
                />
              </div>
            ))}
        </div>

        <div className="item-modal-message-buttoms">
          <div className="item-modal-message-buttom">
            <MainButton
              type={"dark-blue "}
              text="Aceptar"
              onClick={() => onConfirm(files)}
            />
          </div>
        </div>
      </div>
    </ModalFileValidationContainer>
  );
};

export default ModalFileValidation;
