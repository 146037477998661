import React, { Component } from "react";
import { MainButtonContainer } from "./main-button-style";
import { images } from "../../../assets/images";

interface MainButtonProps {
  text?: string;
  type?: string;
  icon?: boolean;
  iconImg?: string;
  iconImgRight?: string;
  onClick: Function;
  disabled?: boolean;
  iconRight?: boolean;
  status?: string;
}

interface MainButtonState { }

class MainButton extends Component<MainButtonProps, MainButtonState> {
  state: MainButtonState = {
    status: "initial"
  };

  public static defaultProps = {
    icon: false,
    disabled: false,
    iconRight: false,
    status: "initial"
  };

  renderMainButton = () => {
    const {
      text,
      type,
      icon,
      iconImg,
      onClick,
      disabled,
      iconRight,
      iconImgRight,
      status
    } = this.props;

    switch (status) {
      case "loading":
        return (
          <>
            {icon && (
              <div className="main-button-icon">
                <img src={iconImg} />
              </div>
            )}
            <div className="main-button-text">
              <p>{text}</p>
            </div>
            {iconRight && (
              <div className="right-button-icon">
                <img src={iconImgRight} />
              </div>
            )}
          </>
        );
      case "initial":
      default:
        return (
          <>
            {icon && (
              <div className="main-button-icon">
                <img src={iconImg} />
              </div>
            )}
            <div className="main-button-text">
              <p>{text}</p>
            </div>
            {iconRight && (
              <div className="right-button-icon">
                <img src={iconImgRight} />
              </div>
            )}
          </>
        );
    }
  };

  render() {
    const { type, onClick, disabled } = this.props;

    return (
      <MainButtonContainer
        disabled={disabled}
        className={`${type} ${disabled ? 'disabled' : ''}`.trim()}
        onClick={() => {
          if (!disabled) onClick();
        }}
      >
        {this.renderMainButton()}
      </MainButtonContainer>
    );
  }
}

export default MainButton;
