// tslint:disable: ban-types
import React, { Component, RefObject } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
// @ts-ignore
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { MainTableContainer } from "./main-table-style";
import cn from "classnames";
import { images } from "../../assets/images";
import Tooltip from "../tooltip/tooltip-layout";

interface MainTableProps extends RouteComponentProps {
  rows: any[];
  columns: any[];
  orderBy?: (column: string, active: boolean) => void;
  keysRow: string[];
  rowKeyLink?: string;
  rowLink?: string;
  className?: string;
  emptyStateMessage?: string;
  fixedLeft?: boolean;
  fixedLeftTitle?: string;
  fixedRight?: boolean;
  fixedRightTitle?: string;
  fixedBoth?: boolean;
}

interface MainTableState {
  activeColumnHead: IActiveObject;
  rowsMaxHeight: any[];
  cellsTooltipShow: any[];
  hoveredRows: boolean[];
  tooltip: {
    show: boolean;
    text: string;
    position: {
      top: number;
      left: number;
      originalTop: number;
      originalLeft: number;
    };
  };
}

interface IActiveObject {
  [key: string]: any;
}

class MainTable extends Component<MainTableProps, MainTableState> {
  tableRef: RefObject<HTMLTableElement> = React.createRef();
  tableWrapperX: RefObject<any> = React.createRef();
  tableWrapperY: RefObject<any> = React.createRef();

  documentScrollListener: any;
  tableWrapperXScrollListener: any;
  tableWrapperYScrollListener: any;

  state: MainTableState = {
    activeColumnHead: {},
    rowsMaxHeight: [],
    cellsTooltipShow: [],
    hoveredRows: [],
    tooltip: {
      show: false,
      text: "TEST TOOLTIP",
      position: {
        top: 0,
        left: 0,
        originalTop: 0,
        originalLeft: 0
      }
    }
  };

  componentDidMount() {
    this.setRowHeights();
  }

  componentDidUpdate(prevProps: MainTableProps) {
    const { rows } = this.props;
    if (prevProps.rows !== rows) {
      const hoveredRows: boolean[] = new Array(rows.length).fill(false);
      this.setState({ hoveredRows }, () => this.setRowHeights());
    }
  }

  setRowHeights() {
    const { rows } = this.props;
    const rowsMaxHeight: number[] = [];
    const cellsTooltipShow: any[] = [];
    const hoveredRows: boolean[] = new Array(rows.length).fill(false);
    this.tableRef.current &&
      Array.from(this.tableRef.current.childNodes).map((tableRow, index) => {
        rowsMaxHeight.push(0);
        tableRow.childNodes.forEach((tableCell: any) => {
          if (tableCell.offsetHeight > rowsMaxHeight[index]) {
            rowsMaxHeight[index] = tableCell.offsetHeight;
          }
          if (tableCell.firstChild.childNodes.length === 1) {
            cellsTooltipShow.push(
              tableCell.firstChild.firstChild.scrollWidth -
                tableCell.firstChild.firstChild.offsetWidth >
                5
            );
          } else {
            const itemsTooltipShow: any[] = [];
            Array.from(tableCell.firstChild.childNodes).forEach(
              (multipleItem: any) => {
                itemsTooltipShow.push(
                  multipleItem.scrollWidth - multipleItem.offsetWidth > 5
                );
              }
            );
            cellsTooltipShow.push(itemsTooltipShow);
          }
        });
        rowsMaxHeight[index] += 1;
      });
    // TOOLTIP FOLLOW SCROLL
    // this.tableWrapperXScrollListener = this.tableWrapperX.current.addEventListener(
    //   "scroll",
    //   () => {
    //     const { tooltip } = Object.assign(this.state);
    //     tooltip.position.left =
    //       tooltip.position.originalLeft - this.tableWrapperX.current.scrollLeft;
    //     this.setState({ tooltip });
    //   }
    // );
    // this.tableWrapperYScrollListener = this.tableWrapperY.current.addEventListener(
    //   "scroll",
    //   () => {
    //     const { tooltip } = Object.assign(this.state);
    //     tooltip.position.top =
    //       tooltip.position.originalTop -
    //       this.tableWrapperY.current.scrollTop -
    //       document.scrollingElement.scrollTop;
    //     this.setState({ tooltip });
    //   }
    // );
    // this.documentScrollListener = document.addEventListener("scroll", () => {
    //   const { tooltip } = Object.assign(this.state);
    //   if (!tooltip || !this.tableWrapperY.current) return;
    //   tooltip.position.top =
    //     tooltip.position.originalTop -
    //     this.tableWrapperY.current.scrollTop -
    //     document.scrollingElement.scrollTop;
    //   this.setState({ tooltip });
    // });
    this.setState({ rowsMaxHeight, cellsTooltipShow, hoveredRows });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.documentScrollListener);
    this.tableWrapperX.current.removeEventListener(
      "scroll",
      this.tableWrapperXScrollListener
    );
    this.tableWrapperY.current.removeEventListener(
      "scroll",
      this.tableWrapperYScrollListener
    );
  }

  handleClickOnColumnHead(key: string) {
    const { orderBy } = this.props;
    const { activeColumnHead } = this.state;

    if (activeColumnHead[key] === true) {
      activeColumnHead[key] = undefined;
    } else if (activeColumnHead[key] === false) {
      activeColumnHead[key] = true;
    } else {
      activeColumnHead[key] = false;
    }
    if (orderBy) {
      orderBy(key, activeColumnHead[key]);
    }
    this.setState({ activeColumnHead });
  }

  onTooltipMouseEnter(
    ev: any,
    tooltipIconRef: RefObject<HTMLDivElement>,
    text: string
  ) {
    const { cellsTooltipShow, tooltip } = this.state;
    ev.stopPropagation();
    const position = tooltipIconRef.current.getBoundingClientRect();
    const newTooltip = Object.assign(tooltip);
    tooltip.show = true;
    tooltip.text = text;
    tooltip.position = {
      top: position.top,
      left: position.left,
      originalTop:
        position.top +
        this.tableWrapperY.current.scrollTop +
        document.scrollingElement.scrollTop,
      originalLeft: position.left + this.tableWrapperX.current.scrollLeft
    };
    this.setState({ tooltip: newTooltip });
  }

  onTooltipMouseLeave() {
    const { tooltip } = this.state;
    tooltip.show = false;
    this.setState({ tooltip });
  }

  onRowHover(rowIndex: number) {
    const { rows } = this.props;
    const { hoveredRows } = this.state;
    hoveredRows.fill(false);
    hoveredRows[rowIndex] = true;
    this.setState({ hoveredRows });
  }

  onRowOut() {
    const { rows } = this.props;
    const { hoveredRows } = this.state;
    hoveredRows.fill(false);
    this.setState({ hoveredRows });
  }

  renderCellTable(item: any, index: number = -1) {
    const { cellsTooltipShow, tooltip } = this.state;
    switch (item.key) {
      case "highlighted":
        return (
          <div className="context-text highlighted">
            <p>{item.text}</p>
          </div>
        );
      case "multiple-last":
      case "multiple":
        if ((item.items && item.items.length <= 1) || !item.items) break;
        return (
          <div className="context-text multiple context-text-multiple-content">
            {item.items.map((itemMulti, i) => {
              if (typeof itemMulti === "object") {
                return <p>{"-"}</p>;
              }
              if (
                (!!cellsTooltipShow[index] && !cellsTooltipShow[index][i]) ||
                item.key === "multiple-last"
              ) {
                return <p>{itemMulti}</p>;
              } else {
                const tooltipIconRef: RefObject<
                  HTMLDivElement
                > = React.createRef();
                return (
                  <div className="context-text tooltip">
                    <div
                      className="context-text-tooltip-content"
                      onMouseLeave={ev => this.onTooltipMouseLeave()}
                      onMouseEnter={ev =>
                        this.onTooltipMouseEnter(
                          ev,
                          tooltipIconRef,
                          itemMulti || (item.items ? item.items[0] : "")
                        )
                      }
                    >
                      <div className="tooltip-item tooltip-item__text">
                        <p>{itemMulti}</p>
                      </div>
                      <div
                        className="tooltip-item tooltip-item__img"
                        ref={tooltipIconRef}
                      >
                        <img src={images.IconInfoGrey} />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );

      case "tooltip":
        return (
          <div className="context-text tooltip">
            <div className="context-text-tooltip-content" onClick={() => {}}>
              <div className="tooltip-item tooltip-item__text">
                <p>texto compi del tooltip</p>
              </div>
              <div className="tooltip-item tooltip-item__img">
                <img src={images.IconInfoGrey} />
              </div>
            </div>
          </div>
        );

      case "link":
        return (
          <div className="context-text">
            <NavLink to={item.link}>{item.text}</NavLink>
          </div>
        );

      case "state":
        return (
          <div className="context-text">
            <p>{item.text}</p>
          </div>
        );
    }

    if (
      index !== -1 &&
      cellsTooltipShow[index] &&
      item.key !== "multiple-last"
    ) {
      const tooltipIconRef: RefObject<HTMLDivElement> = React.createRef();
      return (
        <>
          <div className="context-text tooltip">
            <div
              className="context-text-tooltip-content"
              onMouseLeave={ev => this.onTooltipMouseLeave()}
              onMouseEnter={ev =>
                this.onTooltipMouseEnter(
                  ev,
                  tooltipIconRef,
                  item.text || (item.items ? item.items[0] : "")
                )
              }
            >
              <div className="tooltip-item tooltip-item__text">
                <p>{item.text || (item.items ? item.items[0] : "")}</p>
              </div>
              <div
                className="tooltip-item tooltip-item__img"
                ref={tooltipIconRef}
              >
                <img src={images.IconInfoGrey} />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="context-text">
          <p>
            {item.text
              ? item.text
              : item.items &&
                item.items.length > 0 &&
                typeof item.items[0] === "string"
              ? item.items[0]
              : "-"}
          </p>
        </div>
      );
    }
  }

  renderHeadTable(item: any) {
    switch (item.key) {
      case "check":
        return (
          <div className="main-table-single-check">
            <p>Checkbox</p>
          </div>
        );
      case "highlighted":
        return (
          <div className="title-text">
            <p>Title</p>
          </div>
        );

      default:
        return (
          <div className="title-text">
            <p>{item.text}</p>
          </div>
        );
    }
  }
  render() {
    const {
      rows,
      columns,
      keysRow,
      rowLink,
      rowKeyLink,
      className,
      fixedLeft,
      fixedLeftTitle,
      fixedRight,
      fixedRightTitle,
      fixedBoth,
      emptyStateMessage
    } = this.props;
    const {
      activeColumnHead,
      rowsMaxHeight,
      tooltip,
      hoveredRows
    } = this.state;
    const emptyMessage =
      emptyStateMessage ||
      "¡Oops! No hemos encontrado ningún resultado, prueba con otra búsqueda";
    const mainTableContainerClass: any = {
      "fixed-left": fixedLeft,
      "fixed-right": fixedRight,
      "fixed-booth": fixedBoth
    };
    if (className) {
      mainTableContainerClass[className] = className;
    }

    return (
      <div
        onClick={() => {
          const newTooltip = Object.assign(tooltip);
          tooltip.show = false;
          this.setState({ tooltip: newTooltip });
        }}
      >
        {rows.length < 1 ? (
          <p>No hay contenido</p>
        ) : (
          <ScrollSync>
            <MainTableContainer className={cn(mainTableContainerClass)}>
              <div className="main-table-column left">
                <div className="main-table-column-head">
                  {this.renderHeadTable(columns[0])}
                </div>
                <ScrollSyncPane group="vertical">
                  <div className="main-table-column-scroll">
                    {rows.map((row, index) => (
                      <div
                        style={{
                          height:
                            rowsMaxHeight.length > 0
                              ? `${rowsMaxHeight[index]}px`
                              : "61px"
                        }}
                        onMouseEnter={() => this.onRowHover(index)}
                        onMouseLeave={() => this.onRowOut()}
                        className={`main-table-column-row ${
                          hoveredRows[index] ? "hover" : ""
                        }`}
                        key={`row-${index}`}
                        onClick={() => {
                          if (!tooltip.show) {
                            this.props.history.push(
                              "/solicitud-detalle/" + row.applicationNumber.text
                            );
                          }
                        }}
                      >
                        {this.renderCellTable(row[keysRow[0]])}
                      </div>
                    ))}
                  </div>
                </ScrollSyncPane>
              </div>
              <div
                className="main-table-wrapper-scroll"
                ref={this.tableWrapperX}
              >
                <table>
                  <tr>
                    <td className="main-table-wrapper-reset">
                      <table className="main-table-head">
                        <tr>
                          {columns.map((column, index) => (
                            <th
                              key={`column-${index}`}
                              onClick={() =>
                                column.filter &&
                                this.handleClickOnColumnHead(keysRow[index])
                              }
                              className={`
                              ${column.filter ? "pointer" : ""}
                              ${columns[index] && columns[index].className} 
                              ${
                                column.filter &&
                                activeColumnHead[keysRow[index]] !== undefined
                                  ? `filter ${
                                      activeColumnHead[keysRow[index]]
                                        ? "active"
                                        : ""
                                    }`
                                  : ""
                              }
                              `}
                            >
                              {this.renderHeadTable(column)}
                            </th>
                          ))}
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <ScrollSyncPane group="vertical">
                    <div
                      className="main-table-wrapper-scroll-vertical"
                      ref={this.tableWrapperY}
                    >
                      <tr>
                        <td>
                          {/* <div className="main-table-body"> */}
                          {/* <Scrollbars
                        renderTrackHorizontal={propsTrackHorizontal => (
                          <div
                            {...propsTrackHorizontal}
                            className="track-horizontal"
                          />
                        )}
                        renderThumbHorizontal={propsThumbHorizontal => (
                          <div
                            {...propsThumbHorizontal}
                            className="thumb-horizontal"
                          />
                        )}
                        style={{ height: "100%" }}
                        autoHide={false}
                        autoHideTimeout={200}
                        autoHideDuration={200}
                        autoHeight={true}
                        autoHeightMin={60}
                        autoHeightMax={400}
                        hideTracksWhenNotNeeded={true}
                      > */}
                          <table ref={this.tableRef}>
                            {tooltip.show && (
                              <Tooltip
                                text={tooltip.text}
                                position={tooltip.position}
                              />
                            )}
                            {rows.map((row, index) => (
                              <tr
                                className={`main-table-row ${
                                  hoveredRows[index] ? "hover" : ""
                                }`}
                                key={`row-${index}`}
                                onClick={() => {
                                  if (!tooltip.show) {
                                    this.props.history.push(
                                      "/solicitud-detalle/" +
                                        row.applicationNumber.text
                                    );
                                  }
                                }}
                                onMouseEnter={() => this.onRowHover(index)}
                                onMouseLeave={() => this.onRowOut()}
                              >
                                {keysRow.map((key, indexKey) => (
                                  <td
                                    key={`td-${indexKey}`}
                                    className={`main-table-row-cell ${row[
                                      key
                                    ] && row[key].className}`}
                                    style={{
                                      minHeight:
                                        row.insuredPerson.text.length > 20
                                          ? "90px"
                                          : "auto"
                                    }}
                                  >
                                    {rowLink &&
                                    row[key].key !== "link" &&
                                    row[key].key !== "button" ? (
                                      <NavLink
                                        to={`${rowLink}${
                                          rowKeyLink
                                            ? `/${row[rowKeyLink].text}`
                                            : ""
                                        }`}
                                      >
                                        {this.renderCellTable(row[key])}
                                      </NavLink>
                                    ) : (
                                      this.renderCellTable(
                                        row[key],
                                        indexKey + index * columns.length
                                      )
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </table>
                          {/* </Scrollbars> */}
                        </td>
                      </tr>
                    </div>
                  </ScrollSyncPane>
                </table>
              </div>
              <div className="main-table-column right toRight">
                <div className="main-table-column-head">
                  {this.renderHeadTable(columns[columns.length - 1])}
                </div>
                <ScrollSyncPane group="vertical">
                  <div className="main-table-column-scroll scrollbar">
                    {rows.map((row, index) => (
                      <div
                        style={{
                          height:
                            rowsMaxHeight.length > 0
                              ? `${rowsMaxHeight[index]}px`
                              : "61px"
                        }}
                        onMouseEnter={() => this.onRowHover(index)}
                        onMouseLeave={() => this.onRowOut()}
                        className={`main-table-column-row ${
                          hoveredRows[index] ? "hover" : ""
                        }`}
                        key={`row-${index}`}
                        onClick={() => {
                          if (!tooltip.show) {
                            this.props.history.push(
                              "/solicitud-detalle/" + row.applicationNumber.text
                            );
                          }
                        }}
                      >
                        {this.renderCellTable(row[keysRow[keysRow.length - 1]])}
                      </div>
                    ))}
                  </div>
                </ScrollSyncPane>
              </div>
            </MainTableContainer>
          </ScrollSync>
        )}
      </div>
    );
  }
}

export default withRouter(MainTable);
