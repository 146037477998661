import React, { Component } from "react";
import lang from "../../../config/lang/sp";
import { iconsSVG } from "../../../assets/icons";
import {
  isValidCard,
  isValidCif,
  isValidDate,
  isValidDni,
  isValidEmail,
  isValidPassword,
} from "../../../utils/validation";
import Dropdown from "../../dropdowns/dropdown";
import { InputStandardContainer } from "./input-standard-style";

interface InputStandardProps {
  field?: string;
  initValue?: any;
  onBlur?: Function;
  required: boolean;
  styleInput?: object;
  title: string;
  type:
    | ""
    | "password"
    | "email"
    | "birth_date"
    | "card"
    | "expiration"
    | "number"
    | "phone"
    | "cif"
    | "dni"
    | "cvv"
    | "textarea";
  placeholder?: string;
  errorText?: string;
  className?: string;
  hidden?: boolean;
  submit?: Function;
}

interface InputStandardState {
  error: boolean | object;
  hidden: boolean;
  isFocused: boolean;
  prefix: string;
  value: any;
  formatting: boolean;
  message: string;
  type?: string;
}

export default class InputStandard extends Component<InputStandardProps, InputStandardState> {
  constructor(props: any) {
    super(props);

    this.state = {
      error: false,
      hidden: props.hidden || false,
      isFocused: false,
      prefix: "+34",
      value: props.value ? props.value : "",
      formatting: false,
      message: "",
    };
    this.setPrefix = this.setPrefix.bind(this);
  }

  clearError() {
    this.setState({
      error: false,
      isFocused: true,
    });
  }

  onChangeText(evt: any, index?: any) {
    const { type } = this.props;
    const text = evt.target.value;
    let { value } = evt.target;

    if (this.state.value.length < value.length) {
      this.setState({ formatting: true }, () => {
        if (type === "birth_date") {
          const ciphers = text.length ? text.split("/") : "";
          if (value.length === 1 && parseInt(value, 10) > 3) {
            value = `0${text}/`;
          } else if (value.length === 2 && value.indexOf("/") !== -1) {
            value = `0${text}`;
          } else if (
            (value.length === 2 && value.indexOf("/") === -1) ||
            (ciphers[1] && ciphers[1].length === 2 && !ciphers[2] && value.lastIndexOf("/") !== value.length - 1)
          ) {
            value = `${text}/`;
          } else if (ciphers[1] && ciphers[1].length === 1 && parseInt(ciphers[1], 10) > 1) {
            ciphers[1] = `0${ciphers[1]}/`;
            value = ciphers.join("/");
          } else if (
            ciphers[1] &&
            ciphers[1].length === 1 &&
            !ciphers[2] &&
            value.lastIndexOf("/") === value.length - 1
          ) {
            ciphers[1] = `0${ciphers[1]}/`;
            value = ciphers.join("/");
          }
        } else if (type === "card") {
          const cleanCard = text.split(" ").join("");
          const parts = [];
          for (let i = 0; i < cleanCard.length; i += 4) {
            parts.push(cleanCard.substring(i, i + 4));
          }
          value = parts.join(" ");
        }

        this.setState({ formatting: false });
      });
    }

    if (type === "expiration") {
      let { value } = this.state;
      const splitValue = value.length ? value.split("/") : ["", ""];
      splitValue[index] = text;
      value = splitValue.join("/");
    }

    this.setState({ value });
  }

  setPrefix(prefixValue: string) {
    const { value } = this.state;

    this.setState({ prefix: prefixValue }, () => {
      if (value !== "") setTimeout(() => this.validate(), 1000);
    });
  }
  validate(submit?: boolean) {
    const { prefix, value } = this.state;
    const { required, type, hidden } = this.props;
    const { errors } = lang.inputs;

    const newState = {
      isFocused: false,
      value: value.trim(),
      error: false,
      message: "",
    };
    if (required && value === "") {
      newState.error = true;
      newState.message = errors.required;
    } else if (type === "email" && !isValidEmail(newState.value)) {
      newState.error = true;
      newState.message = errors.email.not_valid;
    } else if (type === "password" && value.trim().length < 6) {
      newState.error = true;
      newState.message = errors.password.too_short;
    } else if (type === "password" && !isValidPassword(value)) {
      newState.error = true;
      newState.message = errors.password.not_valid;
    } else if (type === "phone" && value.trim().length + prefix.length < 12) {
      newState.error = true;
      newState.message = errors.phone.too_short;
    } else if (type === "birth_date" && !isValidDate(value)) {
      newState.error = true;
      newState.message = errors.date.not_valid;
    } else if (type === "cif" && !isValidCif(value)) {
      newState.error = true;
      newState.message = errors.cif.not_valid;
    } else if (type === "dni" && !isValidDni(value)) {
      newState.error = true;
      newState.message = errors.dni.not_valid;
    } else if (type === "cvv" && (value.trim().length < 3 || !value.trim().match(/[0-9]/))) {
      newState.error = true;
      newState.message = errors.cvv.not_valid;
    } else if (type === "card" && !isValidCard(value)) {
      newState.error = true;
      newState.message = errors.card.not_valid;
    } else if (type === "expiration") {
      if (!value.replace("/", "").match(/[0-9]/)) {
        newState.error = true;
        newState.message = errors.expiration.not_valid;
      } else {
        const [month, year] = value.split("/");
        if (parseInt(month, 10) < 1 || parseInt(month, 10) > 12 || parseInt(year, 10) < 19) {
          newState.error = true;
          newState.message = errors.expiration.not_valid;
        }
      }
    }

    this.setState(
      {
        isFocused: newState.isFocused,
        error: newState.error,
        message: newState.message,
        value: newState.value,
      },
      () => this.sendValue(submit),
    );
  }

  sendValue(shouldSend?: boolean) {
    const { prefix, value, error } = this.state;
    const { field, onBlur, type } = this.props;
    let composedValue = value;

    if (type === "phone") {
      composedValue = `(${prefix})${value}`;
    }
    if (onBlur) onBlur(field, composedValue, error, shouldSend);
  }

  renderTitle(text: string) {
    return <p>{text}</p>;
  }

  renderInput() {
    const { initValue, styleInput, placeholder, type } = this.props;
    const { error, hidden, isFocused, prefix, value } = this.state;
    const inputStyle = styleInput ? [styleInput] : [{}];

    let additionalProps: object;
    switch (type) {
      case "email":
        additionalProps = { keyboard: "email-address" };
        break;
      case "phone":
        additionalProps = { keyboard: "phone-pad" };
        break;
      case "birth_date":
        additionalProps = { keyboard: "number-pad", maxLength: 10 };
        break;
      case "number":
        additionalProps = { keyboard: "number-pad" };
        break;
      case "card":
        additionalProps = { keyboard: "number-pad", maxLength: 19 };
        break;
      case "expiration":
        additionalProps = { keyboard: "number-pad", maxLength: 2 };
        break;
      case "cvv":
        additionalProps = { keyboard: "number-pad", maxLength: 3 };
        break;
      default:
        additionalProps = {};
    }

    if (type === "phone") {
      const mockPrefixes = [
        { label: "+34", value: "+34" },
        { label: "+351", value: "+351" },
        { label: "+33", value: "+33" },
      ];

      let dropdown = React.createRef().toString();
      let input = React.createRef().toString();

      return (
        <div>
          <Dropdown
            ref={dropdown}
            texts={{}}
            items={mockPrefixes}
            field="prefix"
            value={prefix}
            onBlur={this.setPrefix(prefix)}
          />
          <input
            autoComplete="off"
            ref={input}
            placeholder={placeholder}
            defaultValue={value}
            onFocus={() => this.clearError()}
            onChange={evt => this.onChangeText(evt)}
            onBlur={() => this.validate()}
            type={hidden ? "password" : "text"}
            {...additionalProps}
          />
        </div>
      );
    }

    if (type === "textarea") {
      return (
        <textarea
          autoComplete="off"
          placeholder={placeholder}
          onFocus={() => this.clearError()}
          onChange={evt => this.onChangeText(evt, 0)}
          onBlur={() => this.validate()}
          {...additionalProps}
        />
      );
    }

    if (type === "expiration") {
      let splitValue = ["", ""];

      if (value.length) splitValue = value.split("/");

      return (
        <div>
          <input
            autoComplete="off"
            placeholder={placeholder}
            defaultValue={splitValue[0]}
            onFocus={() => this.clearError()}
            onChange={evt => this.onChangeText(evt, 0)}
            onBlur={() => this.validate()}
            type={hidden ? "password" : "text"}
            {...additionalProps}
          />
          <input
            autoComplete="off"
            placeholder={placeholder}
            defaultValue={splitValue[1]}
            onFocus={() => this.clearError()}
            onChange={evt => this.onChangeText(evt, 1)}
            onBlur={() => this.validate()}
            type={hidden ? "password" : "text"}
            {...additionalProps}
          />
        </div>
      );
    }

    return (
      <input
        autoComplete="off"
        placeholder={placeholder}
        defaultValue={initValue || value}
        onFocus={() => this.clearError()}
        onChange={evt => !this.state.formatting && this.onChangeText(evt)}
        onBlur={() => this.validate()}
        type={hidden ? "password" : "text"}
        {...additionalProps}
        onKeyUp={e => {
          if (e.which === 13) this.validate(true);
        }}
      />
    );
  }

  renderIcon() {
    const { hidden } = this.state;
    return (
      <div className="input-icon" onMouseDown={() => this.setState({ hidden: !hidden })}>
        <img
          className="icon-password"
          src={hidden ? iconsSVG.iconInputEye : iconsSVG.iconInputEye}
          alt="icono password"
        />
      </div>
    );
  }

  render() {
    const { title, type, className, errorText } = this.props;
    const { message } = this.state;
    return (
      <InputStandardContainer className={className}>
        <div className="input-s-title">{this.renderTitle(title)}</div>
        <div className="input-main">
          {this.renderInput()}
          {type === "password" ? this.renderIcon() : ""}
        </div>
        {type !== "textarea" && <div className="input-s-error">{message}</div>}
      </InputStandardContainer>
    );
  }
}
