import styled from "styled-components";

export const HomeContainer = styled.div`
  width: 100%;
  padding: 80px;
  padding-top: 84px;

  .home-table-content {
    overflow: hidden;
  }

  .home-topbar {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 38px;
  }

  .card-element {
    cursor: pointer;
  }

  .home-title {
    font-family: "museo sans";
    font-size: 32px;
    line-height: 38px;
    color: #091e42;
    font-weight: 300;

    span {
      font-weight: 600;
      display: block;
    }
  }

  .filter-options {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .secure-filter {
    width: 275px;
    margin-right: 28px;
  }

  .btn-filter {
    cursor: pointer;
    font-family: "museo sans";
    font-size: 16px;
    line-height: 16px;
    color: #091e42;
    min-height: 20px;
    max-height: 20px;
    border-left: 1px solid #e2e5ea;
    border-right: 1px solid #e2e5ea;
    padding-left: 28px;
    padding-right: 28px;
  }

  .btn-change-grid {
    margin-left: 28px;
    cursor: pointer;
  }

  .status-filter-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  .status-filter-list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .status-filter {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 44px;
    margin-bottom: 20px;
    min-height: 40px;
    transition: 0.3s ease-in-out all;

    .status-filter-close-icon {
      display: none;
    }

    &.selected {
      padding: 12px 16px;
      background: #e2e5ea;
      border-radius: 28px;

      .status-filter-text {
        margin-right: 12px;
      }

      .status-filter-close-icon {
        display: flex;
        align-items: center;
      }
    }
  }

  .status-filter-text {
    font-family: "museo sans";
    font-size: 14px;
    line-height: 16px;
    color: #091e42;
  }

  .home-grid-content {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }

  .loader-holder {
        position: absolute;
        z-index: 99999;
        top: 44%;
        left: 50%;
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%);
    }

    .spinner {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 999;

        color: #f00;
        -webkit-animation: color-bubble 2s linear 0s infinite;
        animation: color-bubble 2s linear 0s infinite;
    }


  .spinner{
   animation: color-bubble 2s linear 0s infinite;

    &:after, &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 100%;
        box-shadow: 0 0 0 3px;
        -webkit-animation: spinner-bubble 1.3s linear 0s infinite;
        animation: spinner-bubble 1.3s linear 0s infinite;
        margin: auto;
        width: 10px;
        height: 10px;
        opacity: 0;
    }

    &:before {
        -webkit-animation: spinner-bubble 1.3s linear 2s infinite;
        animation: spinner-bubble 1.3s linear 2s infinite;
    }

  }

    @keyframes spinner-bubble {
        from{
            width: 10px;
            height: 10px;
            opacity: .8;
        }
        to{
            width: 50px;
            height: 50px;
            opacity: 0;
        }
    }

    @keyframes color-bubble {
        0%{color: rgb(0, 79, 139)}
        33%{color: rgba(0, 79, 139, .75)}
        66%{color: rgba(0, 79, 139, .5)}
        100%{color: rgba(0, 79, 139, .25)}
    }


  @media (max-width: 768px) {
    padding: 120px 20px;
    .home-topbar  {
      flex-direction: column;
      align-items: flex-start;
      .home-title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;

        span {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
    .filter-options {
      flex-wrap: wrap;
      justify-content: flex-start;
      .secure-filter {
        margin-bottom: 20px;
      }
      .btn-change-grid {
        display: none;
      }
      .btn-filter {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 440px) {
    .filter-options {
      width: 100%;
      display: block;

      .btn-filter {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        border: 1px solid #004f8b;
        max-height: initial;
        max-width: 275px;
        border-radius: 6px;
        p {
          padding: 16px;
          display: inline-block;
        }
      }
    }
  }

  .tooltip-home {
    margin-top: 30px;
  }
`;
