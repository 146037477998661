import React, { Component } from "react";
import { AddCardContainer } from "./add-card-style";
import { images } from "../../../assets/images";
import { icons } from "../../../assets/icons";

interface AddCardProps {
  className?: string;
  text?: string;
}
interface AddCardState {}

class AddCard extends Component<AddCardProps, AddCardState> {
  state: AddCardState = {};

  public static defaultProps = {
    text: "Añadir nueva factura"
  };

  render() {
    const { className, text } = this.props;

    return (
      <AddCardContainer className={className}>
        <div className="add-card-button">
          <div className="add-card-icon">
            <img
              src={
                className === "main" ? icons.IconUploadWhite : icons.IconUpload
              }
              alt="Añadir nueva factura"
            />
          </div>
          <div className="add-card-text">
            <p>{text}</p>
          </div>
        </div>
      </AddCardContainer>
    );
  }
}

export default AddCard;
