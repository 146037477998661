import React, { Component } from "react";
import dateFns from "date-fns";
import es from "date-fns/locale/es";
import { images } from "../../../assets/images";
import { withRouter } from "react-router";
import { CalendarItemContainer } from "./calendar-item-style";
import moment from "moment";

interface CalendarItemProps {
  onSelect: Function; // TODO: finish
  maxDate: Date;
  value?: string;
}

class CalendarItem extends React.Component<any, any> {
  state = {
    showYears: false,
    showMonths: false,
    currentDate: new Date()
  };

  componentDidMount() {
    const { value } = this.props;
    if (value)
      this.setState({ currentDate: moment(value, "DD/MM/YYYY").toDate() });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value && prevProps.value !== value)
      this.setState({ currentDate: moment(value, "DD/MM/YYYY").toDate() });
  }

  renderHeader() {
    const dateFormatMonth = "MMMM";
    const dateFormatYear = "YYYY";

    return (
      <div className="calendar-header">
        {/* Current Month */}
        <div className="current-month">
          <p onClick={() => this.setState({ showMonths: true })}>
            {dateFns.format(this.state.currentDate, dateFormatMonth, {
              locale: es
            })}
          </p>
          <div className="pick-arrow top">
            {/* al hacer click, meter clase active a .select-month-container */}
            <img
              src={images.IconArrowUpCalendar}
              alt="Elegir mes"
              onClick={() => this.prevMonth()}
            />
          </div>
          <div className="pick-arrow bottom">
            {/* al hacer click, meter clase active a .select-month-container */}
            <img
              src={images.IconArrowDownCalendar}
              alt="Elegir mes"
              onClick={() => this.nextMonth()}
            />
          </div>
        </div>
        <div className="current-year">
          <p onClick={() => this.setState({ showYears: true })}>
            {dateFns.format(this.state.currentDate, dateFormatYear, {
              locale: es
            })}
          </p>
          <div className="pick-arrow top" onClick={() => this.nextYear()}>
            {/* al hacer click, meter clase active a .year-select */}
            <img src={images.IconArrowUpCalendar} alt="Elegir año" />
          </div>
          <div className="pick-arrow bottom" onClick={() => this.prevYear()}>
            {/* al hacer click, meter clase active a .year-select */}
            <img src={images.IconArrowDownCalendar} alt="Elegir año" />
          </div>
        </div>
        <div className="calendar-arrow-container">
          {/* Arrow Left */}
          <div className="arrows arrow-left" onClick={() => this.prevMonth()}>
            <img src={images.IconArrowLeftBlue} alt="" />
          </div>
          {/* Arrow Right */}
          <div className="arrows arrow-right" onClick={() => this.nextMonth()}>
            <img src={images.IconArrowRightBlue} alt="" />
          </div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "dd";
    const days = [];
    // "L","M","X","J","V","S","D"
    let startDate = dateFns.startOfWeek(this.state.currentDate, {
      weekStartsOn: 1
    });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-days-day" key={i}>
          <div>
            <p>
              {dateFns.format(dateFns.addDays(startDate, i), dateFormat, {
                locale: es
              })}
            </p>
          </div>
        </div>
      );
    }
    return <div className="calendar-days">{days}</div>;
  }

  renderCells() {
    const { maxDate } = this.props;
    const { currentDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentDate);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = dateFns.endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = "DD";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        let validDay = true;
        if (maxDate) {
          validDay = moment(maxDate).isAfter(day);
        }
        days.push(
          <div
            className={`col cell ${!validDay ? "not-allow" : ""}${
              !dateFns.isSameMonth(day, monthStart)
                ? " disabled"
                : dateFns.isSameDay(day, currentDate)
                ? " selected"
                : ""
            }`}
            key={dateFns.format(day, "DD-MM-YYYY")}
            onClick={() =>
              validDay && this.onDateClick(dateFns.parse(cloneDay))
            }
          >
            {/* Days */}
            <div>
              <p>{formattedDate}</p>
            </div>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={dateFns.format(day, "DD-MM-YYYY")}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  renderSelectYear() {
    const { onSelect } = this.props;
    const { currentDate } = this.state;
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 150;
    let years = [];
    for (let i = currentYear; i > minYear; i--) {
      let child = [<p key={i}>{i}</p>];
      years.push(
        <li
          key={i}
          onClick={() =>
            this.setState(
              {
                currentDate: dateFns.setYear(currentDate, i),
                showYears: false
              },
              () => {
                onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"));
              }
            )
          }
        >
          {child}
        </li>
      );
    }

    const { showYears } = this.state;

    return (
      <div className={"year-select " + (showYears ? "active" : "")}>
        <div
          className="year-select-close"
          onClick={() => this.setState({ showYears: false })}
        >
          <img src={images.IconCrossBlue} alt="cerrar seleccion de año" />
        </div>
        <ul>{years}</ul>
      </div>
    );
  }

  renderSelectMonth() {
    const { onSelect } = this.props;
    const { showMonths, currentDate } = this.state;
    let tempDate = dateFns.setMonth(new Date(), 0);
    let rows = [];
    let months = [];
    for (let i = 0; i < 12; i++) {
      months.push(
        <div
          className="select-month-container-item"
          key={i}
          onClick={() =>
            this.setState(
              {
                currentDate: dateFns.setMonth(currentDate, i),
                showMonths: false
              },
              () => {
                onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"));
              }
            )
          }
        >
          <p>{dateFns.format(tempDate, "MMMM", { locale: es })}</p>
        </div>
      );
      tempDate = dateFns.addMonths(tempDate, 1);

      if ((i + 1) % 3 === 0) {
        rows.push(
          <div className="select-month-container-row" key={i}>
            {months}
          </div>
        );
        months = [];
      }
    }

    return (
      <div className={"select-month-container " + (showMonths ? "active" : "")}>
        {rows}
      </div>
    );
  }

  onDateClick(day: Date) {
    const { onSelect, closeCalendar } = this.props;
    this.setState(
      {
        currentDate: day
      },
      () => {
        onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"));
        closeCalendar();
      }
    );
  }

  nextMonth() {
    const { onSelect, maxDate } = this.props;
    const newDate = dateFns.addMonths(this.state.currentDate, 1);
    let isValid = true;
    if (moment(newDate).isAfter(maxDate)) {
      isValid = false;
    }
    if (isValid) {
      this.setState(
        {
          currentDate: newDate
        },
        () => {
          onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"));
        }
      );
    }
  }

  prevMonth() {
    const { onSelect } = this.props;
    this.setState(
      {
        currentDate: dateFns.subMonths(this.state.currentDate, 1)
      },
      () => {
        onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"));
      }
    );
  }

  nextYear = () => {
    const { onSelect } = this.props;
    this.setState(
      {
        currentDate: dateFns.addYears(this.state.currentDate, 1)
      },
      () => {
        onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"));
      }
    );
  };

  prevYear = () => {
    const { onSelect } = this.props;
    this.setState(
      {
        currentDate: dateFns.subYears(this.state.currentDate, 1)
      },
      () => {
        onSelect(dateFns.format(this.state.currentDate, "DD/MM/YYYY"));
      }
    );
  };

  render() {
    return (
      <CalendarItemContainer>
        <div className="calendar">
          {this.renderHeader()}
          {this.renderDays()}
          {this.renderCells()}
          {this.renderSelectYear()}
          {this.renderSelectMonth()}
        </div>
      </CalendarItemContainer>
    );
  }
}

export default withRouter(CalendarItem);
