import React from "react";
import { CardAsideContainer } from "./card-aside-style";
import { icons } from "../../../assets/icons/index";
import { images } from "../../../assets/images/index";
import { POLIZE_TYPE_NAME } from "../../../constants/data";

export default class CardAsideLayout extends React.Component<any> {
  render() {
    const { group, id, policyNumber, type } = this.props;
    return (
      <CardAsideContainer>
        <div className="card-aside-background">
          <img src={images.BackCardTypeBlue} alt="" />
        </div>
        <div className="card-aside-top">
          <div className="card-aside-top-left">
            <div className="card-aside-top-left__icon">
              <img src={icons.IconCardSlider} alt="" />
            </div>
            <div className="card-aside-top-left__text">
              <div className="card-aside-top-left__text__title">
                <p>Asisa</p>
              </div>
              <div className="card-aside-top-left__text__subtitle">
                <p>{POLIZE_TYPE_NAME[type]}</p>
              </div>
            </div>
          </div>
          <div className="card-aside-top-right">
            <p>{group}</p>
          </div>
        </div>

        <div className="card-aside-bottom">
          <div className="card-aside-bottom__title">
            <p>Nº PÓLIZA</p>
          </div>
          <div className="card-aside-bottom__text">
            <p>{policyNumber}</p>
          </div>
        </div>
      </CardAsideContainer>
    );
  }
}
