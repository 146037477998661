import styled from "styled-components";

export const MainTableContainer = styled.div`
  text-align: left;
  width: 100%;
  color: #091e42;
  display: flex;
  font-family: "museo sans";
  overflow: hidden;

  &.fixed-left {
    .main-table-head {
      tr {
        th  {
          &:first-child {
            display: none;
          }
        }
      }
    }
    .main-table-column {
      &.left {
        display: block;
        box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.1),
          -6px 0px 6px rgba(0, 0, 0, 0.05);
      }
    }
    .main-table-row {
      .main-table-row-cell {
        &:first-child {
          display: none;
        }
      }
    }
  }
  &.fixed-right {
    .main-table-head {
      tr {
        th  {
          &:last-child {
            display: none;
          }
        }
      }
    }
    .main-table-column {
      &.right {
        display: block;
        box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.1),
          -6px 0px 6px rgba(0, 0, 0, 0.05);
      }
    }
    .main-table-row {
      .main-table-row-cell {
        &:last-child {
          display: none;
        }
      }
    }
  }
  &.fixed-both {
    .main-table-head {
      tr {
        th  {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: none;
          }
        }
      }
    }
    .main-table-column {
      display: block;
    }
    .main-table-row {
      .main-table-row-cell {
        &:last-child {
          display: none;
        }
        &:first-child {
          display: none;
        }
      }
    }
  }

  /*  hide scrolls  */
  & > *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  & > * {
    -ms-overflow-style: none;
  }

  .scrollbar {
    -ms-overflow-style: auto;
  }

  .scrollbar::-webkit-scrollbar {
    display: initial !important;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    min-height: 30px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }

  .main-table-column {
    max-height: 100%;
    box-shadow: 10px 0px 16px rgba(0, 0, 0, 0.1),
      -6px 0px 6px rgba(0, 0, 0, 0.06);
    z-index: 9;
    display: none;
  }
  .main-table-column-scroll {
    overflow-y: scroll;
    height: 40vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-table-wrapper-scroll-vertical {
    overflow-y: scroll;
    height: 40vh;
    tr {
      width: 100%;
      display: flex;
      align-items: center;
    }
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .main-table-wrapper-scroll {
    overflow-x: scroll;
    width: 100%;
    /* overflow-y: hidden; */

    &::-webkit-scrollbar {
      display: initial !important;
    }
    -ms-overflow-style: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      min-height: 30px;
      border: 2px solid white; /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  table {
    width: 100%;
    &.main-table-head {
    }
  }

  .main-table-column-head {
    padding: 27px 22px;
    background: #004f8b;
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
    display: flex;
    align-items: center;
  }
  .main-table-head {
    display: inline-table;
    width: 100%;

    th {
      text-align: center;
      padding: 12px 16px;
      background: #004f8b;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 72px;
      min-height: 72px;
      max-height: 72px;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden;
      }

      &.pointer {
        cursor: pointer;
      }

      &.filter {
        cursor: pointer;
        p {
          padding-right: 30px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background: #004f8b;
            transition: 0.3s all ease-in-out;
          }
        }

        &.active {
          p {
            &:after {
              transform: translateY(-50%) rotate(-180deg);
            }
          }
        }
      }

      .title-text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .main-table-column-row {
    display: flex;
    align-items: center;
    padding: 22px;
    height: 60px;
    border-bottom: 1px solid #f1f1f1;
    background: white;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    justify-content: center;
    &.hover {
      background: rgba(0, 79, 139, 0.1);
    }
  }

  .main-table-row  {
    background: white;
    border-bottom: 1px solid #f1f1f1;
    width: 100%;
    align-items: center;
    display: flex;
    white-space: nowrap;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    &.hover {
      background: rgba(0, 79, 139, 0.1);
    }

    .main-table-row-cell {
      text-align: center;
      font-weight: 300;
      flex: auto;
      padding: 0;

      > div {
        padding: 21px;
        min-height: 90px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        p {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          display: block;
          overflow: hidden;
        }
        &.table-icon-container {
          padding: 0;
        }
        &.hire {
          cursor: pointer;
        }
      }

      &.inProgress {
        color: #ffa800;
      }

      &.gracefulPayment {
        color: #ffa800;
      }

      &.pendingInfo {
        color: #ffa800;
      }

      &.accepted {
        color: #00b98c;
      }

      &.notAcepted {
        color: #ff4040;
      }

      &.paid {
        color: #00b98c;
      }

      &.denied {
        color: #ff4040;
      }
    }

    .table-icon-item {
      a {
        padding: 25px 16px;
      }

      button {
        padding: 25px 16px;
        -webkit-appearance: none;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    &.hover {
      box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.05);
    }
  }

  /* Components */
  .main-table-single-check {
    width: 56px;
    background: #f1f1f1;
  }
  .main-table-button {
  }

  .main-table-body {
    overflow-y: auto;
    height: 80px;
  }

  .selectors {
    min-width: 56px;
    max-width: 56px;
    width: 56px;
  }
  .buttons {
    min-width: 85px;
    max-width: 85px;
    width: 85px;
    padding: 0 8px;
  }

  .table-col-1 {
    width: calc(85px * 1);
    max-width: calc(85px * 1);
    min-width: calc(85px * 1);
    overflow: hidden;
  }

  .table-col-1-5 {
    width: calc(85px * 1.5);
    max-width: calc(85px * 1.5);
    min-width: calc(85px * 1.5);
    overflow: hidden;
  }

  .table-col-2 {
    width: calc(85px * 2);
    max-width: calc(85px * 2);
    min-width: calc(85px * 2);
    overflow: hidden;
  }

  .table-col-3 {
    width: calc(85px * 3);
    max-width: calc(85px * 3);
    min-width: calc(85px * 3);
    overflow: hidden;
  }

  .table-col-4 {
    width: calc(85px * 4);
    max-width: calc(85px * 4);
    min-width: calc(85px * 4);
    overflow: hidden;
  }

  .toRight {
    text-align: right;
  }

  .main-table-row-left {
    font-size: 16px;
    color: #091e42;
    font-weight: 500;
  }

  .main-table-row-right {
    font-weight: 500;
    font-size: 14px;
    color: #091e42;
  }

  .context-text-multiple-content {
    p {
      padding-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .multiple {
    .tooltip-item {
      &__img {
        padding-bottom: 12px;
      }
    }
  }

  .context-text-tooltip-content {
    cursor: pointer;
    display: flex;
    align-items: center;

    .tooltip-item {
      &__text {
        margin-right: 6px;
        width: 95%;
      }
      &__img {
        height: 16px;
        width: 20px;
        min-width: 20px;
        display: flex;
        align-items: center;

        img {
          max-width: 20px;
        }
      }
    }
  }
`;
