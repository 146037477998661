import React, { Component } from "react";
import { Link } from "react-router-dom";
import { icons } from "../../assets/icons";
import { IPoliticsProps } from "../../constants/data";
import PrivatePoliticsContainer from "./private-politics-style";

class PrivatePolitics extends Component<IPoliticsProps> {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    const { goBackButton } = this.props;
    return (
      <PrivatePoliticsContainer>
        <div className="private-politics-wrapper">
          { goBackButton && 
            <Link to="/">
              <div className="go-back">
                <img src={icons.iconArrowLeft} alt=""/>
              </div>
            </Link>
          }
          <div className="private-politics-title">
            <h1>Políticas de privacidad</h1>
          </div>

          <article className="private-politics-article">
            <div className="article-content">
              <p>ASISA informa al usuario de que el tratamiento de todos los datos de carácter personal se realiza de conformidad con lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre de Protección de Datos y con el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter personal.</p>
              <p>Los datos de carácter personal facilitados por el usuario a través de nuestras páginas, mediante formularios o envío de correos electrónicos quedarán registrados y se incorporarán a un fichero, del que es responsable ASISA, con la finalidad de gestionar las peticiones formuladas, así como la relativa al mantenimiento, desarrollo y control de la relación jurídica que pueda establecerse entre las partes, así como para remitirle información sobre bienes o servicios cuya contratación pueda ser de su interés.</p>
              <p>Con el suministro de sus datos, el usuario acepta que los mismos puedan ser cedidos, exclusivamente para las finalidades antes indicadas, a entidades del Grupo ASISA (www.asisa.es), filiales y participadas, así como a otras entidades colaboradoras del Grupo.</p>
              <p>En aquellos supuestos de usuarios menores de edad que deseen incorporar datos de carácter personal en los formularios de la website de ASISA, será requisito necesario el previo consentimiento de sus padres o tutores, exonerándose a ASISA de cualquiera clase de responsabilidades derivadas del incumplimiento de dicho requisito.</p>
              <p>En cualquier momento, el usuario podrá revocar el consentimiento prestado, así como ejercer sus derechos de acceso, rectificación, cancelación y oposición, solicitándolo por cualquier medio que deje constancia de su envío y de su recepción ante ASISA (Calle Juan Ignacio Luca de Tena, 12 – Departamento de Asesoría Jurídica o ajuridica.juridica@asisa.es).</p>
              <p>De acuerdo con la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, ASISA se compromete al cumplimiento de su obligación de secreto con respecto a los datos de carácter personal y al deber de tratarlos con confidencialidad. A estos efectos, adoptará las medidas necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado.</p>
              <p>El usuario garantiza que los datos personales facilitados a través de nuestras páginas web son veraces y se hace responsable de comunicar cualquier modificación de los mismos para que, en todo momento respondan a su situación actual.</p>
              <p>ASISA se reserva la facultad de modificar la presente Política de Privacidad para adaptarla a las novedades legislativas, jurisprudenciales o de interpretación de la Agencia Española de Protección de Datos. En este caso, ASISA anunciará dichos cambios, indicando claramente y con la debida antelación las modificaciones efectuadas, y solicitándole, en caso de que sea necesario, la aceptación de dichos cambios.</p>
            </div>
          </article>
        </div>
      </PrivatePoliticsContainer>
    );
  }
}

export default PrivatePolitics;
