import styled from "styled-components";

export const AccessContainer = styled.div`
  .access-title {
    font-size: 44px;
    line-height: 50px;
    color: #091e42;
    margin-bottom: 62px;
  }
  .go-back {
    margin-bottom: 20px;
    img {
      width: 40px;
    }
  }
  .access-text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    color: #6b778c;
    p {
      margin-bottom: 28px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    .access-list{
        margin: 0 0 40px 30px;
        position: relative;
        &:before {
        position: absolute;
        content: '';
        border-radius: 100%;
        width: 6px;
        height: 6px;
        left: -13px;
        top: 9px;
        background-color: #1a4ccd;
      }
    }
  }

  @media (max-width: 650px) {
    .access-title {
      font-size: 26px;
      line-height: 1.5em;
      margin-bottom: 32px;
    }
    .access-text {
      line-height: 1.5em;
      font-size: 14px;
      p {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
