import React, { Component } from "react";
import CardMessageContainer from "./card-message-style";
import { icons } from "../../../assets/icons";
import UploadDocs from "../../form-components/upload-docs";
import { IReport } from "../../../services/requests/create-user-request";
import { isoStringToStringDate } from "../../../utils/date";
import ModalUploadFiles from "../../modals/modal-upload-files";
import ItemDocument from "../../item-document";
import ItemDocumentDownload from "../../item-document-download";
import { getDoc } from "../../../services/docs/get-doc";
import { store } from "../../../redux/store";
import { setLoading, setModalError } from "../../../redux/actions";
import imageCompression from "browser-image-compression";
import services from "../../../utils/services";
import FileUtils from "../../../utils/files";
import classNames from "classnames";
import AutoTextarea from "../../form-components/auto-textarea/auto-textarea";
import TooltipHook from "../../tooltip-hooks/tooltip-hooks";


const fileType = require("file-type");

interface CardMessageProps {
  date: string;
  isNew?: boolean;
  messageId: string | number;
  userName: string;
  message: string;
  responseMessage?: string;
  responseDocs?: any[];
  onClick?: Function;
  isFocus?: boolean;
  placeholderText?: string;
}

const imageCompressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
};

class CardMessage extends Component<CardMessageProps, any> {
  state = {
    message: "",
    showUploadModal: false,
    file: [],
    isNew: false,
    sizeTextarea: 80,
  };

  public static defaultProps = {
    placeholderText: "Mensaje"
  };

  componentDidMount() {
    const { isNew } = this.props;
    if (isNew) {
      this.setState({ isNew });
    }
    this.props.responseDocs && this.getResponseFiles();
  }

  componentDidUpdate(prevProps: CardMessageProps) {
    const { isNew } = this.props;
    if (prevProps.isNew !== isNew) {
      this.setState({ isNew });
    }
  }

  decodeBase64 = (base64: string, fileName: string) => {
    const type = fileName.split(".")[1];
    // decode base64
    const fileContent = atob(base64);
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const fileBuffer = new ArrayBuffer(fileContent.length);
    const view = new Uint8Array(fileBuffer);

    // fill the view, using the decoded base64
    for (let index = 0; index < fileContent.length; index++) {
      view[index] = fileContent.charCodeAt(index);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([fileBuffer], { type: type });

    return blob;
  };

  getResponseFiles = async () => {
    try {
      const { responseDocs } = this.props;
      let fileData: any = {};
      let counter = 0;
      let { file } = this.state;
      file = [];
      for (let document of responseDocs) {
        counter++;
        // fileData = FileUtils.convertBase64ToFile(document.fileId, document.fileName);

        // fileData = await getDoc({ id: document.fileId });
        // String con el base 64
        let mimeInfo = fileType(Buffer.from(document.fileId, "base64"));
        const blobFile = this.decodeBase64(
          document.fileId,
          "Document." + mimeInfo.ext
        );
        blobFile["fileName"] = document.fileName;
        file.push(blobFile);
        // this.downloadFile(blobFile, "Document." + mimeInfo.ext);
      }
      this.setState({ file });
      store.dispatch(setLoading(false));
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = services.errorHandler(err);
      console.error(formatError);
    }
  };

  handleSend = async () => {
    const { onClick } = this.props;
    const { message, file } = this.state;
    const obj: any = {
      body: message,
      files: [],
    };

    if (file.length > 0) {
      file.forEach((fileObj) => {
        obj.files.push({ fileId: fileObj.id, fileName: fileObj.fileName });
      });
    }
    onClick(obj);
  };

  newText = (text: string) =>
    text
      ? text.split("\\n").map((item, i) => {
          return <p key={i}>{item}</p>;
        })
      : "";

  getBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function(event: any) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  addNewDocuments = async (files: any) => {
    let auxArray: any[] = [];
    const compressFiles = ["png", "jpg", "jpeg"];
    if (files.length > 0) {
      try {
        store.dispatch(setLoading(true));
        auxArray = await Promise.all(
          files.map(async (file) => {
            if (file.data) {
              if (
                compressFiles.includes(
                  FileUtils.getExtension(file.name).toLowerCase()
                )
              ) {
                const compressedFile = await imageCompression(
                  file.data,
                  imageCompressionOptions
                );
                const compressedB64: any = await this.getBase64(compressedFile);
                return {
                  id: compressedB64.split("base64,")[1],
                  fileName: file.name,
                  name: file.name,
                  mimeType: file.type,
                  date: isoStringToStringDate(new Date().toISOString(), "/"),
                  description: "",
                };
              }
              const b64: any = await this.getBase64(file.data);
              return {
                id: b64.split("base64,")[1],
                fileName: file.name,
                name: file.name,
                mimeType: file.data.type,
                date: isoStringToStringDate(new Date().toISOString(), "/"),
                description: "",
              };
            } else return file;
          })
        );
        store.dispatch(setLoading(false));
      } catch (err) {
        console.error(err);
      }
    }
    this.setState({ file: auxArray, showUploadModal: false });
  };

  async markAsViewed() {
    const { messageId } = this.props;
    const { isNew } = this.state;
    const isNewAux = !isNew;
    this.setState({ isNew: isNewAux });
    services.post({
      loader: false,
      endpoint: `health/messages/${messageId}/viewed`,
      data: { isViewed: !isNewAux },
      then: () => {},
      catch: () => {},
    });
  }

  deleteFileHandler = (fileId: string) => {
    const { file } = this.state;
    if (file.length > 0) {
      file.splice(
        file.findIndex(function(fileItem) {
          return fileItem.id === fileId;
        }),
        1
      );
    }
  };

  render() {
    const {
      isFocus,
      date,
      userName,
      message,
      responseDocs,
      responseMessage,
      onClick,
      placeholderText
    } = this.props;
    const { showUploadModal, file, isNew, message: newMessage } = this.state;

    var existFiles = responseDocs && responseDocs.length > 0;
    var existMessage = responseMessage != '';
    var existResponse = existMessage || existFiles;

    return (
      <CardMessageContainer className={"new"}/*{`${isFocus ? "new" : ""}`}*/>
        <div className="message-topbar">
          {/* <div className="message-topbar-item">
            <div className="message-username">
              <p>{userName ? userName : ""}</p>
            </div>
          </div> */}
          {/* <div className="extend-button-container">
            <button>Expandir para responder</button>
          </div> */}
          <div className="message-topbar-item-info">
            <div className="message-date">
              <p>{date ? date : ""}</p>
            </div>
            <div
              className="message-status-icon"
              onClick={(event) => {
                event.stopPropagation();
                this.markAsViewed.call(this);
              }}
            >
              <img
                src={
                  isNew
                    ? icons.newMessageIcon
                    : isFocus
                    ? icons.ReadMessageIconBlue
                    : icons.readMessageIcon
                }
                alt=""
              />
              <p>{isNew ? `Marcado como no visto` : `Marcado como visto`}</p>
            </div>
          </div>
        </div>

        <div className="message">{this.newText(message)}</div>

        {existResponse && (
          <div className="response-container">
            <div className="response-topbar">
              <div className="response-text">
                <p>RESPUESTA ENVIADA</p>
              </div>
            </div>
            <div className="response-message">
              {this.newText(responseMessage)}
            </div>
            <ul className="response-doc-list">
              {this.state.file.length !== 0 &&
                this.state.file.map((element: any, index: number) => (
                  <li className="response-doc-element" key={index}>
                    <ItemDocumentDownload
                      file={element}
                      name={element.fileName}
                    />
                  </li>
                ))}
            </ul>
          </div>
        )}

        { !existResponse && (
          <div className="edit-response-container">
            <div className="edit-response-input">
              <AutoTextarea
                onChange={(ev) => {
                  this.setState({ message: ev.target.value });
                }}
                placeholder={file.length > 0 ? placeholderText : 'Mensaje'}
              />
              <ul
                className={classNames("response-doc-list", {
                  active: file.length > 0,
                })}
              >
                {this.state.file.length !== 0 &&
                  this.state.file.map((element: any, index: number) => (
                    <li className="response-doc-element" key={index}>
                      <ItemDocumentDownload
                        file={element}
                        name={element.fileName}
                        deleteButton={() => this.deleteFileHandler(element.id)}
                      />
                    </li>
                  ))}
              </ul>
            </div>
            <div className="edit-response-buttons">
              <TooltipHook content="Importar archivos" direction="bottom">
                <div className="attach">
                  <img
                    src={icons.clipIcon}
                    alt=""
                    onClick={() => this.setState({ showUploadModal: true })}
                  />
                </div>
              </TooltipHook>
              <TooltipHook content={(newMessage || file.length > 0) ? "Enviar" : "Debe adjuntar algún archivo o escribir un mensaje"} direction="bottom">
                <div
                  className={classNames("btn-send", {
                    active: newMessage || file.length > 0,
                  })}
                  onClick={() =>
                    (newMessage || file.length > 0) && this.handleSend()
                  }
                >
                  <img src={icons.sendMessageIcon} alt="" />
                </div>
                </TooltipHook>
            </div>
          </div>
        )}
        {showUploadModal && (
          <ModalUploadFiles
            showModal={showUploadModal}
            multiple={true}
            onClose={() => this.setState({ showUploadModal: false })}
            onConfirm={(files) => this.addNewDocuments(files)}
            files={file}
            notDisabled={true}
          />
        )}
      </CardMessageContainer>
    );
  }
}

export default CardMessage;
