import styled from "styled-components";

const AddDocFormContainer = styled.form`
  .add-doc-form-item {
    max-width: 627px;
    margin-bottom: 32px;
  }

  .doc-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .doc-element {
    /* width: 290px; */
    margin-right: 12px;
    margin-bottom: 12px;
    cursor: pointer;
  }

  @media (max-width: 440px) {
    .doc-element {
      margin: 0;
      width: 100%;
      margin-bottom: 24px;
    }
  }
`;

export default AddDocFormContainer;
