/** Ejemplos, para clases y props
    <SelectBox
        // className="show-list show-tooltip error"
    />
*/

import React, { Component } from "react";
import { SelectBoxContainer } from "./select-box-style";
import { images } from "../../../assets/images";
import TooltipBox from "../../util-components/tooltip-box";
import BodyText from "../../text/body-text";
import NoteText from "../../text/note-text";
import LabelText from "../../text/label-text";

interface SelectBoxProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  labelText?: string;
  errorText?: string;
  tooltipTitle?: string;
  tooltipText?: string;
  optionsText: Array<string>;
  onChange: Function;
  initialValue?: string;
  withTooltip?: boolean;
  required?: boolean;
}

interface SelectBoxState {
  focus: boolean;
  showList: boolean;
  showTooltip: boolean;
  value: string;
}

class SelectBox extends Component<SelectBoxProps, SelectBoxState> {
  private toggleContainer: any;

  constructor(props: SelectBoxProps) {
    super(props);
    this.state = {
      focus: false,
      showList: false,
      showTooltip: false,
      value: "",
    };
    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  public static defaultProps = {
    withTooltip: false,
    icon: images.IconArrowDownBlack,
    iconDisabled: images.IconArrowDownGrey,
  };

  componentDidMount() {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (initialValue && initialValue !== "" && value === "") {
      this.setState({ value: initialValue });
    }
    window.addEventListener("click", this.onClickOutsideHandler);
  }

  componentDidUpdate() {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (initialValue && initialValue !== value) {
      this.setState({ value: initialValue });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (this.state.showList && !this.toggleContainer.current.contains(event.target)) {
      this.setState({ showList: false });
    }
  }

  render() {
    const {
      disabled,
      className,
      icon,
      withTooltip,
      iconFocus,
      iconDisabled,
      labelText,
      errorText,
      tooltipTitle,
      tooltipText,
      optionsText,
      onChange,
      required
    } = this.props;
    const { showList, showTooltip, value } = this.state;

    return (
      <SelectBoxContainer
        ref={this.toggleContainer}
        className={`${className} ${showList ? "show-list" : ""} ${showTooltip ? "show-tooltip" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>
              <p>
                {labelText}
                {required && <label className="mandatory-field"> * </label>}
              </p>
            </LabelText>
          </div>
          {withTooltip && (
            <div
              className="input-box-topbar-icon"
              onClick={() => this.setState((prevState: SelectBoxState) => ({ showTooltip: !prevState.showTooltip }))}
            >
              <img src={disabled ? images.IconInfoGrey : images.IconInfoGreen} />
              <div className="input-box-tooltip">
                <TooltipBox tooltipTitle={tooltipTitle} tooltipText={tooltipText} />
              </div>
            </div>
          )}
        </div>
        <div
          className="input-box-main"
          onClick={() => this.setState((prevState: SelectBoxState) => ({ showList: !prevState.showList }))}
        >
          <div className="input-box-main-field">
            <BodyText>
              <p>{value === "" ? "Seleccionar" : value}</p>
            </BodyText>
          </div>
          <div className="input-box-icon">
            <img src={disabled ? iconDisabled : icon} />
          </div>
        </div>
        <ul className="input-box-main-list">
          {(optionsText || []).map((option: any, i) => (
            <li
              key={i}
              onClick={() => {
                this.setState({ value: `${option}`, showList: false }, () => {
                  onChange(option);
                });
              }}
            >
              <BodyText>
                <p>{option}</p>
              </BodyText>
            </li>
          ))}
        </ul>
        {errorText && (
          <div className="input-box-error">
            <NoteText>
              <p>{errorText}</p>
            </NoteText>
          </div>
        )}
      </SelectBoxContainer>
    );
  }
}

export default SelectBox;
