import styled from "styled-components";
import { CardAsideContainer } from "../../cards/card-aside-profile/card-aside-style";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";
import { ButtonLoaderContainer } from "../../buttons/button-loader/button-loader-style";
import { colors } from "../../../assets/colors";

export const AsideProfileContainer = styled.div`
  .aside-profile-slider {
    overflow: hidden;
    margin-bottom: 32px;

    .slick-dots  {
      width: 100%;
      justify-content: center;
      margin-top: 32px;
      li  {
        &:last-of-type button {
          margin-right: 0px;
        }
        &.slick-active button {
          width: 37.33px;
          height: 8px;
          background: ${colors["PR-001-100"]};
          border-radius: 14px;
        }
      }
      li button {
        border: none;
        width: 8px;
        height: 8px;
        display: inline-block;
        background: #e2e5ea;
        border-radius: 14px;
        transition: all 0.3s ease-in-out;
        margin-right: 16px;
        padding: 0;
        cursor: pointer;
      }
    }
    .slick-list {
      overflow: visible;
    }
    .slick-slide {
      transform: scale(0.9);
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    .slick-active {
      transform: scale(1);
      opacity: 1;
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
  .aside-profile-slider-container  {
    padding: 0 30px;
    margin-bottom: 32px;
  }
  .aside-profile-card-item {
    &:focus {
      border: none;
      outline: none;
    }
  }
  .aside-profile-slider-dots {
    border: 1px solid paleturquoise;
    height: 8px;
  }
  .aside-profile-name {
    font-size: 48px;
    line-height: normal;

    color: #091e42;
  }
  .aside-profile-content  {
    padding: 0 30px 60px;
  }
  .aside-profile-surname {
    font-size: 36px;
    line-height: normal;
    font-weight: 700;
    color: #091e42;
    margin-bottom: 60px;
  }
  .aside-profile-form {
    min-height: 400px;
    margin-bottom: 0;
    &__item {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .aside-profile-button {
    display: flex;
    justify-content: space-between;
    &__item  {
      width: 206px;
    }
  }

  @media (max-width: 900px) {
    .card-aside-bottom {
      &__title {
        font-size: 14px;
      }
      &__text {
        font-size: 22px;
      }
    }

    .aside-profile-name {
      font-size: 26px;
    }

    .aside-profile-surname {
      font-size: 26px;
    }
  }

  @media (max-width: 550px) {
    &.active {
      width: 100%;
      max-width: none;
    }

    .header-aside-container {
      width: 100%;
      height: calc(100vh - 53px);
    }

    .aside-profile-slider {
      margin-bottom: 0;
      padding-left: 30px;

      .slick-dots {
        transform: translateX(-14px);
      }
    }

    .aside-profile-slider-container {
      padding: 0;
    }

    ${CardAsideContainer} {
      height: 180px;
    }

    .aside-profile-name {
      font-size: 24px;
      margin-bottom: 2px;
    }

    .aside-profile-surname {
      font-size: 24px;
      margin-bottom: 42px;
    }

    .slick-list {
      padding: 0 !important;
    }

    .aside-profile-form {
      margin-bottom: 0;
      &__item {
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .aside-profile-button {
      &__item {
        width: 50%;
      }
      ${MainButtonContainer} {
        font-size: 14px;
        height: 48px;
      }
      ${ButtonLoaderContainer} {
        font-size: 14px;
        height: 48px;
      }
    }
  }
`;
