import React, { Component } from "react";
import ItemAsideNotification from "../../item-aside-notification/item-aside-notification";
import { AsideNotificationContainer } from "./aside-notification-styles";
import NoContent from "../../util-components/no-content";
import { withRouter, RouteComponentProps } from "react-router";
import { RequestNotification } from "./aside-notification-functional";

interface AsideNotificationLayoutProps extends RouteComponentProps {
  notifications: Array<RequestNotification>;
  goToNotification: (notification: RequestNotification) => void;
}
//thisone
class AsideNotificationLayout extends Component<AsideNotificationLayoutProps, {}> {
  render() {
    const { notifications, goToNotification } = this.props;

    return (
      <AsideNotificationContainer>
        <div className="header-aside-title">
          <p>Notificaciones</p>
        </div>
        <div className="header-aside-list">
          {notifications.length <= 0 ? (
            <NoContent />
          ) : (
            notifications.map((item, i) => (
              <div key={i} className="header-aside-item" onClick={() => goToNotification(item)}>
                <ItemAsideNotification notification={item} className={item.viewed ? "old" : ""} />
              </div>
            ))
          )}
        </div>
      </AsideNotificationContainer>
    );
  }
}

export default withRouter(AsideNotificationLayout);
