import styled from "styled-components";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";
import { images } from "../../../assets/images";

const ModalMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  z-index: 9999;

  &.error {
    color: #ff4100;

    ${MainButtonContainer} {
      background: #ff4100;
    }
  }

  &.warning {
    .modal-message-wrapper {
      width: 540px;
      background-image: url(${images.introBackground});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }

  .modal-message-wrapper {
    padding: 50px 40px;
    width: 740px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    position: relative;

    .btn-close {
      position: absolute;
      top: 22px;
      right: 22px;
      cursor: pointer;
    }

    .modal-message-title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      .title {
        font-family: "museo sans";
        font-size: 32px;
        line-height: 40px;
        color: #091e42;
      }
    }

    .modal-message-text {
      font-family: "museo sans";
      font-size: 16px;
      line-height: 24px;
      color: #091e42;
      margin-bottom: 62px;

      p {
        margin-bottom: 20px
      }

    }

    .modal-message-btn {
      width: 234px;
    }
  }

  @media (max-width: 440px) {
    .modal-message-wrapper {
      .modal-message-title-container {
        .title {
          font-size: 22px;
        }
      }
    }

    .modal-message-wrapper {
      width: 90%;
      padding: 24px 16px;

      .btn-close {
        top: 32px;
      }
    }
  }
`;

export default ModalMessageContainer;
