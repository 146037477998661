/** Ejemplos, para clases y props
     <TextareaBox
className="error"
icon={images.IconCalendarGreen}
iconDisabled={images.IconCalendarGrey}
/>
<TextareaBox
disabled
iconDisabled={images.IconCalendarGrey}
/>
<TextareaBox
className="show-tooltip"
icon={images.IconCalendarGreen}
iconDisabled={images.IconCalendarGrey}
/> 
*/

import React, { Component } from "react";
import { TextareaBoxContainer } from "./textarea-box-style";
import { withRouter, RouteComponentProps } from "react-router";
import { images } from "../../../assets/images";
import TooltipBox from "../../util-components/tooltip-box";
import NoteText from "../../text/note-text";
import LabelText from "../../text/label-text";

interface TextareaBoxProps extends RouteComponentProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  labelText: string;
  errorText?: string;
  tooltipTitle?: string;
  tooltipText?: string;
  onChange?: Function;
  onBlur?: Function;
  initialValue: string;
  withTooltip?: boolean;
  name: string;
  placeholder?: string;
}

interface TextareaBoxState {
  prevInitialValue: string;
  focus: boolean;
  showTooltip: boolean;
  value: string;
}

class TextareaBox extends Component<TextareaBoxProps, {}> {
  state: TextareaBoxState = {
    prevInitialValue: "",
    focus: false,
    showTooltip: false,
    value: ""
  };

  public static defaultProps = {
    withTooltip: false
  };

  componentDidMount() {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (initialValue && value === "") {
      this.setState({ value: initialValue });
    }
  }

  componentDidUpdate() {
    const { value } = this.state;
    const { initialValue } = this.props;
    if (initialValue && initialValue !== value) {
      this.setState({ value: initialValue });
    }
  }

  render() {
    const {
      placeholder,
      disabled,
      className,
      withTooltip,
      icon,
      iconFocus,
      iconDisabled,
      labelText,
      errorText,
      tooltipTitle,
      tooltipText,
      onChange
    } = this.props;
    const { showTooltip, value } = this.state;

    return (
      <TextareaBoxContainer
        className={`${className} ${showTooltip ? "show-tooltip" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {labelText && (
          <div className="textarea-box-topbar">
            <div className="textarea-box-topbar-label">
              <LabelText>
                <p>{labelText}</p>
              </LabelText>
            </div>

            {withTooltip && (
              <div
                className="textarea-box-topbar-icon"
                onClick={() =>
                  this.setState((prevState: TextareaBoxState) => ({
                    showTooltip: !prevState.showTooltip
                  }))
                }
              >
                <img
                  src={disabled ? images.IconInfoGrey : images.IconInfoGreen}
                />
                <div className="textarea-box-tooltip">
                  <TooltipBox
                    tooltipTitle={tooltipTitle}
                    tooltipText={tooltipText}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="textarea-box-main">
          <div className="textarea-box-main-field">
            <textarea
              value={value}
              onChange={e => {
                this.setState({ value: e.target.value }, () =>
                  onChange(this.props.name, this.state.value)
                );
              }}
              onBlur={e => this.props.onBlur(this.props.name, e.target.value)}
              placeholder={placeholder}
            />
          </div>
          <div className="textarea-box-icon">
            <img src={disabled ? iconDisabled : icon} />
          </div>
        </div>
        {errorText && (
          <div className="textarea-box-error">
            <NoteText>
              <p>{errorText}</p>
            </NoteText>
          </div>
        )}
      </TextareaBoxContainer>
    );
  }
}

export default withRouter(TextareaBox);
