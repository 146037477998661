import styled from "styled-components";

const DetailRequestContainer = styled.section`
  padding: 84px 160px;
  background: #f8f9fb;
  font-family: "museo sans";

  .bill-list {
    display: flex;
    flex-wrap: wrap;
  }

  .bill-element {
    width: 300px;
    min-width: 300px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .messages-without-request {
    display: flex;
    align-items: center;
    span {
      display: flex;
      right: 0;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: red;
      color: white;
      align-items: center;
      margin-right: 3px;
    }
  }
  .pending-documentation-container {
    margin: auto;
    width: 50%;
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    background: lightgoldenrodyellow;
    border-radius: 5px;
    color: #BDB76B;
  }

  .accordion-content-description {
    margin-top: 30px;
  }

  .accordion-content-description-title {
    font-size: 12px;
    color: #6b778c;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .accordion-content-description-main {
    font-weight: 300;
    font-size: 16px;
    color: #091e42;
    line-height: 1.5em;
  }

  .accordion-content {
    padding: 23px;
  }

  .collapse {
    display: none;
  }

  .chat-element {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-content-type {
    display: flex;
    align-items: center;
  }

  .accordion-content-description-type-icon {
    margin-right: 18px;
    img {
      max-width: 24px;
    }
  }

  .accordion-content-description-type-text {
    font-weight: 500;
    font-size: 16px;
    color: #6b778c;

    .success {
      color: #01b2ea;
    }
  }

  .accordion-container {
    background: #ffffff;
    margin-bottom: 20px;
    box-shadow: 0px 4px 15px rgba(206, 206, 206, 0.15);
    border-radius: 6px;

    &.open {
      .accordion-topbar {
        background: rgba(1, 178, 234, 0.15);
      }

      .accordion-content {
        padding: 32px 23px;
      }

      .accordion-topbar-item {
        &__img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .accordion-topbar-item {
    font-weight: 600;
    font-size: 24px;
    color: #004f8b;

    &__img {
      transition: 0.3s all ease-in-out;
    }

    img {
      max-width: 32px;
    }

    &.with-icon {
      align-items: center;
      display: flex;
    }
  }

  .accordion-topbar {
    padding: 32px 23px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    &:hover {
      background: rgba(1, 178, 234, 0.15);
    }
  }

  .accordion-topbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .main-detail-card-body-row-title {
    font-weight: 600;
    font-size: 18px;
    color: #004f8b;
    margin-bottom: 16px;
  }

  .main-detail-card-body-row-item-title {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #6b778c;
    margin-bottom: 8px;
  }

  .main-detail-card-body-row-item-content {
    font-weight: 500;
    font-size: 16px;
    color: #091e42;
    &_email{
      cursor: pointer;
      text-decoration:underline;
      color: #3274b1;
      &:hover{
        color: #093353;
      }
    }
  }

  .main-detail-card-topbar-info-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .main-detail-card-topbar-info-row-request {
    font-weight: 300;
    font-size: 16px;
    color: #091e42;
    span {
      font-weight: 700;
    }
  }

  .main-detail-card-topbar-info-row-date {
    font-weight: 300;
    font-size: 12px;
    color: #6b778c;
    position: relative;
    margin-right: 26px;
    &:after {
      content: "";
      width: 4px;
      height: 4px;
      background: #6b778c;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15px;
    }
  }

  .bill-body-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media (max-width: 440px) {
    padding: 16px;
    .bill-title {
      margin-bottom: 22px;
      font-weight: 700;
      padding-right: 20px;
    }
  }

  .main-detail-card-topbar-info-row-status {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;

    &.inProgress {
      color: #ffa800;
    }

    &.pendingInfo {
      color: #ffa800;
    }

    &.accepted {
      color: #00b98c;
    }

    &.notAcepted {
      color: #ff4040;
    }

    &.paid {
      color: #00b98c;
    }

    &.denied {
      color: #ff4040;
    }
  }

  .main-detail-card-topbar-title {
    font-weight: 300;
    font-size: 26px;
    color: #091e42;

    span {
      font-weight: 700;
    }
  }

  .detail-breadcrumbs {
    margin-bottom: 24px;
  }

  .main-detail-card {
    background: #ffffff;
    padding: 31px 22px;
    box-shadow: 0px 4px 15px rgba(206, 206, 206, 0.15);
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .breadcrumbs-container {
    display: flex;
    text-transform: uppercase;
    color: #6b778c;
    font-size: 14px;
    font-weight: 500;
  }

  .breadcrumbs-item {
    margin-right: 16px;
    position: relative;

    &:after {
      content: "/";
      position: absolute;
      right: -11px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      margin: 0;
      font-weight: 700;
      &:after {
        display: none;
      }
    }
  }

  .main-detail-card-topbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 16px;
    border-bottom: 0.75px solid #e2e5ea;
  }

  .main-detail-card-body {
    padding-top: 28px;
  }

  .main-detail-card-body-row {
    margin-bottom: 44px;

    &__holder {
      display: flex;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .main-detail-card-body-row-container {
    display: flex;

    &:last-child {
      .main-detail-card-body-row-item {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .main-detail-card-body-row-item {
    margin-right: 120px;
  }

  @media (max-width: 1030px) {
    .main-detail-card-body-row {
      &__holder {
        flex-wrap: wrap;
      }
    }

    .main-detail-card-body-row-container {
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 800px) {
    padding: 140px 100px 84px;
    .main-detail-card-body-row {
      &__holder {
        margin-bottom: 16px;
      }
    }

    .main-detail-card-body-row-container {
      flex-wrap: wrap;
      margin: 0;
    }

    .main-detail-card-body-row-item {
      margin-right: 30px;
      min-width: 165px;
      margin-bottom: 24px;
    }

    .main-detail-card-topbar {
      flex-wrap: wrap;
    }

    .main-detail-card-topbar-item {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .main-detail-card-topbar-info-row {
      justify-content: flex-start;
    }
  }
`;

export default DetailRequestContainer;
