import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Cookie from "js-cookie";

import { ModalContainerStyle } from "./modal-cookies-style";
import useEventListener from "../../../hooks/UseEventListener";
import { icons } from "../../../assets/icons";

interface IOptionItem {
	text: string;
	cookieKey: string;
	options: string[];
	value: boolean;
	required: boolean;
	[key: string]: any;
}
interface ModalContainerProps {
	cookiesText: string;
	cookiesButtonTextSettings: string;
	cookiesButtonTextAccept: string;
	titleSettings: string;
	textSettings: string[];
	titleOptions: string;
	optionsItems: IOptionItem[];
	buttonAcceptText: string;
	buttonRejectText: string;
}

const ModalCookies: React.FC<ModalContainerProps> = ({
	cookiesText,
	cookiesButtonTextSettings,
	cookiesButtonTextAccept,
	titleSettings,
	textSettings,
	titleOptions,
	optionsItems,
	buttonAcceptText,
	buttonRejectText,
}) => {
	const [active, setActive] = useState<boolean>(Cookie.get("cookiesAccepted") !== "true");
	const [activeModal, setActiveModal] = useState<boolean>(false);
	const [showOptions, setShowOptions] = useState<any>({});
	const [options, setOptions] = useState<any[]>(optionsItems);

	useEffect(() => {
		if (activeModal) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "scroll";
		}

		return () => {
			document.body.style.overflowY = "scroll";
		};
	}, [activeModal]);

	// useEventListener(
	// 	"scroll",
	// 	(e: any) => {
	// 		if (window.scrollY > 300) {
	// 			acceptModal();
	// 		}
	// 	},
	// 	{},
	// );

	const acceptModal = () => {
		options.forEach((option: any) => {
			if (option.value) {
				Cookie.set(option.cookieKey, "true", { expires: 7 });
			}
		});

		Cookie.set("cookiesAccepted", "true", { expires: 7 });
		setActiveModal(false);
		setActive(false);
	};

	const handleShowOptions = (key: string) => {
		showOptions[key] = !showOptions[key];
		setShowOptions({ ...showOptions });
	};

	const handleAccept = (index: number) => {
		const newOptions = [...options];
		if (!options[index].required) {
			newOptions[index].value = true;
			setOptions(newOptions);
		}
	};

	const handleRefuse = (index: number) => {
		const newOptions = [...options];
		if (!options[index].required) {
			newOptions[index].value = false;
			setOptions(newOptions);
		}
	};

	return (
		<ModalContainerStyle>
			<div className={classNames("box-modal-cookies", { off: true })}>
				<p className="modal-description">{cookiesText}</p>
				<div className="box-modal-cookies-buttons">
					<div className="box-modal-cookies-buttons__item">
						<button onClick={() => acceptModal()} className="btn-ok-cookies">
							{cookiesButtonTextAccept}
						</button>
					</div>
					<div className="box-modal-cookies-buttons__item">
						<button
							onClick={() => {
								setActive(false);
								setActiveModal(true);
							}}
							className="btn-options-cookies"
						>
							{cookiesButtonTextSettings}
						</button>
					</div>
				</div>
			</div>

			<div
				className={classNames("modal-cookies-settings", {
					active: activeModal,
				})}
			>
				<div className="modal-cookies-settings-container">
					<div className="modal-cookies-settings-header">
						<div
							className="modal-cookies-settings-close"
							onClick={() => {
								setActive(true);
								setActiveModal(false);
							}}
						>
							<div className="modal-cookies-settings-close-top" />
							<div className="modal-cookies-settings-close-bottom" />
						</div>
					</div>
					{textSettings.map((text: string, index: number) => (
						<div key={`setting-text-${index}`} className="modal-cookies-settings-text">
							<p>{text}</p>
						</div>
					))}

					<div className="modal-cookies-settings-options-title">
						<p>{titleOptions}</p>
					</div>

					<div className="modal-cookies-settings-options-container">
						{options.map((option, index) => (
							<div
								key={`modal-cookies-settings-options-item-${index}`}
								className={classNames("modal-cookies-settings-options", {
									open: showOptions[`option-item-${index}`],
								})}
							>
								<div className="modal-cookies-settings-options-info">
									<div
										className="modal-cookies-settings-options-item"
										onClick={() => handleShowOptions(`option-item-${index}`)}
									>
										<div className="modal-cookies-settings-options-more-info">
											<div className="modal-cookies-settings-options-more-info-icon">
												<img src={icons.iconChevronDown} alt="cerrar modal video" />
											</div>
										</div>
										<div className="modal-cookies-settings-drop-title">
											<p>{option.text}</p>
										</div>
									</div>
									<div
										className={classNames("modal-cookies-settings-options-text", {
											active: showOptions[`option-item-${index}`],
										})}
									>
										{option.options.map((op, indexOp) => (
											<p key={`op-${indexOp}`}>{op}</p>
										))}
									</div>
								</div>
								<div
									className={classNames("modal-cookies-settings-option-buttons", {
										active: option.value,
										required: option.required,
									})}
								>
									<div
										className={classNames(
											"modal-cookies-settings-options-reject",
											"option-reject",
											{
												active: !option.value,
												required: option.required,
											},
										)}
										onClick={() => handleRefuse(index)}
									>
										<p>{buttonRejectText}</p>
									</div>
									<div
										className={classNames(
											"modal-cookies-settings-option-button",
											"modal-cookies-settings-option-button-agree",
											{
												active: option.value,
												required: option.required,
											},
										)}
										onClick={() => handleAccept(index)}
									>
										<p>{buttonAcceptText}</p>
									</div>
								</div>
							</div>
						))}
					</div>

					<div className="modal-cookies-settings-save">
						<div
							onClick={() => {
								setActive(true);
								setActiveModal(false);
							}}
							className="modal-cookies-settings-save__reject-button"
						>
							<p>Cancelar</p>
						</div>
						<div onClick={() => acceptModal()} className="modal-cookies-settings-save__success-button">
							<p>Aceptar y guardar</p>
						</div>
					</div>
				</div>
			</div>
		</ModalContainerStyle>
	);
};

export default ModalCookies;
