import React, {Component} from 'react';
import {GoBackContainer} from './go-back-style';
import { images } from '../../assets/images';
import { withRouter, RouteComponentProps} from 'react-router';
import BreadcrumbText from '../text/breadcrumb-text';

interface GoBackProps extends RouteComponentProps {
    img?: string;
    text?: string;
    url?: string;
}

class GoBackLayout extends Component<GoBackProps, {}> {

    public static defaultProps = {
        img: images.IconArrowLeftGrey
    }

    render(){

        const {img, text, url} = this.props;

        return(
            <GoBackContainer>
                <div 
                    className="go-back-container"
                    onClick={() => {
                        if(url) {
                            this.props.history.push(url)
                        } else {
                            this.props.history.goBack()
                        }
                    }}
                >
                    <img src={img}/>
                    <div className="go-back-text">
                        <BreadcrumbText>
                            <p>{text}</p>
                        </BreadcrumbText>
                    </div>
                </div>
            </GoBackContainer>
        )
    }
}

export default withRouter(GoBackLayout);