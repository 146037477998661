import styled from "styled-components";

const DocumentationDetailCardContainer = styled.div`
  font-family: "museo sans";
  padding: 20px 16px 13px;
  cursor: pointer;
  position: relative;
  background: #ffffff;
  border: 1px solid #e2e5ea;
  border-radius: 6px;

  .documentation-detail-card-topbar-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
  }

  .documentation-detail-card-topbar-row-item-type {
    font-weight: 500;
    font-size: 12px;
    color: #6b778c;
    text-transform: uppercase;
    margin-right: 14px;
  }

  .documentation-detail-card-topbar-row-item-date {
    font-size: 12px;
    color: #6b778c;
  }

  .documentation-detail-card-name-row {
    margin-bottom: 8px;
    font-weight: 300;
    font-size: 14px;
    color: #091e42;
  }

  .documentation-detail-card-bottom-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .documentation-detail-card-number {
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #091e42;
    margin-right: 30px;
  }

  .documentation-detail-card-price {
    font-size: 20px;
    color: #01b2ea;
    font-weight: 500;
    line-height: 26px;
    span {
      font-size: 12px;
    }
  }

  .documentation-detail-card-icon {
    width: 40px;
    height: 40px;
    background: rgba(1, 178, 234, 0.15);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 24px;
    }
  }
`;

export default DocumentationDetailCardContainer;
