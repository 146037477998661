import introBackground from './background-intro.svg';
import kidBackground from './background-kid.svg';
import logoAsisa from './logo-asisa.svg';
import logoAsisaHeader from './icon-logo-white.svg';
import IconCloseBlue from './icon-close-blue.svg';
import IconSearchBlue from './icon-search-blue.svg';
import DashedBack from './dashet-drag.svg';
import BackCardTypeBlue from './card-type-request-blue.svg';
import BackCardTypeBlueLight from './card-type-request-blue-light.svg';
import IconArrowDownGrey from './icon-arrow-down-grey.svg';
import IconArrowDownCalendar from './icon-arrow-down-calendar.svg';
import IconArrowLeftBlue from './icon-arrow-left-blue.svg';
import IconArrowLeftGreen from './icon-arrow-left-green.svg';
import IconArrowLeftGrey from './icon-arrow-left-grey.svg';
import IconArrowRightGreen from './icon-arrow-right-green.svg';
import IconArrowRightBlue from './icon-arrow-right-blue.svg';
import IconArrowUpCalendar from './icon-arrow-up-calendar.svg';
import IconArrowUpWhite from './icon-arrow-up-white.svg';
import IconCrossBlue from './icon-cross-blue.svg';
import IconTickWhite from './icon-tick-white.svg';
import IconArrowDownBlack from './icon-arrow-down-black.svg';
import IconPlusLightBlue from './icon-plus-light-blue.svg';
import IconInfoGrey from './icon-info-grey.svg';
import IconInfoGreen from './icon-info-green.svg';
import ModalCloseIcon from './modal-close-icon.svg';
import ModalEndingBackground from './modal-ending-background.svg';
import IconCrossWhiteBg from './icon-cross-white-bg.svg';
import NoContent from './no-content.svg';
import IconAddBlueBg from './icon-add-blue-bg.svg';
import IconCrossBlueBg from './icon-cross-blue-bg.svg';
import IconCrossBlack from './icon-cross-black.svg';
import IconCalendarBlack from './icon-calendar-black.svg';
import IconCrossWhite from './icon-cross-white.svg';
import IconArrowRightLightBlue from './icon-arrow-right-light-blue.svg';

// icons Header

import IconHeaderBellWhite from './icon-header-bell-white.svg';
import IconHeaderBellLightBlue from './icon-header-bell-lightblue.svg';
import IconHeaderCreateWhite from './icon-header-create-white.svg';
import IconHeaderCreateLightBlue from './icon-header-create-lightblue.svg';
import IconHeaderHomeWhite from './icon-header-home-white.svg';
import IconHeaderHomeLightBlue from './icon-header-home-lightblue.svg';
import IconHeaderLogOutWhite from './icon-header-logout-white.svg';
import IconHeaderLogOutLightBlue from './icon-header-logout-lightblue.svg';
import IconHeaderAlertWhite from './icon-header-notification-white.svg';
import IconHeaderAlertLightBlue from './icon-header-notification-lightblue.svg';
import IconHeaderUserWhite from './icon-header-user-white.svg';
import IconHeaderUserLightBlue from './icon-header-user-lightblue.svg';
import IconArrowDownLightBlue from './icon-arrow-down-ligh-blue.svg';
import LogoWhite from './logo.svg';

export const images = {
  'introBackground' : introBackground, 
  'kidBackground' : kidBackground,
  'logoAsisa' : logoAsisa,
  'logoAsisaHeader' : logoAsisaHeader,
  'IconCloseBlue' : IconCloseBlue,
  'IconSearchBlue' : IconSearchBlue,
  'DashedBack' : DashedBack,
  'IconArrowDownCalendar' : IconArrowDownCalendar,
  'BackCardTypeBlue': BackCardTypeBlue,
  'BackCardTypeBlueLight': BackCardTypeBlueLight,
  'IconArrowDownGrey' : IconArrowDownGrey,
  'IconArrowLeftBlue' : IconArrowLeftBlue,
  'IconArrowLeftGreen' : IconArrowLeftGreen,
  'IconArrowLeftGrey' : IconArrowLeftGrey,
  'IconArrowRightGreen' : IconArrowRightGreen,
  'IconArrowUpCalendar' : IconArrowUpCalendar,
  'IconArrowUpWhite' : IconArrowUpWhite,
  'IconCrossBlue' : IconCrossBlue,
  'IconTickWhite' : IconTickWhite,
  'IconArrowDownBlack' : IconArrowDownBlack,
  'IconInfoGrey' : IconInfoGrey,
  'IconInfoGreen' : IconInfoGreen,
  'IconPlusLightBlue' : IconPlusLightBlue,
  'ModalCloseIcon' : ModalCloseIcon,
  'ModalEndingBackground' : ModalEndingBackground,
  'IconCrossWhiteBg' : IconCrossWhiteBg,
  'IconAddBlueBg' : IconAddBlueBg,
  'IconCrossBlueBg' : IconCrossBlueBg,
  'IconCrossBlack' : IconCrossBlack,
  'IconCalendarBlack' : IconCalendarBlack,
  'IconArrowRightBlue' : IconArrowRightBlue,
  'IconArrowDownLightBlue' : IconArrowDownLightBlue,
  'IconCrossWhite' : IconCrossWhite,
  'LogoWhite' : LogoWhite,
  'IconArrowRightLightBlue' : IconArrowRightLightBlue,

  // icons Header
  'IconHeaderBellWhite':IconHeaderBellWhite,
  'IconHeaderBellLightBlue':IconHeaderBellLightBlue,
  'IconHeaderCreateWhite':IconHeaderCreateWhite,
  'IconHeaderCreateLightBlue':IconHeaderCreateLightBlue,
  'IconHeaderHomeWhite':IconHeaderHomeWhite,
  'IconHeaderHomeLightBlue':IconHeaderHomeLightBlue,
  'IconHeaderLogOutWhite':IconHeaderLogOutWhite,
  'IconHeaderLogOutLightBlue':IconHeaderLogOutLightBlue,
  'IconHeaderAlertWhite':IconHeaderAlertWhite,
  'IconHeaderAlertLightBlue':IconHeaderAlertLightBlue,
  'IconHeaderUserWhite':IconHeaderUserWhite,
  'IconHeaderUserLightBlue':IconHeaderUserLightBlue,

  'NoContent': NoContent,

}
