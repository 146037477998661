import styled from "styled-components";

const AddInsuredFormContainer = styled.form`
  width: 100%;

  .insured-selector {
    width: 100%;
    margin-bottom: 35px;
    max-width: 446px;
  }

  .insured-info {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e9eef2;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 40px 28px;
  }

  .insured-info-title {
    font-family: "museo sans";
    font-size: 20px;
    line-height: 28px;
    color: #6b778c;
    margin-bottom: 44px;
  }

  .insured-info-field-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    .insured-info-field {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .insured-info-field {
    margin-bottom: 40px;
    margin-right: 32px;
    width: calc((100%-32px) / 2);

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  @media (max-width: 440px) {
    .insured-info-title {
      font-size: 18px;
    }
    .insured-info {
      padding: 36px 24px;
    }
  }
`;

export default AddInsuredFormContainer;
