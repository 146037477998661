import styled from 'styled-components';

export const InputStandardContainer = styled.div`

  .input-s-title {
    font-family: 'museo sans';
    text-transform: uppercase;
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 12px;
  }

  .input-s-input {
    margin-bottom: 4px;
  }

  .input-field {
    position: relative;
  }

  input[type="password"] {
    padding-right: 30px;
  }

  input {
    font-family: 'museo sans';
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    font-weight: 300;
    padding: 9px 0;
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    &::placeholder  {
    }
    &:focus {
    }
  }

  textarea {
    border: none;
    resize: none;
    width: 100%;
    height: 100%;
    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 16px;
  }

  .input-s-error {
    line-height: 24px;
    font-size: 14px;
    min-height: 24px;
    transition: all 0.2s ease-in-out;
    margin: 3px 0 16px;
  }


  .input-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 9;
    width: 30px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;

    .icon-password {
      max-width: 20px;
    }

  }


  &.error {
    input {

    }
    .input-s-error {
      opacity: 1;
      visibility: visible;
    }
  }

  .input-main {
    position: relative;
  }

`;
