import styled from "styled-components";

export const MainButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  height: 48px;
  max-height: 48px;
  background: #49abb8;
  color: #ffffff;
  font-size: 16px;
  font-family: "museo sans";

  &:hover {
    background: #3c9ba8;
  }

  .main-button-icon {
    margin-right: 20px;
    display: flex;
    align-items: center;
    img {
      max-width: 18px;
    }
  }

  &.ghost {
    background: #fff;
  }

  &.dark-blue {
    background: #004f8b;
    &.disabled {
      background: #e2e5ea;
      color: #6b778c;
      cursor: not-allowed;
      text-transform: capitalize;
    }

    &:hover {
      background: #003d6b;
    }
  }

  &.google {
    background: #fff;
  }

  &.light-blue {
    text-transform: initial;
    background: rgba(1, 178, 234, 0.15);
    color: #49abb8;
    border-radius: 44px;
  }

  .main-button-icon {
    margin-right: 6px;
  }

  &.blue {
    background: #004f8b;

    &.disabled {
      background: #e2e5ea;
      color: white;
      cursor: not-allowed;
      text-transform: uppercase;
    }
  }

  &.blue {
    background: #004f8b;
  }

  &.white-cancel {
    color: #ff4040;
    background: none;
  }
  &.white {
    color: #091e42;
    background: none;
    text-transform: capitalize;
  }
  &.grey {
    background: #e2e5ea;
    &:hover {
      background: #d8dade;
    }
  }

  &.light-grey {
    background: #F7F9FA;
    color: #6B778C;
    text-transform: none;
    padding: 12px 24px;
    max-height: 40px;
  }

  &.disabled {
    background: #e2e5ea;
    text-transform: initial;
    color: #6b778c;
  }

  &.congrats {
    color: #49abb8;
    background: rgba(1, 178, 234, 0.15);
  }

  &.upload {
    color: #01b2ea;
    background: rgba(1, 178, 234, 0.15);
    height: 40px;
    min-height: 40px;
    border-radius: 24px;
    font-size: 14px;
    line-height: initial;
  }

  &.edit {
    color: #01b2ea;
    background: rgba(1, 178, 234, 0.15);
    height: 40px;
    min-height: 40px;
    border-radius: 4px;
    font-size: 14px;
    line-height: initial;
    text-transform: none;
    padding: 12px 24px;
  }

  &.blue {
    color: #ffffff;
    background: #01b2ea;

    &:hover {
      background: #04a0d2;
    }
  }

  &.white-border {
    background: #ffffff;
    color: #004F8B;
    border: 0.5px solid #E2E5EA;
    border-radius: 4px;
    text-transform: none;
    padding: 12px 24px;
    max-height: 40px;
  }

  .right-button-icon {
    margin-left: 16px;
  }
`;
