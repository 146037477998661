import axios from "axios";
import { SERVICES_URL, SERVICES_KEY } from "../../env";
import { store } from "../../redux/store";
import { setLoading } from "../../redux/actions";
import { IInvoice, IReport } from "./create-user-request";

export interface GetRequestParams {
  id: string;
}

interface Accident {
  infoAccidentId: number;
  asisaComments: string;
  insuredComments: string;
  infoAccidentDate: string;
  responseDt: string;
  status: string;
}

export interface GetRequestResponse {
  accidentId: string;
  policyId: string;
  policyControl: number;
  policyOrder: number;
  insuredPhone: string;
  insuredMail: string;
  bankId: string;
  bankPlace: string;
  bankControl: string;
  bankAccount: string;
  accidentType: string;
  accidentDescription: string;
  accidentDate: string;
  accidentStatus: string;
  accidentStatusDescription: string;
  accidentAmount: number;
  currency: string;
  insuredId: number;
  insuredFullName: string;
  insuredNif: string;
  invoices: IInvoice[];
  reports: IReport[];
  infoAccidents: Accident[];
  policyNumber: string;
  bankInsuredName: string;
  bankInsuredNif: string;
}

export const getRequest = (params: GetRequestParams) => {
  const endpoint = `health/accidents/${params.id}`;
  store.dispatch(setLoading(true));
  return axios({
    method: "GET",
    url: `${SERVICES_URL}${endpoint}`,
    data: {
      id: params.id,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};
