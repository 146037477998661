import React from "react";
import { HeaderContainer, HeaderAside } from "./header-style";
import { images } from "../../../assets/images";
import { iconsSVG, icons } from "../../../assets/icons";
import ItemAsideNotification from "../../item-aside-notification/item-aside-notification";
import Services from "../../../utils/services";
import { RouteComponentProps, withRouter, matchPath } from "react-router";
import { setExpiredToken, setLoading } from "../../../redux/actions";
import { NavLink } from "react-router-dom";
import AsideNotification from "../../asides/aside-notification";
import AsideProfile from "../../asides/aside-profile/index";
import LogOutModal from "../../modals/logout-modal";
import StartRequestModal from "../../modals/request-modal/request-modal-layout";
import Notifications from "./notifications";
import { isoStringToStringDate } from "../../../utils/date";
import { connect } from "react-redux";

interface HeaderProps extends RouteComponentProps {
  logout: Function;
  onNewRequestClick: Function;
  user?: any;
}

interface HeaderState {
  showAside: boolean;
  active: string;
  showLogoutModal: boolean;
  openHeader: boolean;
  isRequestCreation: boolean;
  isExact: boolean;
}

const url = window.location.href;
const isSolicitudeId = url.indexOf("solicitud-detalle/") !== -1;

const urlSolicitudeId = isSolicitudeId
  ? url
    .split("solicitud-detalle/")[1]
  : "";

class Header extends React.Component<HeaderProps, HeaderState> {
  notShowHeader = ["/"];

  pathnamesActive: { [key: string]: string } = {
    "/": "home",
    "/home": "home",
    "/crear-solicitud": "add",
    [`/solicitud-detalle/${urlSolicitudeId}`]: "add",
    "/crear-solicitud/salud": "add",
    "/crear-solicitud/bucodental": "add",
    "/avisos-legales": "legal"
  };

  noPathnamesActive = ["notification", "profile", "logout"];
  //readonly state: HeaderState;

  constructor(props: HeaderProps) {
    super(props);
    const currentUrl = this.props.history.location.pathname;

    const isRequestCreation = currentUrl.indexOf("/crear-solicitud") !== -1;
    let isDetails = false;
    const existUrl = matchPath(currentUrl, "/solicitud-detalle/:id/");
    if (existUrl) {
      isDetails = existUrl.isExact;
    }

    this.state = {
      showAside: false,
      active: "",
      showLogoutModal: false,
      openHeader: false,
      isRequestCreation,
      isExact: isDetails
    };
  }

  componentDidMount() {
    this.checkIfShoudBeActive();
  }

  componentWillUpdate() {
    const { pathname } = this.props.history.location;
    let { isRequestCreation, isExact } = this.state;

    if (!isRequestCreation && pathname.indexOf("/crear-solicitud") !== -1) {
      isRequestCreation = true;

      this.setState({ isRequestCreation });
    }

    if (isRequestCreation && pathname.indexOf("/crear-solicitud") === -1) {
      isRequestCreation = false;

      this.setState({ isRequestCreation });
    }

    let isDetails = false;
    const existUrl = matchPath(pathname, "/solicitud-detalle/:id/");
    if (existUrl) {
      isDetails = existUrl.isExact;
    }
    if (!isExact && isDetails) {
      isExact = true;

      this.setState({ isExact });
    }

    if (isExact && isDetails === false) {
      isExact = false;

      this.setState({ isExact });
    }
  }

  componentDidUpdate() {
    this.checkIfShoudBeActive();
  }
  shouldComponentUpdate(nextProps: HeaderProps, nextState: HeaderState) {
    const { pathname } = nextProps.history.location;
    const { active } = this.state;

    if (
      (this.pathnamesActive.hasOwnProperty(pathname) &&
        active !== this.pathnamesActive[pathname]) ||
      (active !== "" && !this.pathnamesActive[pathname])
    ) {
      return true;
    } else if (nextState.openHeader !== this.state.openHeader) {
      return true;
    }
    return false;
  }
  
  checkIfShoudBeActive() {
    const { pathname } = this.props.history.location;
    const { active } = this.state;
    if (
      active !== this.pathnamesActive[pathname] &&
      !this.noPathnamesActive.includes(active)
    ) {
      if (this.pathnamesActive.hasOwnProperty(pathname)) {
        this.setItemActive(this.pathnamesActive[pathname]);
      } else {
        this.setItemActive("");
      }
    }
  }

  handleLogout() {
    this.setState({ showLogoutModal: false }, () => {
      localStorage.clear();
      this.props.history.push("/");
      this.props.logout();
      Services.setValue(setLoading(false));
    });
  }

  setItemActive(item: string, onFinish?: Function) {
    this.setState({ active: item, openHeader: false }, () => {
      if (onFinish) {
        onFinish();
      }
    });
  }

  renderAside() {
    const { active } = this.state;

    if (active === "notification") {
      return (
        <AsideNotification
          onClose={(onFinish: Function) =>
            this.setState({ active: "", showAside: false }, onFinish())
          }
        />
      );
    } else if (active === "profile") {
      return <AsideProfile />;
    }
  }

  renderAsideModal() {
    const { showAside } = this.state;
    return (
      <HeaderAside className={showAside && "active"}>
        <div
          className="header-aside-veil"
          onClick={() => this.setState({ active: "", showAside: false })}
        />
        <div className="header-aside-close">
          <div
            className="header-aside-close__item"
            onClick={() => this.setState({ active: "", showAside: false })}
          >
            <img src={icons.iconClose} alt="" />
          </div>
        </div>

        <div className="header-aside-container">{this.renderAside()}</div>
      </HeaderAside>
    );
  }

  openHeader() {
    this.setState({ openHeader: true });
  }

  closeHeader() {
    this.setState({ openHeader: false });
  }

  render() {
    const currentUrl = this.props.history.location.pathname;
    const { onNewRequestClick } = this.props;
    const {
      active,
      showLogoutModal,
      openHeader,
      isRequestCreation,
      isExact
    } = this.state;

    return (
      <HeaderContainer>
        <div className="header-logo">
          <NavLink to="/">
            <div className="logo-asisa-white">
              <img src={icons.logoAsisaWhite} alt="" />
            </div>

            <div className="logo-asisa-name">
              <p>Portal Reembolso</p>
            </div>
          </NavLink>
        </div>

        <div className="menu-navicon" onClick={() => this.openHeader()} />

        <div className={`menu-container ${openHeader ? "active" : ""}`}>
          <div className="menu-header">
            <div
              className="menu-logo"
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              <img src={icons.logoAsisaWhite} alt="" />
            </div>
            <div className="menu-close" onClick={() => this.closeHeader()}>
              <img src={images.IconCrossWhite} alt="cerrar menu" />
            </div>
          </div>

          {isRequestCreation ? (
            <div className="header-buttons">
              <div
                className={`header-buttons-item active`}
                onClick={() => {
                  this.props.history.push("/");
                }}
              >
                <div className="header-buttons-item-svg add" />
                <div className="header-buttons-item-text">
                  <p>CANCELAR LA SOLICITUD</p>
                </div>
              </div>
            </div>
          ) : (
              <div className="header-buttons">
                {/* NEW REQUEST */}
                <div
                  className={`header-buttons-item header-buttons-create active`}
                  onClick={() => {
                    onNewRequestClick();
                  }}
                >
                  <div className="header-buttons-item-svg add" />
                  <div className="header-buttons-item-text">
                    <p>Crear solicitud</p>
                  </div>
                </div>
                {isExact && (
                  <div
                    className={`header-buttons-item active`}
                    onClick={() => {
                      this.props.history.push("/");
                    }}
                  >
                    <div className="header-buttons-item-svg add" />
                    <div className="header-buttons-item-text">
                      <p>VOLVER</p>
                    </div>
                  </div>
                )}

                {/* NOTIFICATIONS */}
                <div
                  className={`header-buttons-item ${active === "notification" &&
                    "active"}`}
                  style={{position: "relative"}}
                  onClick={() =>
                    this.setItemActive("notification", () => {
                      this.setState({ showAside: true });
                    })
                  }
                >
                  <div className="header-buttons-item-svg bell" />
                  <div className="header-buttons-item-text header-button-aside">
                    <p>Notificaciones</p>
                  </div>
                  <Notifications className={"header-button-item-notifications"}/>
                </div>
                
                {/* PROFILE */}
                <div
                  className={`header-buttons-item ${active === "profile" &&
                    "active"}`}
                  onClick={() =>
                    this.setItemActive("profile", () => {
                      this.setState({ showAside: true });
                    })
                  }
                >
                  <div className="header-buttons-item-svg user" />
                  <div className="header-buttons-item-text header-button-aside">
                    <p>Perfil</p>
                  </div>
                </div>

                {/* LOGOUT */}
                <div
                  className={`header-buttons-item ${active === "logout" &&
                    "active"}`}
                  onClick={() =>
                    this.setItemActive("logout", () => {
                      this.setState({ showLogoutModal: true });
                    })
                  }
                >
                  <div className="header-buttons-item-svg logout" />
                  <div className="header-buttons-item-text log-out">
                    <p>Cerrar Sesión</p>
                  </div>
                </div>
              </div>
            )}
        </div>

        {this.renderAsideModal()}
        {showLogoutModal && (
          <LogOutModal
            showModal={showLogoutModal}
            title={"Vas a cerrar la sesión, ¿estás seguro?"}
            buttonText={"Si, salir"}
            onAccept={() => this.handleLogout()}
            onCancel={() =>
              this.setState({ showLogoutModal: false, active: "" })
            }
          />
        )}
      </HeaderContainer>
    );
  }
}

export default withRouter(Header);
