import React, { Component } from "react";
import { ExpiredSessionModalContainer } from "./expired-session-modal-style";
import { icons } from "../../../assets/icons";
import H4Text from "../../text/h4-text";
import MainButton from "../../buttons/main-button";
import { images } from "../../../assets/images";
import { string } from "prop-types";

interface ExpiredSessionModalProps {
  className?: string;
  img?: string;
  text: string;
  buttonText?: string;
  onClickAccept: Function;
  title?: string;
}

interface ExpiredSessionModalState {
  active: string;
}

class ExpiredSessionModal extends Component<ExpiredSessionModalProps, ExpiredSessionModalState> {
  public static defaultProps = {
    warning: false,
    buttonText: "ok",
    img: icons.IconLogoutBlue,
    onClickAccept: () => {},
  };

  constructor(props: ExpiredSessionModalProps) {
    super(props);
    this.state = {
      active: "active",
    };

    document.addEventListener("openInfoModal", this.openInfoModal.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("openInfoModal", this.openInfoModal);
  }

  openInfoModal(event: any) {
    this.setState({ active: "active" });
  }

  closeInfoModal() {
    this.setState({ active: "" });
  }

  acceptInfoModal() {
    this.props.onClickAccept();
    this.closeInfoModal();
  }

  render() {
    const { img, text, className, buttonText, title } = this.props;
    const { active } = this.state;

    return (
      <ExpiredSessionModalContainer className={`${active} ${className}`}>
        <div className="info-modal-container">
          <div className="info-modal-close">
            <img src={images.IconCrossBlue} onClick={() => this.closeInfoModal()} />
          </div>
          <div className="info-modal-title">
            <p>{title}</p>
          </div>
          <div className="info-modal-text">
            <p>{text}</p>
          </div>
          <div className="info-modal-button">
            <MainButton type={"dark-blue"} onClick={() => this.acceptInfoModal()} text={buttonText} />
          </div>
        </div>
      </ExpiredSessionModalContainer>
    );
  }
}

export default ExpiredSessionModal;
