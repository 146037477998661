import styled from 'styled-components';

export const TooltipBoxContainer=styled.div`

.tooltip-modal {
    color: #ffffff;
    background: #49ABB8;
    padding: 20px 12px;
    position: relative;
    max-width: 260px;
    border-radius: 3px;
    z-index: 19;

    &:before {
        content: "";
        position: absolute;
        right: 3px;
        transform: rotate(45deg);
        top: -4px;
        width: 13px;
        height: 16px;
        border-radius: 4px;
        background: #49ABB8;
    }
}

.tooltip-title {
    margin-bottom: 8px;
    color: #ffffff;
}

.tooltip-main {
    font-size: 12px;
}


`;