import React, { Component } from "react";
import { icons } from "../../../assets/icons";
import H4Text from "../../text/h4-text";
import MainButton from "../../buttons/main-button";
import { images } from "../../../assets/images";
import { string } from "prop-types";
import { LogOutModalContainer } from "./logout-modal-style";

interface LogOutModalProps {
  className?: string;
  title?: string;
  img?: string;
  buttonText?: string;
  showModal: boolean;
  onAccept: Function;
  onCancel: Function;
}

class LogOutModal extends Component<LogOutModalProps, {}> {
  public static defaultProps = {
    warning: false,
    buttonText: "ok",
    img: icons.IconLogoutBlue,
    onClickAccept: () => {},
  };

  componentDidMount() {
    document.body.classList.add("no-scroll");
  }
  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  render() {
    const { showModal, className, buttonText, title, onAccept, onCancel } = this.props;

    return (
      <LogOutModalContainer className={`${showModal ? "active" : ""} ${className}`}>
        <div className="logout-modal-container">
          <div className="logout-modal-close">
            <img src={images.IconCrossBlue} onClick={() => onCancel()} />
          </div>
          <div className="logout-modal-title">
            <p>{title}</p>
          </div>

          <div className="logout-modal-button">
            <MainButton type={"dark-blue"} onClick={() => onAccept()} text={buttonText} />
          </div>
        </div>
      </LogOutModalContainer>
    );
  }
}

export default LogOutModal;
