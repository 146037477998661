import styled from "styled-components";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";
import { ItemDocumentContainer } from "../../item-document/item-document-style";

export const UploadDocsContainer = styled.div`
  font-family: "museo sans";

  .docs-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .no-docs {
    font-size: 16px;
    line-height: 24px;
    color: #6b778c;
    font-weight: 400;
  }

  .docs-title {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 28px;
    color: #091e42;
    font-weight: 600;

    .p-inline {
      display: inline;
    }
  }

  .docs-button {
    min-width: 120px;
    width: 120px;

    ${MainButtonContainer} {
      text-transform: capitalize;
    }
  }

  .error-limit-files {
    color: #ff4040;
  }

  .docs-content {
    display: flex;
    flex-wrap: wrap;

    ${ItemDocumentContainer} {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 900px) {
    .docs-content {
      ${ItemDocumentContainer} {
        width: calc((100% - 24px) / 2);
        margin-right: 24px;

        &:nth-child(2n + 2) {
          margin-right: 0;
        }

        .link-container {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 650px) {
    .error-limit-files {
      padding: 20px 10px;
      font-size: 14px;
      color: #ff4040;
    }

    .docs-title {
      font-size: 14px;
      line-height: 1.5em;
      margin-right: 16px;
      width: 80%;
    }

    .docs-button {
      min-width: 0;
      width: auto;

      ${MainButtonContainer} {
        width: auto;
        height: auto;
        min-width: 100px;
        padding: 10px 0;

        .main-button-icon {
          img {
            max-width: 12px;
          }
        }

        .main-button-text {
          font-size: 14px;
        }
      }
    }

    .no-docs {
      font-size: 14px;
      line-height: 1.3em;
    }
  }

  @media (max-width: 500px) {
    .docs-title {
      width: calc(100% - 50px);
      font-size: 12px;
    }

    .docs-button {
      .main-button-text {
        display: none;
      }
      ${MainButtonContainer} {
        min-width: 0;
        padding: 0;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .main-button-icon {
          margin: 0;
        }
      }
    }

    .docs-content {
      ${ItemDocumentContainer} {
        width: 100%;
        margin-right: 0;
      }
    }

    .no-docs {
      font-size: 12px;
    }
  }


  .upload-docs-container {
    .input-box-topbar-icon {
      display: inline;
      vertical-align: sub;
      position: relative;
      margin-left: 5px;
      
      img {
        width: 24px;
        cursor: pointer;
      }
    }

    .input-box-tooltip {
      position: absolute;
      top: calc(100% + 6px);
      left: calc(100% - 24px);
      z-index: 29;
      width: 350px;
      right: 0;
      display: block;
      text-transform: none;
    
      .tooltip-modal {
        max-width: 350px;
        width: 350px;
        
        &:before {
          content: "";
          position: absolute;
          left: 1%;
          transform: rotate(45deg);
          bottom: 100%;
          width: 13px;
          height: 16px;
          border-radius: 4px;
          background: #49ABB8;
        }
      }
    }
  }
`;
