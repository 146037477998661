import styled from "styled-components";

export const NoContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .no-content-img {
    max-width: 190px;
    display: flex;
  }

  .no-content-text {
    &__title {
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #6b778c;
      margin-bottom: 8px;
    }
    &__text {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #6b778c;
    }
  }
`;
