import React, { Component } from "react";
import ModalNewBillContainer from "./modal-new-bill-style";
import InputBox from "../../form-components/input-box";
import CalendarBox from "../../form-components/calendar-box";
import SelectBox from "../../form-components/select-box";
import SelectSearch from "../../form-components/select-search/select-search";
import CircleCheckbox from "../../form-components/circle-checkbox/circle-checkbox";
import UploadDocs from "../../form-components/upload-docs";
import { icons } from "../../../assets/icons";
import MainButton from "../../buttons/main-button";
import {
  IInvoice,
  IReport
} from "../../../services/requests/create-user-request";
import { isoStringToStringDate } from "../../../utils/date";
import { checkDocumentFormat, isValidCif, isValidDocumentID } from "../../../utils/validation";
import { currencyFormat } from "../../../utils/text";
import ModalMessage from "../modal-message/modal-message";
import { MONEY_TYPES } from "../../../constants/money-types";
import { AttachedFile } from "../../../screens/request-detail/types";
import moment from "moment";
import { getSpecialitiesRequest } from "../../../services/info/get-specialities";
import { getRequestTypesRequest } from "../../../services/info/get-request-types";
import { getCurrenciesRequest } from "../../../services/currency/get-currency-request";
import TextareaBox from "../../form-components/textarea-box";
import { setExpiredToken, setLoading, setModalError } from "../../../redux/actions";
import { store } from "../../../redux/store";
import Services from "../../../utils/services";


interface ModalNewBillProps {
  onClose: () => void;
  onSave: (invoice: any) => void;
  onDelete: () => void;
  invoice: IInvoice | "";
  totalFileSize?: number;
}

interface ISelectOption {
  label: string;
  value: string;
}

interface IModalNewBillForm {
  billNumber: string;
  date: string;
  country: "S" | "E";
  money: string;
  moneyType: string;
  doctorName: string;
  doctorCif: string;
  assistanceType: string;
  speciality: string;
  expedient?: boolean;
  description: string;
}

type IFormDataErrors = { [key in keyof IModalNewBillForm]: string };

interface ModalNewBillState {
  formData: IModalNewBillForm;
  formDataErrors: IFormDataErrors;
  formReports: IReport[];
  formDocuments: IReport[];
  errorModal: {
    showErrorModal: boolean;
    errorMessage: any;
    title?: string;
  };
  docs: any[];
  reports: any[];
  specialitiesSelect: ISelectOption[];
  typesSelect: ISelectOption[];
  currenciesSelect: ISelectOption[];
  assistanceReceived?: boolean;
}

const DOCUMENT_TYPES = {
  "report": 'informe médico',
  "document": 'factura'
}

class ModalNewBill extends Component<ModalNewBillProps, ModalNewBillState> {
  state: ModalNewBillState;

  constructor(props) {
    super(props);
    const { invoice } = this.props;
    this.state = {
      assistanceReceived:
        invoice &&
          invoice.invoiceDescription &&
          invoice.invoiceDescription !== "" ?
          true
          : invoice ? false : undefined,
      formData: {
        billNumber: invoice ? invoice.id : "",
        date: invoice ? invoice.date : "",
        country: invoice ? invoice.assistancePlace : "S",
        money: invoice ? currencyFormat(invoice.amount).toString() : "0",
        moneyType: invoice ? invoice.currency : "EUR",
        doctorName: invoice ? invoice.doctorName : "",
        doctorCif: invoice ? invoice.doctorId : "",
        assistanceType: invoice ? invoice.assistanceType : "TYPE_01",
        speciality: invoice ? invoice.speciality : "18",
        expedient: invoice ? invoice.first : undefined,
        description: invoice ? invoice.invoiceDescription : ""
      },

      formDataErrors: {
        billNumber: "",
        date: "",
        country: "",
        money: "",
        moneyType: "",
        doctorName: "",
        doctorCif: "",
        assistanceType: "",
        speciality: "",
        expedient: "",
        description: ""
      },
      formReports:
        this.props.invoice && this.props.invoice.reports.length > 0
          ? this.props.invoice.reports
          : [],
      formDocuments:
        this.props.invoice && this.props.invoice.documents.length > 0
          ? this.props.invoice.documents
          : [],
      errorModal: {
        showErrorModal: false,
        errorMessage: []
      },
      docs: [],
      reports: [],
      specialitiesSelect: [],
      typesSelect: [],
      currenciesSelect: []
    };
  }

  componentDidMount() {
    document.body.classList.add("no-scroll");
    this.props.invoice && this.getDocs();
    this.props.invoice && this.getReports();
    this.getSpecialities();
    this.getRequestTypes();
    this.getCurrencies();
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  changeErrorModal = (show: boolean) => {
    let { errorModal } = this.state;
    errorModal.showErrorModal = show;
    errorModal.title = ''
    this.setState({ errorModal });
  };

  handleChange = (value: string | boolean, name: string) => {
    let { formData } = this.state;
    if (value !== "CONSULTA" && name === "assistanceType") {
      formData = {
        ...formData,
        ["speciality"]: ""
      };
    }
    formData = {
      ...formData,
      [name]: value
    };
    this.setState({ formData });
  };

  getBase64 = file => {
    return new Promise(resolve => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event: any) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  // sendFile(file: any, type: "report" | "document") {
  //   const endpoint = "https://asisa.aluxion.dev/gitdoc/upload-file/open-data";
  //   const data = new FormData();

  //   data.set("file", file.data);

  //   Services.postFormData({
  //     loader: true,
  //     endpoint,
  //     data,
  //     then: (response: any) => {
  //       let { formReports, formDocuments } = this.state;
  //       if (type === "report") {
  //         formReports.push({
  //           id: response.data.id,
  //           date: isoStringToStringDate(new Date().toISOString(), "/"),
  //           description: "",
  //         });
  //       } else {
  //         formDocuments.push({
  //           id: response.data.id,
  //           date: isoStringToStringDate(new Date().toISOString(), "/"),
  //           description: "",
  //         });
  //       }
  //       this.setState({ formReports, formDocuments });
  //     },
  //     catch: (err: any) => {
  //       console.log(err);
  //     },
  //   });
  // }

  addNewDocuments = async (files: any, type: "report" | "document") => {
    let auxArray: IReport[] = [];
    for (let file of files) {
      let b64: any = await this.getBase64(file.data ? file.data : file);
      auxArray.push({
        id: b64.split("base64,")[1],
        fileName: file.name,
        date: isoStringToStringDate(new Date().toISOString(), "/"),
        description: "",
        size: file.size,
        type
      });
    }
    if (type === "report") {
      this.setState({ formReports: auxArray });
    } else if (type === "document") {
      this.setState({ formDocuments: auxArray });
    }
  };

  checkIfDocumentsExist = (loadedFiles : Array<AttachedFile>) => {
    const { formReports, formDocuments } = this.state
    const allFiles = [
      ...formReports,
      ...formDocuments
    ]
    let errorMessages = []
    let nonDuplicatedDocuments = []

    
    loadedFiles.forEach(loadedFile => {
      const foundDocument= allFiles.find(document => document.fileName === loadedFile.name )
      if(foundDocument){
        errorMessages.push(
          <p>
            - El archivo {loadedFile.name} ya ha sido adjuntado como {DOCUMENT_TYPES[foundDocument.type]}.
          </p>
        )
      }else{
        nonDuplicatedDocuments.push(loadedFile)
      }
    })

    if(errorMessages.length){
      this.setState({
        errorModal: {
          showErrorModal: true,
          errorMessage: errorMessages,
          title: 'Archivos duplicados'
        }
      })
    }

    return nonDuplicatedDocuments
  }

  validateFields = () => {
    const { formData } = this.state;
    let {
      formDataErrors,
      errorModal,
      formDocuments,
      formReports,
      assistanceReceived
    } = this.state;
    let valid = true;
    errorModal.errorMessage = [];
    errorModal.showErrorModal = false;
    if (assistanceReceived === undefined) {
      valid = false;
      errorModal.errorMessage.push(
        <p>
          - Debe indicar si la asistencia prestada es consecuencia de un
          accidente.
        </p>
      );
      errorModal.showErrorModal = true;
    }
    Object.keys(formData).map((key: string) => {
      if (key !== "speciality") {
        formDataErrors = {
          ...formDataErrors,
          [key]: ""
        };
        if (
          key === "expedient" &&
          (formData.assistanceType === "TYPE_02" ||
            formData.assistanceType === "TYPE_03") &&
          formData.expedient === undefined
        ) {
          valid = false;
          errorModal.errorMessage.push(
            <p>- Debe indicar si se trata de un primer tratamiento</p>
          );
          errorModal.showErrorModal = true;
        } else if (
          (key !== "doctorCif" || formData.country === "S") &&
          !formData[key] &&
          (key !== "description" || assistanceReceived) &&
          (key !== 'expedient') &&
          formData[key] !== undefined
        ) {
          formDataErrors = {
            ...formDataErrors,
            [key]: "El campo está vacío"
          };
          valid = false;
          if (key === 'description') {
            errorModal.errorMessage.push(
              <p>
                - Debe indicar una descripción del accidente
              </p>
            );
          } else {
            errorModal.errorMessage.push(
              <p>
                - El campo
                {key === "billNumber"
                  ? " número de factura "
                  : key === "doctorName"
                    ? " nombre del médico "
                    : key === "doctorCif"
                      ? " NIF médico/CIF centro "
                      : key === "date"
                        ? " fecha de la factura "
                        : " "}
                está vacío
              </p>
            );
          }

          errorModal.showErrorModal = true;
        } else if (key === "doctorCif" && formData.country === "S") {
          const formatChecked = checkDocumentFormat(formData[key]);
          if (formatChecked) {
            formDataErrors = {
              ...formDataErrors,
              [key]: formatChecked,
            };
            valid = false;
            errorModal.errorMessage.push(
              <>
                <p>- Nif/Cif inválido:</p>
                <p>&ensp; {formatChecked}</p>
              </>
            );
            errorModal.showErrorModal = true;
          } else if (
            !isValidCif(formData[key]) &&
            !isValidDocumentID(formData[key], "NIF") &&
            !isValidDocumentID(formData[key], "NIE")
          ) {
            formDataErrors = {
              ...formDataErrors,
              [key]: "Nif/Cif inválido"
            };
            valid = false;
            errorModal.errorMessage.push(
              <>
                <p>- Nif/Cif inválido:</p>
                <p>&ensp; Revise el formato aceptado de documentos</p>
              </>
            );
            errorModal.showErrorModal = true;
          }
        } else if (
          key === "date" &&
          moment(formData.date, "DD/MM/YYYY").isAfter(moment())
        ) {
          formDataErrors = {
            ...formDataErrors,
            [key]: "Fecha posterior al día de hoy"
          };
          valid = false;
          errorModal.errorMessage.push(
            <p>- La fecha de la factura no puede posterior al día de hoy</p>
          );
          errorModal.showErrorModal = true;
        } else if (
          key === "money" &&
          parseFloat(formData.money.replace(",", ".")) <= 0
        ) {
          formDataErrors = {
            ...formDataErrors,
            [key]: "El importe debe ser mayor que cero"
          };
          valid = false;
          errorModal.errorMessage.push(
            <p>- El importe de la factura tiene que ser mayor que 0</p>
          );
          errorModal.showErrorModal = true;
        } else if (formData[key] === "TYPE_01" && formData.speciality === "") {
          formDataErrors = {
            ...formDataErrors,
            [key]: "Debe escoger una especialidad"
          };
          valid = false;
          errorModal.errorMessage.push(<p>- Debe escoger una especialidad</p>);
          errorModal.showErrorModal = true;
        }
      }
    });

    if (this.isMandatoryReport()) {
      if (formReports.length === 0) {
        valid = false;
        errorModal.errorMessage.push(<p>- No se ha agregado ningún informe</p>);
        errorModal.showErrorModal = true;
      }
    }

    if (formDocuments.length === 0) {
      valid = false;
      errorModal.errorMessage.push(<p>- No se ha agregado ninguna factura</p>);
      errorModal.showErrorModal = true;
    }
    this.setState({ formDataErrors, errorModal });
    return valid;
  };
  
  isMandatoryReport = () => {
    const { formData } = this.state
    return (
      (formData.assistanceType === "TYPE_02" && formData.expedient) ||
      (formData.assistanceType === "TYPE_03" && formData.expedient) ||
      formData.assistanceType === "TYPE_04" ||
      formData.assistanceType === "TYPE_06" ||
      formData.assistanceType === "TYPE_05"
    )
  } 

  saveInvoice = () => {
    const valid = this.validateFields();
    if (valid) {
      const {
        formData,
        formReports,
        formDocuments,
        assistanceReceived
      } = this.state;
      const { onSave, onClose } = this.props;
      const invoiceData: IInvoice = {
        id: formData.billNumber,
        assistanceType: formData.assistanceType,
        speciality: formData.speciality,
        assistancePlace: formData.country,
        doctorId: formData.doctorCif,
        doctorName: formData.doctorName,
        amount:
          Number(formData.money.replace(/\./g, "").replace(/\,/g, ".")) || 0,
        currency: formData.country === "S" ? "EUR" : formData.moneyType,
        date: formData.date,
        first:
          formData.expedient &&
          (formData.assistanceType === "TYPE_02" ||
            formData.assistanceType === "TYPE_03"),
        reports: formReports,
        documents: formDocuments
      };

      if (formData.description !== "" && assistanceReceived) {
        invoiceData.invoiceDescription = formData.description;
        invoiceData.invoiceType = "SI";
      }
      onSave(invoiceData);
      onClose();
    }
  };

  getRequestTypes = async () => {
    try {
      const requestTypesRequest = await getRequestTypesRequest();

      const requestTypes = requestTypesRequest.data;

      let { typesSelect } = this.state;

      for (let request of requestTypes) {
        let requestKey = Object.keys(request)[0];

        typesSelect.push({
          label: request[requestKey],
          value: requestKey
        });
      }
      this.setState({ typesSelect });
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
  };

  getSpecialities = async () => {
    try {
      const specialitiesRequest = await getSpecialitiesRequest();

      const specialities = specialitiesRequest.data;

      let { specialitiesSelect } = this.state;
      for (let speciality of specialities) {
        let specialityKey = Object.keys(speciality)[0];

        specialitiesSelect.push({
          label: speciality[specialityKey],
          value: specialityKey
        });
      }

      this.setState({ specialitiesSelect });
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
  };

  decodeBase64 = (base64: string, fileName: string) => {
    const type = fileName.split(".")[1];
    // decode base64
    const fileContent = atob(base64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const fileBuffer = new ArrayBuffer(fileContent.length);
    const view = new Uint8Array(fileBuffer);

    // fill the view, using the decoded base64
    for (let index = 0; index < fileContent.length; index++) {
      view[index] = fileContent.charCodeAt(index);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([fileBuffer], { type: type });

    return blob;
  };

  getDocs = async () => {
    const { invoice } = this.props;
    let auxAttachedFiles: AttachedFile[] = [];
    if (invoice && invoice.documents.length !== 0) {
      for (let document of invoice.documents) {
        let blobFile = this.decodeBase64(document.id, document.fileName);
        let type = document.fileName.split(".")[
          document.fileName.split(".").length - 1
        ];
        let file = new File([blobFile], document.fileName, {
          type: type,
          lastModified: Date.now()
        });
        let attachedFile: AttachedFile = {
          data: file,
          error: false,
          name: document.fileName,
          size: String(blobFile.size),
          creation_date: moment(file.lastModified).format("DD MMM YYYY")
        };

        auxAttachedFiles.push(attachedFile);
      }

      this.setState({ docs: auxAttachedFiles });
    }
  };

  getReports = async () => {
    const { invoice } = this.props;

    let auxAttachedFiles: AttachedFile[] = [];
    if (invoice && invoice.reports.length !== 0) {
      for (let report of invoice.reports) {
        let blobFile = this.decodeBase64(report.id, report.fileName);
        let type = report.fileName.split(".")[
          report.fileName.split(".").length - 1
        ];
        let file = new File([blobFile], report.fileName, {
          type: type,
          lastModified: Date.now()
        });
        let attachedFile: AttachedFile = {
          data: file,
          error: false,
          name: report.fileName,
          size: String(blobFile.size),
          creation_date: moment(file.lastModified).format("DD MMM YYYY")
        };

        auxAttachedFiles.push(attachedFile);
      }
      this.setState({ reports: auxAttachedFiles });
    }
  };

  getCurrencies = async () => {
    try {
      const currencyRequest = await getCurrenciesRequest();

      const currencies = currencyRequest.data.items;
      let arrayAux: ISelectOption[] = [];

      for (let currency of currencies) {
        arrayAux.push({
          label: currency.symbol,
          value: `${currency.name} (${currency.symbol}) `
        });
      }
      this.setState({ currenciesSelect: arrayAux });
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
  };

  changeAssistanceReceived = (name: string, show: boolean) => {
    let { assistanceReceived } = this.state;

    assistanceReceived = name === "assistanceReceived";

    this.setState({ assistanceReceived });

    if (name === "assistanceNotReceived") {
      // this.props.changeStep();
    }
  };

  changeAssistanceText = (name: string, value: string) => {
    // this.props.addData({
    //   accidentDescription: value,
    //   accidentType: this.state.assistanceReceived ? "SI" : "NO"
    // });
    // this.props.changeStep();
  };

  getAssistanceTypeInfo = (assisType: string) => {
    switch (assisType) {
      case "TYPE_02":
        return "En caso de inicio de tratamiento debe incluir, además de la factura, el informe médico o prescripción que justifique la atención recibida";
      case "TYPE_03":
        return "En caso de inicio de tratamiento debe incluir, además de la factura, el informe médico o prescripción que justifique la atención recibida";
      case "TYPE_04":
        return "Debe incluir factura e informe relativo a la atención recibida";
      case "TYPE_05":
        return "Debe incluir factura e informe y/o prescripción que justifique la atención recibida";
      case "TYPE_06":
        return "Debe incluir factura e informe y/o prescripción que justifique la atención recibida";
      default:
        return null;
    }
  };

  render() {
    const { onClose, totalFileSize } = this.props;
    const {
      formDataErrors,
      formData,
      formReports,
      formDocuments,
      errorModal,
      assistanceReceived
    } = this.state;

    const assistanceTypeValues = this.state.typesSelect.filter(
      typesSelect => typesSelect.label === formData.assistanceType
    );

    const assistanceTypeValue =
      assistanceTypeValues && assistanceTypeValues.length > 0
        ? assistanceTypeValues[0].value
        : "";

    const specialityValues = this.state.specialitiesSelect.filter(
      specialitySelect => specialitySelect.label === formData.speciality
    );

    const specialityValue =
      specialityValues && specialityValues.length > 0
        ? specialityValues[0].value
        : "";

    const assisType = this.getAssistanceTypeInfo(formData.assistanceType);
    let totalFilesSize = totalFileSize;
    totalFilesSize += formReports.reduce((size, rep) => rep.size + size, 0);
    totalFilesSize += formDocuments.reduce((size, doc) => doc.size + size, 0);
    return (
      <ModalNewBillContainer>
        <div className="modal-new-bill-wrapper">
          <div className="modal-new-bill-title-container">
            <div className="modal-new-bill-title">
              <h2>NUEVA FACTURA</h2>
            </div>

            <div className="modal-new-bill-btn-close" onClick={() => onClose()}>
              <img src={icons.closeBillModal} alt="" />
            </div>
          </div>

          <form className="modal-new-bill-form">
            {/* bloque principal gris */}
            <label className="mandatory-field"> * Campos obligatorios </label>
            <div className="fieldset bill-data-fieldset">
              <div className="bill-data-fieldset-row">
                <div className="field">
                  <InputBox
                    name="billNumber"
                    type="text"
                    initialValue={formData.billNumber}
                    labelText="Número de la factura"
                    errorText={formDataErrors.billNumber || ""}
                    onChange={(name: string, value: string) =>
                      this.handleChange(value, name)
                    }
                    required={true}
                  />
                </div>

                <div className="field">
                  <CalendarBox
                    labelText="FECHA DE LA FACTURA"
                    onChange={(value: string) =>
                      this.handleChange(value, "date")
                    }
                    errorText={formDataErrors.date || ""}
                    maxDate={new Date()}
                    value={formData.date}
                    required={true}
                  />
                </div>
              </div>

              <div className="bill-data-fieldset-row">
                <div className="field">
                  <SelectBox
                    labelText="PAÍS DONDE RECIBISTE LA ASISTENCIA"
                    initialValue={
                      formData.country === "S" ? "España" : "Extranjero"
                    }
                    onChange={(value: string) =>
                      this.handleChange(
                        value === "España" ? "S" : "E",
                        "country"
                      )
                    }
                    optionsText={["España", "Extranjero"]}
                    errorText={formDataErrors.country || ""}
                    required={true}
                  />
                </div>

                <div className="field two-fields">
                  <div className="field-input">
                    <InputBox
                      type="text"
                      cleaveOptions={{
                        numeral: true,
                        delimiter: ".",
                        numeralDecimalMark: ",",
                        numeralDecimalScale: 2,
                        numeralThousandsGroupStyle: "thousand"
                      }}
                      name="money"
                      defaultValue={formData.money}
                      errorText={formDataErrors.money || ""}
                      labelText="IMPORTE DE LA FACTURA"
                      onChange={(name: string, value: string) =>
                        this.handleChange(value, name)
                      }
                      required={true}
                    />
                  </div>
                  {formData.country === "E" && (
                    <div className="field-select">
                      <SelectSearch
                        initialValue={{
                          label: formData.moneyType,
                          value: formData.moneyType
                        }}
                        value={{
                          label: formData.moneyType,
                          value: formData.moneyType
                        }}
                        onChange={(name: string, value: string) =>
                          this.handleChange(value, name)
                        }
                        name="moneyType"
                        options={this.state.currenciesSelect}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* bloque datos personales */}
            <div className="fieldset doctor-data-fieldset">
              <div className="fieldset-title">
                <h3>DATOS DEL DOCTOR/CENTRO MÉDICO</h3>
              </div>
              <div className="doctor-fieldset-row">
                <div className="field">
                  <InputBox
                    name="doctorName"
                    initialValue={formData.doctorName}
                    errorText={formDataErrors.doctorName || ""}
                    labelText="NOMBRE"
                    onChange={(name: string, value: string) =>
                      this.handleChange(value, name)
                    }
                    required={true}
                  />
                </div>
                {formData.country === "S" && (
                  <div className="field">
                    <InputBox
                      name="doctorCif"
                      initialValue={formData.doctorCif}
                      errorText={formDataErrors.doctorCif || ""}
                      labelText="NIF/CIF DE LA FACTURA"
                      withTooltip={true}
                      tooltipText={[
                        " · La longitud debe ser de 9 caracteres",
                        " · No está permitido ningún carácter distinto a letras o números",
                      ]}
                      tooltipTitle="Formatos aceptados de Documentos"
                      onChange={(name: string, value: string) =>
                        this.handleChange(value, name)
                      }
                      cleaveOptions={{
                        uppercase: true
                      }}
                      required={true}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* bloque descripcion medica */}
            <div className="fieldset description-data-fieldset">
              <div className="fieldset-description-title">
                <h3>DESCRIPCIÓN DE LA ASISTENCIA</h3>
              </div>

              <div className="add-description-decision">
                <div className="decision-label">
                  <label>
                    ¿La asistencia prestada es consecuencia de un accidente de
                    tráfico, laboral, escolar, deportivo…?{" "}
                  </label>
                  <label className="mandatory-field"> * </label>
                </div>
                <div className="decision">
                  <div className="decision-option decision-yes">
                    <CircleCheckbox
                      name="assistanceReceived"
                      value={assistanceReceived === true}
                      initialValue={assistanceReceived === true}
                      labelText="Sí"
                      onChange={this.changeAssistanceReceived}
                    />
                  </div>
                  <div className="decision-option decision-no">
                    <CircleCheckbox
                      value={assistanceReceived === false}
                      name="assistanceNotReceived"
                      initialValue={assistanceReceived === false}
                      labelText="No"
                      onChange={this.changeAssistanceReceived}
                    />
                  </div>
                </div>
              </div>
              {assistanceReceived && (
                <div className="add-description-text">
                  <TextareaBox
                    disabled={!assistanceReceived}
                    name="description"
                    className="add-description-textarea"
                    errorText={formDataErrors.description || ""}
                    labelText=""
                    initialValue={formData.description}
                    placeholder="Descripción del accidente *"
                    onChange={(name: string, value: string) =>
                      this.handleChange(value, name)
                    }
                    onBlur={() => { }}
                  />
                </div>
              )}
            </div>

            {/* bloque tipo de asistencia */}
            <div className="fieldset assistance-type-fieldset">
              <div className="fieldset-title">
                <h3>TIPO DE ASISTENCIA</h3>
              </div>

              <div className="doctor-fieldset-row">
                <div className="field">
                  <label className="mandatory-field"> * </label>
                  <SelectSearch
                    value={{
                      label: formData.assistanceType,
                      value: assistanceTypeValue
                    }}
                    onChange={(name: string, value: string) =>
                      this.handleChange(value, name)
                    }
                    name="assistanceType"
                    options={this.state.typesSelect}
                    iconImg={icons.IconPencilGrey}
                    iconImgActive={icons.IconPencilBlue}
                  />
                </div>

                {formData.assistanceType === "TYPE_01" && (
                  <div className="field">
                    <label className="mandatory-field"> * </label>
                    <SelectSearch
                      value={{
                        label: formData.speciality,
                        value: specialityValue
                      }}
                      onChange={(name: string, value: string) =>
                        this.handleChange(value, name)
                      }
                      name="speciality"
                      options={this.state.specialitiesSelect}
                      iconImg={icons.IconFolderGrey}
                      iconImgActive={icons.IconFolderBlue}
                    />
                  </div>
                )}
              </div>

              {assisType && (
                <div className="fieldset-special-decision">
                  <div className="decision-label">
                    <label>{assisType} </label>
                  </div>
                </div>
              )}

              {(formData.assistanceType === "TYPE_02" ||
                formData.assistanceType === "TYPE_03") && (
                  <div className="fieldset-special-decision">
                    <div className="decision-label">
                      <label>¿Se trata de un primer tratamiento?</label>
                      <label className="mandatory-field"> * </label>
                    </div>
                    <div className="decision">
                      <div className="decision-option decision-option-yes">
                        <CircleCheckbox
                          initialValue={formData.expedient === true}
                          value={formData.expedient === true}
                          name="expedient"
                          labelText="Sí, es un primer tratamiento"
                          onChange={() => this.handleChange(true, "expedient")}
                        />
                      </div>
                      <div className="decision-option decision-option-no">
                        <CircleCheckbox
                          initialValue={formData.expedient === false}
                          value={formData.expedient === false}
                          name="expedient"
                          labelText="No, es un tratamiento sucesivo"
                          onChange={() => this.handleChange(false, "expedient")}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>

            {/* bloque subir facturas */}
            <div className="fieldset upload-bills-fieldset">
              <div className="upload-bill">
                <UploadDocs
                  totalFilesSize={totalFilesSize}
                  files={
                    this.props.invoice && this.props.invoice.documents
                      ? this.state.docs
                      : []
                  }
                  title="FACTURAS"
                  bodyInfoText={'ASISA se reserva el derecho de solicitarte los originales de las facturas'}
                  checkIfDocumentsExist={this.checkIfDocumentsExist}
                  onConfirm={file => this.addNewDocuments(file, "document")}
                  required={true}
                  withTooltip={true}
                  tooltipTitle={"FACTURAS:"}
                  tooltipText={[
                    "La factura debe estar emitida por la persona física o jurídica que haya prestado la asistencia y debe incluir los datos identificativos de la emisora.",
                    " · Nombre y apellidos/Denominación social",
                    " · Domicilio",
                    " · Teléfono",
                    " · NIF/CIF",
                    " · En el caso de factura emitida por una persona jurídica, es imprescindible que conste la identidad (nombre, apellidos, especialidad y n.º de colegiado) del profesional sanitario que ha prestado la asistencia sanitaria.",
                    "<br>",
                    "La factura debe incluir, además:",
                    " · Nombre y apellidos del paciente",
                    " · DNI",
                    " · Desglose adecuado de los diversos conceptos asistenciales y su naturaleza (consulta, pruebas, intervenciones, tratamientos, servicios, etc.), con indicación de fecha de realización e importe en cada caso.",
                  ]}
                />
              </div>
            </div>

            {/* bloque subir informes */}
            <div className="fieldset upload-informs-fieldset">
              <div className="upload-doc">
                <UploadDocs
                  totalFilesSize={totalFilesSize}
                  files={
                    this.props.invoice && this.props.invoice.reports
                      ? this.state.reports
                      : []
                  }
                  title={
                    formData.assistanceType === "TYPE_02"
                      ? "INFORMES O PRESCRIPCIONES MÉDICAS"
                      : "INFORMES MÉDICOS"
                  }
                  bodyInfoText={'ASISA se reserva el derecho de solicitarte los originales de los informes médicos'}
                  checkIfDocumentsExist={this.checkIfDocumentsExist}
                  onConfirm={file => this.addNewDocuments(file, "report")}
                  required={this.isMandatoryReport()}
                  withTooltip={true}
                  tooltipTitle={"INFORMES:"}
                  tooltipText={[`El informe médico o la prescripción deben indicar el diagnóstico y tratamiento prescrito, es imprescindible que conste la identidad (nombre, apellidos, especialidad y n.º de colegiado) del profesional sanitario que ha prestado la asistencia sanitaria.`]}
                />
              </div>
            </div>

            <div className="modal-new-bill-buttons">
              <div className="btn-save">
                <MainButton
                  type="blue"
                  text="Guardar"
                  onClick={() => this.saveInvoice()}
                />
              </div>
              <div className="btn-delete">
                <MainButton
                  type="grey"
                  text="Finalizar"
                  onClick={() => this.props.onDelete()}
                />
              </div>
            </div>
          </form>
        </div>

        {errorModal.showErrorModal && (
          <ModalMessage
            title={errorModal.title || "Error"}
            messages={errorModal.errorMessage}
            btnText="Aceptar"
            onAccept={() => this.changeErrorModal(false)}
            onClose={() => this.changeErrorModal(false)}
          />
        )}
      </ModalNewBillContainer>
    );
  }
}

export default ModalNewBill;
