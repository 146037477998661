import styled from "styled-components";

const CookiesPoliticsContainer = styled.section`
  display: flex;
  justify-content: center;

  .btn-azul {
    background: #004F8B;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
    border: medium none;
    color: #fff !important;
    display: inline-block;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    float: right;
    font-weight: bold;
    font-size: 16px;
  }
  
  .btn-azul:hover {
    background: #003157;
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
  }

  .cookies-politics-wrapper {
    width: 90%;
    padding-top: 84px;
  }
  .go-back {
    margin-bottom: 20px;
    img {
      width: 40px;
    }
  }
  .cookies-politics-title {
    font-family: "museo sans";
    font-size: 44px;
    line-height: 50px;
    color: #091e42;
    margin-bottom: 62px;
  }

  .cookies-politics-article {
    .article-title {
      font-family: "museo sans";
      font-size: 28px;
      line-height: 36px;
      color: #091e42;
      margin-bottom: 20px;
    }

    .article-content {
      font-family: "museo sans";
      font-size: 18px;
      line-height: 26px;
      color: #6b778c;
      text-align: justify;
      font-weight: 300;
      margin-bottom: 4em;

      p {
        margin-bottom: 20px;

        &: last;
      }

      a {
        color: #004F8B;
        font-weight: bold;
      }

      ul {
        padding-left: 1em;
        margin-bottom: 20px;
      }

      li:before {
        content: "• ";
        font-size: 18px;
        margin-bottom: 10px;
        color: #004F8B
      }

      .tablaGenerica th {
        font-weight: bold;
        vertical-align: middle;
        color: #ffffff;
        background-color: #004F8B;
        text-align: left;
        display: table-cell;
        font-size: 18px;
        line-height: 18px;
        padding-top: 14px;
        padding-left: 20px;
        padding-right: 1%;
        padding-bottom: 14px;
      }

      .tablaGenerica tr:nth-of-type(odd) {
        background: #f8f8f8;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        margin: 0 auto;
      }

      .tablaGenerica td {
        color: #333;
        vertical-align: middle;
        border: none;
        font-size: 18px;
        line-height: 1.4em;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 1em;
      }

      .tablaGenerica th, .tablaGenerica td {
          width: 16%;
      }

      table.tablaGenerica {
        background: #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        border: 1px solid #ddd;
        width: 100%;
        margin-bottom: 20px;
      }

      table.tablaGenerica thead, table.tablaGenerica tfoot {
        background: #f5f5f5;
        text-align: justify;
        font-size: 18px;
      }

      table {
          border-collapse: collapse;
          border-spacing: 0;
          font-family: "museo sans";
      }
    }
  }
`;

export default CookiesPoliticsContainer;
