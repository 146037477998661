import styled from "styled-components";
import { LabelTextContainer } from "../../text/label-text/label-text-style";

export const SelectBoxContainer = styled.div`
  position: relative;
  width: 100%;

  &.show-list {
    z-index: 12;

    .input-box-main-field {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }

    .input-box-main-list {
      display: block;
    }

    .input-box-icon {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &.show-tooltip {
    .input-box-topbar {
      z-index: 13;
    }

    .input-box-tooltip {
      display: block;
    }
  }

  &.error {
    .input-box-main-field {
      border-bottom: 1.5px solid #ff4100;
    }
  }

  &.disabled {
    pointer-events: none;

    .input-box-topbar-label {
      color: #b5bcc6;
    }

    .input-box-main-field {
      background: #f7f7f7;
      border-bottom: 1px solid #b5bcc6;
      color: #b5bcc6;
    }
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }

  .input-box-topbar-label {
    font-size: 14px;
    text-transform: uppercase;
    color: #6b778c;
    padding: 2px 0;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 0;
    padding: 11px 13px;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    color: #091e42;
    border-bottom: 1px solid #cecece;
    cursor: pointer;
    height: 48px;

    p {
      cursor: inherit;
    }

    &:hover {
      background: #004f8b0d;
    }

    &:focus {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    width: 16px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;

    img {
      max-width: 16px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    display: none;
    right: 0;
    display: none;
  }

  .input-box-main-list {
    position: absolute;
    top: calc(100% + 10px);
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: #ffffff;
    width: 100%;
    display: none;
    color: #091e42;
    max-height: 60vh;
    overflow-y: scroll;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
        background: #004f8b0d;
      }
    }
  }

  .input-box-error {
    color: #ff4100;
    min-height: 17px;
    margin-top: 4px;
  }

  @media (max-width: 900px) {
    input {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    .input-box-topbar {
      margin-bottom: 0;
    }

    .input-box-topbar-label {
      ${LabelTextContainer} {
        font-size: 12px;
      }
    }

    input {
      font-size: 14px;
    }
  }
`;
