import { combineReducers } from "redux";
import { NOTIFICATIONS } from "../../constants/actions";
import { IModalError } from "../../screens/request-detail/types";
import { LOADING, EXPIRED, USER, MODAL_ERROR } from "./../../constants";

export const  loading = (state: any = { value: false }, action: any) => {
  switch (action.type) {
    case LOADING:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const  expiredToken = (state: any = { value: false }, action: any) => {
  switch (action.type) {
    case EXPIRED:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const  user = (state: any = { value: false }, action: any) => {
  switch (action.type) {
    case USER:
      return Object.assign({}, state, { value: action.value });
    default:
      return state;
  }
};

export const modalError = (state: IModalError = { show: false, message: '', redirect: '', title: '' }, action: any) => {
  switch (action.type) {
    case MODAL_ERROR:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export const notifications = (state: any = [], action: any) => {
  switch (action.type) {
    case NOTIFICATIONS:
      return action.value;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  expiredToken,
  user,
  modalError,
  notifications,
});