import React from "react";
import { Menu02TextContainer } from "./menu-02-text-style";

interface Menu02TextProps {
    children: any;
    className?: string;
    color?: string;
}

const Menu02Text = ({children = '', className='', color=''}:Menu02TextProps) => {
        return (
        <Menu02TextContainer color={color} className={className}>
            {children}
        </Menu02TextContainer>
    )
} 
export default Menu02Text;