import React, { Component } from "react";
import dateFns from "date-fns";
import { CalendarBoxContainer } from "./calendar-box-style";
import { withRouter, RouteComponentProps } from "react-router";
import { images } from "../../../assets/images";
import TooltipBox from "../../util-components/tooltip-box";
import NoteText from "../../text/note-text";
import BodyText from "../../text/body-text";
import CalendarItem from "../calendar-item";
import LabelText from "../../text/label-text";
import classNames from 'classnames';

interface CalendarBoxProps extends RouteComponentProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconFocus?: string;
  iconDisabled?: string;
  labelText: string;
  errorText?: string;
  onChange: Function;
  withTooltip?: boolean;
  maxDate?: Date;
  value?: string;
  required?: boolean;
}

interface CalendarBoxState {
  focus: boolean;
  showCalendar: boolean;
  showTooltip: boolean;
  value: string;
}

class CalendarBox extends Component<CalendarBoxProps, CalendarBoxState> {
  private toggleContainer: any;

  constructor(props: CalendarBoxProps) {
    super(props);
    this.state = {
      focus: false,
      showCalendar: false,
      showTooltip: false,
      value: ""
    };
    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  public static defaultProps = {
    withTooltip: false,
    icon: images.IconCalendarBlack
  };

  componentDidMount() {
    const { value } = this.props;
    if (value) this.setState({ value });
    window.addEventListener("click", this.onClickOutsideHandler);
  }

  componentDidUpdate(prevProps: CalendarBoxProps) {
    const { value } = this.props;
    if (value && prevProps.value !== value) this.setState({ value });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
  }

  onClickOutsideHandler(event: Event) {
    if (
      this.state.showCalendar &&
      !this.toggleContainer.current.contains(event.target)
    ) {
      this.setState({ showCalendar: false });
    }
  }

  render() {
    const {
      disabled,
      className,
      withTooltip,
      icon,
      maxDate,
      iconDisabled,
      labelText,
      errorText,
      onChange,
      required
    } = this.props;
    const { showCalendar, showTooltip, value } = this.state;

    return (
      <CalendarBoxContainer
        ref={this.toggleContainer}
        className={classNames(`${className}`, {
          "show-calendar": showCalendar,
          "show-tooltip": showTooltip,
          "disabled": disabled,
        })}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>
              <p>
                {labelText}
                {required && <label className="mandatory-field"> * </label>}
              </p>
              
            </LabelText>
          </div>
          {withTooltip && (
            <div
              className="input-box-topbar-icon"
              onClick={() =>
                this.setState((prevState: CalendarBoxState) => ({
                  showTooltip: !prevState.showTooltip
                }))
              }
            >
              <img
                src={
                  disabled ? images.IconArrowLeftGrey : images.IconArrowLeftGrey
                }
              />
              <div className={"input-box-tooltip "}>
                <TooltipBox />
              </div>
            </div>
          )}
        </div>
        <div className="input-box-main">
          <div
            className="input-box-main-field"
            onClick={() =>
              this.setState((prevState: CalendarBoxState) => ({
                showCalendar: !prevState.showCalendar
              }))
            }
          >
            <BodyText>{value}</BodyText>
          </div>
          <div className="input-box-icon">
            <img src={disabled ? iconDisabled : icon} />
          </div>
        </div>
        <div className="calendar-popup">
          <CalendarItem
            onSelect={(value: string) => {
              this.setState({ value }, () => {
                onChange(value);
              });
            }}
            value={value}
            closeCalendar={() => this.setState({ showCalendar: false })}
            maxDate={maxDate}
          />
        </div>
        {errorText && (
          <div className="input-box-error">
            <NoteText>
              <p>{errorText}</p>
            </NoteText>
          </div>
        )}
      </CalendarBoxContainer>
    );
  }
}

export default withRouter(CalendarBox);
