import React, { Component } from "react";
import { SimpleCheckboxContainer } from "./simple-checkbox-style";
import { images } from "../../../assets/images";
import NoteText from "../../text/note-text";
import { withRouter, RouteComponentProps } from "react-router";

interface SimpleCheckboxProps {
  type?: string;
  text?: string;
  name?: string;
  errorText?: string;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
}

class SimpleCheckbox extends Component<SimpleCheckboxProps, {}> {
  public static defaultProps = {
    type: "checkbox",
    text: "lorem ipsum",
    name: "",
    checked: false
  };

  render() {
    const { type, name, text, onCheck, errorText, checked } = this.props;

    return (
      <SimpleCheckboxContainer>
        <label className="checkbox-wrapper">
          <input
            type={type ? "checkbox" : "radio"}
            name={name ? name : ""}
            checked={checked}
            onChange={e => {
              if (onCheck) {
                onCheck(e.target.checked);
              }
            }}
          />
          <div className="checkbox-container">
            <div className="checkbox-item-icon">
              <img src={images.IconTickWhite} alt="" />
            </div>
            <div className="checkbox-item-text">
              <p>{text}</p>
            </div>
          </div>
          {errorText && (
            <div className="check-box-error">
              <NoteText>
                <p>{errorText}</p>
              </NoteText>
            </div>
          )}
        </label>
      </SimpleCheckboxContainer>
    );
  }
}

export default SimpleCheckbox;
