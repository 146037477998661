// export const SERVICES_URL = `http://localhost:4000/`;
const SERVICES_URL_PRE = `https://alxreembolsopre.asisa.es/backend/`;
const SERVICES_URL_PRU = `https://alxreembolsopru.asisa.es/backend/`;
const SERVICES_URL_PRO = `https://reembolso.asisa.es/backend/`;

const SERVICES_KEY_PRE = '36c3124075534df4b757c5885232914b';
const SERVICES_KEY_PRU = '66473ff9697944b8a36a1f9482f7bc15';
const SERVICES_KEY_PRO = '90750d057db041679bddd7cb78a4c849';

let config = "";
try {
  config = process.env.REACT_APP_ENV;
} catch (e) { }
let domain = SERVICES_URL_PRU;
let key = SERVICES_KEY_PRU;

switch (config) {
  case "master":
    domain = SERVICES_URL_PRO;
    key = SERVICES_KEY_PRO;
    break;
  case "staging":
    domain = SERVICES_URL_PRE;
    key = SERVICES_KEY_PRE;
    break;
}

export const SERVICES_URL = domain;
export const SERVICES_KEY = key;
