import { LOADING, EXPIRED, USER, MODAL_ERROR } from "../../constants";
import { NOTIFICATIONS } from "../../constants/actions";
import { IModalError } from "../../screens/request-detail/types";

export const setLoading = (value:boolean) => ({ type: LOADING, value });

export const setExpiredToken = (value:boolean) => ({ type: EXPIRED, value });

export const setUser = (value:any) => ({ type: USER, value });

export const setModalError = (payload: IModalError) => ({ type: MODAL_ERROR, payload });

export const setNotifications = (value:any) => ({ type: NOTIFICATIONS, value });