import React, { Component } from "react";
import CircleCheckboxContainer from "./circle-checkbox-style";
import { images } from "../../../assets/images";

interface CircleCheckboxProps {
  name: string;
  onChange: (name: string, value: boolean) => void;
  labelText?: string;
  initialValue?: boolean;
  value?: boolean;
  secondLine?: string;
}

interface CircleCheckboxState {
  isSelected: boolean;
}

class CircleCheckbox extends Component<CircleCheckboxProps, CircleCheckboxState> {
  state: CircleCheckboxState;

  constructor(props: CircleCheckboxProps) {
    super(props);

    this.state = {
      isSelected: this.props.initialValue,
    };
  }

  handleChangeSelectedValue = (val: boolean) => {
    const { name, onChange } = this.props;
    let { isSelected } = this.state;
    if (!isSelected) {
      isSelected = val;
      this.setState({ isSelected });
      onChange(name, val);
    }
  };

  componentWillUpdate(nextprops) {
    if (this.props.value !== nextprops.value) {
      let { isSelected } = this.state;

      isSelected = nextprops.value;
      this.setState({ isSelected });
    }
  }

  render() {
    const { labelText, secondLine, value } = this.props;
    const { isSelected } = this.state;

    return (
      <CircleCheckboxContainer onClick={() => this.handleChangeSelectedValue(!isSelected)}>
        <div className={`circle-checkbox ${isSelected ? "selected" : ""}`}>
          {isSelected && <img src={images.IconTickWhite} alt="check" />}
        </div>
        <div className={`circle-checkbox-label ${isSelected ? "selected" : ""}`}>
          {secondLine && secondLine !== "" ? (
            <label>{labelText}<br></br>{secondLine}</label>
          ) : (
            <label>{labelText}</label>
          )}
        </div>
      </CircleCheckboxContainer>
    );
  }
}

export default CircleCheckbox;
