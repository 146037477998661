import React from 'react';
import TooltipBoxLayout from './tooltip-box-layout';

export default class TooltipBoxScreen extends React.Component<any, {}> {
    render(){

        const {active, tooltipTitle, tooltipText} = this.props;

        return(
            <TooltipBoxLayout active={active} tooltipTitle={tooltipTitle} tooltipText={tooltipText}/>
        )
    }
}