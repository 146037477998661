import styled from "styled-components";

export const SimpleLayout = styled.div`
  padding: 80px 80px 100px;

  &.grey-background {
    background: #f8f9fb;
  }

  @media (max-width: 900px) {
    padding: 60px 48px 100px;
  }

  @media (max-width: 768px) {
    padding-top: 112px;
  }

  @media (max-width: 690px) {
    padding-top: 40px;
  }

  @media (max-width: 550px) {
    padding: 126px 24px 60px;
  }
`;
