import styled, { css } from 'styled-components';
 

export const H4TextContainer = styled.div`
    font-family: "museo sans";
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    
    ${props => css`
        color: ${props.color}
    `}




`;