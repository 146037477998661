import styled, { css } from "styled-components";

export const LabelTextContainer = styled.div`
  font-family: "museo sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;

  ${props => css`
    color: ${props.color};
  `}
`;
