import { SERVICES_URL, SERVICES_KEY } from "../../env";
import axios from "axios";

export interface GetRequestMessagesParams {
  id: string;
}

export interface IMessage {
  id: number;
  createdAt: string;
  updatedAt: string;
  body: string;
  isViewed: boolean;
  type: string;
  child: Array<Object>;
  agent: {
    id: number;
    createdAt: string;
    updatedAt: string;
    firstname: string;
    lastname: string;
  };
  children: any;
}

export interface GetRequestMessagesResponse {
  requestMessages: IMessage[];
}

export const sendRequestMessages = (params: any) => {
  const endpoint = `health/messages/${params.id}/messages`;

  return axios({
    method: "POST",
    url: `${SERVICES_URL}${endpoint}`,
    data: params.data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};
