import { SERVICES_URL, SERVICES_KEY } from "../../env";
import axios from "axios";
import { store } from "../../redux/store";
import { setLoading } from "../../redux/actions";
import { iData } from "../../screens/login/types";

export const authValidateTokenRequest = () => {
    const endpoint = `auth/validateToken`;
    store.dispatch(setLoading(true));

    return axios({
        method: "GET",
        url: `${SERVICES_URL}${endpoint}`,

        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'api-version': "v1",
            'ocp-apim-subscription-key': SERVICES_KEY,

            Authorization: `Bearer ${localStorage.accessToken}`,
        },
    });
};

export const authLogin = (data: iData) => {
    const endpoint = `auth/login`;
    return axios({
        method: "POST",
        url: `${SERVICES_URL}${endpoint}`,
        data,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'api-version': "v1",
            'ocp-apim-subscription-key': SERVICES_KEY,
        },
    });
}
