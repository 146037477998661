import React from 'react';
import TooltipBoxScreen from './tooltip-box-screen';

export default class TooltipBox extends React.Component<any, {}> {
    render(){

        const {active, tooltipTitle, tooltipText} = this.props;

        return(
            <TooltipBoxScreen active={active} tooltipTitle={tooltipTitle} tooltipText={tooltipText}/>
        )
    }
}