import styled, { css } from 'styled-components';

export const H1TextContainer = styled.div`
    font-weight: 500;
    font-size: 84px;
    line-height: 92px;
    
    ${props => css`
        color: ${props.color}
    `}




`;