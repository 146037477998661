import styled from "styled-components";

export const TooltipContainer = styled.div`
  font-family: "museo sans";
  font-weight: 300;
  font-size: 14px;
  color: #091e42;

  .tooltip-container {
    padding: 19px 14px;
    position: relative;
    background: #c4c4c4;
    border-radius: 4px;
    position: fixed;
    top: ${props => props.position.top + "px"};
    left: ${props => props.position.left + "px"};
    z-index: 99;

    &:before {
      content: "";
      position: absolute;
      top: -5px;
      right: 10px;
      background: #c4c4c4;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      border-radius: 4px;
    }
  }
`;
