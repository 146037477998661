import styled from "styled-components";

const SelectSearchContainer = styled.div`
  width: 100%;
  position: relative;

  .select-search-topbar {
    cursor: pointer;
    width: 100%;
    height: 62px;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    border: 1px solid #e2e5ea;
  }

  .select-search-label {
    font-family: "museo sans";
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 24px;
      margin-right: 16px;
    }
  }

  .select-search-topbar-icon {
    cursor: pointer;

    img {
      transition: 0.3s all ease-in-out;
    }
  }

  .select-search-list-container {
    position: absolute;
    margin-top: 4px;
    padding: 32px 24px;
    background: #fff;
    max-height: 429px;
    overflow: auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 100%;
    z-index: 99;
  }

  .select-search-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #e2e5ea;
    margin-bottom: 50px;
  }

  .select-search-input-icon {
    width: 18px;
    height: 18px;
  }

  .select-search-list {
    width: 100%;
  }

  .select-search-option {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.active {
    .select-search-topbar {
      background: #ccf0fb;
      border: 1px solid transparent;
      background: rgba(1, 178, 234, 0.15);
    }

    .select-search-label {
      color: #01b2ea;
    }
    .select-search-topbar-icon {
      img {
        transition: 0.3s all ease-in-out;
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 440px) {
    .select-search-topbar {
      height: auto;
      padding: 14px 10px;
    }

    .select-search-label {
      font-size: 14px;
    }

    .select-search-list-container {
      padding: 0;
    }

    .select-search-input {
      margin: 0;
      padding: 12px 6px;
    }

    .select-search-option {
      padding: 12px 6px;
      &:last-child {
        padding-bottom: 16px;
      }
    }
  }
`;

export default SelectSearchContainer;
