import axios from "axios";
import { SERVICES_URL, SERVICES_KEY } from "../env";
import { store } from "../redux/store/index.js";
import { setLoading, setModalError, setExpiredToken } from "../redux/actions";

class Services {
  setValue = (args: any) => {
    store.dispatch(args);
  };

  errorHandler(error: any) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      switch (error.response.status) {
        case 424:
        case 500:
        case 504:
          this.setValue(
            setModalError({
              show: true,
              type: "error",
              message:
                "Lo sentimos, ha ocurrido un error en el servidor. Inténtelo de nuevo más tarde.",
              redirect: "/",
              title: "¡Oops!",
            })
          );
          return {
            statusCode: error.response.status,
            message: error,
          };
        case 401:
          if ( error.response.data && error.response.data.error === "invalid credentials" ) {
            if ( error.response.data.code && error.response.data.code === "NEVER_LOGGED" ) {
              this.setValue(
                setModalError({
                  show: true,
                  message: "",
                  redirect: "",
                  type: "warning",
                  title: "¡Un momento!",
                  messages: [
                    "Hemos detectado que es la primera vez que accedes al portal de Reembolso desde que actualizaste tus credenciales.",
                    "Para garantizar tu privacidad, introduce tus datos de acceso para asegurarnos de tu identidad.",
                  ],
                })
              );
            } else if (error.response.data.code && error.response.data.code === "BAD_CREDENTIALS") {
              this.setValue(
                setModalError({
                  show: true,
                  message: "No se ha podido verificar su identidad. Por seguridad, por favor indique de nuevo usuario y contraseña.",
                  redirect: "",
                  title: "¡Oops!",
                  type: "error",
                })
              );
            }
            
            return {
              statusCode: error.response.status,
              message: error,
            };
          } else {
            this.setValue(setExpiredToken(true));
            this.setValue(
              setModalError({
                show: true,
                message: "Su sesión ha expirado.",
                redirect: "",
                title: "¡Oops!",
                type: "error",
              })
            );
            return {
              statusCode: error.response.status,
              message: error,
            };
          }
        case 422:
          if ( error.response.data &&  error.response.data.message === "Cookie not found" ) {
            return {
              statusCode: error.response.status,
              message: error,
            };
          } else {
            this.setValue(
              setModalError({
                show: true,
                message:
                  error.response.data && error.response.data.message && !Array.isArray(error.response.data.message)
                    ? error.response.data.message
                    : "Algo ha salido mal. Inténtelo de nuevo más tarde.",
                redirect: "",
                title: "¡Oops!",
                type: "error",
              })
            );
          }

          return {
            statusCode: error.response.status,
            message: error,
          };
        default:
          this.setValue(
            setModalError({
              show: true,
              message:
                error.response.data && error.response.data.message && !Array.isArray(error.response.data.message)
                  ? error.response.data.message
                  : "Algo ha salido mal. Inténtelo de nuevo más tarde.",
              redirect: "",
              title: "¡Oops!",
              type: "error",
            })
          );
          return {
            statusCode: error.response.status,
            message: error,
          };
      }
    } else {
      this.setValue(
        setModalError({
          show: true,
          message:
            "Lo sentimos, ha ocurrido un error en el servidor. Inténtelo de nuevo más tarde.",
          redirect: "/",
          title: "¡Oops!",
          type: "error",
        })
      );
      return error;
    }
  }

  get = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-version": "v1",
        "ocp-apim-subscription-key": SERVICES_KEY,

        Authorization: `Bearer ${localStorage.accessToken}`,
      };
      const response = await axios({
        method: "GET",
        url: SERVICES_URL + args.endpoint,
        headers,
      });
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.setValue(setLoading(false));
      const formatError = this.errorHandler(err);
      args.catch(formatError);
    }
  };

  post = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-version": "v1",
        "ocp-apim-subscription-key": SERVICES_KEY,
        Authorization: `Bearer ${localStorage.accessToken}`,
      };
      const response = await axios({
        method: "POST",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: args.data,
        withCredentials: args.withCredential,
      });
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.setValue(setLoading(false));
      const formatError = this.errorHandler(err);
      args.catch(formatError);
    }
  };

  patch = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-version": "v1",
        "ocp-apim-subscription-key": SERVICES_KEY,

        Authorization: `Bearer ${localStorage.accessToken}`,
      };
      const response = await axios({
        method: "PATCH",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: args.data,
      });
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.setValue(setLoading(false));
      const formatError = this.errorHandler(err);
      args.catch(formatError);
    }
  };

  deleteReq = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-version": "v1",
        "ocp-apim-subscription-key": SERVICES_KEY,

        Authorization: `Bearer ${localStorage.accessToken}`,
      };
      const response = await axios({
        method: "DELETE",
        url: SERVICES_URL + args.endpoint,
        headers,
        data: args.data,
      });
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.setValue(setLoading(false));
      const formatError = this.errorHandler(err);
      args.catch(formatError);
    }
  };

  postFormData = async (args: any) => {
    this.setValue(setLoading(args.loader));
    try {
      const config = {
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      };
      const response = await axios.post(args.endpoint, args.data, config);
      this.setValue(setLoading(false));
      args.then(response);
    } catch (err) {
      this.setValue(setLoading(false));
      const formatError = this.errorHandler(err);
      args.catch(formatError);
    }
  };
}

export default new Services();
