import React, { Component } from "react";
import { RequestModalContainer } from "./request-modal-style";
import { icons } from "../../../assets/icons";
import MainButton from "../../buttons/main-button";
import { images } from "../../../assets/images";
import { RouteComponentProps, withRouter } from "react-router";

interface RequestModalProps extends RouteComponentProps {
  className?: string;
  img?: string;
  text: string;
  buttonText?: string;
  onClickAccept: Function;
  onCloseModal: Function;
  title?: string;
}

interface RequestModalState {
  active: string;
}

class StartRequestModal extends Component<
  RequestModalProps,
  RequestModalState
  > {
  public static defaultProps = {
    warning: false,
    buttonText: "ok",
    img: icons.IconLogoutBlue,
    onClickAccept: () => { },
    onCloseModal: () => { }
  };

  constructor(props: RequestModalProps) {
    super(props);
    this.state = {
      active: "active"
    };

    document.addEventListener("openInfoModal", this.openInfoModal.bind(this));
  }

  componentDidMount() {
    this.setState({ active: "active" });
    this.acceptInfoModal();
  }

  componentWillUnmount() {
    document.removeEventListener("openInfoModal", this.openInfoModal);
  }

  openInfoModal(event: any) {
    this.setState({ active: "active" });
  }

  closeInfoModal() {
    const { onCloseModal } = this.props;
    onCloseModal();
    this.setState({ active: "" });
  }

  acceptInfoModal() {
    this.props.history.push("/crear-solicitud");
    this.closeInfoModal();
  }

  render() {
    const { img, text, className, buttonText, title } = this.props;
    const { active } = this.state;

    return (
      <RequestModalContainer className={`${active} ${className}`}>
        <div
          className="request-modal-veil"
          onClick={() => this.closeInfoModal()}
        />
        <div className="request-modal-container">
          <div className="request-modal-top-row">
            <div className="top-row-title">
              <p>{title}</p>
            </div>
            <div className="top-row-img">
              <img
                src={images.IconCrossBlue}
                onClick={() => this.closeInfoModal()}
              />
            </div>
          </div>
          <div className="request-modal-main">
            <p>{text}</p>
          </div>
          <div className="request-modal-button">
            <MainButton
              type={"dark-blue"}
              onClick={() => this.acceptInfoModal()}
              text={buttonText}
            />
          </div>
        </div>
      </RequestModalContainer>
    );
  }
}

export default withRouter(StartRequestModal);
