import React, { Component } from "react";
import { HomeContainer } from "./home-style";
import { REQUEST_STATUS, REQUEST_STATUS_FILTERS } from "../../constants/request-status";
import { icons } from "../../assets/icons";
import CardRequest from "../../components/cards/card-request";
import { connect } from "react-redux";
import Services from "../../utils/services";
import SelectSearch from "../../components/form-components/select-search/select-search";
import { isoStringToStringDate } from "../../utils/date";
import {
  getUserRequests,
  getUserNamesRequests,
  getUserAccidentsRequests
} from "../../services/requests/get-user-requests";
import { store } from "../../redux/store";
import { setLoading, setExpiredToken, setModalError } from "../../redux/actions";
import { RouteComponentProps, withRouter } from "react-router";
import { getRequestTypesRequest } from "../../services/info/get-request-types";
import MainTable from "../../components/main-table/main-table-layout";
import { currencyFormat } from "../../utils/text";

interface Request {
  // id: string;
  // insuredId: string;
  // secureName: string;
  // requestNumber: string;
  // startedDate: string;
  // sinisterType: string;
  // status: string;
  // statusDate: string;
  // amount: string;
  // assistanceType: string;
  // provider: string;
  [key: string]: any;
}

interface RequestCard {
  id: string;
  state: string;
  creation_date: string;
  request_no: number;
  insuredId: string;
  name: string;
  polize_type: string | null;
  request_type: string;
}

interface SelectOptionType {
  label: string;
  value: string;
  secondLine?: string;
}

interface StatusFilters {
  gracefulPayment: boolean;
  pendingInfo: boolean;
  inProgress: boolean;
  accepted: boolean;
  paid: boolean;
  denied: boolean;
  notAcepted: boolean;
}

interface RequestFilters {
  secureName: string;
  status: StatusFilters;
}

interface HomeProps extends RouteComponentProps {
  user: any;
}

interface HomeState {
  filters: RequestFilters;
  requests: Request[];
  requestsCards: RequestCard[];
  filterRequests: Request[];
  filterRequestsCards: RequestCard[];
  insureds: any;
  areFiltersOpen: boolean;
  isGrid: boolean;
  insuredNames: SelectOptionType[];
  loading: boolean;
}

class Home extends Component<HomeProps, HomeState> {
  state: HomeState;

  constructor(props: HomeProps) {
    super(props);

    this.state = {
      filters: {
        secureName: "all",
        status: {
          gracefulPayment: false,
          pendingInfo: false,
          inProgress: false,
          accepted: false,
          paid: false,
          denied: false,
          notAcepted: false
        }
      },
      requests: [],
      requestsCards: [],
      filterRequests: [],
      filterRequestsCards: [],
      insureds: [],
      areFiltersOpen: false,
      isGrid: window.innerWidth < 768,
      insuredNames: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getRequest();
  }

  getRequest = async () => {
    let {
      requests,
      requestsCards,
    } = this.state;
    const { insured } = this.props.user;
    let insuredNames: SelectOptionType[] = [{ label: "", value: "", secondLine: "", }];
    requests = [];
    requestsCards = [];
    this.setState({ loading: true });
    try {

      const insuredsReq = getUserRequests({ id: insured.id, loading: false });
      const insuredsNamesReq = getUserNamesRequests({
        id: insured.id, loading: false
      });
      const requestTypesReq = getRequestTypesRequest();

      const allPromiseReq = await Promise.all([
        insuredsReq,
        insuredsNamesReq,
        requestTypesReq
      ]);

      const insuredsResponse = allPromiseReq[0];
      const insuredsNamesResponse = allPromiseReq[1];
      const requestTypesRequest = allPromiseReq[2];

      const assistanceTypes = requestTypesRequest.data;
      const assistanceTypesKeyValues: { [key: string]: any } = {};
      assistanceTypes.forEach(assisType => {
        Object.entries(assisType).forEach((entry: { [key: string]: any }) => {
          assistanceTypesKeyValues[entry[1]] = entry[0];
        });
      });
      insuredNames = [];
      for (let request of insuredsResponse.data) {
        let assistanceType = "";
        for (const type of assistanceTypes) {
          const typeKey = Object.keys(type)[0];
          const typeValue = type[typeKey];
          if (typeValue === request.assistanceType) {
            assistanceType = typeKey;
          }
        }
        const isRepeated = insuredNames.find(
          element => element.label === request.insured.id
        );
        requests.push({
          insuredPerson: {
            key: "",
            text: request.insuredName,
            className: "table-col-2"
          },
          applicationNumber: {
            key: "",
            text: request.accidentId,
            className: "table-col-1-5"
          },
          creationDate: {
            key: "",
            text: isoStringToStringDate(request.accidentDate, "/"),
            className: "table-col-1-5"
          },
          assistanceType: {
            key: "multiple",
            items: request.invoices.map((inv: any) =>
              typeof inv.assistanceType === "object"
                ? "-"
                : assistanceTypesKeyValues[inv.assistanceType]
            ),
            className: "table-col-3"
          },
          provider: {
            key: "multiple",
            items: request.invoices.map((inv: any) =>
              typeof inv.provider === "object" ? "-" : inv.provider
            ),
            className: "table-col-2"
          },
          state: {
            key: "",
            value: request.accidentStatus,
            text: REQUEST_STATUS[request.accidentStatus] || "En trámite",
            className: "table-col-3"
          },
          reason: {
            key: "multiple",
            items: request.invoices.map((inv: any) =>
              request.accidentStatus === "inProgress" || request.accidentStatus === 'pendingInfo'
                ? "-"
                : inv.reason === ""
                  ? "No se indicó motivo"
                  : inv.reason
            ),
            className: "table-col-2"
          },
          amountRequested: {
            key: "multiple",
            items: request.invoices.map((inv: any) =>
              typeof inv.amount === "object"
                ? "-"
                : currencyFormat(inv.amount).toString()
            ),
            className: "table-col-2 toRight"
          },
          amount: {
            key: "multiple-last",
            items: request.invoices.map((inv: any) =>
              typeof inv.amountRefund === "object" ||
                request.accidentStatus === "pendingInfo" ||
                request.accidentStatus === "inProgress" ||
                request.accidentStatus === "denied"
                ? "-"
                : currencyFormat(inv.amountRefund).toString()
            ),
            className: "table-col-2"
          }
        });
        requestsCards.push({
          id: request.accidentId,
          insuredId: request.insured.id,
          state: request.accidentStatus,
          creation_date: isoStringToStringDate(request.accidentDate, "/"),
          request_no: request.accidentId,
          name: request.insuredName,
          polize_type: "health",
          request_type: "health"
        });
      }
      for (const request of insuredsNamesResponse.data) {
        const { dni, birthDate, policyNumber, policyOrder, firstname, lastname, policyProductDescription } = request;

        insuredNames.push( {
          label: JSON.stringify({ dni, birthDate, policyNumber, policyOrder }) ,
          value: `${firstname} ${lastname}`,
          secondLine: policyProductDescription ? `(${policyProductDescription})` : ''
        });
      }
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }

    store.dispatch(setLoading(false));
    this.setState({ insuredNames, requests, requestsCards, loading: false });
  };

  changeShowInfoType = (show: boolean) => {
    let { isGrid } = this.state;
    isGrid = show;
    this.setState({ isGrid });
  };

  changeShowFilters = (show: boolean) => {
    let { areFiltersOpen } = this.state;
    areFiltersOpen = show;

    this.setState({ areFiltersOpen });
  };

  changeSecureFilter = async (name: string, value: string) => {
    try {
      if (value === "all") {
        this.getRequest();
        return;
      }
      let {
        requests,
        requestsCards,
        filterRequests,
        filterRequestsCards
      } = this.state;
      const { insured: { id } } = this.props.user;
      this.setState({ loading: true });

      // Búsqueda de resultados por asegurado 
      const {dni, birthDate, policyNumber, policyOrder} = JSON.parse(value);
      const insuredsResponse = await getUserAccidentsRequests({ id, dni, birthDate, policyNumber, policyOrder });

      const requestTypesRequest = await getRequestTypesRequest();
      const assistanceTypes = requestTypesRequest.data;
      requests = [];
      requestsCards = [];
  
      const assistanceTypesKeyValues: { [key: string]: any } = {};
      assistanceTypes.forEach(assisType => {
        Object.entries(assisType).forEach((entry: { [key: string]: any }) => {
          assistanceTypesKeyValues[entry[1]] = entry[0];
        });
      });
  
      const accidentIds = [];
  
      for (let request of insuredsResponse.data) {
        let assistanceType = "";
        for (const type of assistanceTypes) {
          const typeKey = Object.keys(type)[0];
          const typeValue = type[typeKey];
          if (typeValue === request.assistanceType) {
            assistanceType = typeKey;
          }
        }
  
        requests.push({
          insuredPerson: {
            key: "",
            text: request.insuredName,
            className: "table-col-2"
          },
          applicationNumber: {
            key: "",
            text: request.accidentId,
            className: "table-col-1-5"
          },
          creationDate: {
            key: "",
            text: isoStringToStringDate(request.accidentDate, "/"),
            className: "table-col-1-5"
          },
          assistanceType: {
            key: "multiple",
            items: request.invoices.map(
              (inv: any) => assistanceTypesKeyValues[inv.assistanceType]
            ),
            className: "table-col-3"
          },
          provider: {
            key: "multiple",
            items: request.invoices.map((inv: any) => inv.provider),
            className: "table-col-2"
          },
          state: {
            key: "",
            value: request.accidentStatus,
            text: REQUEST_STATUS[request.accidentStatus] || "En trámite",
            className: "table-col-2"
          },
          reason: {
            key: "multiple",
            items: request.invoices.map((inv: any) =>
              request.accidentStatus === "inProgress" || request.accidentStatus === 'pendingInfo'
                ? "-"
                : inv.reason === ""
                  ? "No se indicó motivo"
                  : inv.reason
            ),
            className: "table-col-2"
          },
          amountRequested: {
            key: "multiple",
            items: request.invoices.map((inv: any) =>
              currencyFormat(inv.amount).toString()
            ),
            className: "table-col-2 toRight"
          },
          amount: {
            key: "multiple-last",
            items: request.invoices.map((inv: any) =>
              typeof inv.amountRefund === "object" ||
                request.accidentStatus === "pendingInfo" ||
                request.accidentStatus === "inProgress" ||
                request.accidentStatus === "denied"
                ? "-"
                : currencyFormat(inv.amountRefund).toString()
            ),
            className: "table-col-2"
          }
        });
        requestsCards.push({
          id: request.accidentId,
          insuredId: request.insured.id,
          state: request.accidentStatus,
          creation_date: isoStringToStringDate(request.accidentDate, "/"),
          request_no: request.accidentId,
          name: request.insuredName,
          polize_type: "health",
          request_type: "health"
        });
        accidentIds.push(request.accidentId);
      }
  
      const newFilterRequests = filterRequests.filter((req: any) =>
        accidentIds.includes(req.applicationNumber.text)
      );
      const newFilterRequestsCards = filterRequestsCards.filter((req: any) =>
        accidentIds.includes(req.id)
      );
      store.dispatch(setLoading(false));
      this.setState({
        requests,
        requestsCards,
        filterRequests: newFilterRequests,
        filterRequestsCards: newFilterRequestsCards,
        loading: false
      });
    }catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
    
  };

  filterRequestsData = (all: boolean) => {
    const { requests, requestsCards, filters } = this.state;
    let { filterRequests, filterRequestsCards } = this.state;
    filterRequests = requests;
    filterRequestsCards = requestsCards;
    if (!all) {
      filterRequests = filterRequests.filter(
        request => filters.status[request.state.value]
      );
      filterRequestsCards = filterRequestsCards.filter(
        request => filters.status[request.state]
      );
    }
    this.setState({ filterRequestsCards, filterRequests });
  };

  filterAll = (isInsured?: boolean) => {
    let { filters, filterRequests, filterRequestsCards } = this.state;
    Object.keys(filters.status).map((key: string) => {
      filters.status[key] = false;
    });
    if (isInsured) {
    } else {
      this.filterRequestsData(true);
    }

    this.setState({ filters, filterRequests, filterRequestsCards });
  };

  filterRequestStatus = (selectedKey: string) => {
    let { filters, filterRequests, filterRequestsCards } = this.state;
    if (filters.status[selectedKey]) {
      filters.status[selectedKey] = false;
      this.filterRequestsData(true);
    } else {
      Object.keys(filters.status).map((key: string) => {
        //filters.status[key] = false;
        if (key === selectedKey) {
          filters.status[key] = true;
        }
      });
    }

    this.setState({ filters }, () => {
      this.filterRequestsData(false);
    });
  };

  isFilterSelected = (selectedKey: string) => {
    const { filters } = this.state;
    let isSelected = false;
    let isAll = true;

    Object.keys(filters.status).map((key: string) => {
      if (filters.status[key]) {
        isAll = false;
      }
      if (filters.status[key] && key === selectedKey) {
        isSelected = true;
      }
    });

    if (isAll && selectedKey === "all") {
      isSelected = true;
    }

    return isSelected;
  };

  render() {
    const {
      filters,
      requests,
      insureds,
      areFiltersOpen,
      isGrid,
      insuredNames,
      requestsCards,
      filterRequests,
      filterRequestsCards,
      loading
    } = this.state;
    return (
      <HomeContainer>
        <div className="home-topbar">
          <div className="home-title">
            <h1>
              Hola <span>{`${this.props.user.insured.firstname} ${this.props.user.insured.lastname}`}</span>
            </h1>
          </div>

          <div className="filter-options">
            <div className="secure-filter">
              <SelectSearch
                name="secureName"
                initialValue={{ label: "all", value: "Todos los asegurados" }}
                isMulti
                options={insuredNames}
                onChange={this.changeSecureFilter}
                hasAll
              />
            </div>

            <div
              className="btn-filter"
              onClick={() => this.changeShowFilters(!areFiltersOpen)}
            >
              <p>Filtrar</p>
            </div>

            <div
              className="btn-change-grid"
              onClick={() => this.changeShowInfoType(!isGrid)}
            >
              <img src={isGrid ? icons.listIcon : icons.gridIcon} alt="" />
            </div>
          </div>
        </div>

        {areFiltersOpen && (
          <div className="status-filter-container">
            <ul className="status-filter-list">
              <li
                className={`status-filter ${
                  this.isFilterSelected("all") ? "selected" : ""
                  }`}
                onClick={() => this.filterAll()}
              >
                <div className="status-filter-text">
                  <p>Todos</p>
                </div>
              </li>
              {Object.keys(REQUEST_STATUS_FILTERS).map((key: string) => (
                <li
                  className={`status-filter ${
                    this.isFilterSelected(key) ? "selected" : ""
                    }`}
                  onClick={() => this.filterRequestStatus(key)}
                >
                  <div className="status-filter-text">
                    <p>{REQUEST_STATUS[key]}</p>
                  </div>

                  <div
                    className="status-filter-close-icon"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      this.filterAll();
                    }}
                  >
                    <img src={icons.btnCloseFilter} alt="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="home-content">
          {loading && (
            <div className="table-loader">
              <div className="loader-holder">
                <div className="spinner"></div>
              </div>
            </div>
          )}
          {!loading && requests.length === 0 && <p>No hay ninguna solicitud</p>}
          {isGrid ? (
            <div className="home-grid-content">
              {requestsCards && this.isFilterSelected("all")
                ? requestsCards.map((request: RequestCard) => (
                  <div
                    className="card-element"
                    onClick={() =>
                      this.props.history.push(
                        "/solicitud-detalle/" + request.id
                      )
                    }
                  >
                    <CardRequest
                      request={{
                        id: request.id,
                        state: request.state,
                        creation_date: request.creation_date,
                        request_no: request.request_no,
                        name: request.name,
                        polize_type: null,
                        request_type: request.request_type
                      }}
                    />
                  </div>
                ))
                : filterRequestsCards.map((request: RequestCard) => (
                  <div
                    className="card-element"
                    onClick={() =>
                      this.props.history.push(
                        "/solicitud-detalle/" + request.id
                      )
                    }
                  >
                    <CardRequest
                      request={{
                        id: request.id,
                        state: request.state,
                        creation_date: request.creation_date,
                        request_no: request.request_no,
                        name: request.name,
                        polize_type: null,
                        request_type: request.request_type
                      }}
                    />
                  </div>
                ))}
            </div>
          ) : (
              <div className="home-table-content">
                {requests.length > 0 &&
                  requests &&
                  !loading &&
                  this.isFilterSelected("all") ? (
                    <MainTable
                      fixedLeft={true}
                      fixedLeftTitle="Asegurado"
                      fixedRight={true}
                      fixedRightTitle="Importe"
                      fixedBoth={true}
                      columns={this.tableColumns}
                      rows={requests}
                      keysRow={this.rowKeys}
                      orderBy={() => "TODO"}
                    />
                  ) : (
                    !loading &&
                    (requests.length > 0 && (
                      <MainTable
                        fixedLeft={true}
                        fixedLeftTitle="Asegurado"
                        fixedRight={true}
                        fixedRightTitle="Importe"
                        fixedBoth={true}
                        columns={this.tableColumns}
                        rows={filterRequests}
                        keysRow={this.rowKeys}
                        orderBy={() => "TODO"}
                      />
                    ))
                  )}
              </div>
            )}
        </div>
      </HomeContainer>
    );
  }
  tableColumns = [
    {
      text: "Asegurado",
      filter: false,
      ascendent: true
    },
    {
      text: "nº expediente",
      filter: false,
      ascendent: true,
      className: "table-col-1-5"
    },
    {
      text: "f. creación",
      filter: false,
      ascendent: true,
      className: "table-col-1-5"
    },
    {
      text: "tipo asistencia",
      filter: false,
      ascendent: true,
      className: "table-col-3"
    },
    {
      text: "Proveedor",
      filter: false,
      ascendent: true,
      className: "table-col-2"
    },
    {
      text: "Estado",
      filter: false,
      ascendent: true,
      className: "table-col-3"
    },
    {
      text: "Motivo",
      filter: false,
      ascendent: true,
      className: "table-col-2"
    },
    {
      text: "Imp. solicitado",
      filter: false,
      ascendent: true,
      className: "table-col-2"
    },
    {
      text: "Importe a pagar",
      filter: false,
      ascendent: true
    }
  ];

  rowKeys = [
    "insuredPerson",
    "applicationNumber",
    "creationDate",
    "assistanceType",
    "provider",
    "state",
    "reason",
    "amountRequested",
    "amount"
  ];
}

export default connect((state: any) => ({ user: state.user.value }))(
  withRouter(Home)
);
