import styled from "styled-components";
import { NoContentContainer } from "../../util-components/no-content/no-content-style";

export const AsideNotificationContainer = styled.div`
  padding: 0 30px 60px;
  .header-aside-title {
    font-family: "museo sans";
    line-height: 48px;
    font-weight: 700;
    font-size: 40px;
    color: #091e42;
    margin-bottom: 80px;
  }
  .header-aside-item {
    margin-bottom: 48px;
  }

  ${NoContentContainer} {
    /* transform: translateY(-80px); */
  }

  @media (max-width: 900px) {
    .header-aside-title {
      font-size: 34px;
      margin-bottom: 58px;
    }
  }

  @media (max-width: 550px) {
    .header-aside-title {
      font-size: 24px;
      margin-bottom: 30px;
      line-height: 1em;
    }

    ${NoContentContainer} {
      transform: none;
    }

    .header-aside-list {
      margin-top: 54px;
    }

    .header-aside-item {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
