import styled from 'styled-components';

export const GoBackContainer=styled.div`
    display: inline-block;
    width: 100%;
    color: #B5BCC6;

    .go-back-text {
        margin-left: 16px;
        letter-spacing: .05em;
        cursor: pointer;
        padding-top: 2px;
    }

    .go-back-container {
        display: flex;
        align-items: center;
    }   

    img {
        max-width: 20px;
        cursor: pointer;
    }

`