import React from 'react';
import AsideProfileLayout from './aside-profile-layout';

export default class AsideProfile extends React.Component {
    
    componentDidMount(){
        document.body.classList.add('no-scroll');
    }
    componentWillUnmount(){
        document.body.classList.remove('no-scroll');
    }
    
    render() {
        return(
       <AsideProfileLayout/>
        )
    }

}