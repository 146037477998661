import styled from "styled-components";

export const CardRequestTypeContainer = styled.div`
  height: 229px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 20px;
  cursor: pointer;

  .card-request-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    right: 0%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-request-head {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  .card-request-bottom {
    color: white;
    &__company {
      font-size: 22px;
      line-height: normal;
      margin-bottom: 6px;
      span {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &__title  {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  @media (max-width: 860px) {
    height: 200px;
    .card-request-bottom {
      &__company {
        font-size: 14px;
        line-height: 1em;
        margin-bottom: 18px;
        span {
          display: block;
          font-size: 22px;
          line-height: 1em;
        }
      }
      &__title {
        font-size: 22px;
        line-height: 1em;
      }
    }

  }

  @media (max-width: 690px) {
    padding: 24px 16px;
    
    .card-request-head {
      margin-bottom: 16px;
    }

    .card-request-bottom {
      &__company {
        font-size: 14px;
        line-height: 1em;
        margin-bottom: 6px;
        span {
          font-size: 16px;
          line-height: 1em;
        }
      }
      &__title {
        font-size: 16px;
        line-height: 1em;
      }
    }

  }

`;
