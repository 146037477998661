import React, { Component } from "react";
import { ModalLegalContainer } from "./modal-legal-style";
import { images } from "../../../assets/images";
import MainButton from "../../buttons/main-button";
import SimpleCheckbox from "../../form-components/simple-checkbox/index";

interface ModalLegalProps {
  showModal: boolean;
  onAccept: Function;
  onClose: Function;
}

interface ModalLegalState {
  accept1: boolean;
  accept2: boolean;
}

export default class ModalLegal extends Component<ModalLegalProps, ModalLegalState> {
  componentDidMount() {
    this.props.showModal && document.body.classList.add("no-scroll");
  }

  componentWillUpdate(nextprops: ModalLegalProps) {
    if (this.props.showModal !== nextprops.showModal) {
      nextprops.showModal && document.body.classList.add("no-scroll");
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  state: ModalLegalState = {
    accept1: false,
    accept2: false,
  };

  render() {
    const { accept1, accept2 } = this.state;
    const { showModal, onAccept, onClose } = this.props;

    return (
      <ModalLegalContainer className={showModal ? "active" : ""}>
        <div className="modal-legal-content">
          <div className="modal-legal-content-top">
            <div className="modal-legal-content-top__title">
              <p>Avisos legales</p>
            </div>
            <div className="modal-legal-content-top__close">
              <img src={images.ModalCloseIcon} alt="" onClick={() => onClose()} />
            </div>
          </div>

          <div className="modal-legal-content-text">
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit sapien, dignissim cursus montes sem torquent morbi
              elementum, ornare sociosqu integer lacus taciti bibendum fames. Scelerisque a ac mi lectus praesent
              hendrerit interdum vitae sem, eleifend eu non vehicula suspendisse aptent volutpat justo iaculis, lacinia
              natoque neque dictumst porta est mauris turpis. Congue nulla urna taciti erat dapibus felis augue sed,
              tempus turpis senectus a risus nunc.
            </p>
            <p>
              Dignissim cursus montes sem torquent morbi elementum, ornare sociosqu integer lacus taciti bibendum fames.
              Scelerisque a ac mi lectus praesent hendrerit interdum vitae sem, eleifend eu non vehicula suspendisse
              aptent volutpat justo iaculis, lacinia natoque neque dictumst porta est mauris turpis. Congue nulla urna
              taciti erat dapibus felis augue sed, tempus turpis senectus a risus nunc.
            </p>
            <p>
              Scelerisque a ac mi lectus praesent hendrerit interdum vitae sem, eleifend eu non vehicula suspendisse
              aptent volutpat justo iaculis, lacinia natoque neque dictumst porta est mauris turpis. Congue nulla urna
              taciti erat dapibus felis augue sed, tempus turpis senectus a risus nunc. Lorem ipsum dolor sit amet
              consectetur adipiscing elit sapien, dignissim cursus montes sem torquent morbi elementum, ornare sociosqu
              integer lacus taciti bibendum fames. Scelerisque a ac mi lectus praesent hendrerit interdum vitae sem,
              eleifend eu non vehicula suspendisse aptent volutpat justo iaculis, lacinia natoque neque dictumst porta
              est mauris turpis. Congue nulla urna taciti erat dapibus felis augue sed, tempus turpis senectus a risus
              nunc.
            </p>
          </div>
          <div className="modal-legal-content-check">
            <div className="modal-legal-content-check__item">
              <SimpleCheckbox
                text="He leído y acepto las políticas de protección de datos"
                onCheck={checked => this.setState({ accept1: checked })}
              />
            </div>
            <div className="modal-legal-content-check__item">
              <SimpleCheckbox
                text="He leído y acepto los avisos legales"
                onCheck={checked => this.setState({ accept2: checked })}
              />
            </div>
          </div>
          <div className="modal-legal-content-button">
            <MainButton
              text={"Aceptar y confirmar"}
              type={`blue ${accept1 && accept2 ? "" : "disabled"}`}
              status={"icon-left"}
              iconImg={images.IconArrowLeftBlue}
              onClick={() => {
                if (accept1 && accept2) {
                  onAccept();
                }
              }}
            />
          </div>
        </div>
      </ModalLegalContainer>
    );
  }
}
