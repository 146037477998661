import styled from "styled-components";

const CardMessageContainer = styled.div`
  background: #ffffff;
  border: 0.75px solid #e2e5ea;
  box-sizing: border-box;
  border-radius: 8px;
  border-radius: 8px;
  padding: 40px 20px 32px;
  cursor: pointer;

  .message-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .message-status {
    display: flex;
    align-items: center;

    .message-status-icon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }

    .message-status-text {
      font-family: "museo sans";
      font-size: 14px;
      line-height: 17px;

      .new {
        color: #01b2ea;
      }

      .read {
        color: #6b778c;
      }
    }
  }

  .message-user-name {
    font-family: "museo sans";
    font-size: 20px;
    line-height: 28px;
    color: #091e42;
    margin-bottom: 12px;
  }

  .message {
    font-family: "museo sans";
    color: #091e42;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5em;
    color: #091e42;
    p {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .edit-response-container {
    margin-top: 24px;
    background: #ffffff;
    border: 0.5px solid #e2e5ea;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;
    border: 1px solid gray;
    border-radius: 15px;

    .response-doc-list {
      width: 100%;
      display: grid;
      gap: 32px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      display: none;

      &.active {
        display: inline-grid;
      }

      .response-doc-element {
        border: 1px solid gray;
        border-radius: 7px;
        margin-top: 20px;
      }

    }
  }

  .edit-response-input {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: 0;
    border-bottom: 1px solid gray
    padding: 10px

    textarea {
      width: 100%;
      min-height: 22px;
      font-family: "museo sans";
      font-size: 14px;
      line-height: 22px;
      color: #6b778c;
      resize: none;
      overflow: hidden;
      border: 0;
      outline: 0;
    }
  }

  .edit-response-buttons {
    display: flex;
    // align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 8px;

    .attach {
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;

      img {
        width: 60%;
      }
    }

    .btn-send {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: grey;
      border-radius: 6px;

      &.active {
        background: #01b2ea;
        cursor: pointer;
      }

      img {
        width: 60%;
      }
    }
  }

  .response-container {
    margin-top: 32px;
    background: #e9eef2;
    border-radius: 8px;
    padding: 32px 18px;

    .response-topbar {
      margin-bottom: 12px;

      .response-text {
        font-family: "museo sans";
        font-size: 14px;
        line-height: 17px;
        color: #004f8b;
        font-weight: 600;
      }
    }

    .response-message {
      font-family: "museo sans";
      font-size: 14px;
      line-height: 22px;
      color: #004f8b;
      font-weight: 300;
    }

    .response-doc-list {
      margin-top: 32px;
      width: 100%;
      display: grid;
      gap: 32px;
      grid-template-columns: repeat(auto-fill, minmax(309px, 1fr));
    }
  }

  &.new {
    background: #ccf0fb;
    border: 0.75px solid #01b2ea;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: auto;

    .message-status-icon {
      background: white;
    }

  }

  .message-topbar-item-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .message-date {
    margin-right: 20px;
    font-size: 14px;
    color: #04a0d2;
    font-weight: 500;
    line-height: initial;
  }

  .message-status-icon {
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
    height: 24px;
    cursor: pointer;
    background: #01b2ea26;
    padding: 10px 20px;
    border-radius: 10px;
  
    img {
      max-width: 16px;
    }

    p {
      margin-left: 10px;
    }
  }

  .extend-button-container {
    width: 100%;
    display: flex;

    button {
      border: none;
      background: #01b2ea;
      padding: 10px 20px;
      border-radius: 20px;
      margin-top: 20px;
      cursor: pointer;
      font-size: 12px;
      color: white;
    }
  }

`;

export default CardMessageContainer;
