import React, { Component } from "react";
import { ModalEndingContainer } from "./modal-ending-style";
import { images } from "../../../assets/images";
import MainButton from "../../buttons/main-button";
import { RouteComponentProps, withRouter } from "react-router";

interface ModalEndingProps extends RouteComponentProps {
  accidentId?: string;
  showModal: boolean;
  onClose: Function;
}

class ModalEnding extends Component<ModalEndingProps, {}> {
  componentDidMount() {
    this.props.showModal && document.body.classList.add("no-scroll");
  }

  componentWillUpdate(nextprops: ModalEndingProps) {
    if (this.props.showModal !== nextprops.showModal) {
      nextprops.showModal && document.body.classList.add("no-scroll");
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }
  render() {
    const { showModal, onClose, accidentId } = this.props;

    return (
      <ModalEndingContainer className={showModal ? "active" : ""}>
        <div className="modal-ending-card">
          <div className="modal-ending-content">
            <div className="modal-ending-content-top">
              <div className="modal-ending-content-top__close">
                <img
                  src={images.IconCrossWhiteBg}
                  alt="cerrar modal"
                  onClick={() => onClose()}
                />
              </div>
            </div>

            {/* como puede tardar un poco en crearse la solicitud, primero sale "modal-ending-content-loading" */}
            {/* <div className="modal-ending-content-loading">
              <div className="modal-ending-content-title">
                <p>Se está creando su solicitud</p>
              </div>
              <div className="modal-ending-content-text">
                <p>En estos momentos, su solicitud está siendo procesada.</p>
              </div>
            </div> */}

            {/* Una vez se ha creado la solicitud ya se carga "modal-ending-content-ended" y se oculta "modal-ending-content-loading" */}
            <div className="modal-ending-content-ended">
              {/* <div className="modal-ending-content-subtitle">
                <p>Enhorabuena,</p>
              </div> */}
              <div className="modal-ending-content-title">
                <p>Solicitud creada con éxito</p>
              </div>
              <div className="modal-ending-content-text">
                {accidentId && (
                  <p>{`El número de expediente asignado es: ${accidentId}.`}</p>
                )}
                <p>
                  Analizaremos tu solicitud y nos pondremos en contacto contigo.
                </p>
                <p>
                  Desde tu área privada puedes conocer en todo momento el estado
                  de tu solicitud.
                </p>
              </div>
              <div className="modal-ending-content-button">
                <MainButton
                  text={"Mis Solicitudes"}
                  type={"congrats"}
                  iconRight
                  iconImgRight={images.IconArrowRightLightBlue}
                  onClick={() => {
                    this.props.history.push("/home");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-ending-content-background" />
        </div>
      </ModalEndingContainer>
    );
  }
}

export default withRouter(ModalEnding);
