import React, {Component} from 'react';
import {LoaderContainer} from './loader-style';

interface LoaderState {
    active: boolean;
}

interface LoaderProps {

}

class Loader extends Component<LoaderProps, LoaderState> {

    state: LoaderState = {
        active: true
    }

    render() {

    const {active} = this.state;

      return (
        <LoaderContainer className={`${(active) ? 'active': ''}`}>
            <div className="loader-holder">
                <div className="spinner"></div>
            </div>
        </LoaderContainer>
      );
    }
  }

export default Loader;