import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import CardAside from "../../cards/card-aside-profile";
import { AsideProfileContainer } from "./aside-profile-style";
import InputProfile from "../../form-components/input-profile";
interface AsideProfileLayoutState {
  settings: any;
}
class AsideProfileLayout extends React.Component<{ user: any }, AsideProfileLayoutState> {
  public slider: any = null;
  readonly state = {
    settings: {
      dots: true,
      // appendDots: (dots: any) => <ul>{dots}</ul>,
      customPaging: () => <button />,
      infinite: false,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "20px",
      arrows: false,
      focusOnSelect: false,
    },
  };
  componentDidMount() {
    const ul = document.getElementsByClassName("slick-dots") as HTMLCollectionOf<HTMLElement>;
    // if (ul) {
    //   ul[0].style.display = "flex";
    // }
  }

  render() {
    let { user } = this.props;
    user = user || { insured: {} };
    const { insured } = user;
    insured.policies = insured.policies.filter(item => item.type === "health") || [];
    const { settings } = this.state;
    return (
      <AsideProfileContainer>
        <div className="aside-profile-slider">
          <div className="aside-profile-slider-container">
            <Slider ref={c => (this.slider = c)} {...settings}>
              {insured.policies.map((policy: any, key) => (
                <div key={key} className="aside-profile-card-item">
                  <CardAside {...policy} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="aside-profile-content">
          <div className="aside-profile-name">
            <p>{insured.firstname}</p>
          </div>
          <div className="aside-profile-surname">
            <p>{insured.lastname}</p>
          </div>
          <div className="aside-profile-form">
            <div className="aside-profile-form__item">
              {/* DNI */}
              <InputProfile className={"card"} labelText="DNI" initialValue={""} text={insured.dni} />
            </div>

            {insured.birthDate && (
              <div className="aside-profile-form__item">
                {/* Fecha de nacimiento */}
                <InputProfile
                  className={"cake"}
                  labelText="Fecha de nacimiento"
                  initialValue={""}
                  text={insured.birthDate}
                />
              </div>
            )}
            {insured.address && (
              <div className="aside-profile-form__item">
                {/* Dirección */}
                <InputProfile className={"location"} labelText="Dirección" initialValue={""} text={insured.address} />
              </div>
            )}
            {insured.gender && (
              <div className="aside-profile-form__item">
                {/* Sexo */}
                <InputProfile className={"user"} labelText="Sexo" initialValue={""} text={insured.gender} />
              </div>
            )}

            {insured.mobilePhoneNumber && (
              <div className="aside-profile-form__item">
                {/* Telefono de contacto */}
                <InputProfile
                  className={"phone"}
                  labelText="Teléfono de contacto"
                  initialValue={""}
                  text={insured.mobilePhoneNumber}
                />
              </div>
            )}
            <div className="aside-profile-form__item">
              {/* Email */}
              <InputProfile
                className={"mail"}
                labelText="Email"
                initialValue={""}
                text={insured.email}
                email={insured.email}
              />
            </div>
          </div>

          {/* <div className="aside-profile-button">
            <div className="aside-profile-button__item">
              <ButtonLoader />
            </div>
            <div className="aside-profile-button__item">
              <MainButton text="Cancelar" type="white" onClick={() => {}} />
            </div>
          </div> */}
        </div>
      </AsideProfileContainer>
    );
  }
}

const mapStateToProps = (state: any) => ({ user: state.user.value });

export default connect(mapStateToProps)(AsideProfileLayout);
