import React from "react";
import { SimpleLayout } from "../../components/wrappers/layout/layout-style";
import { CreateRequestContainer } from "./create-request-style";
import CardRequestType from "../../components/cards/card-request-type";
import { Switch, Route, RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { POLIZE_TYPE_NAME } from "../../constants/data";
import Services from "../../utils/services";
import SelectSearch from "../../components/form-components/select-search/select-search";
import AddInsuredForm, {
  AddInsuredFormState
} from "../../components/forms/add-request-forms/add-insured-form/add-insured-form";
import AddBankForm, {
  AddBankFormState
} from "../../components/forms/add-request-forms/add-bank-form/add-bank-form";
import AddDescriptionForm from "../../components/forms/add-request-forms/add-description/add-description-form";
import AddDocForm, {
  AddDocFormState
} from "../../components/forms/add-request-forms/add-doc-form/add-doc-form";
import MainButton from "../../components/buttons/main-button";
import ModalLegal from "../../components/modals/modal-legal";
import ModalMessageContainer from "../../components/modals/modal-message/modal-message-style";
import ModalMessage from "../../components/modals/modal-message/modal-message";
import ModalEnding from "../../components/modals/modal-ending";
import anime from "animejs";
import {
  CreateUserRequestParams,
  IInsured,
  IInvoice,
  createUserRequests
} from "../../services/requests/create-user-request";
import { trackSelectedBank } from "../../services/bank/track-bank-info";
import { REQUEST_STATUS } from "../../constants/request-status";
import { setLoading, setExpiredToken, setModalError } from "../../redux/actions";
import { store } from "../../redux/store";
import RequestModal from "../../components/modals/request-modal/request-modal-layout";

type StepsKey = "second" | "third" | "fourth";

const StepTexts = {
  0: {
    title: "Seleccione asegurado",
    text:
      "Puede crear los expedientes necesarios para la presentación de su solicitud."
  },
  1: {
    title: "Datos bancarios",
    text:
      ""
  },
  2: {
    title: "Documentación",
    text:
      "A continuación, podrá incluir todos los datos de las facturas de su solicitud."
  }
};

interface AddInsuredData {
  id: string;
  birthdate: string;
  dni: string;
  name: string;
  policyId: string;
}

export interface IBankData {
  bankAccount: string;
  bankControl: string;
  bankIban: string;
  bankId: string;
  bankPlace: string;
  bankName: string;
  bankDni: string;
}

export interface IDescriptionData {
  accidentDescription: string;
  accidentType: "SI" | "NO";
}

interface IFormData {
  id?: string;
  birthdate?: string;
  dni?: string;
  name?: string;
  firstname?: string;
  lastname?: string;
  policyId?: string;
  policyNumber?: string;
  policyOrder?: number;
  bankAccount?: string;
  bankControl?: string;
  bankIban?: string;
  bankId?: string;
  bankPlace?: string;
  bankName?: string;
  bankLastName?: string;
  bankDni?: string;
  accidentType?: "NO" | "SI";
  accidentDescription?: string;
  email?: string;
  phone?: string;
  bankInsuredName?: string;
}

interface CreateRequestState {
  activeCard?: boolean;
  showLegalModal: boolean;
  showLeaveModal: boolean;
  showEndingModal: boolean;
  step: number;
  submitStep: boolean;
  createdAccidentId?: string;
  savedStates: {
    0?: AddInsuredFormState;
    1?: AddBankFormState;
    2?: AddDocFormState;
  };
  steps: {
    second: boolean;
    third: boolean;
    fourth: boolean;
  };
  formData: IFormData;
  invoices: IInvoice[];
  errorModal: {
    showErrorModal: boolean;
    errorMessage: string;
  };

  [key: string]: any;
}

interface CreateRequestProps extends RouteComponentProps {
  user: any;
}

class CreateRequest extends React.Component<
  CreateRequestProps,
  CreateRequestState
  > {
  state: CreateRequestState;
  secondStepRef: any;
  thirdStepRef: any;
  fourthStepRef: any;

  constructor(props: CreateRequestProps) {
    super(props);
    this.secondStepRef = React.createRef();
    this.thirdStepRef = React.createRef();
    this.fourthStepRef = React.createRef();

    this.state = {
      activeCard: false,
      showLegalModal: false,
      showLeaveModal: false,
      showEndingModal: false,
      formData: {},
      invoices: [],
      step: 0,
      submitStep: false,
      savedStates: {},
      steps: {
        second: false,
        third: false,
        fourth: false
      },
      errorModal: {
        errorMessage: "",
        showErrorModal: false
      }
    };
  }

  changeSecure = (name: string, value: string) => { };

  changeShowLegalModal = (show: boolean) => {
    let { showLegalModal } = this.state;

    showLegalModal = show;

    this.setState({ showLegalModal });
  };

  changeShowLeaveModal = (show: boolean) => {
    let { showLeaveModal } = this.state;

    showLeaveModal = show;

    this.setState({ showLeaveModal });
  };

  changeShowEndingModal = (show: boolean) => {
    let { showEndingModal } = this.state;

    showEndingModal = show;

    this.setState({ showEndingModal });
  };

  changeErrorModal = (show: boolean) => {
    let { errorModal } = this.state;
    errorModal.showErrorModal = show;
    this.setState({ errorModal });
  };

  transitionScroll = (ends: number, time: number) => {
    const scrollElement =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement;

    anime({
      targets: scrollElement,
      scrollTop: ends - 50,
      duration: time,
      easing: "easeInOutQuad"
    });
  };

  addData = (
    values: AddInsuredData | IDescriptionData | IBankData | any
  ) => {
    let { formData, step } = this.state;
    formData = {
      ...formData,
      ...values
    };
    if (step === 1) {
      this.handleBankAccount(formData.bankIban, formData.bankAccount);
    }
    this.setState({ formData, submitStep: false }, () => this.nextStep());
  };

  addInvoice = (invoice: any, index?: number) => {
    let { invoices } = this.state;
    if (index || index == 0) {
      if (index > -1) {
        invoices[index] = invoice;
      } else {
        invoices.push(invoice);
      }
    } else {
      invoices.push(invoice);
    }
    this.setState({ invoices });
  };

  removeInvoice = (index: number) => {
    if (index !== -1) {
      const { invoices } = this.state;
      invoices.splice(index, 1);
      this.setState({ invoices });
    }
  };

  validData = () => {
    const { formData } = this.state;

    const dataForm: IFormData = {
      id: formData.id,
      birthdate: formData.birthdate,
      dni: formData.dni,
      name: formData.name,
      policyId: formData.policyId,
      policyNumber: formData.policyNumber,
      policyOrder: formData.policyOrder,
      bankAccount: formData.bankAccount,
      bankControl: formData.bankControl,
      bankIban: formData.bankIban,
      bankId: formData.bankId,
      bankPlace: formData.bankPlace,
      bankName: formData.bankName,
      bankDni: formData.bankDni,
      accidentType: formData.accidentType || "NO",
      accidentDescription: formData.accidentDescription
    };

    let { errorModal } = this.state;
    let valid = true;

    errorModal.showErrorModal = false;
    errorModal.errorMessage = "";

    Object.keys(dataForm).map((key: string) => {
      if (key !== "accidentDescription") {
        if (!dataForm[key]) {
          errorModal.showErrorModal = true;
          errorModal.errorMessage = "Hay campos vacíos";
          valid = false;
        }
      }
    });

    if (!valid) {
      this.setState({ errorModal });
    } else {
      this.changeShowLegalModal(true);
    }
  };

  handleBankAccount = async (
    bankIban: string,
    bankAccount: string
  ) => {
    const { insured } = this.props.user;
    const params = {
      id: insured.id,
      selectedBank: `${bankIban} *** ${bankAccount.substring(bankAccount.length-4, bankAccount.length)}`,
    };
    trackSelectedBank(params);
  }

  handleSubmit = async () => {
    const { formData, invoices } = this.state;
    if (!invoices || invoices.length === 0) return;

    const insured: IInsured = {
      birthDate: formData.birthdate,
      dni: formData.dni,
      name: formData.name
    };

    const today = new Date().toISOString();

    const requestParams: CreateUserRequestParams = {
      policyId: formData.policyId,
      policyNumber: formData.policyNumber,
      policyOrder: formData.policyOrder,
      bankId: formData.bankId,
      bankPlace: formData.bankPlace,
      IBANcd: formData.bankIban,
      bankControl: formData.bankControl,
      bankAccount: formData.bankAccount,
      accidentType: formData.accidentType || "NO",
      accidentDescription: formData.accidentDescription || "",
      accidentDate: today,
      accidentStatus: "inProgress",
      accidentStatusDescription: REQUEST_STATUS.inProgress,
      origin: "W",
      insured: insured,
      invoices: invoices,
      insuredMail: formData.email,
      insuredPhone: formData.phone,
      bankInsuredName: `${formData.bankName} ${formData.bankLastName}`,
      bankInsuredNif: formData.bankDni
    };
    try {
      const request = await createUserRequests(requestParams);
      this.setState({ createdAccidentId: request.data.accidentId });
      store.dispatch(setLoading(false));
      this.changeShowEndingModal(true);
    } catch (err) {
      store.dispatch(setLoading(false));
      if (err.response && err.response.status === 504) {
        store.dispatch(setModalError({
          show: true,
          message: 'La solicitud se está procesando y tarda más de lo esperado, en breve estará creada.',
          redirect: '/',
          title: "",
        }));
      }else if (err.response && err.response.status === 409) {
        store.dispatch(setModalError({
          show: true,
          message: 'Por favor, intenta crear esta solicitud nuevamente en un par de minutos.',
          redirect: '',
          title: "",
        }));
      } else {
        const formatError = Services.errorHandler(err);
        console.error(formatError);
      }
    }
  };

  prevStep() {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  nextStep() {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  saveState(state: any) {
    const { step, savedStates } = this.state;
    savedStates[step] = state;
    this.setState({ savedStates });
  }

  render() {
    const {
      step,
      showLegalModal,
      showLeaveModal,
      showEndingModal,
      formData,
      invoices,
      errorModal,
      submitStep,
      savedStates,
      createdAccidentId
    } = this.state;
    let {
      user: {
        insured: { policies }
      }
    } = this.props;
    policies = policies.filter(item => item.type === "dental");
    return (
      <>
        <SimpleLayout className="grey-background">
          <CreateRequestContainer>
            <section className="card-step-container">
              <div className="card-step-container-main">
                <div className="create-request-topbar">
                  <div className="create-request-title">
                    <h1>Crear solicitud</h1>
                  </div>
                  <div className="create-request-steps-container">
                    <div className={`create-request-steps-item active`}>
                      <p>1</p>
                    </div>
                    <div
                      className={`create-request-steps-item ${
                        step > 0 ? "active" : ""
                        }`}
                    >
                      <p>2</p>
                    </div>
                    <div
                      className={`create-request-steps-item ${
                        step > 1 ? "active" : ""
                        }`}
                    >
                      <p>3</p>
                    </div>
                  </div>
                </div>
                {/* mirar datos a cambiar en el div "create-request-main" al cambiar de paso */}
                <div className="create-request-main">
                  <div className="create-request-step-titles">
                    <div className="title">
                      <p>{StepTexts[step].title}</p>
                    </div>
                    {StepTexts[step].text !== '' && (
                      <div className="subtitle">
                        <p>{StepTexts[step].text}</p>
                      </div>
                    )}

                  </div>
                  <div className="create-request-step-content add-insured">
                    {step === 0 ? (
                      <AddInsuredForm
                        addData={this.addData}
                        submitStep={submitStep}
                        saveState={this.saveState.bind(this)}
                        previousState={savedStates[0]}
                      />
                    ) : step === 1 ? (
                      <AddBankForm
                        selectedInsuredId={formData.id ? formData.id : ""}
                        selectedInsured={formData}
                        addData={this.addData}
                        submitStep={submitStep}
                        saveState={this.saveState.bind(this)}
                        previousState={savedStates[1]}
                      />
                    ) : (
                          <AddDocForm
                            addData={this.addInvoice}
                            invoices={invoices}
                            removeInvoice={this.removeInvoice}
                          />
                        )}
                  </div>
                </div>
              </div>
              <div className="card-step-container-actions">
                <div className="create-request-step-buttons-container">
                  {step > 0 && (
                    <div className="create-request-step-buttons-item">
                      <MainButton
                        text="Atrás"
                        type="grey"
                        onClick={() => {
                          this.prevStep();
                        }}
                      />
                    </div>
                  )}
                  <div className="create-request-step-buttons-item">
                    <MainButton
                      text={step === 2 ? "Finalizar" : "Siguiente"}
                      type="blue"
                      disabled={
                        step === 2 && (!invoices || invoices.length === 0)
                      }
                      onClick={() => {
                        if (step === 2) {
                          this.handleSubmit();
                        } else {
                          this.setState({ submitStep: true }, () =>
                            this.setState({ submitStep: false })
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
            <ModalLegal
              showModal={showLegalModal}
              onAccept={() => {
                this.changeShowLegalModal(false);
                this.changeShowEndingModal(true);
              }}
              onClose={() => {
                document.body.classList.remove("no-scroll");
                this.changeShowLegalModal(false);
              }}
            />
            {/* sacar este modal en caso de que el user vaya a irse de la pagina (ojo click a elementos del footer o el header) */}
            {showLeaveModal && (
              <RequestModal
                className="request-modal"
                title="Abandonar solicitud"
                text="Al abandonar este proceso de solicitud perderá todos los datos introducidos hasta el momento. Si quisiera volver a retomar esta solicitud deberá iniciar un nuevo proceso."
                buttonText="Abandonar"
                onClickAccept={() => {
                  this.props.history.push("/");
                }}
                onCloseModal={() => { }}
              />
            )}
            {showEndingModal && (
              <ModalEnding
                accidentId={createdAccidentId}
                showModal={showEndingModal}
                onClose={() => this.props.history.push("/")}
              />
            )}
            {errorModal.showErrorModal && (
              <ModalMessage
                title="Error"
                message={errorModal.errorMessage}
                btnText="Aceptar"
                onAccept={() => this.changeErrorModal(false)}
                onClose={() => this.changeErrorModal(false)}
              />
            )}
          </CreateRequestContainer>
        </SimpleLayout>
      </>
    );
  }
}

export default connect((state: any) => ({ user: state.user.value }))(
  withRouter(CreateRequest)
);
