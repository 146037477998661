import React from "react";
import { ItemAsideNotificationContainer } from "./item-aside-notification-style";
import { iconsSVG } from "../../assets/icons";
import { RequestNotification } from "../asides/aside-notification/aside-notification-functional";
import { MESSAGE_TYPE_NAME } from "../../constants/data";

interface ItemAsideNotificationProps {
  notification: RequestNotification;
  className: string;
}

export default class ItemAsideNotification extends React.Component<ItemAsideNotificationProps, {}> {
  render() {
    const { notification, className } = this.props;

    let title = MESSAGE_TYPE_NAME[notification.type];

    return (
      <ItemAsideNotificationContainer className={className}>
        <div className="item-aside-notification-icon">
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              className="header-button-svg-fill"
              d={iconsSVG.iconHeaderBell[0]}
              stroke={"#091E42"}
              fill="none"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              className="header-button-svg-fill"
              d={iconsSVG.iconHeaderBell[1]}
              stroke={"#091E42"}
              fill="none"
            />
          </svg>
        </div>

        <div className="item-aside-notification-text">
          <div className="item-aside-notification-text__title">
            <p>{title}</p>
          </div>
          <div className="item-aside-notification-text__subtitle">
            <p> {notification.description}</p>
          </div>
        </div>
        <div className="space-between" />
        <div className="item-aside-notification-hour">
          <p>{notification.creation_date}</p>
        </div>
      </ItemAsideNotificationContainer>
    );
  }
}
