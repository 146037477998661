import React, {Component} from 'react';
import {TooltipBoxContainer} from './tooltip-box-style';
import { withRouter } from 'react-router';
import InfoText from '../../text/info-text';
import Menu02Text from '../../text/menu-02-text';

interface TooltipBoxProps {
    active?: boolean;
    tooltipTitle: string;
    tooltipText: string;
}

class TooltipBoxLayout extends Component<any, {}> {

    public static defaultProps = {
        active: false,
        tooltipTitle: 'Titulo string',
        tooltipText: 'body string'
    }

    private parseTextBody(tooltipText: string | string[]) {
        let elements: JSX.Element[] = [];
        if (typeof tooltipText == 'string') {
            elements.push(<p>{tooltipText}</p>)
        } else {
            tooltipText.forEach(e => {
                if (e === "<br>") elements.push(<br/>)
                else elements.push(<p>{e}</p>)
            })
        }
        return elements;
    }

    render(){

        const {active, tooltipTitle, tooltipText} = this.props;

        return(
            <TooltipBoxContainer className={active ? 'active' : ''}>
                <div className="tooltip-modal">
                    <div className="tooltip-title">
                        <Menu02Text>
                            <p>{tooltipTitle}</p>
                        </Menu02Text>
                    </div> 
                    <div className="tooltip-main">
                        <InfoText>
                            {this.parseTextBody(tooltipText)}
                        </InfoText>
                    </div>
                </div>
            </TooltipBoxContainer>
        )
    }
}

export default withRouter(TooltipBoxLayout);