import axios from "axios";
import { SERVICES_URL, SERVICES_KEY } from "../../env";
import { store } from "../../redux/store";
import { setLoading } from "../../redux/actions";

export interface IInvoice {
  id: string;
  assistanceType: string;
  speciality: string;
  assistancePlace: "S" | "E";
  doctorId: string;
  doctorName: string;
  amount: number;
  currency: string;
  date: string;
  first: boolean;
  reports: IReport[];
  documents?: IReport[];
  invoiceDescription?: string;
  invoiceType?: string;
}

export interface IInsured {
  birthDate: string;
  dni: string;
  name: string;
}

export interface IReport {
  id: string;
  fileName: string;
  date: string;
  description: string;
  size?: number;
  type: string;
}

export interface CreateUserRequestParams {
  policyId: string;
  policyNumber?: string;
  policyOrder?: number;
  bankId: string;
  bankPlace: string;
  bankControl: string;
  bankAccount: string;
  accidentType: string;
  accidentDescription: string;
  accidentDate: string;
  accidentStatus: string;
  accidentStatusDescription: string;
  origin?: "W";
  insured: IInsured;
  invoices: IInvoice[];
  insuredMail: string;
  insuredPhone: string;
  bankInsuredName: string;
  bankInsuredNif: string;
  IBANcd?: string;
}

export const createUserRequests = (params: CreateUserRequestParams) => {
  const endpoint = `health/accidents`;
  store.dispatch(setLoading(true));
  return axios({
    method: "POST",
    url: `${SERVICES_URL}${endpoint}`,
    data: params,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`
    }
  });
};
