import styled from "styled-components";

const AddBankFormContainer = styled.form`
  width: 100%;

  .select-bank-account {
    width: 100%;
    margin-bottom: 34px;
  }

  .subtitle {
    margin-bottom: 50px;
  }

  .select-bank-account-label {
    font-family: "museo sans";
    color: #091e42;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 1.3em;

    span {
      font-family: "museo sans";
      font-size: 20px;
      line-height: 28px;
      color: #01b2ea;
      margin-left: 6px;
    }
  }

  .select-bank-account-radio {
    display: flex;
    align-items: center;
  }

  .select-bank-account-yes {
    margin-right: 53px;
  }

  .bank-info-container {
    background: #ffffff;
    border: 1px solid #e9eef2;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 42px 28px 36px;
    max-width: 520px;
  }

  .bank-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .bank-info-row-item {
    margin-right: 32px;
    width: calc((100%-32px) / 2);

    &.col-100 {
      width: 100%;
    }

    &.col-50 {
      width: 50%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .bank-info-field {
    margin-top: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    .bank-info-container {
      padding: 28px 20px;
    }
  }

  @media (max-width: 440px) {
    .bank-info-container {
      padding: 36px 24px;
    }
  }
`;

export default AddBankFormContainer;
