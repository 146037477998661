import styled from "styled-components";
import { TextareaBoxContainer } from "../../form-components/textarea-box/textarea-box-style";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";

const ModalNewBillContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .mandatory-field{
    color: red;
  }

  .upload-bills-fieldset {
    margin-bottom: 97px;
  }

  .upload-informs-fieldset {
    margin-bottom: 120px;
  }

  .fieldset-special-decision {
    margin-top: 60px;

    .decision-label {
      margin-bottom: 18px;
    }

    .decision-option-yes {
      margin-right: 34px;
    }
  }

  .assistance-type-fieldset {
    margin-bottom: 97px;
  }

  .description-data-fieldset {
    margin-bottom: 72px;
  }

  .add-description-text {
    ${TextareaBoxContainer} {
      textarea {
        color: #6b778c;
        font-size: 14px;
        font-weight: 300;
        border: 1px solid #e9eef2;
        border-radius: 4px;
        padding: 14px 14px 30px;
        max-width: 630px;

        &::placeholder {
          color: #909aad;
        }
      }
    }
  }

  .fieldset-description-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #091e42;
    margin-bottom: 20px;
  }

  .decision-label {
    margin-bottom: 18px;
  }

  .decision {
    display: flex;
  }

  .decision-yes {
    margin-right: 34px;
  }

  .add-description-text {
    margin-top: 27px;
  }

  .doctor-data-fieldset {
    margin-bottom: 96px;
  }

  .doctor-fieldset-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .field {
      width: calc((100% - 38px)/2);
    }
  }

  .bill-data-fieldset {
    margin-bottom: 72px;
    background: #F7F9FA;
    border-radius: 12px;
    padding: 40px 30px 54px;
  }

  .bill-data-fieldset-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 64px;

    .field {
      width: calc((100% - 20px)/2);
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }

      &.two-fields {
        display: flex;
        justify-content: space-between;
        .field-input {
          margin-right: 14px;
          width: 100%;
        }
        .field-select {
          min-width: 178px;
          max-width: 178px;
          width: 178px;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .modal-new-bill-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding-top: 0;
    width: 980px;

    min-height: 90vh;
    max-height: 90vh;

    overflow-y: scroll;

    .modal-new-bill-btn-close {
      cursor: pointer;
    }

    .modal-new-bill-title-container {
      background: #004f8b;
      width: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 42px 40px 22px;

      .modal-new-bill-title {
        font-family: "museo sans";
        font-size: 28px;
        line-height: 36px;
        color: #ffffff;
        font-weight: 500;
      }
    }

    .modal-new-bill-form {
      width: 100%;
      padding: 44px 40px;
      padding-bottom: 84px;

        .fieldset-title {
          font-family: "museo sans";
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          color: #091e42;
          margin-bottom: 40px;
        }

        .fieldset-decision {
          width: 100%;

          .decision-label {
            font-family: "museo sans";
            font-size: 18px;
            line-height: 26px;
            color: #091e42;
            margin-bottom: 26px;
          }

          .decision {
            display: flex;
            align-items: center;

            .decision-option-yes {
              margin-right: 53px;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.bill-data-fieldset {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          background: #f7f9fa;
          border-radius: 12px;
          padding: 40px 30px 54px;

          .field {
            display: flex;
            align-items: center;
            margin-bottom: 64px;

            .field-select {
              margin-bottom: 3px;
              width: 50%;
            }

            &:nth-child(2n + 2) {
              margin-right: 0;
            }

            &:nth-last-child(-n + 2) {
              margin-bottom: 0;
            }

            &:last-child {
              align-items: flex-end;

              .field-input {
                margin-right: 12px;
                min-width: 50%;
              }
            }
          }
        }
      }

      .modal-new-bill-buttons {
        display: flex;
        align-items: center;

        ${MainButtonContainer}{
          text-transform: capitalize;
        }

        .btn-save {
          width: 234px;
          height: 52px;
          margin-right: 20px;
        }

        .btn-delete {
          width: 234px;
          height: 52px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    /* .fieldset .bill-data-fieldset .modal-new-bill-wrapper  {
      min-height: 100vh;
      max-height: 100vh;
    } */
  }

  @media (max-width: 440px) {
    .modal-new-bill-wrapper {
      .modal-new-bill-form {
        .fieldset {
          margin-bottom: 42px;

          &.bill-data-fieldset {
            padding: 36px 24px;
            margin: 0;
            margin-bottom: 42px;
            .field {
              width: 100%;
              margin: 0;
              margin-bottom: 20px;
              &:nth-last-child(-n + 2) {
                margin-bottom: 20px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              .field-input {
                margin-right: 16px;
              }
            }
          }

          .fieldset-title {
            font-size: 16px;
            margin-bottom: 24px;
            line-height: 1.3em;
          }

          .fieldset-row {
            flex-wrap: wrap;
            margin-bottom: 42px;
            .field {
              width: 100%;
              margin-right: 0;
              margin-bottom: 20px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export default ModalNewBillContainer;
