import React from "react";
import { connect } from "react-redux";
import IStoreState from "../../../redux/store/IStoreState";

class Notifications extends React.Component<{ notifications: any, className: string }, {}> {
  render() {
    const { notifications, className } = this.props;
    const filterNotifications = notifications.filter(
      (notification) => notification.viewed === false
    );
    const pendingNotifications = filterNotifications.length;
    return (
      <>
        {pendingNotifications !== 0 && (
          <div className={`${className}`}
          >
            {pendingNotifications}
          </div>
        )}
      </>
    );
  }
}
function mapStateToProps(state: IStoreState) {
  return {
    notifications: state.notifications,
  };
}
export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(Notifications);
