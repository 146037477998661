import React from "react";
import { AccessContainer } from "./styles";
import { SimpleLayout } from "../../components/wrappers/layout/layout-style";
import { IPoliticsProps } from "../../constants/data";
import { icons } from "../../assets/icons";
import { Link } from "react-router-dom";

export default class AccessLayout extends React.Component<IPoliticsProps> {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render() {
    const { goBackButton } = this.props;
    return (
      <>
        <SimpleLayout>
          <AccessContainer>
            { goBackButton && 
              <Link to="/">
                <div className="go-back">
                  <img src={icons.iconArrowLeft} alt=""/>
                </div>
              </Link> 
            }
            <div className="access-title">
              <p>Accesibilidad</p>
            </div>
            <div className="access-text">
              <p>El presente sitio web ha adquirido el compromiso de garantizar la accesibilidad de sus contenidos a todos los ciudadanos en cumplimiento de lo dispuesto por la Ley de Servicios de la Sociedad de la Información y Comercio Electrónico. Por ello, el desarrollo de sus páginas se ha hecho siguiendo las pautas de accesibilidad a los contenidos proporcionadas por la WAI (Web Accesibility Initiative), organismo creado por el W3C (World Wide Web Consortium), para informar, investigar y fomentar el trabajo en la accesibilidad web.</p>
              <p>La WAI ha establecido tres niveles de accesibilidad: "A", doble "A" y triple "A", alcanzándose cada nivel con el cumplimiento de las correspondientes pautas o principios.</p>
              <p>En el caso de este sitio web, se ha fijado como objetivo cumplir el nivel "AA" de accesibilidad y, con objeto de asegurar estos niveles de cumplimiento de criterios de accesibilidad, se han realizado las correspondientes comprobaciones de las páginas con el programa TAW (Test de Accesibilidad Web).</p>
              <p>Algunas de las características implementadas para facilitar la accesibilidad son las siguientes:</p>
              <p></p>
              <ul>
                  <li className="access-list">Se ha creado una estructura clara de páginas, definiendo los encabezados, listas, elementos de comprensión, etc. lo que facilita el acceso a los contenidos con lectores de pantalla.</li>
                  <li className="access-list">El código empleado se ajusta a las gramáticas formales para su correcta interpretación y visualización de contenidos en distintos navegadores.</li>
                  <li className="access-list">Las fuentes de texto se ha definido con unidades relativas, lo que permite al usuario ampiar o disminuir el tamaño de las mismas desde las opciones del navegador.</li>
                  <li className="access-list">Además, tanto los tipos de fuente como los colores de las mismas, colores de fondo, etc. se han definido mediante Hojas de Estilo (CSS), de forma que el usuario pueda definir sus preferencias visuales.</li>
              </ul>
            </div>
            
          </AccessContainer>
        </SimpleLayout>
      </>
    );
  }
}
