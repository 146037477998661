import React, { Component } from "react";
import FooterContainer from "./footer-style";
import { icons } from "../../../assets/icons";
import { Link } from "react-router-dom";
import appStore from "../../../assets/images/app-apple-store.svg";
import appGoogle from "../../../assets/images/app-play-store.png";

import { URLS } from "../../../constants/urls";

interface FooterProps {
  secondaryColor?: boolean;
}
class Footer extends Component<FooterProps> {
  showOptanonSettingsDisplay = () => {
    var btn = document.getElementById("openCookieSettings");
    btn && btn.click();
  }

  render() {
    const { secondaryColor } = this.props;
    return (
      <FooterContainer secondaryColor={secondaryColor}>
        <nav className="footer-wrapper">
          <div className="footer-top">
            <div className="logo-icon">
              { secondaryColor ?
              <img src={icons.logoAsisaWhite} alt="logo-icon" />
              :  
              <img src={icons.logoAsisaBlue} alt="logo-icon" />
            }
            </div>
            <ul className="footer-list">
              <li className="footer-element">
                <Link to="/avisos-legales">
                  <p>Aviso Legal</p>
                </Link>
              </li>
              <li className="footer-element">
                <Link to="/accesibilidad">
                  <p>Accesibilidad</p>
                </Link>
              </li>
              <li className="footer-element">
                <Link to="/politicas-cookies">
                  <p>Política de cookies</p>
                </Link>
              </li>
              <li className="footer-element">
                <p className="footer-configuration" onClick={() => this.showOptanonSettingsDisplay()}>Configuración de cookies</p>
              </li>
              <li className="footer-element">
                <Link to="/politicas-privacidad">
                  <p>Política de privacidad</p>
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-bottom">
            <div className="footer-bottom-apple">
              <a
                href={URLS.appleApp}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStore} alt="apple store" />
              </a>
            </div>
            <div className="footer-bottom-google">
              <a
                href={URLS.androidApp}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appGoogle} alt="google play" />
              </a>
            </div>
          </div>
        </nav>
      </FooterContainer>
    );
  }
}

export default Footer;
