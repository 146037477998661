import React from "react";
import { CardRequestContainer } from "./card-request-style";
import { icons } from "../../../assets/icons";
import { STATE_ACCIDENTS } from "../../../constants";

interface CardRequestProps {
  className?: string;
  message?: boolean;
  request: {
    id: string;
    state: string;
    creation_date: string;
    request_no: number;
    name: string;
    // surnames: string;
    polize_type: Array<string> | null;
    request_type: string;
  };
}

export default class CardRequest extends React.Component<CardRequestProps> {
  render() {
    const { message, request } = this.props;

    let stateClassname = "";
    switch (request.state) {
      case "pendingInfo":
        stateClassname = "in-progress";
        break;
      case "gracefulPayment":
      case "inProgress":
        stateClassname = "in-progress";
        break;
      case "accepted":
        stateClassname = "resolved accepted";
        break;
      case "denied":
        stateClassname = "resolved deny";
        break;
    }

    let requestIcon = ["inProgress", "pendingInfo", "gracefulPayment"].includes(
      request.state
    )
      ? icons.iconAsisaWhite
      : icons.iconAsisaBlue;
    let requestTypeClassname =
      request.request_type === "health" ? "health" : "dental";

    return (
      <CardRequestContainer className={stateClassname}>
        <div className="card-request-top">
          <div className="card-request-top__icon__left">
            <img src={requestIcon} alt="" />
          </div>
          <div className={"card-request-top__text " + "dental"}>
            <div className="card-request-top__text__title">
              <p>ASISA</p>
            </div>
            <div className="card-request-top__text__subtitle">
              <p>Reembolso</p>
            </div>
          </div>
          {message && (
            <div className="card-request-top__icon__right">
              <img src={icons.iconMail} alt="" />
            </div>
          )}
        </div>

        <div className="card-request-bottom">
          <div className="card-request-bottom__name">
            <p>{`${request.name}`}</p>
          </div>
          <div className="card-request-bottom__info">
            <div className="card-request-bottom__info__code">
              <p>{request.request_no}</p>
            </div>
            <div className="card-request-bottom__info__date">
              <p>{request.creation_date}</p>
            </div>
          </div>
          <div className="card-request-bottom__state">
            <p>{STATE_ACCIDENTS[request.state]}</p>
          </div>
        </div>
      </CardRequestContainer>
    );
  }
}
