import styled, { css } from 'styled-components';
 

export const H2TextContainer = styled.div`
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    
    ${props => css`
        color: ${props.color}
    `}




`;