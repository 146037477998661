import styled from "styled-components";

export const ItemAsideNotificationContainer = styled.div`
  display: flex;
  cursor: pointer;

  .space-between {
    flex-grow: 1;
  }

  &.old {
    filter: grayscale(100%);
    opacity: 0.6;
  }

  .item-aside-notification-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccf0fb;
    border-radius: 100%;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    padding: 10px;
    padding-left: 13px;
    margin-right: 24px;
  }

  .item-aside-notification-text {
    margin-right: 40px;
    &__title {
      font-family: "museo sans";
      line-height: 28px;
      font-size: 20px;
      color: #091e42;
      margin-bottom: 6px;
    }
    &__subtitle {
      font-family: "museo sans";
      line-height: 20px;
      font-size: 16px;
      color: #091e42;
      width: 255px;
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .item-aside-notification-hour  {
    font-family: "museo sans";
    line-height: 20px;
    font-size: 16px;
    color: #091e42;
    min-width: 95px;
    text-align: right;
  }

  @media (max-width: 550px) {
    flex-wrap: wrap;

    .space-between {
      display: none;
    }

    .item-aside-notification-hour {
      width: 100%;
      text-align: left;
      padding-left: 57px;
      margin-top: 5px;
      font-size: 12px;
    }

    .item-aside-notification-icon {
      margin-right: 18px;
    }
    .item-aside-notification-text {
      width: calc( 100% - 58px);
      margin-right: 0;
      &__title {
        font-size: 16px;
        line-height: 1.35em;
        margin-bottom: 4px;
      }
      &__subtitle {
        font-size: 14px;
        line-height: 1.35em;
      }
    }
  }
`;
