import React, { Component } from 'react';
import AsideNotificationFunctional from './aside-notification-functional';

interface AsideNotificationProps {
    onClose: Function
}

export default class AsideNotification extends Component<AsideNotificationProps, {}> {    

    componentDidMount(){
        document.body.classList.add('no-scroll');
    }
    componentWillUnmount(){
        document.body.classList.remove('no-scroll');
    }
    render() {
        return(
            <AsideNotificationFunctional 
            onClose={(onFinish: Function) => this.props.onClose(() => onFinish())}
            />
        )
    }

}