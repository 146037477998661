import React, { Component, Fragment } from "react";
import AddBankFormContainer from "./add-bank-form-style";
import CircleCheckbox from "../../../form-components/circle-checkbox/circle-checkbox";
import InputBox from "../../../form-components/input-box";
import SimpleCheckbox from "../../../form-components/simple-checkbox";
import { connect } from "react-redux";
import { getDefaultBank } from "../../../../services/bank/get-default-bank";
import { store } from "../../../../redux/store";
import { setLoading, setExpiredToken, setModalError } from "../../../../redux/actions";
import {
  isValidDni,
  isValidNIE,
  isValidCif,
  isValidDocumentID,
  checkDocumentFormat
} from "../../../../utils/validation";
import IBAN from "iban";
import ModalMessage from "../../../modals/modal-message/modal-message";
import Services from "../../../../utils/services";

interface AddBankFormProps {
  previousState: AddBankFormState;
  saveState: (state: AddBankFormState) => void;
  selectedInsuredId: string;
  selectedInsured: any;
  addData: (data: IBankData) => void;
  user: any;
  submitStep: boolean;
}

interface IBankData {
  bankAccount: string;
  bankControl: string;
  bankIban: string;
  bankId: string;
  bankPlace: string;
  bankName: string;
  bankLastName: string;
  bankDni: string;
}

interface IBankForm {
  iban: string;
  name: string;
  lastname: string;
  nif: string;
  authorize: boolean;
}

type IBankFormErrors = { [key in keyof IBankForm]: string };

export interface AddBankFormState {
  isNewBank: boolean;
  selectedInsured: any;
  bankData: IBankData;
  alternativeBankData?: IBankData;
  formData: IBankForm;
  formDataErrors: IBankFormErrors;
  errorService: boolean;
  authorize: boolean;
}

class AddBankForm extends Component<AddBankFormProps, AddBankFormState> {
  state: AddBankFormState;

  constructor(props: AddBankFormProps) {
    super(props);

    this.state = {
      isNewBank: false,
      authorize: false,
      selectedInsured: {},
      bankData: {
        bankAccount: "",
        bankControl: "",
        bankIban: "",
        bankId: "",
        bankPlace: "",
        bankDni: "",
        bankName: "",
        bankLastName: ""
      },
      formData: {
        iban: "",
        name: "",
        lastname: "",
        nif: "",
        authorize: false
      },
      formDataErrors: {
        iban: "",
        name: "",
        lastname: "",
        nif: "",
        authorize: ""
      },
      errorService: false
    };
  }

  componentDidMount() {
    const { previousState } = this.props;
    if (previousState) this.setState(previousState);
    else this.getInitialData();
  }

  async getInitialData() {
    await this.getDefaultBankData();
  }

  componentDidUpdate(prevProps) {
    const { submitStep, addData, saveState } = this.props;
    const { formData } = this.state;
    if (submitStep && prevProps.submitStep !== submitStep) {
      if (this.validateFields()) {
        const iban = formData.iban.replace(/ /g, "");
        const bankIban = iban.substr(0, 4);
        const bankId = iban.substr(4, 4);
        const bankPlace = iban.substr(8, 4);
        const bankControl = iban.substr(12, 2);
        const bankAccount = iban.substr(14);

        saveState(this.state);
        addData({
          bankAccount,
          bankControl,
          bankIban,
          bankId,
          bankPlace,
          bankDni: formData.nif,
          bankName: formData.name,
          bankLastName: formData.lastname
        });
      }
    }
  }

  validateFields = () => {
    const { formData, isNewBank } = this.state;
    let { formDataErrors } = this.state;
    let valid = true;
    Object.keys(formData).map((key: string) => {
      formDataErrors = {
        ...formDataErrors,
        [key]: ""
      };
      if (!formData[key]) {
        formDataErrors = {
          ...formDataErrors,
          [key]: "El campo está vacío"
        };
        valid = false;
      }
    });

    if (valid && isNewBank) {
      if (!(IBAN.isValid(formData.iban) && formData.iban.startsWith("ES")) && isNewBank) {
        valid = false;
        formDataErrors.iban = "Iban no válido";
      } else {
        formDataErrors.iban = "";
      }

      if (formData.nif) {
        const formatChecked = checkDocumentFormat(formData.nif);
        if (formatChecked) {
          valid = false;
          formDataErrors.nif = formatChecked;
          this.setState({ formDataErrors });
          return valid;
        }
        else if (
          !isValidCif(formData.nif) &&
          !isValidDocumentID(formData.nif, "NIF")
        ) {
          if (isValidDocumentID(formData.nif, "NIE")) {
            formDataErrors.nif = "";
          } else {
            valid = false;
            formDataErrors.nif = "NIF no válido";
          }
        } else {
          formDataErrors.nif = "";
        }
      } else {
        valid = false;
        formDataErrors.nif = "NIF no válido";
      }

      if (
        formData.nif &&
        (formData.nif[0].toUpperCase() === "X" ||
          formData.nif[0].toUpperCase() === "Y")
      ) {
        if (!isValidNIE(formData.nif)) {
          valid = false;
          formDataErrors.nif = "NIE no válido";
        } else {
          formDataErrors.nif = "";
        }
      }
    }
    this.setState({ formDataErrors });
    return valid;
  };

  handleChange = (name: string, value: any) => {
    let { formData, formDataErrors } = this.state;
    formData[name] = value;
    this.setState({ formData });

    let valid = this.validateFields();
    if (!valid) {
      this.setState({ formDataErrors });
    }
  };

  changeIsNewBank = async (name: string, show: boolean) => {
    const { selectedInsured } = this.props;
    let {
      isNewBank,
      formData,
      formDataErrors,
      alternativeBankData
    } = this.state;

    isNewBank = name === "otherBank";
    if (isNewBank) {
      formDataErrors = {
        iban: "",
        name: "",
        lastname: "",
        nif: "",
        authorize: ""
      };
      if (alternativeBankData) {
        formData = {
          iban: `${alternativeBankData.bankIban} ${
            alternativeBankData.bankId
            } ${alternativeBankData.bankPlace} ${
            alternativeBankData.bankControl
            } ${alternativeBankData.bankAccount}`,
          name: this.props.selectedInsured.firstname,
          lastname: this.props.selectedInsured.lastname,
          nif: this.props.selectedInsured.dni,
          authorize: formData.authorize
        };
      } else {
        formData = {
          iban: "",
          name: "",
          lastname: "",
          nif: "",
          authorize: formData.authorize
        };
      }
      this.setState({ isNewBank, formData, formDataErrors });
    } else {
      this.setState({ selectedInsured });
      let { bankData } = this.state;
      formDataErrors = {
        iban: "",
        name: "",
        lastname: "",
        nif: "",
        authorize: ""
      };
      formData = {
        iban: `${bankData.bankIban} ${bankData.bankId} ${bankData.bankPlace} ${
          bankData.bankControl
          } ${bankData.bankAccount}`,
        name: this.props.selectedInsured.firstname,
        lastname: this.props.selectedInsured.lastname,
        nif: this.props.selectedInsured.dni,
        authorize: formData.authorize
      };
      bankData = {
        ...bankData,
        bankName: this.props.selectedInsured.name,
        bankDni: this.props.selectedInsured.dni
      };
      this.setState({ isNewBank, formData, bankData, formDataErrors });
    }
  };

  changeBankData = (name: string, value: string) => {
    let { bankData } = this.state;
    bankData[name] = value;
    this.setState({ bankData });

    let valid = true;
    Object.keys(bankData).map((key: string) => {
      if (!bankData[key]) {
        valid = false;
      }
    });
  };

  getDefaultBankData = async () => {
    const { insured } = this.props.user;
    const { policyNumber, policyOrder, policyControl, policyProductCode, clientCode} = this.props.selectedInsured;
    try {
      const defaultBankData = await getDefaultBank({ id: insured.id, policyNumber, policyOrder, policyControl, policyProductCode, clientCode });
      let { bankData } = this.state;
      bankData = {
        ...(defaultBankData.data.mainBank
          ? defaultBankData.data.mainBank
          : defaultBankData.data.secondBank)
      };
      
      if (defaultBankData.data.secondBank && defaultBankData.data.mainBank) {
        const alternativeBankData = { ...defaultBankData.data.secondBank };
        this.setState({ bankData, alternativeBankData }, () => this.changeIsNewBank("yourBank", true));
      } else if (defaultBankData.data.statusCode && defaultBankData.data.status === 201) {
        store.dispatch(setModalError({
          show: true,
          message: 'Por favor, rellene sus datos bancarios.',
          redirect: '',
          title:'',
        }));
        this.setState({}, () => this.changeIsNewBank("otherBank", true));
      } else {
        this.setState({ bankData }, () => this.changeIsNewBank("yourBank", true));
      }
      store.dispatch(setLoading(false));
    } catch (err) {
      store.dispatch(setLoading(false));
      const formatError = Services.errorHandler(err);
      console.error(formatError);

      this.setState({ errorService: true }, () =>
      this.changeIsNewBank("otherBank", true)
      );
      return true;
    }
  };

  render() {
    const {
      isNewBank,
      bankData,
      selectedInsured,
      errorService,
      formData
    } = this.state;
    return (
      <AddBankFormContainer>
        <div className="select-bank-account">
          { !errorService && bankData && bankData.bankAccount && bankData.bankAccount !== "" && (
            <Fragment>
              <div className="select-bank-account-label">
                <label>
                  <p>
                    ¿Desea que se realice la transferencia en la cuenta bancaria
                    que tiene asociada <span> • • • • </span>
                    <span>
                      {bankData.bankAccount.substr(bankData.bankAccount.length - 4)}
                    </span>{" "}
                    ?
                  </p>
                </label>
              </div>

              <div className="select-bank-account-radio">
                <div className="select-bank-account-option select-bank-account-yes">
                  <CircleCheckbox
                    name="yourBank"
                    initialValue={!isNewBank}
                    value={!isNewBank}
                    labelText="SÍ"
                    onChange={this.changeIsNewBank}
                  />
                </div>

                <div className="select-bank-account-option select-bank-account-no">
                  <CircleCheckbox
                    name="otherBank"
                    initialValue={isNewBank}
                    value={isNewBank}
                    labelText="NO"
                    onChange={this.changeIsNewBank}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
        
        {isNewBank && (
          <div className="subtitle">
            <p>{'Rellene los datos de la cuenta bancaria donde quiere que se realice el reembolso.'}</p>
          </div>
        )}

        <div className="bank-info-container">
          <div className="bank-info-row">
            <div className="bank-info-row-item col-100">
              <div className="bank-info-field">
                <InputBox
                  disabled={!isNewBank}
                  value={
                    !isNewBank
                      ? selectedInsured.firstname
                        ? selectedInsured.firstname
                        : ""
                      : formData.name
                  }
                  name="name"
                  labelText="NOMBRE DEL TITULAR (*)"
                  errorText={this.state.formDataErrors.name}
                  onBlur={e => this.handleChange("name", e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="bank-info-row">
            <div className="bank-info-row-item col-100">
              <div className="bank-info-field">
                <InputBox
                  disabled={!isNewBank}
                  value={
                    !isNewBank
                      ? selectedInsured.lastname
                        ? selectedInsured.lastname
                        : ""
                      : formData.lastname
                  }
                  name="lastname"
                  labelText="APELLIDOS DEL TITULAR (*)"
                  errorText={this.state.formDataErrors.lastname}
                  onBlur={e => this.handleChange("lastname", e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="bank-info-row">
            <div className="bank-info-row-item col-100">
              <div className="bank-info-field">
                <InputBox
                  disabled={!isNewBank}
                  value={
                    !isNewBank && bankData.bankAccount
                      ? `**** **** **** ** ******${
                      bankData.bankAccount !== ""
                        ? bankData.bankAccount.substr(
                          bankData.bankAccount.length - 4
                        )
                        : 1234
                      }`
                      : formData.iban
                  }
                  name="iban"
                  labelText="IBAN (*)"
                  cleaveOptions={{
                    blocks: [4, 4, 4, 2, 10],
                    delimiter: " "
                  }}
                  errorText={this.state.formDataErrors.iban}
                  onBlur={e => this.handleChange("iban", e.target.value)}
                />
              </div>
            </div>
            <div className="bank-info-row-item col-50">
              <div className="bank-info-field">
                <InputBox
                  disabled={!isNewBank}
                  value={
                    !isNewBank
                      ? selectedInsured.dni
                        ? selectedInsured.dni
                        : ""
                      : formData.nif
                  }
                  name="nif"
                  labelText="NIF (*)"
                  withTooltip={true}
                  tooltipText={[
                    " · La longitud debe ser de 9 caracteres",
                    " · No está permitido ningún carácter distinto a letras o números",
                  ]}
                  tooltipTitle="Formatos aceptados de Documentos"
                  errorText={this.state.formDataErrors.nif}
                  onBlur={e => this.handleChange("nif", e.target.value)}
                  cleaveOptions={{
                    uppercase: true
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bank-info-field">
          <SimpleCheckbox
            text="(*) AUTORIZO que el reembolso se destine al titular y cuenta introducidos en este apartado"
            checked={this.state.formData.authorize}
            onCheck={(value: boolean) => this.handleChange("authorize", value)}
            errorText={this.state.formDataErrors.authorize}
          />
        </div>
        {/* {errorService && (
          <ModalMessage
            className={"error"}
            onClose={() => this.setState({ errorService: false }, () => {})}
            onAccept={() => this.setState({ errorService: false }, () => {})}
            title={"Error"}
            message={"Introduce los datos a mano para continuar."}
            btnText={"Cerrar"}
          />
        )} */}
      </AddBankFormContainer>
    );
  }
}

export default connect((state: any) => ({ user: state.user.value }))(
  AddBankForm
);
