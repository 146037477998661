import styled from 'styled-components';

export const InputSelectContainer = styled.div`
  position: relative;
  font-family: 'museo sans';
  .input-s-title {
    line-height: 24px;
    font-size: 12px;
    color: #8993A4;
    height: 24px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .input-s-input {
    display: flex;
    cursor: pointer;
  }

  input {
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    font-weight: 300;
    padding-right: 30px;
    padding-bottom: 9px;
    transition: all 0.2s ease-in-out;
    &::placeholder  {
      font-family: 'museo sans';
    }
    &:focus {
    }
  }
  .input-select-arrow {
    width: 15px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    position: absolute;
    right: 0;
    top: 35px;
  }

  .input-s-error {
    line-height: 24px;
    font-size: 16px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }

  &.error {
    input {
    }
    .input-s-error {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }

`

export const InputSelectList = styled.div`
  position: absolute;
  width: 100%;
  top: 63px;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 18px 20px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  max-height: 0px;
  overflow-y: scroll;
  ${props =>
    props.active &&
    `
    max-height: 300px;
    opacity: 1 ;
    visibility: visible;

    `}

  .input-select-list-item {
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
    }
  }
`
