const lang = {
  add_credit_card: {
    header: 'Añadir tarjeta',
    billing_title: 'Dirección de facturación',
    card_title: 'Introduce los datos de tu tarjeta',
    details_title: 'Detalle de la compra',
    discount_input: {
      label: 'Código de descuento',
      placeholder: 'Introduce tu código de descuento',
    },
    discount_success: '¡Añadido código de descuento!',
    longTotal: 'TOTAL A PAGAR',
    method_title: 'Seleccione métido de pago',
    save_data:
      'Guardar los datos de mi tarjeta para agilizar los futuros procesos de reserva',
  },
  add_skills: {
    header: 'Añadir skills',
    // title: 'Puesto de trabajo',
    title: 'Datos profesionales',
    skills: 'Tus skills',
  },
  become_member: {
    title: '¿Quieres formar parte de la comunidad Utopicus?',
    texts: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu viverra magna. Vestibulum dictum eleifend varius. Maecenas finibus hendrerit magna posuere tincidunt. Mauris vulputate mauris et tellus dictum, quis mattis nulla commodo. Suspendisse consequat justo et urna aliquet tempus. Quisque finibus lectus eu facilisis dapibus. Phasellus mattis ornare tortor, ac viverra tortor vehicula nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed et purus at erat ornare rhoncus at faucibus nibh. Nulla sed tempus lorem. In ac vulputate sem. Vivamus id accumsan nulla. Sed tincidunt, dui quis fringilla consectetur, sapien augue placerat eros, sit amet dapibus magna lacus vitae ante. Donec et libero ac ligula sollicitudin facilisis. Aenean tempor vulputate ante sed elementum.',
      'Vestibulum eget nulla a tellus iaculis lobortis. Cras commodo odio sit amet laoreet viverra. Suspendisse elementum erat at tortor sagittis rhoncus. Quisque rutrum pulvinar felis id elementum. Sed eleifend quam sed orci tempor rutrum. Ut eu luctus augue, at feugiat sapien. Nullam dignissim massa lorem, eget auctor eros ornare in. Sed nibh purus, ultrices eget condimentum nec, egestas in lacus. Aliquam sagittis, dolor et elementum mollis, tortor felis malesuada nisi, nec fringilla sapien neque ac elit. Donec tempus eu enim sodales cursus. Morbi vel turpis vitae purus imperdiet efficitur. Donec dolor metus, mattis eu ipsum ut, ultrices dapibus metus. Curabitur ex magna, bibendum eu fermentum eget, imperdiet quis quam.',
      'Aenean at placerat lacus, ac porttitor arcu. Morbi rhoncus velit vitae felis sodales mattis. Aenean interdum maximus tortor, id faucibus enim laoreet a. Nunc tincidunt venenatis ligula, et lacinia nisl tempor non. Mauris finibus congue nulla mattis dictum. In quis purus quis metus semper interdum. Suspendisse potenti. Donec lectus nisi, dignissim ac hendrerit vitae, elementum quis ligula. Fusce porta non ipsum non tincidunt. Nulla lobortis condimentum eros, eu dapibus tellus bibendum ac. Sed odio est, suscipit eu pretium ut, viverra at dui.',
    ],
    accept_button: 'SI, QUIERO SER MIEMBRO',
    deny_button: 'NO POR AHORA',
  },
  buttons: {
    accept: 'ACEPTAR',
    add: 'AÑADIR',
    confirm: 'CONFIRMAR',
    goToHome: 'IR A LA HOME',
    next: 'SIGUIENTE',
    ready: 'LISTO',
    seeMore: 'Ver más',
    send: 'ENVIAR',
    skip: 'SALTAR',
    tryAgain: 'VOLVER A INTENTARLO',
  },
  choose_profile: {
    person: 'Usuario individual',
    company: 'Empresa',
  },
  confirmation: {
    beware: {
      title: '¡Ojo!',
      texts: [
        'Hemos enviado un correo a tu dirección de email.',
        'Revísalo para continuar el proceso y activar tu cuenta.',
      ],
      button: 'VOLVER AL INICIO',
      footer:
        'Si tienes algún problema, contacta en info@utopicus.es o el 666 666 666',
    },
    call: {
      title: '¡Gracias!',
      texts: [
        '¡Atento a tu móvil!',
        'Nos pondremos en contacto contigo vía móvil de la manera más breve posible.',
      ],
      button: 'VOLVER AL INICIO',
      footer:
        'Si tienes algún problema, contacta en info@utopicus.es o el 666 666 666',
    },
    error: {
      title: '¡Oops!',
      texts: {
        0: [
          'Ha ocurrido algo y no se ha podido completar el registro.',
          'Inténtalo de nuevo en unos minutos. Si el sistema no responde, contacte con la Community Manager de su espacio.',
        ],
        400: [
          'Ha habido un problema con el servicio.',
          'Por favor, inténtalo de nuevo pasados unos minutos.',
        ],
        401: [
          'El usuario o la contraseña son incorrectos.',
          'Inténtalo de nuevo o recupera la contraseña en caso de que no la recuerdes.',
        ],
        404: [
          'No tenemos ningún usuario registrado con este correo.',
          'Inténtalo de nuevo o ponte en contacto con nosotros por los medios indicados más abajo.',
        ],
        601: [
          'Ya existe un usuario registrado con esa cuenta de correo.',
          'Inténtalo de nuevo con otra cuenta de correo o ponte en contacto con nosotros.',
        ],
        network_error: [
          'Parece que hay un problema con la red.',
          'Comprueba que tu móvil tiene conexión a internet. Si el problema persiste, por favor, ponte en contacto con nosotros por los medios indicados más abajo.',
        ],
        terms: [
          'Es necesario que aceptes todos los términos y condiciones antes de continuar con el registro.',
        ],
      },
      button: 'CERRAR',
      footer:
        'Si tienes algún problema, contacta en info@utopicus.es o el 666 666 666',
    },
    recover: {
      title: '¡Genial!',
      texts: [
        'Has cambiado tu contraseña con éxito.',
        'Disfruta a través de la app de todo lo que te ofrece Utopicus.',
      ],
      button: 'VOLVER AL INICIO',
      footer:
        'Si tienes algún problema, contacta en info@utopicus.es o el 666 666 666',
    },
    register_ok: {
      title: '¡Genial!',
      texts: [
        'Gracias por completar el proceso de registro. Confirme la cuenta con el email que le hemos enviado. Revise la carpeta de no deseados.',
      ],
      button: 'VOLVER AL INICIO',
      footer:
        'Si tienes algún problema, contacta en info@utopicus.es o el 666 666 666',
    },
    welcome: {
      title: '¡Bienvenido!',
      texts: [
        'Gracias por registrarte en Utopicus.',
        'Ya puedes disfrutar desde la app de todo lo que te ofrece tu tarifa User.',
      ],
      button: 'IR AL INICIO',
      footer:
        'Si tienes algún problema, contacta en info@utopicus.es o el 666 666 666',
    },
  },
  contact: {
    email: 'info@utopicus.es',
    phone: '666 666 666',
  },
  footer: {
    utopicus: 'Utopicus',
    privacy: 'Privacidad',
  },
  forgot_password: {
    header: 'Recuperar cuenta',
    title: '¿Olvidaste tu contraseña?',
    text:
      'Introduce tu dirección de correo electrónico para que encontremos tu cuenta.',
    input: { placeholder: 'Introducir aquí', label: 'Email' },
    button: 'Siguiente',
    recover_title: 'Establecer tu nueva contraseña',
    recover_text:
      'Introduce tu nueva contraseña. Recuerda que debe contener más de 6 caracteres, utilizando letras, números y símbolos.',
    new_password: { placeholder: 'Nueva contraseña' },
    confirm_password: { placeholder: 'Confirma contraseña' },
    has_password: '¡Ya tengo mi contraseña! ',
  },
  inputs: {
    address: {
      label: 'Dirección',
      placeholder: 'Calle y número',
    },
    birth_date: {
      label: 'Fecha de nacimiento',
      placeholder: '00/00/0000',
    },
    cardholder: {
      label: 'Titular de la tarjeta',
      placeholder: 'Introduzca el titular de la tarjeta',
    },
    card_number: {
      label: 'Número de tarjeta bancaria',
      placeholder: 'Introduzca el número de la tarjeta bancaria',
    },
    cif: {
      label: 'CIF',
      placeholder: 'Incluya los datos correspondientes',
    },
    city: {
      label: 'Ciudad',
      placeholder: 'Introduzca su ciudad',
    },
    company: {
      label: 'Nombre de la empresa',
      placeholder: 'Incluya el nombre de su empresa',
    },
    country: {
      label: 'País',
      placeholder: 'País de residencia',
    },
    cvv: {
      label: 'Código CVV',
      placeholder: 'CVV',
    },
    email: {
      label: 'Email',
      placeholder: 'Introduzca su dirección de email',
    },
    employees_number: {
      label: 'Números de empleados',
      placeholder: 'Seleccione un rango',
    },
    expiration_date: {
      label: 'Fecha de expiración',
      placeholder: ['MM', 'AA'],
    },
    fullName: {
      label: 'Nombre y Apellidos',
      placeholder: 'Incluya su nombre completo',
    },
    gender: {
      label: 'Sexo',
      placeholder: 'Seleccione su sexo',
      options: [
        { label: 'Hombre', value: 'Male' },
        { label: 'Mujer', value: 'Female' },
        { label: 'No binario', value: 'Non-binary' },
      ],
    },
    id: {
      label: 'DNI/NIE/NIF/Pasaporte',
      placeholder: 'Introduzca su documento de identificación',
    },
    name: {
      label: 'Nombre',
      placeholder: 'Introduzca su nombre',
    },
    optionalAddress: {
      label: 'Línea 2 dirección (opcional)',
      placeholder: 'Piso, número',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Introduzca su contraseña',
      message:
        'Debe contener más de 6 caracteres, utilizando letras, números y símbolos',
    },
    repeatPassword: {
      label: 'Repita Contraseña',
      placeholder: 'Introduzca su contraseña',
      message:
        'Debe contener más de 6 caracteres, utilizando letras, números y símbolos',
    },
    phone: {
      label: 'Teléfono',
      placeholder: 'Introduzca su número de teléfono',
    },
    position: {
      label: '¿A qué te dedicas?',
      placeholder: 'Introduce tu puesto de trabajo',
    },
    postal: {
      label: 'Código postal',
      placeholder: 'Introduzca el código postal',
    },
    province: {
      label: 'Provincia',
      placeholder: 'Seleccione provincia',
    },
    reason: {
      label: 'Motivo consulta',
      placeholder: 'Quiero informarme sobre la tarifa Pro Oficina',
    },
    skills: {
      label: 'Habilidades destacadas',
      placeholder: 'Selecciona tus habilidades',
    },
    surname: {
      label: 'Apellidos',
      placeholder: 'Introduzca sus apellidos',
    },
    errors: {
      required: 'Este campo es obligatorio',
      card: {
        not_valid: 'Este no es un número de tarjeta válido',
      },
      cif: {
        not_valid: 'Ese no es un CIF válido',
      },
      cvv: {
        not_valid: 'No válido',
      },
      date: {
        not_valid: 'Esta no es una fecha válida',
      },
      dni: {
        not_valid: 'Ese no es un DNI válido',
      },
      email: {
        not_valid: 'Esta no es una dirección de email válida',
        registered:
          'Ya se ha registrado una cuenta con esta dirección de email',
      },
      expiration: {
        not_valid: 'La fecha de expiración no es válida',
      },
      password: {
        too_short: 'La contraseña introducida es demasiado corta',
        not_valid: 'La contraseña debe contener letras, números y símbolos',
        confirm_password: 'Las dos contraseñas no coinciden',
      },
      phone: {
        too_short: 'El número de teléfono introducido es demasiado corto',
      },
    },
  },
  login: {
    main: 'LOGIN',
    title: 'Log in',
    email: { placeholder: 'Escribe tu email', label: 'Email' },
    password: {
      placeholder: 'Escribe tu contraseña',
      password: 'Contraseña',
      label: 'Contraseña',
    },
    button: 'ENTRAR',
    forgot_password: '¿Olvidaste tu contraseña?',
    forgot_password_rec: 'RECUPERAR',
  },
  onboarding: {
    skip_button: 'SKIP',
    next_button: 'NEXT',
    finish_button: "LET'S START!",
    screens: [
      {
        title: 'ONBOARDING SCREEN 1',
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dolor felis, pretium eget interdum vitae, pharetra in arcu.',
      },
      {
        title: 'ONBOARDING SCREEN 2',
        text:
          'Donec sit amet tortor fringilla, volutpat neque non, tempor nulla. Nulla ultricies vestibulum nunc id mollis.',
      },
      {
        title: 'ONBOARDING SCREEN 3',
        text:
          'Maecenas condimentum aliquam lacus at ultrices. In hac habitasse platea dictumst. Donec suscipit euismod pretium. Duis scelerisque finibus sapien at sagittis.',
      },
    ],
  },
  rates_list: {
    header: 'Selecciona tarifa',
    detail: 'Detalle tarifa',
    cost: 'Coste total',
  },
  signup: {
    welcome: 'Bienvenido/a',
    not_registered: '¿No estás registrado? ',
    linkedin: 'CONTINUAR CON LINKEDIN',
    create_account: 'SIGN UP',
    log_question: '¿Ya tienes una cuenta?',
    log_in: 'LOG IN',
  },
  signup_contact: {
    company: {
      title: 'Datos de empresa',
    },
    office: {
      title: 'Contacta',
      button: 'ENVIAR A LA COMMUNITY',
    },
  },
  signup_form: {
    header: 'Crear cuenta',
    // title: 'Datos personales',
    title: 'Sign Up',
    company_code: 'Si tienes un código de empresa, introdúcelo ',
    insert_here: 'aquí',
    modal_title: 'Código empresa',
    modal_text:
      'Si eres empleado de una empresa que ya pertenece a Utopicus, introduce el código que te hemos facilitado.',
    modal_input: {
      placeholder: 'Introduce tu código de empresa',
    },
    tac: {
      service: [
        'Acepto los',
        'Términos de servicio',
        'y la',
        'Política de privacidad de Utopicus',
      ],
      terms: ['Acepto lorem ipsum dolor sit amet lorem ipsum dolor sit amet'],
    },
  },
  spaces_list: {
    header: 'Selecciona espacio',
    title: 'Espacio Virtual',
    subtitle: 'Espacios en',
    detail: 'Detalle espacio',
  },
}

export default lang
