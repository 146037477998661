const FileUtils = {
    getName: (filename: string) => {
        let name = filename.replace(/\.[^/.]+$/, "")
        return name
    },
    getExtension: (filename: string) => {
        let re = (/(?:\.([^.]+))?$/).exec(filename)
        let extension = re === null ? "" : re[1]
        return extension
    },
    formatBytes(bytes: number, decimals?: number) {
        if(bytes == 0) return '0 Bytes';
        if(!decimals) { decimals = 0 }
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}

export default FileUtils
 