import React, { Component } from "react";
import ModalMessageContainer from "./modal-message-style";
import MainButton from "../../buttons/main-button";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { icons } from "../../../assets/icons";

interface ModalMessageProps extends RouteComponentProps {
  onClose: () => void;
  onAccept: () => void;
  title: string;
  message?: string;
  messages?: any;
  btnText: string;
  className?: string;
  redirect?: string;
  multiMessages?: string[];
}

class ModalMessage extends Component<ModalMessageProps> {
  constructor(props: ModalMessageProps) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("no-scroll");
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  render() {
    const {
      onClose,
      onAccept,
      title,
      message,
      messages,
      btnText,
      className,
      history,
      redirect,
      multiMessages,
    } = this.props;
    return (
      <ModalMessageContainer className={className || ""}>
        <div className={`modal-message-wrapper`}>
          <div className="modal-message-title-container">
            <div className="title">
              <h2>{title}</h2>
            </div>
            <div
              className="btn-close"
              onClick={() => {
                onClose();
                redirect && history.push(redirect);
              }}
            >
              <img src={icons.closeMessageModal} alt="" />
            </div>
          </div>
          {message && (
            <div className="modal-message-text">
              <p>{message}</p>
            </div>
          )}
          {multiMessages && (
            <div className="modal-message-text">
              {multiMessages.map((message: string) => <p>{message}</p>)}
            </div>
          )}
          {messages && (
            <div className="modal-message-text">
              {messages.map((msg: any) => msg)}
            </div>
          )}
          <div className="modal-message-btn">
            <MainButton
              type="blue"
              text={btnText}
              onClick={() => {
                onAccept();
                redirect && history.push(redirect);
              }}
            />
          </div>
        </div>
      </ModalMessageContainer>
    );
  }
}

export default withRouter(ModalMessage);
