import styled from "styled-components";
import { MainButtonContainer } from "../../buttons/main-button/main-button-style";
import { SimpleCheckboxContainer } from "../../form-components/simple-checkbox/simple-checkbox-style";

export const ModalLegalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  background: #ffffffaa;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;

  &.active {
    display: block;
  }

  .modal-legal-content {
    width: 100%;
    max-width: 740px;
    background: white;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 44px 40px;
    max-height: 80vh;
    background: #fff;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-legal-content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;

    &__title {
      font-size: 32px;
      color: #091e42;
      font-weight: 500;
    }

    &__close {
      cursor: pointer;
      display: flex;
    }
  }

  .modal-legal-content-text {
    font-size: 16px;
    line-height: 22px;
    color: #091e42;
    margin-bottom: 42px;
    font-weight: 300;

    p {
      margin-bottom: 32px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    a {
      color: #49abb8;
    }
  }

  .modal-legal-content-check {
    margin-bottom: 60px;
    &__item {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  ${SimpleCheckboxContainer} {
    .checkbox-wrapper input:checked ~ .checkbox-container .checkbox-item-icon {
      background: #091e42;
      border: 1px solid #091e42;
      img {
        max-width: 10px;
      }
    }

    .checkbox-item-icon {
      border: 1px solid #091e42;
    }

    .checkbox-item-text {
      font-size: 16px;
      font-weight: 500;
      color: #091e42;
    }
  }

  .modal-legal-content-button {
    width: 219px;
  }

  ${MainButtonContainer} {
    text-transform: initial;
    &.blue {
      text-transform: initial;
      &.disabled {
        text-transform: initial;
      }
    }
  }

  @media (max-width: 550px) {
    background: #a7a7a7d6;
    .modal-legal-content {
      width: 90%;
      margin: 0 auto;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      padding: 20px 24px;
    }

    .modal-legal-content-top {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
    }

    .modal-legal-content-text {
      font-size: 14px;
    }

    .modal-legal-content-text {
      margin-bottom: 30px;
    }

    .modal-legal-content-check {
      margin-bottom: 40px;
    }

    .modal-legal-content-button {
      width: auto;
      padding: 0;

      ${MainButtonContainer} {
        font-size: 14px;
      }
    }
  }
`;
