import styled from 'styled-components';

export const ButtonLoaderContainer = styled.div`
width: 100%;
height: 52px;
font-size: 16px;
background: #004F8B; 
border-radius: 4px;
display: flex;
justify-content: center; 
align-items: center;
cursor: pointer;
transition: all 0.3s ease-in-out;

.button-loader-end {
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 4px;
  }
}

&.edit {
  background: #49ABB8;
  color: white;
}
&.save {
  background: #004F8B;;
  color: white;
}
&.loading {
  background: #004F8B;;
}
&.succes {
  background: white;
  color: #00B98C;
  font-size: 14px;
}
&.error {
  background: white;
  color:  #FF4040;
  font-size: 14px;
}



.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  /* margin: 6px; */
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



`