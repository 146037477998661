import styled from "styled-components";
import { InputStandardContainer } from "../../components/form-components/input-standard/input-standard-style";
import { colors } from "../../assets/colors";

export const LoginContainer = styled.div`
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;
  overflow: hidden;

  .login-background {
    position: absolute;
    bottom: -200px;
    left: 0px;
    width: 100%;
    z-index: -1;
  }

  .login-header {
    margin-left: 10%;
    margin-top: 6%;
    margin-bottom: 100px;
    &__logo {
      width: 240px;
      margin-bottom: 24px;
    }
    &__subtitle {
      font-family: "museo sans";
      line-height: 38px;
      font-size: 32px;
      color: ${colors["SC-001-100"]};
      margin-bottom: 2px;
    }
    &__title {
      font-family: "museo sans";
      line-height: 48px;
      font-size: 40px;
      color: ${colors["SC-001-100"]};
    }
  }

  .login-form {
    width: 380px;
    min-width: 380px;
    background: #fff;
    padding: 62px 30px 42px;
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 9;
    background: white;
    margin-left: 10%;

    .error-login {
      margin-bottom: 15px;
    }
  }

  .login-logo-asisa-reembolso {
    margin-bottom: 22px;
    img {
      max-width: 200px;
    }
  }

  ${InputStandardContainer} {
    .input-s-title {
      color: #80a7c5;
      text-transform: lowercase;
    }

    .input-icon {
      top: 8px;
    }

    .input-main {
      border-bottom: 1px solid #004f8b;

      input {
        color: #004f8b;
        font-weight: 700;
        font-size: 16px;
        padding: 14px 0 0;

        &::placeholder {
          color: #004f8b;
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
  }

  .login-form-button {
    margin-bottom: 28px;
  }

  .login-form-forgot {
    font-family: "museo sans";
    font-weight: 500;
    font-size: 14px;
    line-height: 1em;
    color: #80a7c5;
    text-align: right;

    p {
      display: inline-block;
      a {
        display: inline-block;
        margin-left: 4px;
        font-weight: 700;
      }
    }

    a {
      color: #3372a2;
    }
  }

  /* INTRO  */

  .login-intro {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 102vh;
    visibility: hidden;
    opacity: 0;
    transition: all 1s ease-in-out;
    background: white;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-header {
      margin: 0;
    }
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  .login-intro-background {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
  }



  @media (max-width: 1300px) {
    .login-title-text {
      font-size: 68px;
      line-height: 82px;
    }

    .login-main {
      padding-right: 100px;
    }

    .login-upper-wave {
      max-width: 560px;
    }

    .login-bottom-wave {
      max-width: 960px;
    }
  }

  @media (max-height: 620px) {
    .login-main {
      margin-top: 50px;
    }

    .login-header {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 550px) {
    padding: 62px 24px 200px;
    overflow: initial;

    .login-header {
      margin: 0;
      padding: 0;
      margin-bottom: 54px;

      &__logo {
        margin-bottom: 24px;
        img {
          max-width: 100px;
        }
      }

      &__title {
        font-size: 32px;
        line-height: 1em;
      }

      &__subtitle {
        font-size: 18px;
        line-height: 1em;
        margin-bottom: 8px;
      }
    }

    .login-form {
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: none;
      min-width: 0;
      padding: 32px 16px;
    }

    .login-background {
      overflow: hidden;
      bottom: 0;
      img {
        transform: translateY(50px);
      }
    }

    .login-intro {
      height: 100vh;

      .login-header {
        &__logo {
          margin-bottom: 40px;
          img {
            max-width: 176px;
          }
        }
        &__subtitle {
          line-height: 1em;
          font-size: 24px;
        }
        &__title {
          line-height: 1em;
          font-size: 42px;
        }
      }
    }
  }
`;
