import { SERVICES_URL, SERVICES_KEY } from "../../env";
import axios from "axios";

export interface GetRequestMessagesParams {
  id: string;
}

export interface IMessage {
  id: number;
  createdAt: string;
  updatedAt: string;
  body: string;
  isViewed: boolean;
  type: string;
  child: any;
  fileId?: any;
  agent: {
    id: number;
    createdAt: string;
    updatedAt: string;
    firstname: string;
    lastname: string;
  };
  children: any;
}

export interface GetRequestMessagesResponse {
  requestMessages: IMessage[];
}

export const getRequestMessages = (params: GetRequestMessagesParams) => {
  const endpoint = `health/accidents/${params.id}/messages`;

  return axios({
    method: "GET",
    url: `${SERVICES_URL}${endpoint}`,
    data: {
      id: params.id,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'api-version': "v1",
      'ocp-apim-subscription-key': SERVICES_KEY,

      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  });
};
