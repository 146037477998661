export function isValidCard(text: string) {
  if (!text || text.length < 18) return false;

  const number = text.split(" ").join("");

  if (number.length < 15 || number.length > 16) return false;

  if (number.length === 15 && number.substr(0, 2) !== "34" && number.substr(0, 2) !== "37") return false;

  if (number.length === 16) {
    const firstDigits = parseInt(number.substr(0, 2), 10);

    if (number.substr(0, 1) !== "4" && (firstDigits < 51 || firstDigits > 55)) return false;
  }

  return number.match(/[0-9]/);
}

export function isValidCif(text: string) {
  if (!text || text.length !== 9) return false;

  const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "N", "P", "Q", "R", "S", "U", "V", "W"];
  const letter = text.substr(0, 1);
  const province = text.substr(1, 2);
  const control = text.substr(text.length - 1);

  if (letters.indexOf(letter) === -1) return false;

  if ((["P", "Q", "R", "S", "W"].indexOf(letter) !== -1 || province === "00") && !control.match(/[A-Z]/)) return false;

  if (["A", "B", "E", "H"].indexOf(letter) !== -1 && !control.match(/[0-9]/)) return false;

  const central = text.substr(1, 7);

  if (Number.isNaN(parseInt(central, 10))) return false;

  let sum = 0;

  central.split("").forEach((value, index) => {
    if ((index + 1) % 2 === 0) {
      sum += parseInt(value, 10);
    } else {
      let doubleValue = parseInt(value, 10) * 2;
      if (doubleValue > 9) doubleValue -= 9;
      sum += doubleValue;
    }
  });

  const units = sum % 10;
  const digit = units === 0 ? 0 : 10 - units;
  const relation = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];

  if (control !== digit.toString() && control !== relation[digit]) return false;

  return true;
}

export function isValidDate(text: string) {
  const ciphers = text.split("/");

  // Checks if day, month and year are provided
  if (ciphers.length !== 3) return false;

  // Checks if all data has the appropiate length
  if (ciphers[0].length !== 2 || ciphers[1].length !== 2 || ciphers[2].length !== 4) return false;

  const day = parseInt(ciphers[0], 10);
  const month = parseInt(ciphers[1], 10);
  const year = parseInt(ciphers[2], 10);
  const months30 = [4, 6, 9, 11];
  const months31 = [1, 3, 5, 7, 8, 10, 12];

  if (month > 12) return false;

  // Checks the number of days for given month
  if (months30.indexOf(month) !== -1 && day > 30) return false;
  if (months31.indexOf(month) !== -1 && day > 31) return false;
  if (month === 2 && year % 4 === 0 && day > 29) return false;
  if (month === 2 && year % 4 !== 0 && day > 28) return false;

  return true;
}

export function isValidDni(text: string) {
  if (text.length < 8) return false;

  const letter = text.substr(text.length - 1);

  if (!letter.match(/[A-Z]/)) return false;

  const cipher = parseInt(text.split(letter)[0], 10);
  const index = cipher % 23;
  const lettersArray = "TRWAGMYFPDXBNJZSQVHLCKET".split("");

  if (letter !== lettersArray[index]) return false;

  return true;
}

export function isValidNIE(text: string) {
  const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
  const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
  const str = text.toString().toUpperCase();

  if (!nieRexp.test(str)) return false;

  const nie = str
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2');

  const letter = str.substr(-1);
  const charIndex = parseInt(nie.substr(0, 8)) % 23;

  if (validChars.charAt(charIndex) === letter) return true;

  return false;
}

export function isValidEmail(text: string) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(text);
}

export function isValidPassword(text: string) {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[=@$!%*#?&_.;-])[A-Za-z\d=@$!%*#?&_.;-]{6,}$/;

  return text.length > 5; //regex.test(text)
}

export const checkDocumentFormat = (text: string): string => {
  const invalidChars = checkInvalidChars(text);
  if (invalidChars) {
    return invalidChars;
  } else {
    return checkSpaces(text);
  }
}

const checkInvalidChars = (text: string): string => {
  const regex = /^[a-zA-Z0-9]+$/;
  if (!regex.test(text)) {
    return "Solo se permiten dígitos y letras"
  }
}

const checkSpaces = (text: string): string => {
  if (text.length < 9) {
    return "Longitud inferior a 9 caracteres";
  } else if (text.length > 9) {
    return "Longitud superior a 9 caracteres";
  }
}

export const isValidDocumentID = (text: string, elemID: string) => {
  if (["NIF", "NIE", "DNI"].includes(elemID)) {
    const validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRexp = /^[XYZ012][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str = text.toString().toUpperCase();
    if (elemID === "NIF" && !nifRexp.test(str)) return false;
    if (elemID === "NIE" && !nieRexp.test(str)) return false;
    const nie = str
      .replace(/^[X]/, "0")
      .replace(/^[Y]/, "1")
      .replace(/^[Z]/, "2");
    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8)) % 23;
    if (validChars.charAt(charIndex) === letter) return true;
    return false;
  }
  if (elemID === "PASP") {
    const pass_esp = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
    const pass_world = /^[A-Za-z0-9]{9}$/i;
    return pass_esp.test(text) || pass_world.test(text);
  }
  if (elemID === "NIFP") {
    return true;
  }
  return false;
};

// VALID FILE SIZE
export const isValidSize = (file: File) => {
  if (!file.type) return false;
  if (file.size > 7000000) {
    return false;
  } else return true;
};

// VALID FILE NAME
export const isValidFileName = (fileName: string) => {
  if (!fileName) return false;
  const name = fileName.substring(0, fileName.lastIndexOf("."));
    const regex = /¡| |\+|¢|£|€|¤|¥|§|¨|©|ª|«|¬|®|¯|°|±|´|µ|¶|·|¸|º|»|¿|À|Á|Â|Ã|Ä|Å|Æ|Ç|È|É|Ê|Ë|Ì|Í|Î|Ï|Ñ|Ò|Ó|Ô|Õ|Ö|Ø|Ù|Ú|Û|Ü|ß|à|á|â|ã|ä|å|æ|ç|è|é|ê|ë|ì|í|î|ï|ñ|ò|ó|ô|õ|ö|÷|ø|ù|ú|û|ü|ÿ|₫|á|é|í|ó|ú|Á|É|Í|Ó|Ú/g;
  return  !regex.test(name || fileName);
};
