import styled from "styled-components";

export const LegalContainer = styled.div`
  .legal-title {
    font-size: 44px;
    line-height: 50px;
    color: #091e42;
    margin-bottom: 62px;
  }
  .go-back {
    margin-bottom: 20px;
    img {
      width: 40px;
    }
  }
  .legal-subtitle {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: #091e42;
    margin-bottom: 28px;
    display: flex;
    &__number {
      margin-right: 10px;
    }
  }
  .legal-text {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    color: #6b778c;
    p {
      margin-bottom: 28px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: 650px) {
    .legal-title {
      font-size: 26px;
      line-height: 1.5em;
      margin-bottom: 32px;
    }
    .legal-subtitle {
      font-size: 18px;
      line-height: 1.25em;
      margin-bottom: 18px;
      &__number {
        font-size: 14px;
        padding-top: 1px;
      }
    }
    .legal-text {
      line-height: 1.5em;
      font-size: 14px;
      p {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
