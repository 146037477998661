import React, { Component } from "react";
import IStoreState from "./redux/store/IStoreState";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoute from "./components/private-route";
import NotFound from "./components/util-components/not-found";
import Services from "./utils/services";
import "./main.scss";

import Header from "./components/headers/header";
import Login from "./screens/login";
import Home from "./screens/home/home";
import RequestDetailHealth from "./screens/request-detail/health";
import RequestDetailDental from "./screens/request-detail/dental";
import CreateRequest from "./screens/create-request";
import Legal from "./screens/legal/index";
import Access from "./screens/accessibility/index";
import {
  setUser,
  setExpiredToken,
  setModalError,
  setNotifications,
} from "./redux/actions";
import Loader from "./components/util-components/loader";
import Footer from "./components/footers/footer/footer";
import CookiesPolitics from "./screens/cookies-politics/cookies-politics";
import PrivatePolitics from "./screens/private-politics/private-politics";
import ModalLegal from "./components/modals/modal-legal";
import DetailRequest from "./screens/detail-request/detail-request";
import ModalMessage from "./components/modals/modal-message/modal-message";
import { authValidateTokenRequest } from "./services/auth/auth-validate-token";
import Cookies from "js-cookie";
import StartRequestModal from "./components/modals/request-modal/request-modal-layout";
import ModalCookies from "./components/modals/modal-cookies/ModalCookies";
import { MODAL_COOKIES_TEXT } from "./constants/data";
import { store } from "./redux/store";
import { isoStringToStringDate } from "./utils/date";
import { RequestNotification } from "./components/asides/aside-notification/aside-notification-functional";
import axios from "axios";
import { SERVICES_KEY, SERVICES_URL } from "./env";
import { GetRequestMessagesParams } from "./services/messages/get-request-messages";

class App extends Component<any, any> {
  state = {
    logged: false,
    errorGetUser: false,
    startRequestModalOpened: false,
    errorModal: false,
    totalPendingMessages: 0
  };
  componentDidMount() {
    this.checkCookieLogin();
  }

  changeErrorModal = (show: boolean) => {
    let { errorModal } = this.state;
    errorModal = show;
    this.setState({ errorModal });
  };

  validToken = async () => {
    try {
      const loginCookieRequest = await authValidateTokenRequest();
      this.getUser(localStorage.userID);
      const user = loginCookieRequest.data;
    } catch (err) {
      localStorage.clear();
      this.checkCookieLogin();
      this.setState({ logged: false, loading: false });
      const formatError = Services.errorHandler(err);
      console.error(formatError);
    }
  };

  checkCookieLogin = () => {
    Services.post({
      loader: true,
      endpoint: `auth/loginCookie`,
      withCredential: true,
      then: (response: any) => {
        response.data = response.data || {};
        response.data.insured = response.data.insured || {};
        localStorage.setItem("accessToken", response.data.token);
        const user = `${response.data.insured.firstname} ${
          response.data.insured.lastname
        }`;
        const userID = response.data.id;
        localStorage.setItem("userID", userID);
        this.getUser(userID);
        Services.setValue(setExpiredToken(false));
      },
      catch: (err: any) => {
        if (localStorage.accessToken && localStorage.userID) {
          this.validToken();
        }
        if (err && err.response && err.response.status) {
          console.error(err.response.status);
        }
      },
    });
  };

  getUser = (id: number) => {
    Services.get({
      loader: true,
      endpoint: `users/${id}`,
      then: (response: any) => {
        Services.get({
          loader: true,
          endpoint: `insureds/${response.data.insured.id}`,
          then: async (res: any) => {
            const policies = await this.getPolicies(response.data.insured.id);
            if (policies instanceof Array) {
              for (let policy of policies) {
                const insured = policy.policyInsureds.find(
                  (ins) => ins.insuredId === res.data.id
                );
                if (insured && insured.isPolicyHolder) {
                  policy.insureds = await this.getInsureds(policy.id);
                }
              }
            }
            res.data.policies = policies;
            this.props.dispatch(
              setUser({ ...response.data, insured: { ...res.data } })
            );

            this.getNotifications(response.data.insured.id);
            // this.getRequestMessages(response.data.insured.id)

            Services.setValue(setExpiredToken(false));
            this.setState({ logged: true });
          },
          catch: () => {
            this.setState({ errorGetUser: true });
          },
        });
      },
      catch: (error: any) => {
        this.setState({ errorGetUser: true });
      },
    });
  };

  getRequestMessages = (id) => {
    let endpoint = `health/accidents/${id}/total-messages`;

    Services.get({
      loader: true,
      endpoint,
      then: (response: any) => {
        this.setState({totalPendingMessages: response.data.filter(message => message.child.length === 0).length , errorModal: true})
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  };

  getPolicies = (id) => {
    return new Promise((resolve, reject) => {
      Services.get({
        loader: true,
        endpoint: `insureds/${id}/policies`,
        then: (res) => {
          resolve(res.data.items);
        },
        catch: (err) => {
          //localStorage.clear();
          console.error(err);
        },
      });
    });
  };

  getNotifications(insuredId) {
    let endpoint = `insureds/${insuredId}/health/notifications`;

    Services.get({
      loader: true,
      endpoint,
      then: (response: any) => {
        let notifications: Array<any> = response.data.map((item: any) => ({
          id: item.notificationId,
          creation_date: isoStringToStringDate(item.date, "/"),
          type: item.notificationType,
          description: item.notificationContent,
          request_no: item.accidentId,
          viewed: item.viewed,
          messageId: item.message || "",
        }));
        store.dispatch(setNotifications(notifications));
      },
      catch: (err: any) => {
        console.log(err);
      },
    });
  }

  logout:any = () => {
    Services.get({
      loader: true,
      endpoint: `auth/logout`,
      then: () => {
        this.props.dispatch(setNotifications([]));
        this.setState({ logged: false });
      },
      catch: (err) => {
        this.setState({ logged: false });
      },
    });
  };

  getInsureds = (id) => {
    return new Promise((resolve, reject) => {
      Services.get({
        loader: true,
        endpoint: `policies/${id}/insureds`,
        then: (response: any) => {
          const res =
            response && response.data && response.data.items
              ? response.data.items
              : [];
          resolve(res);
        },
        catch: (err: any) => {
          //localStorage.clear();
          console.error(err);
        },
      });
    });
  };

  render() {
    const { logged, startRequestModalOpened, errorModal, totalPendingMessages } = this.state;
    const { loading, modalError } = this.props;

    return !logged || this.props.expiredToken ? (
      <Router>
        <>
          <main className="main-app">
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Login getUser={this.getUser} />}
              />
              <Route
                path="/avisos-legales"
                render={() => <Legal goBackButton />}
              />
              <Route
                path="/accesibilidad"
                render={() => <Access goBackButton />}
              />
              <Route
                path="/politicas-cookies"
                render={() => <CookiesPolitics goBackButton />}
              />
              <Route
                path="/politicas-privacidad"
                render={() => <PrivatePolitics goBackButton />}
              />

              {/* <Route path="/not-found" component={NotFound} /> */}
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </main>
          {loading && <Loader />}
          {/* <ModalCookies {...MODAL_COOKIES_TEXT} /> */}
          <Footer secondaryColor={window.location.pathname === "/"} />
          {modalError && modalError.show && (
            <ModalMessage
              className={modalError.type || ""}
              onClose={() =>
                Services.setValue(
                  setModalError({
                    show: false,
                    message: "",
                    redirect: "",
                    title: "",
                    type: "",
                    messages: null,
                  })
                )
              }
              onAccept={() =>
                Services.setValue(
                  setModalError({
                    show: false,
                    message: "",
                    redirect: "",
                    title: "",
                    type: "",
                    messages: null,
                  })
                )
              }
              title={modalError.title}
              message={modalError.message}
              btnText={"Cerrar"}
              redirect={modalError.redirect}
              multiMessages={modalError.messages}
            />
          )}
          {(errorModal && (totalPendingMessages > 0)) && (
            <ModalMessage
              className={modalError.type || ""}
              onAccept={() => this.changeErrorModal(false)}
              onClose={() => this.changeErrorModal(false)}
              title={"ALERTA"}
              message={`Hay ${totalPendingMessages} mensaje(s) pendientes sin responder`}
              btnText={"Aceptar"}
            />
            )}
        </>
      </Router>
    ) : (
      <>
        <Router>
          <div className="router-container">
            <Header
              logout={this.logout}
              onNewRequestClick={() =>
                this.setState({ startRequestModalOpened: true })
              }
            />
            {startRequestModalOpened && (
              <StartRequestModal
                className="request-modal"
                title="Iniciar solicitud"
                text="Conserve por favor los originales, podemos necesitarlos en algún momento."
                buttonText="Acepto"
                onClickAccept={() => {}}
                onCloseModal={() => {
                  this.setState({ startRequestModalOpened: false });
                }}
              />
            )}
            <main className="main-app">
              <Switch>
                <Route
                  exact
                  path="/"
                  component={Home}
                  expiredToken={this.props.expiredToken}
                />
                <PrivateRoute
                  path="/home"
                  component={Home}
                  expiredToken={this.props.expiredToken}
                />
                {/* <PrivateRoute
                  path="/solicitud-detalle"
                  component={DetailRequest}
                  expiredToken={this.props.expiredToken}
                /> */}
                <PrivateRoute
                  path="/solicitud-detalle/:id"
                  component={DetailRequest}
                  expiredToken={this.props.expiredToken}
                />
                <Route path="/crear-solicitud" component={CreateRequest} />
                <Route path="/avisos-legales" component={Legal} />
                <Route path="/accesibilidad" component={Access} />
                <Route path="/politicas-cookies" component={CookiesPolitics} />
                <Route
                  path="/politicas-privacidad"
                  component={PrivatePolitics}
                />
                <Route path="/" component={NotFound} />
              </Switch>
            </main>
            {loading && <Loader />}
            <ModalCookies {...MODAL_COOKIES_TEXT} />
            <Footer />
            {modalError && modalError.show && (
              <ModalMessage
                className={modalError.type || ""}
                onClose={() =>
                  Services.setValue(
                    setModalError({
                      show: false,
                      message: "",
                      redirect: "",
                      title: "",
                      type: "",
                      messages: null,
                    })
                  )
                }
                onAccept={() =>
                  Services.setValue(
                    setModalError({
                      show: false,
                      message: "",
                      redirect: "",
                      title: "",
                      type: "",
                      messages: null,
                    })
                  )
                }
                title={modalError.title}
                message={modalError.message}
                btnText={"Cerrar"}
                redirect={modalError.redirect}
                multiMessages={modalError.messages}
              />
            )}
            {(errorModal && (totalPendingMessages > 0)) && (
              <ModalMessage
                className={modalError.type || ""}
                onAccept={() => this.changeErrorModal(false)}
                onClose={() => this.changeErrorModal(false)}
                title={"ALERTA"}
                message={`Hay ${totalPendingMessages} mensaje(s) pendientes sin responder`}
                btnText={"Aceptar"}
              />
             )}
          </div>
        </Router>
      </>
    );
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    loading: state.loading.value,
    expiredToken: state.expiredToken.value,
    modalError: state.modalError,
    notifications: state.notifications,
  };
}
export default connect<{}, {}, {}, IStoreState>(mapStateToProps)(App);
