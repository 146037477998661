import React from 'react';
import { IPoliticsProps } from '../../constants/data';
import LegalLayout from './legal-layout';

export default class Legal extends React.Component<IPoliticsProps> {
    render() {
        const { goBackButton } = this.props;
        return(
            <LegalLayout goBackButton={goBackButton}/>
        )
    }
}